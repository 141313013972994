import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import BaseDateOfBirthField, {
  DateOfBirthProps as BaseDateOfBirthFieldProps,
} from '@ui/atoms/fields/date-of-birth/DateOfBirth';
import { useTranslation } from 'react-i18next';

type DateOfBirthFieldProps = BaseDateOfBirthFieldProps & {
  control: Control;
};

const DateOfBirthField: FC<DateOfBirthFieldProps> = ({
  name,
  control,
  ...props
}) => {


  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      render={({ field: { value, onChange, name } }) => {
        return (
          <BaseDateOfBirthField
            name={name}
            value={value}
            placeholders={{
              date: t('ui.fields.DD'),
              month: t('ui.fields.MM'),
              year: t('ui.fields.YYYY'),
            }}
            onChange={onChange}
            {...props}
          />
        );
      }}
      name={name}
    />
  );
};

export default DateOfBirthField;
