import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Control, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isNil } from 'lodash';

import Email, { Attachment } from '@/packages/back-end/mssante/email';
import Patient, {
  GET_COLLECTION as GET_PATIENTS_COLLECTION,
  buildOption as buildPatientOption,
} from '@/packages/back-end/patient';
import DocumentType, {
  GET_COLLECTION as GET_TYPES_COLLECTION,
} from '@/packages/back-end/document-type';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import { parseSimpleSelectorValue } from '@/utils/form';
import { OptionType } from '@ui/types';
import useToast from '@/utils/hooks/useToast';
import TextField from '@ui/atoms/fields/text/TextField';
import Button from '@ui/atoms/buttons/button/Button';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import SimpleSelector from '@ui/organisms/selectors/simple/SimpleSelector';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import AddPatientForm from '@/app/structure/menu/AddPatientDrawer/AddPatientForm';

import { getError } from '@/components/form/utils';
import Drawer from '@/components/drawer/Drawer';
import DateField from '@/components/form/fields/date/DateField';
import ApiSelectorField from '@/components/form/fields/api-selector';
import ConstantSelector from '@/components/form/fields/constant-selector';
import useSidebarWidth from '@/components/dashboard-staff/AiDrawer/useSidebarWidth';
import ElementSelector from '@/app/structure/page/mssante/attachment-template/ElementSelector';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import User from '@/packages/back-end/user';
import Label from '@ui/atoms/labels/label/Label';
import Sidebar from '@/app/structure/page/mssante/attachment-template/Sidebar';
import i18n from '@/i18n';

type FormDataType = {
  title: string;
  createdDate: string;
  type: OptionType | null;
  visibility: OptionType | null;
  patient: OptionType | null;
};

type FooterProps = {
  saving: boolean;
  onClose?: () => void;
};

const Footer: FC<FooterProps> = ({ saving, onClose }) => {
  const { t } = useTranslation('ms_sante', {
    keyPrefix: 'classify_document_drawer',
  });

  return (
    <ContainerFooter
      className='px-4 sm:pr-8 sm:pl-8'
      size='small'
    >
      <Button
        className='flex-grow'
        disabled={saving}
        type='button'
        onClick={onClose}
      >
        <Trans i18nKey='ui.button.cancel' />
      </Button>
      <Button
        className='flex-grow'
        saving={saving}
        type='submit'
        color='primary'
      >
        {t('submit_button')}
      </Button>
    </ContainerFooter>
  );
};

type ClassifyAttachmentDrawerProps = {
  email?: Email;
  impersonated?: string | number;
  attachment: Attachment;
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
  onClose: () => void;
};

const parseData = (data: FormDataType) => {
  return {
    ...data,
    patient: parseSimpleSelectorValue(data.patient),
    visibility: parseSimpleSelectorValue(data.visibility, true),
    type: parseSimpleSelectorValue(data.type),
  };
};

const getDefaultValue = (attachment: Attachment, email?: Email) => {
  return {
    message: email?.id,
    attachment: attachment.id,
    title: attachment.attributes.name,
    createdDate: email?.attributes.createdDate,
  };
};

const ClassifyAttachmentDrawer: FC<ClassifyAttachmentDrawerProps> = ({
  email,
  attachment: attachmentProp,
  attachments: attachmentsProp,
  setAttachments,
  impersonated,
  onClose,
}) => {
  const { addToast } = useToast();
  const { t } = useTranslation('ms_sante', {
    keyPrefix: 'classify_document_drawer',
  });
  const { t: tError } = useTranslation('ms_sante', {
    keyPrefix: 'form_errors',
  });

  const [localAttachments,setLocalAttachments] = useState<Attachment[]>(attachmentsProp);

  const [showPatientField,setShowPatientField]
    = useState(!attachmentProp.relationships?.matchingPatients?.data);

  const { loading: loadingDocumentType, data: documentTypeData } =
    useJsonAPIRequest<{
      data: DocumentType[];
    }>({
      url: GET_TYPES_COLLECTION(),
      method: 'GET',
    });

  const { fetchData: classifyDocument, loading: saving } = useJsonAPIRequest<{
    data: Document;
  }>({
    url: buildUrl('documents', 'ms_sante',{
      impersonated
    }),
    skip: true,
    method: 'post',
  });

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    register,
    handleSubmit,
    resetField,
    reset,
  } = useForm<FormDataType>({
    defaultValues: getDefaultValue(attachmentProp, email),
  });

  const documentType = watch('type');
  const visibility = watch('visibility');
  const patient = watch('patient');

  const keepRef = useRef({ visibility, patient, attachments: attachmentsProp });
  keepRef.current.visibility = visibility;
  keepRef.current.patient = patient;

  const [isAddingPatient, setAddingPatient] = useState(false);
  const [attachment, setAttachment] = useState(attachmentProp);

  const { sideBarWidth } = useSidebarWidth(true);

  const documentTypeOptions = useMemo<OptionType[]>(() => {
    return (documentTypeData?.data || []).map((item) => ({
      value: item.id,
      label: item.attributes.name,
      data: item,
    }));
  }, [documentTypeData]);

  const handleAddedPatient = (patient: Patient | null) => {
    setAddingPatient(false);
    const patientUser = patient?.relationships?.user?.data;
    if (patientUser) {
      setValue('patient', {
        value: patientUser.id,
        label: (patientUser.attributes?.fullName as string) || '',
        data: patientUser,
      });
    }
  };

  const onSubmit = useCallback(
    async (data) => {
      try {
        const response = await classifyDocument(parseData(data), {
          throw: true,
        });

        const attachments = keepRef.current.attachments.map((item) => {
          if (item.id !== attachment.id) {
            return item;
          }
          return {
            ...item,
            relationships: { ...item.relationships, document: response },
          };
        });

        keepRef.current.attachments = attachments as Attachment[];
        setAttachments(keepRef.current.attachments);
        setLocalAttachments(keepRef.current.attachments);

        const unclassifiedAttachments = keepRef.current.attachments.filter(
          (item) => !item.relationships?.document
        );
        const attachmentIndex = unclassifiedAttachments.findIndex(
          ({ id }) => id === attachment.id
        );
        const nextAttachment =
          unclassifiedAttachments[attachmentIndex + 1] ||
          unclassifiedAttachments[0];

        if (nextAttachment) {
          setAttachment(nextAttachment);
          addToast('success', t('classify_document_success'));
        } else {
          addToast('success', t('classify_all_documents_success'));
          onClose();
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [classifyDocument, setAttachments, attachment.id, addToast, t, onClose]
  );

  useEffect(() => {
    reset(getDefaultValue(attachment, email));
    setValue('patient', keepRef.current.patient || null);
  }, [attachment, email, reset, setValue]);

  useEffect(() => {
    const documentTypeData = documentType?.data as DocumentType | undefined;
    if (isNil(keepRef.current.visibility) && documentTypeData) {
      setValue('visibility', {
        value: String(documentTypeData?.attributes?.defaultVisibility),
        label: '',
      });
    }
  }, [documentType, setValue]);


  const unclassifiedAttachments = localAttachments.filter(
    (item) => !item.relationships?.document
  );
  const attachmentIndex = unclassifiedAttachments.findIndex(
    ({ id }) => id === attachment.id
  );
  const nextAttachment =
    unclassifiedAttachments[attachmentIndex + 1] ||
    unclassifiedAttachments[0];

  const previousAttachment =
    unclassifiedAttachments[attachmentIndex - 1] ||
    unclassifiedAttachments[unclassifiedAttachments.length - 1];


  const onNext = nextAttachment ? () => {
    setAttachment(nextAttachment);
  } : undefined;

  const onPrevious = previousAttachment ? () => {
    setAttachment(previousAttachment);
  } : undefined;

  return (
    <Drawer
      title={t(localAttachments.length <= 1 ? 'title_single' : 'title',{
        total: localAttachments.length,
        count: attachmentIndex+1
      })}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      sidebarWidth={sideBarWidth}
      sidebar={<Sidebar
        onPrevious={onPrevious}
        onNext={onNext}
        attachment={attachment}
        email={email}
      />}
      footer={
        !isAddingPatient && (
          <Footer
            saving={saving}
            onClose={onClose}
          />
        )
      }
    >
      <div
        className={clsx(
          'flex flex-col gap-6 p-4 sm:p-8',
          isAddingPatient && 'hidden'
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          {...register('title')}
          label={t('fields.name')}
          placeholder={t('fields.name_placeholder')}
        />

        <DateField
          label={t('fields.created_date')}
          name='createdDate'
          control={control as unknown as Control}
        />

        <SimpleSelector
          required
          loadingData={loadingDocumentType}
          label={t('fields.type')}
          placeholder={t('fields.type_placeholder')}
          name='type'
          error={getError('type', errors, tError)}
          options={documentTypeOptions}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          control={control as any}
        />

        <ConstantSelector
          label={t('fields.visibility')}
          placeholder={t('fields.visibility_placeholder')}
          name='visibility'
          property='visibility'
          entity='document'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          control={control as unknown as Control}
        />

        {!showPatientField && (<div className='flex flex-col gap-2' >

          <Label>{t('patients_found')}</Label>

          {attachmentProp.relationships?.matchingPatients?.data.map((p) => {

            const pa = p as Patient;
            const user = pa.relationships.user.data as User;

            const date = user.attributes.dateOfBirth ?
              new Date(user.attributes.dateOfBirth).toLocaleDateString(i18n.language) : '';

            return (<ElementSelector
              title={user.attributes.fullName}
              selected={patient?.value == pa.id}
              key={pa.id}
              {...getAvatarProps({ user: user.attributes, colorLength: 10 })}
              subtitle={date}
              onClick={() => {
                setValue('patient',buildPatientOption(pa));
              }}
            />);
          })}

          <Button onClick={() => setShowPatientField(true)} >{t('no_patient_match')}</Button>

        </div>)}

        {showPatientField && (<ApiSelectorField
          apiSearch
          label={t('fields.patient')}
          placeholder={t('fields.patient_placeholder')}
          name='patient'
          url={GET_PATIENTS_COLLECTION({ include: 'user' })}
          buildOption={buildPatientOption}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          control={control as unknown as Control}
          onClickActionOption={(option, { resetSearch }) => {
            if (option.type === 'action-click') {
              if (option.value === 'create_patient_action') {
                setAddingPatient(true);
              }
              resetSearch();
            }
          }}
        />)}

      </div>

      {isAddingPatient && (
        <AddPatientForm
          isAddFromOutside
          submitTextKey='add_from_ranking_button'
          cancelTextKey='cancel_add_from_ranking_button'
          onClose={() => setAddingPatient(false)}
          onAdded={handleAddedPatient}
        />
      )}
    </Drawer>
  );
};

const useClassifyAttachmentDrawer = ({
  attachments,
  setAttachments,
}: {
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
}) => {
  const { openDrawer, updateData , closeDrawer } = useDrawerManager();

  const { sideBarWidth } = useSidebarWidth(true);

  useEffect(() => {
    updateData({ sidebarWidth: sideBarWidth });
  }, [updateData, sideBarWidth]);


  const onOpen = useCallback(
    ({ attachment, email, impersonated }) => {
      openDrawer({
        content: (
          <ClassifyAttachmentDrawer
            attachment={attachment}
            email={email}
            impersonated={impersonated}
            attachments={attachments}
            setAttachments={setAttachments}
            onClose={closeDrawer}
          />
        ),
        sidebarWidth: sideBarWidth,
        size: 'medium'
      });
    },
    [sideBarWidth,attachments, setAttachments,closeDrawer, openDrawer]
  );

  return { onOpen };
};

export default useClassifyAttachmentDrawer;
