import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import Discussion from '@/packages/back-end/Discussion';
import { buildUrl } from '@/utils/Api';
import { Item } from '@/packages/back-end/jsonapi';
import { useDiscussions } from '@/app/structure/page/chat/ChatContext';
import useToast from '@/utils/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';


type useUpdateDiscussionProps = {
  discussion : Discussion;
  refresh?: () => void;
  isMultipleEdit ?: boolean;
  updateAll?: boolean;
}

const useUpdateDiscussion = ({ discussion, isMultipleEdit, updateAll, refresh } : useUpdateDiscussionProps) => {

  const { addToast } = useToast();

  const { t } = useTranslation('chat');

  const { discussions, setDiscussions } = useDiscussions();

  const { fetchData: updateDiscussion,loading } = useJsonAPIRequest<{
    data: Discussion;
  }>({
    url: isMultipleEdit
      ? buildUrl('discussions/bulk') : buildUrl(discussion?.id as string),
    method: 'PUT',
    skip: true,
  });

  type BodyType = {
    read?: boolean;
    archived?: boolean;
    readForMedicalTeam?: boolean;
  };

  type MultipleUpdateBodyType = {
    data: BodyType;
    ids: string[];
  };


  const actionOnDiscussion = useCallback(async (
    body: MultipleUpdateBodyType | BodyType,
    toastLabel: string
  ) => {

    const loadingState = discussions
      .map((d) => {
        return d.selected ? { ...d, isLoading: true }: d;
      });
    setDiscussions(loadingState);

    if (isMultipleEdit) {
      const selectedIds = discussions
        .filter((d) => d.selected)
        .map((d) => d.id);

      body = {
        data: { ...body },
        ...(updateAll ? { updateAll: true, ids: [] } : { ids: selectedIds }),
      } as MultipleUpdateBodyType;
    }

    const res =
      await updateDiscussion(body,{ throw: true, displayToastOnError: true }) as Item<Discussion> | Item<Discussion>[];

    const response = 'data' in res ? [res.data] : res.map((item) => item.data);

    if((response.length) > 5 && isMultipleEdit && refresh) {
      refresh();
    } else {
      response.forEach((item) => {
        const index: number = discussions.findIndex((d) => d.id === item.id);

        if (index != -1) {
          discussions[index] = {
            ...discussions[index],
            selected: isMultipleEdit ? false : discussions[index].selected,
            attributes: {
              ...discussions[index]?.attributes,
              read: item.attributes.read,
              archived: item.attributes.archived,
            },
          };
        }
      });
      setDiscussions([...discussions]);
    }
    addToast('success', t(isMultipleEdit ? `${toastLabel}_other` : toastLabel));

    return response;

  },[discussions, setDiscussions, isMultipleEdit, updateDiscussion, refresh, addToast, t, updateAll]);


  return {
    loading,
    update: actionOnDiscussion
  };

};

export default useUpdateDiscussion;