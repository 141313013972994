import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MEDIUM_GRAY } from './MessageComposer';
import Dropdown from 'instamed-styleguide/ui/molecules/dropdown/Dropdown';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import { buildUrl } from '@/utils/Api';
import useRequest from '@/services/request/useRequest';
import { isMedicalStaff } from '@/packages/back-end/user';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import storage, { StorageKeys } from '@/services/request/helpers/storage';

const ButtonAction = styled(Dropdown)`
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  padding: 3px;
  border: 0;
  span {
    padding: 0 !important;
  }
`;

interface SettingsButtonProps {
  entity: 'discussion' | 'email';
}

export const SettingsButton:FC<SettingsButtonProps> = ({ entity }) => {
  const { t } = useTranslation('chat');
  const { user } = useRecoilValue(authAtom);

  let storageKey = StorageKeys.DISCUSSIONS__ENTER_TO_SEND;
  if (entity === 'email') {
    storageKey = StorageKeys.EMAIL__ENTER_TO_SEND;
  }
  
  const [enterToSend, setEnterToSendState] =
    useState(storage.getJSON<boolean>(storageKey));

  const setEnterToSend = (value: boolean) => {
    value ? storage.setJSON(storageKey, true) :
      storage.removeItem(storageKey);
    setEnterToSendState(value);
  };

  if (!isMedicalStaff(user)) {
    return <></>;
  }

  return (
    <ButtonAction
      icon='discussionSettings'
      iconSize={17}
      iconColor={enterToSend ? '#333c9d' : MEDIUM_GRAY}
      title={t('message_composer.settings')}
      items={[
        {
          children: t('message_composer.enter_to_send'),
          className: enterToSend ? 'text-[#333c9d]' : '',
          onClick: () => setEnterToSend(true),
        },
        {
          children: t('message_composer.enter_to_newline'),
          className: !enterToSend ? 'text-[#333c9d]' : '',
          onClick: () => setEnterToSend(false),
        },
      ]}
    />
  );
};

interface SignatureButtonProps {
  signature: string | null;
  onChange: (signature: string | null) => void;
}

export const SignatureButton:FC<SignatureButtonProps> = ({ signature, onChange }) => {
  const { t } = useTranslation('chat');
  const { user } = useRecoilValue(authAtom);

  const { fetchData } = useRequest<{ signature: string }>({
    url: buildUrl('settings/discussions'),
    method: 'GET',
    skip: true
  });

  const keepRef = useRef({ onChange });


  const handleSetSignature = useCallback(async () => {
    await fetchData().then((data) => {
      const res = data as { signature: string };
      if (res?.signature) {
        keepRef.current.onChange(res?.signature);
        storage.setJSON(StorageKeys.DISCUSSIONS__ENABLE_SIGNATURE,true);
      } else {
        keepRef.current.onChange(null);
        storage.removeItem(StorageKeys.DISCUSSIONS__ENABLE_SIGNATURE);
      }
    });
  },[fetchData]);

  useEffect(() => {

    const enableSignature = storage.getJSON(StorageKeys.DISCUSSIONS__ENABLE_SIGNATURE);

    if(!enableSignature) {
      return;
    }

    handleSetSignature();

  }, [handleSetSignature]);


  if (!isMedicalStaff(user)) {
    return <></>;
  }

  return (
    <ButtonAction
      icon='signature'
      iconSize={23}
      iconColor={signature ? '#333c9d' : MEDIUM_GRAY}
      title={t('message_composer.signature')}
      items={[
        {
          children: t('message_composer.enable_signature'),
          selected: !!signature,
          onClick: () => handleSetSignature(),
        },
        {
          children: t('message_composer.disable_signature'),
          selected: !signature,
          onClick: () => onChange(null),
        },
      ]}
    />

  );
};


interface AttachDocumentButtonProps {
  isPatient?: boolean;
  hasPatientSelected?: boolean;
  onClickUpload?: () => void;
  onClickSearch?: () => void;
}

export const AttachDocumentButton: FC<AttachDocumentButtonProps> = ({
  isPatient,
  hasPatientSelected,
  onClickUpload,
  onClickSearch,
}) => {
  const { t } = useTranslation('chat');

  return (
    <div
      className='flex justify-between items-center py-2 px-1'
      title={t('message_composer.add_documents')}
      data-testid='attach_document_dropdown'
    >
      <Dropdown
        className='!h-5 !bg-transparent !border-0'
        popperClassName='z-[9999]'
        innerClassName='!px-1.5'
        items={[
          {
            children: t('attachment_dropdown.upload_document'),
            onClick: () => onClickUpload?.(),
          },
          {
            children: t(
              isPatient
                ? 'attachment_dropdown.search_documents'
                : hasPatientSelected ? 'attachment_dropdown.search_patient_own_documents'
                  : 'attachment_dropdown.search_patient_documents'
            ),
            onClick: () => onClickSearch?.(),
            'data-testid': 'attach_document_dropdown_search_document_item'
          },
        ]}
      >
        <Icon
          icon='attachment'
          width={20}
          height={20}
          color={MEDIUM_GRAY}
        />
      </Dropdown>
    </div>
  );
};