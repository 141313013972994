import React, { FC, Fragment } from 'react';

import HeaderPageUi, { HeaderPageType } from '@ui/molecules/texts/header-page/HeaderPage';
import { PageTitle } from '@/utils/PageTitle';

type HeaderPageProps = HeaderPageType & {
    options?: Record<string, string|undefined>;
    };

const HeaderPage: FC<HeaderPageProps> = ({ options,title,...rest }) => {

  return (
    <Fragment>
      <PageTitle options={options} value={title} />
      <HeaderPageUi
        className='mb-[15px]'
        title={title}
        {...rest} />
    </Fragment>
  );
};
HeaderPage.displayName = 'HeaderPage';

export default HeaderPage;
