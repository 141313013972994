import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PublicProfileSearch from '@/packages/back-end/public-profile-search';
import useSearchParams from '@/utils/hooks/useSearchParams';
import useJsonAPIRequest, { attachRelationships } from '@/services/request/useJsonAPIRequest';
import { Collection } from '@/packages/back-end/jsonapi';
import { buildUrl } from '@/utils/Api';
import { useHistory, useParams } from 'react-router-dom';
import useChangeSearchParams from '@/utils/hooks/useChangeSearchParams';
import { buildSearchUrl } from '@/app/structure/body/routes/Public.utils';
import useOnlineAppointmentContext from '@/app/structure/page/public/doctor-list/useOnlineAppointmentContext';
import { City } from '@/app/structure/page/public/doctor-list/SearchCitySelectorField';
import RppsSpecialty from '@/packages/back-end/data/rppsSpecialty';


type CoordinatesType = { latitude?: number|string, longitude?: number|string };

type SearchDoctorsContextProps = {
  data?: {
    profiles: Collection<PublicProfileSearch>;
  };
  loading: boolean;
  page?: string;
  city?: string;
  specialty?: string;
  first_letter?: string;
  isSearchPage?: boolean;
  isDirectoryPage?: boolean;
  payment_methods?: string[];
  languages?: string[];
  search?: string;
  setCity: (params: {
    city: string,
    latitude: number,
    longitude: number
  }) => void;
  onSearch: (params: {
    search?: string,
    specialty?: RppsSpecialty,
    languages?: string[],
    payment_methods?: string[],
    city?: City
  }) => void;
  called?: boolean;
  setCoordinates: (place: CoordinatesType) => void;
  coordinates?: CoordinatesType;
  buildSearchUrl: (page: number | string) => string;
};

const SearchDoctorsContext = createContext<SearchDoctorsContextProps>({
  data: undefined,
  onSearch: () => { throw new Error('onSearch not implemented'); },
  loading: false,
  isDirectoryPage: false,
  called: false,
  search: undefined,
  setCity: () => { throw new Error('setCity not implemented'); },
  city: undefined,
  page: undefined,
  payment_methods: undefined,
  languages: undefined,
  specialty: undefined,
  first_letter: undefined,
  setCoordinates: () => { throw new Error('setCoordinates not implemented'); },
  coordinates: undefined,
  buildSearchUrl: () => { throw new Error('buildSearchUrl not implemented'); },
});

export const SearchDoctorsProvider = ({
  children,
  isSearchPage,
  isDirectoryPage,
}: {
  isSearchPage?: boolean;
  isDirectoryPage?: boolean;
  children: ReactNode;
}) => {

  const history = useHistory();

  const { data: contextData } = useOnlineAppointmentContext();

  const { onUpdateSearchParams } = useChangeSearchParams();
  const queryParams = useSearchParams();

  const [coordinates, setCoordinatesState] = useState<CoordinatesType | undefined>({
    latitude: queryParams.latitude ? parseFloat(queryParams.latitude as string) : undefined,
    longitude: queryParams.longitude ? parseFloat(queryParams.longitude as string) : undefined,
  });

  const params = useParams<{
    page?: string,
    first_letter?: string,
    city?: string,
    specialty: string
  }>();

  const setCoordinates = useCallback((place: CoordinatesType) => {
    if (isSearchPage) {
      onUpdateSearchParams({
        latitude: place.latitude?.toString(),
        longitude: place.longitude?.toString(),
      });
    }
    setCoordinatesState(place);
  },[onUpdateSearchParams,isSearchPage]);

  const page = (isSearchPage ? queryParams.page : params.page) as string | undefined;
  const city = (isSearchPage ? queryParams.city : params?.city) as string | undefined;
  const specialty = (isSearchPage ? queryParams.specialty : params?.specialty) as string | undefined;
  const first_letter = (isSearchPage ? queryParams.first_letter : params?.first_letter) as string | undefined;
  const search = (isSearchPage ? queryParams.search : undefined) as string | undefined;

  const payment_methods = (isSearchPage ? queryParams.payment_methods : undefined) as string[] | undefined;
  const languages = (isSearchPage ? queryParams.languages : undefined) as string[] | undefined;


  const {
    loading,
    called,
    data,
    fetchData
  } = useJsonAPIRequest<{
    profiles: Collection<PublicProfileSearch>;
  }>({
    url: buildUrl('online_appointments/search', undefined, {
      include: 'specialties',
      online_appointments: false,
      _per_page: isDirectoryPage ? 100 : undefined,
      latitude: isSearchPage ? coordinates?.latitude : undefined,
      longitude: isSearchPage ? coordinates?.longitude : undefined,
      city: isSearchPage ? undefined : city,
      languages,
      payment_methods,
      specialty,
      search,
      first_letter,
      page
    }),
  });


  const buildSearchUrlFn = useCallback((page: number | string) => {
    return buildSearchUrl({
      specialty, city, page: page?.toString(), first_letter,search, isSearchPage
    });
  }, [specialty, city, first_letter, isSearchPage,search]);


  useEffect(() => {
    if (page === '1') {
      history.push(buildSearchUrlFn(1));
    }

  }, [history, page, buildSearchUrlFn]);


  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const parsedData = useMemo(() => {

    if (!data) {
      return;
    }

    return {
      ...data,
      profiles: attachRelationships(data.profiles) as Collection<PublicProfileSearch>
    };
  }, [data]);


  useEffect(() => {

    if(contextData.city) {
      setCoordinates({
        latitude: contextData.city.latitude,
        longitude: contextData.city.longitude
      });
    }

  },[setCoordinates,contextData]);

  const onSearch = useCallback(({ search,specialty, languages, payment_methods, city }) => {

    if (!isSearchPage && (search || languages?.length || payment_methods?.length)) {
      history.push(buildSearchUrl({
        search,
        city: city?.attributes?.canonical,
        specialty: specialty?.attributes?.canonical,
        first_letter,
        isSearchPage: true,
        languages,
        payment_methods
      }));
      return;
    }

    if(!search && !languages?.length && !payment_methods?.length) {
      history.push(buildSearchUrl({
        city: city?.attributes?.canonical,
        specialty: specialty?.attributes?.canonical,
        first_letter,
        isSearchPage: false
      }));
      return;
    }

    if(city) {
      // Default coords is Paris
      setCoordinates({
        latitude: city.attributes.latitude ? parseFloat(city.attributes.latitude) : 48.8575,
        longitude: city.attributes.longitude ? parseFloat(city.attributes.longitude) : 2.3514
      });
    }

    onUpdateSearchParams({
      city: city?.attributes?.canonical,
      specialty: specialty?.attributes?.canonical,
      search,
      languages,
      payment_methods,
    });
  },[history, setCoordinates, onUpdateSearchParams, isSearchPage, first_letter]);


  const setCity = useCallback(({
    city,
    latitude,
    longitude
  }) => {
    if(isSearchPage) {
      onUpdateSearchParams({ city });
      setCoordinates({ latitude,longitude });
    } else {
      history.push(buildSearchUrl({
        city,
        specialty,
        first_letter,
        isSearchPage: false
      }));
    }
  },[isSearchPage, onUpdateSearchParams, setCoordinates, history, specialty, first_letter]);

  return (
    <SearchDoctorsContext.Provider
      value={{
        data: parsedData,
        loading,
        city: params.city,
        isDirectoryPage,
        setCity,
        page,
        buildSearchUrl: buildSearchUrlFn,
        specialty: params.specialty,
        first_letter: params.first_letter,
        called,
        search,
        isSearchPage,
        onSearch,
        setCoordinates,
        coordinates,
        languages,
        payment_methods,
      }}
    >
      {children}
    </SearchDoctorsContext.Provider>
  );
};

export const useSearchDoctors = () => useContext(SearchDoctorsContext);
