import { getEnv } from '@/utils/importMeta';

/**
 * convert asset to right url format
 */
const getAssetURL = (url: string): string => {

  if(document.location.host.includes('localhost')) {
    return `/${url}`;
  }

  return `${getEnv('VITE_BASE_URL')}/${url}`;


};

export default getAssetURL;
