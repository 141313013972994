import LandingPage from '@/components/landing-page/LandingPage';
import getAssetURL from '@/utils/getAssetURL';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';

import useURLParams from '@/utils/hooks/useURLParams';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { storeAuthInfos } from '@/auth/hooks/useLogIn.utils';
import { useHistory } from 'react-router-dom';
import useMe from '@/utils/hooks/useMe';
import LinkButton from '@ui/atoms/texts/link-button/LinkButton';
import { buildWebappUrl } from '@/utils/router';
import { useLayout } from '@/layout/LayoutContext';

type AuthType = {
  token : string,
  refresh_token : string,
  refresh_token_expiry : number;
}

const SignupCallback = () => {
  
  const { fetchData: save,error } = useRequest<AuthType>({
    url: buildUrl('auth', 'pro_sante_connect'),
    skip: true,
    method: 'POST',
    unauthenticated: false,
  });

  const { fetchData: fetchMe } = useMe({
    skip: true
  });

  const history = useHistory();

  const { t } = useTranslation('auth', { keyPrefix: 'pro_sante_connect.signup' });

  const { code, state } = useURLParams(['code', 'state']);

  useEffect(() => {

    (async () => {

      storage.removeItem(StorageKeys.TOKEN);
      storage.removeItem(StorageKeys.IMPERSONATION_KEY);
      storage.removeItem(StorageKeys.SYNAPSE_TOKEN);
      storage.removeItem(StorageKeys.CONSTANTS);
      storage.removeItem(StorageKeys.FEATURES);

      const result = await save({
        code: code,
        state: state
      },{
        throw: true,
        displayToastOnError: true
      }) as AuthType;

      storeAuthInfos({
        token: result.token,
        refreshToken: result.refresh_token,
        refreshTokenExpiry: result.refresh_token_expiry
      });

      await fetchMe({},{
        throw: true
      });

      // Todo, refacto here to use constants
      history.push('/auth/medic/complete');

    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, state]);

  useLayout({ hide: true });


  return (
    <LandingPage
      title={t('title')}
      subtitle={error ? error.message : t('subtitle')}
      image={getAssetURL('/assets/images/auto-login.svg')}
    >
      {error && (<LinkButton
        className='mt-4'
        href={buildWebappUrl('/auth/login')}
      >
        {t('return_to_home')}
      </LinkButton>)}
    </LandingPage>
  );
};


export default SignupCallback;
