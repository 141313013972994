import Email from '@/packages/back-end/mssante/email';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { getHTMLBody } from '@/app/structure/page/chat/utils';
import * as ReactDOMServer from 'react-dom/server';
import { nl2br } from '@ui/utils/nl2br';
import cls from 'classnames';



type EmailContentProps = {
  email ?: Email
}

type FrameMessage = {
  frameHeight: number;
  frameId: string;
};

const EmailContent : FC<EmailContentProps> = ({ email }) => {

  const [height,setHeight] = useState(0);

  const html = useMemo(() => {

    if(!email) {
      return undefined;
    }

    if(email.attributes.bodyHTML) {
      return email.attributes.bodyHTML;
    }
    if(!email?.attributes?.bodyText) {
      return undefined;
    }

    return ReactDOMServer.renderToStaticMarkup(nl2br(email?.attributes.bodyText) as JSX.Element);


  },[email]);

  useEffect(() => {
    const handleResize = (event : MessageEvent<FrameMessage>) => {
      if (event.data.frameId === email?.id) {
        setHeight(event.data.frameHeight);
      }
    };

    window.addEventListener('message', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleResize);
    };
  }, [email]); // Depend on email to update the iframe selector if needed

  return (<>
    {(html && email) && (
      <iframe
        height={(height + 35)}
        title={email.attributes.subject}
        srcDoc={getHTMLBody(email.attributes.subject,html,email.id)}
        className={cls(height ? '' : 'invisible','flex flex-col flex-grow flex-shrink-0 gap-4 p-3 pb-0 w-full')}
      />)}
    {!html || !height &&
      (<div className='flex flex-col flex-grow flex-shrink gap-4 p-3 pb-0 w-full h-full'></div>)
    }</>);

};

export default EmailContent;