import React, { FC } from 'react';
import Modal, { ModalProps } from '@ui/molecules/modal/Modal';
import DocumentIframe from '@/components/documents/DocumentIframe';



type DocumentModalProps = Omit<ModalProps,'children'|'size'> & {
  url : string
}

const DocumentModal : FC<DocumentModalProps> = ({
  url,
  title,
  onDismiss }) => {


  return (<Modal
    size='large'
    className='flex !p-0 w-auto sm:!w-auto sm:!max-w-[80vw] h-auto'
    onDismiss={onDismiss}
    title={title}
  >
    <DocumentIframe
      data-testid='document-modal'
      title={title}
      url={url}
    />
  </Modal>);


};
export default DocumentModal;