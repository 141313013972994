import Profile from '@/packages/back-end/profile';
import { Item, Relationship } from '@/packages/back-end/jsonapi';
import User from '@/packages/back-end/user';
import { buildUrl, UrlArg } from '@/utils/Api';
import { OptionType } from '@ui/types';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';
import { DATE_NUMERIC, dateFormat } from '@ui/utils/dateFormat';

const formatter = dateFormat(DATE_NUMERIC);

export enum STATUS {
  ARCHIVED = 1,
}

type Patient = Profile & {
  attributes: {
    email?: string;
    name: string,
    fullName: string,
    firstName: string;
    lastName: string;
    dateOfBirth?: string | null;
    age?: number;
    givenName: string | null;
    lastLoginDate?: string;
    height?: number;
    weight?: number;
    sex?: 1 | 2;
    aiSuggestionCount?: number,
    status: STATUS;
    deceased?: boolean;
    deceasedDate?: string;
    shareable: boolean;
    acceptNewsletter?: boolean;
  }

  relationships: {
    user?: Item<Relationship | User>
    medicalTeam?: Item<Relationship[]>
  }

}

export default Patient;

export const GET_COLLECTION = (args? : UrlArg) => buildUrl('patients',undefined,args);
export const POST_COLLECTION = (args?: UrlArg) => buildUrl('patients', undefined, args);
export const PUT_ITEM = (id?: string | number, args?: UrlArg) => {
  return buildUrl('patients', id, args);
};
export const DELETE_ITEM = (id: string | number, args?: UrlArg) => {
  return buildUrl('patients', id, args);
};
export const DOWNLOAD_PATIENT = (id?: string | number) =>
  buildUrl(`patients/${id}`, 'download');

export const buildOption = (item: Patient): OptionType<Patient> => {

  const user = item.relationships.user?.data as User | undefined;

  return {
    value: item.id,
    data: item,
    label: user?.attributes?.fullName ?? '',
    subtitle: typeof user?.attributes?.dateOfBirth === 'string' ?
      formatter(user?.attributes?.dateOfBirth) : undefined,
    avatar: user ? getAvatarProps({ user: user?.attributes as UserType, colorLength: 10 }) : undefined,
  };

};
