import React, { useCallback } from 'react';
import get from 'lodash/get';

import { DrawerSizeKeysType } from './types';


export const EVENTS = {
  updateData: 'drawer:update',
  openDrawer: 'drawer:open',
  closeDrawer: 'drawer:close',
};

type KeysEvent = keyof typeof EVENTS;

export type DataEventType = {
  content: React.ReactNode,
  eventType?: typeof EVENTS[KeysEvent];
  overlayClickClose?: boolean,
  size?: DrawerSizeKeysType;
  sidebarWidth?: number;
  hideBar?: boolean | undefined
  onClickOverlay?: () => void;
}

export type OnOpenDrawerArgsType = {
  e: Event | {
    detail: {
      data: DataEventType
    }
  };
  setData: React.Dispatch<React.SetStateAction<DataEventType | null>>;
  eventType: string;
};

export type UseDrawerManagerType = () => {
  closeDrawer: () => void;
  updateData: (data: Partial<DataEventType>) => void;
  openDrawer: (data: DataEventType) => void;
  onDrawerOpened: (args: OnOpenDrawerArgsType) => void
  onDrawerClosed: (args: {
    setData: React.Dispatch<React.SetStateAction<DataEventType | null>>;
  }) => void;
}

const triggerEvent = (target: Document | HTMLElement, eventType: string, detail?: object) => {
  const event = new CustomEvent(eventType, { detail });
  target.dispatchEvent(event);
};

/**
 * Hook for use drawer
 */
const useDrawerManager:UseDrawerManagerType = () => {
  // const [isOpen, setIsOpen] = useState(false);

  const updateData = useCallback((data: Partial<DataEventType>) => {
    triggerEvent(document, EVENTS.updateData, { data });
  }, []);

  const closeDrawer = useCallback(() => {
    triggerEvent(document, EVENTS.closeDrawer);
  }, []);

  const openDrawer = useCallback((data: DataEventType) => {
    triggerEvent(document, EVENTS.openDrawer, { data });
  }, []);

  // Handles the drawer:open events
  const onDrawerOpened = useCallback(({ e, setData, eventType }) => {
    const data = get(e, 'detail.data');

    if (!data) { return; }

    data.eventType = eventType;

    setData(data);
  }, []);

  // Handles the drawer:close event
  const onDrawerClosed = useCallback(({ setData }: {
    setData: (data: null) => void
  }) => setData(null), []);

  return {
    updateData,
    closeDrawer,
    openDrawer,
    onDrawerOpened,
    onDrawerClosed,
    //isOpen,
  };
};

export default useDrawerManager;
