import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import MultipleSelector from '@ui/organisms/selectors/multiple/MultipleSelector';
import SimpleSelector from '@ui/organisms/selectors/simple/SimpleSelector';
import { onChangeType, onMultipleChangeType, OptionType } from '@ui/organisms/selectors/types';
import { Collection, Entity } from '@/packages/back-end/jsonapi';
import { Control, FieldValues, useController } from 'react-hook-form';
import { UseFormResetField } from 'react-hook-form/dist/types/form';
import { IconType } from '@ui/atoms/icons/icons';

export type RenderLabelType<TItem = object> = (entity: TItem) => string;

export type BuildOptionType<TItem = object> = (
  entity: TItem,
  renderLabel: RenderLabelType<TItem>
) => OptionType;

export type TransformDataType<TItem = object,TCollection = Collection<TItem>> =
  (
    data: TCollection,
    renderLabel: RenderLabelType<TItem>,
    buildOption: BuildOptionType<TItem>,
    search ?: string
  ) => OptionType[];


export type ApiSelectorFieldProps
  <TItem extends object = Entity | OptionType,TCollectionType extends object = Collection<TItem>> = {
  multiple?: boolean
  freeText?: boolean
  name: string,
  value?: OptionType | OptionType[],
  freeTextEmptyText?: string,
  unauthenticated?: boolean,
  options?: OptionType[] | null,
  fixedOptions?: OptionType[] | null,
  renderLabel?: RenderLabelType<TItem>,
  buildOption?: (item: TItem) => OptionType,
  sortOption?: (prevOption: OptionType, nextOption: OptionType) => number,
  allowFreeText?: boolean,
  control: Control
  onChange?: (value: OptionType<TItem>[] | OptionType<TItem> | null) => void
  onClickActionOption?: (
    option: OptionType,
    params: { inputValue?: string, resetSearch: () => void }
  ) => void
  onLoaded?: (options: OptionType<TItem>[], searchValue?: string) => void,
  onOptionsChanged?: (options: OptionType[]) => void,
  loading?: boolean,
  readonly?: boolean,
  placeholder?: string,
  helpText?: string,
  label?: string
  icon?: IconType,
  iconAfter?: boolean,
  iconClass?: string,
  suffix?: ReactNode,
  apiSearch?: boolean,
  sortByGroup?: boolean,
  skip?: boolean,
  url: string,
  onSearch?: (value: string|undefined) => void
  fixedUrl?: boolean
  required?: boolean | ReactNode
  error?: string
  autocompleteClassName?: string
  className?: string
  frontEndFilter?: boolean
  transformData?: TransformDataType<TItem,TCollectionType>,
  resetField: UseFormResetField<FieldValues>,
  showSelectAll?: boolean;
  disableCloseOnSelect?: boolean;
  closeOnSelectWhenOneOption?: boolean;
  loadingData?: boolean;
  prefix?: ReactNode;
}

type ValueType = string | OptionType[] | OptionType | null | undefined | number;

export const getValueInList = (
  options: OptionType[],
  value: ValueType
): OptionType | OptionType[] | null => {

  const originalValue = value;

  if (Array.isArray(value)) {
    const values = value.map((elem) => getValueInList(options, elem)) as OptionType[];
    return values.filter((item) => typeof item !== 'undefined' && item !== null);
  }

  let label;
  if (typeof value === 'object' && value?.value) {
    label = value.label ?? value.value;
    value = value.value;
  }

  if (null === value || typeof value === 'undefined') {
    return null;
  }

  if (options.length === 0) {
    return {
      label,
      value,
    } as OptionType;
  }

  const option = options.find((option) => option.value === value?.toString());

  if (!option) {
    if (typeof originalValue === 'object' &&
      originalValue !== null &&
      Object.prototype.hasOwnProperty.call(originalValue, 'label') &&
      Object.prototype.hasOwnProperty.call(originalValue, 'value'))
    {
      return originalValue as OptionType;
    }

    return null;
  }

  return option;

};


type ResetValueType = {
  name: string,
  value: ValueType,
  resetField: UseFormResetField<FieldValues>
}

export const resetValue: (props: ResetValueType) => void = ({ name, value, resetField }) => {

  resetField(name, {
    defaultValue: value,
    keepError: true,
    keepDirty: true,
    keepTouched: true,
  });
};



function isOptionType(entity: Entity | OptionType): entity is OptionType {
  return (entity as OptionType).label !== undefined;
}


const buildOptionFn: BuildOptionType<Entity | OptionType> = (entity, renderLabel): OptionType => {

  if (isOptionType(entity)) {
    return entity;
  }

  return {
    value: entity.id.toString(),
    label: renderLabel(entity),
    data: entity
  };

};

export const transformDataFn: TransformDataType<Entity|OptionType,Collection> =
  (data, renderLabel, buildOption, search) => {

    if (!data) {
      return [];
    }

    const result = data.data.map((entity) => buildOption(entity, renderLabel));

    if (!search) {
      return result;
    }

    return result.filter((option) => option.label.toLowerCase().includes(search));


  };

const renderLabelFn: RenderLabelType<OptionType|Entity> =
  (entity) => isOptionType(entity) ? entity.label : entity.attributes.name as string;


const ApiSelectorField = <T extends object = OptionType|Entity,CollectionType extends object = Collection<T>>
  ({
    url,
    fixedUrl = true,
    required,
    unauthenticated,
    resetField,
    control,
    options: optionsProp,
    fixedOptions,
    allowFreeText,
    onSearch: onSearchProp,
    onChange: onChangeProp,
    onClickActionOption,
    onLoaded,
    onOptionsChanged,
    sortByGroup,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    renderLabel = renderLabelFn,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    buildOption = buildOptionFn,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    transformData = transformDataFn,
    sortOption,
    multiple = false,
    frontEndFilter = false,
    apiSearch = false,
    showSelectAll = true,
    skip = false,
    name,
    value: optionValueProp,
    loadingData: loadingDataProp,
    ...rest
  }: ApiSelectorFieldProps<T,CollectionType>): JSX.Element => {
  const keepRef = useRef({ search: '', sortOption, onLoaded, onOptionsChanged });
  keepRef.current.sortOption = sortOption;
  keepRef.current.onLoaded = onLoaded;
  keepRef.current.onOptionsChanged = onOptionsChanged;

  const [isApiLoaded, setApiLoaded] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState<OptionType[]>([]);

  const [timeoutHandler, setTimeoutHandler] = React.useState<NodeJS.Timeout | undefined>(undefined);

  const { field: { value, onChange } } = useController({
    rules: {
      required: required ? typeof required === 'string' ? required : 'form.error.required' : undefined,
    },
    control,
    name
  });

  const [search, setSearch] = useState<string | undefined>(undefined);
  keepRef.current.search = search || '';

  const { loading: loadingData, data,called, fetchData } = useJsonAPIRequest<CollectionType>({
    url: url,
    unauthenticated,
    method: 'GET',
    skip: apiSearch || skip
  });

  useEffect(() => {
    if (!apiSearch && fixedUrl) {
      return;
    }

    if(skip) {
      return;
    }

    setApiLoaded(false);
    fetchData(search ? {
      search
    } : undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedUrl, url, search, apiSearch]);


  useEffect(() => {
    if (!data) {
      return;
    }

    setLoadedOptions((prevState) => [
      ...prevState,
      ...transformData(data, renderLabel, buildOption)
    ].filter((x, i, a) => a.findIndex(k => x.value === k.value) === i));

  }, [data, buildOption, transformData, renderLabel]);


  const optionedValue = useMemo(() => {
    // controlled
    if (optionValueProp) {
      return optionValueProp;
    }

    const option = getValueInList(loadedOptions, value);

    if (option) {
      return option;
    }

    return multiple ? [] : null;


  }, [optionValueProp, multiple, value, loadedOptions]);


  const options: OptionType[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const result = transformData(data, renderLabel, buildOption, apiSearch ? undefined : search);
    if (!keepRef.current.sortOption) {
      return result;
    }
    return [...result].sort(keepRef.current.sortOption);

  }, [apiSearch, transformData, buildOption, search, renderLabel, data]);

  const displayOptions = useMemo<OptionType[]>(() => {
    const result = optionsProp || options || [];

    if (!fixedOptions?.length) {
      return result;
    }
    return [...result, ...fixedOptions];
  }, [optionsProp, options, fixedOptions]);

  const handleClickActionOption = (option: OptionType, params: { inputValue?: string }) => {
    onClickActionOption?.(
      option,
      {
        ...params,
        resetSearch: () => setSearch(undefined)
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetValue({ name, value: optionedValue, resetField }), [name, resetField, loadedOptions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFieldChange = useCallback((value?: OptionType<T> | OptionType<T>[] | null) => {

    if (null === value) {
      onChange(multiple ? [] : null);
      onChangeProp?.(multiple ? [] as OptionType<T>[] : null);
      return;
    }
    onChange(value);
    onChangeProp?.(value as OptionType<T> | null);

  }, [onChange,onChangeProp, multiple]);


  const onSearch = useCallback((value: string | undefined) => {

    onSearchProp?.(value);

    if (!apiSearch || typeof value === 'undefined') {
      return;
    }

    clearTimeout(timeoutHandler);
    setTimeoutHandler(setTimeout(() => {
      setApiLoaded(false);
      setSearch(value);
    }, 300));

  }, [apiSearch,onSearchProp, timeoutHandler]);

  const searchFunction = useMemo(() => {
    return apiSearch ? onSearch : undefined;
  }, [apiSearch, onSearch]);


  const onOpen = useCallback(() => {
    if(!skip) {
      return;
    }
    if(called) {
      return;
    }

    setApiLoaded(false);
    fetchData();

  },[called,fetchData,skip]);

  useEffect(() => {
    if (!loadingData) {
      setApiLoaded(true);
    }
  }, [loadingData]);

  useEffect(() => {
    if (isApiLoaded && called) {
      keepRef.current.onLoaded?.(options as OptionType<T>[], keepRef.current.search);
    }
  }, [isApiLoaded,called, options]);

  useEffect(() => {
    keepRef.current.onOptionsChanged?.(options);
  } ,[options]);

  {if (!multiple) {
    return (<SimpleSelector
      data-testid='selector'
      allowFreeText={allowFreeText}
      options={displayOptions}
      sortByGroup={sortByGroup}
      onSearch={searchFunction}
      onOpen={onOpen}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onFieldChange as onChangeType}
      onClickActionOption={handleClickActionOption}
      required={!!required}
      value={optionedValue as OptionType | null}
      name={name}
      loadingData={loadingData || !!loadingDataProp}
      {...rest}
    />);
  }}

  return (<MultipleSelector
    onSearch={searchFunction}
    data-testid='selector'
    options={displayOptions}
    allowFreeText={allowFreeText}
    onOpen={onOpen}
    frontEndFilter={frontEndFilter}
    onChange={onFieldChange as onMultipleChangeType}
    onClickActionOption={handleClickActionOption}
    name={name}
    showSelectAll={showSelectAll}
    value={optionedValue as OptionType[]}
    loadingData={loadingData || !!loadingDataProp}
    {...rest}
  />);



};

export default ApiSelectorField;
