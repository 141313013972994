import React, { FC, useState } from 'react';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import { useTranslation } from 'react-i18next';
import ApiSelectorField from '@/components/form/fields/api-selector';
import User, { buildOption, GET_COLLECTION } from '@/packages/back-end/user';
import { ROLE } from '@/packages/types';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import Button from '@ui/atoms/buttons/button/Button';
import { useForm } from 'react-hook-form';
import { OptionType } from '@ui/types';


type SelectMedicProps = {
  onUserSelected: (user: User) => void;
}

enum STATUS {
  PENDING = 'pending',
  SELECTED = 'selected',
}

const ChooseMedic : FC<SelectMedicProps> = ({ onUserSelected }) => {

  const [status,setStatus] = useState(STATUS.PENDING);

  const { t } = useTranslation('admin',{
    keyPrefix: 'dashboard.export_data.select_medic'
  });

  const { control, watch, resetField } = useForm();

  const user : OptionType<User> | undefined = watch('user');


  return <RetractableItem
    open={status === STATUS.PENDING}
    retractableType={'hidden'}
    status={status === STATUS.SELECTED ? 'checked' : undefined}
    onToggle={(open) => {
      if(open) {
        setStatus(STATUS.PENDING);
      }}}
    title={t('title')}
    subtitle={t(`subtitle.${status}`,{
      user: user?.data?.attributes.fullName
    })}
  >
    <div className='flex flex-col gap-4 w-full' >
      <ApiSelectorField<User>
        url={GET_COLLECTION({
          roles: ROLE.MEDECIN
        })}
        name={'user'}
        control={control}
        apiSearch={true}
        buildOption={buildOption}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resetField={resetField}
      />
      <ContainerFooter size='small'>
        <Button color='primary' disabled={!user}
          onClick={() => {
            if(!user?.data) {
              return;
            }
            onUserSelected(user.data);
            setStatus(STATUS.SELECTED);
          }}
        >{t('buttons.continue')}</Button>
      </ContainerFooter>
    </div>
  </RetractableItem>;

};

export default ChooseMedic;