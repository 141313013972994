import React, { FC, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import Button from '@ui/atoms/buttons/button/Button';
import { useTranslation } from 'react-i18next';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { redirectToLegacy } from '@/utils/Legacy';


const SWITCH_URL = '/user/switch?_switch_user=_exit';

const ImpersonationButton : FC = () => {

  const { t } = useTranslation('medical_staff',{
    keyPrefix: 'dashboard.admin'
  });

  const onClick = useCallback(() => {

    storage.removeItem(StorageKeys.IMPERSONATION_KEY);
    storage.removeItem(StorageKeys.SYNAPSE_TOKEN);
    storage.removeItem(StorageKeys.FEATURES);
    storage.removeItem(StorageKeys.CONSTANTS);

    redirectToLegacy(SWITCH_URL);

  },[]);

  const me = useRecoilValue(authAtom);

  if(!me.isImpersonating) {
    return null;
  }

  return (
    <div className='fixed right-4 bottom-4 z-50' >
      <Button
        onClick={onClick}
        color='primary'
        icon='back'
      >{t('back_to_admin')}</Button>
    </div>
  );


};

export default ImpersonationButton;