import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { MeUser } from '@/packages/back-end/user';
import { attachErrorsToFields, RequestError } from '@/services/request/utils';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import useLogIn from '@/auth/hooks/useLogIn';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import TextField from '@ui/atoms/fields/text/TextField';
import { TwoFaCheckReturnType } from '@/app/structure/page/staff/profile/security/two-factor-auth/TwoFactorAuth';

import { getError } from '@/components/form/utils';

import { EnterEmailStepData } from './EnterEmailStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import useToast from '@/utils/hooks/useToast';
import { TwoFactorMethod } from '@/auth/pages/login/types';
import { useBookMeeting } from '../../BookMeetingContext';
import P from '@ui/atoms/texts/base/P';

type FormDataType = {
  code: string;
};

const schema = yup.object({
  code: yup.string().required('field_required'),
});

export type TwoFactorStepData = {
  code?: string;
};

type TwoFactorStepProps = {
  stepNumber: number;
  open: boolean;
  enterEmailStepData: EnterEmailStepData;
  twoFactorConfigs?: { token?: string; method?: TwoFactorMethod };
  onToggle: (open: boolean) => void;
  onDone: (newMeUser: MeUser) => void;
};

const TwoFactorStep: FC<TwoFactorStepProps> = ({
  stepNumber,
  open,
  twoFactorConfigs,
  onToggle,
  onDone,
}) => {
  const Authorization = `Bearer ${twoFactorConfigs?.token || ''}`;

  const [timeBeforeSendNextCode, setTimeBeforeSendNextCode] = useState(0);

  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('two_factor_step');

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { loading: loginLoading, legacyLogIn } = useLogIn();

  const {
    called: generateFa2Called,
    loading: generate2faLoading,
    headers,
    fetchData: generateFa2,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('2fa/otp/generate'),
    headers: { Authorization },
  });

  const {
    loading: fa2CheckLoading,
    error: fa2CheckErrors,
    fetchData: fa2Check,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('2fa/check'),
    headers: { Authorization },
  });

  const {
    formState: { errors },
    setError,
    register,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const [twoFaMethod, setTwoFaMethod] = useState<TwoFactorMethod | undefined>(twoFactorConfigs?.method);


  const { addToast } = useToast();

  const [newMeUser, setNewMeUser] = useState<MeUser | null>(null);

  const handleGenerate2Fa = useCallback(async (initialLoad : boolean) => {
    if(twoFaMethod === 'sms' || twoFaMethod === 'email') {

      try {
        await generateFa2(
          { method: twoFaMethod || '' },
          { throw: true }
        );

        if(!initialLoad) {
          addToast('success', t('two_factor_step.toasts.resend'));
        }

        setTimeBeforeSendNextCode(30);

      } catch(e) {

        const error = e as RequestError;

        addToast('danger',t('two_factor_step.toasts.error',{
          retry: error?.response?.headers?.['retry-after'] || '30'
        }));

      }
    }
  }, [addToast,twoFaMethod, generateFa2, t]);

  const onSubmit = handleSubmit(async ({ code }) => {
    const fa2CheckResponse = (await fa2Check(
      { method: twoFaMethod, code },
      { throw: true, displayToastOnError: true }
    )) as TwoFaCheckReturnType;

    const newMeUser = await legacyLogIn({
      token: fa2CheckResponse?.token,
      refreshToken: fa2CheckResponse?.refresh_token,
      refreshTokenExpiry: fa2CheckResponse?.refresh_token_expiry,
    });

    setNewMeUser(newMeUser);
    onDone(newMeUser);
    onScrollToHideStep();

  });

  useEffect(() => {

    if(headers?.['retry-after']) {
      setTimeBeforeSendNextCode(parseInt(headers?.['retry-after']));
    }

  }, [headers]);

  useEffect(() => {
    if (timeBeforeSendNextCode > 0){
      const interval = setInterval(() => setTimeBeforeSendNextCode(timeBeforeSendNextCode - 1), 1000);

      return () => clearInterval(interval);
    }
  }, [timeBeforeSendNextCode]);

  useEffect(() => {
    attachErrorsToFields(setError, fa2CheckErrors);
  }, [fa2CheckErrors, setError]);

  useEffect(() => {
    handleGenerate2Fa(true);
  }, [handleGenerate2Fa]);

  const saving = fa2CheckLoading || loginLoading;

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      withSeparator={!!newMeUser}
      status={newMeUser ? 'checked' : undefined}
      subtitle={newMeUser ? t('two_factor_step.subtitle',{
        firstName: newMeUser?.attributes?.firstName,
      }) : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('two_factor_step.title')}`}
      onToggle={onToggle}
    >
      {(
        <form
          className='flex flex-col gap-4 w-full'
          onSubmit={onSubmit}
        >
          <p className='text-sm font-medium'>
            {t(`two_factor_step.description.${twoFaMethod}`)}
          </p>

          <TextField
            {...register('code')}
            loading={!generateFa2Called && generate2faLoading}
            error={getError('code', errors, errorT)}
          />
          <Button
            type='submit'
            color='primary'
            disabled={!generateFa2Called && generate2faLoading}
            saving={saving}
          >
            {t('two_factor_step.login')}
          </Button>
          {!!generateFa2Called && twoFaMethod !== 'backup_code' && (
            <div>

              {timeBeforeSendNextCode > 0 ?
                (<P className='text-sm'>
                  {t('two_factor_step.resend_code_availability', { timeInSeconde: timeBeforeSendNextCode })}
                </P>) : (<Button
                  className='underline'
                  type='button'
                  saving={generate2faLoading}
                  color='transparent'
                  innerClassName='!px-0'
                  onClick={() => handleGenerate2Fa(false)}
                >
                  {t('two_factor_step.resend')}
                </Button>)}

              <Button
                className='underline'
                type='button'
                color='transparent'
                innerClassName='px-0'
                onClick={() => setTwoFaMethod('backup_code')}
              >
                {t('two_factor_step.use_backup_code')}
              </Button>
            </div>
          )}
        </form>
      )}
    </RetractableItem>
  );
};

export default TwoFactorStep;
