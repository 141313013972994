import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import Label from '@ui/atoms/labels/label/Label';
import Link from '@ui/atoms/texts/link/Link';
import ErrorText from 'instamed-styleguide/ui/atoms/texts/error/ErrorText';
import { getEnv } from '@/utils/importMeta';

export type CGUAgreedProps = {
  className?: string;
  checkboxClassName?: string;
  error?: string;
  name: string;
  prefix?: string;
  required?: boolean;
  loading?: boolean;
  labelText?: string;
  linkText?: string;

  link?: string;
  register: (name: string) => { name: string };
};

/**
 * TODO revoir ce composant -> voir revoir comment les checkbox sont intégrée...
 *
 * Le label devrait être un children, comme ça on pourrait passer le content qu'on veut
 */
const CGUAgreed: FC<CGUAgreedProps> = ({
  checkboxClassName,
  error,
  name,
  register,
  loading = false,
  labelText,
  linkText,
  prefix = 'cgu',
  link = `${getEnv('VITE_PRO_URL')}/conditions-generales-dutilisation/`,
}) => {
  const { t } = useTranslation('auth', { keyPrefix: 'form.create.medic' });

  return (
    <div className='flex flex-col'>
      <Checkbox
        className={cls('flex flex-shrink-0 mt-4', checkboxClassName)}
        data-testid={prefix}
        loading={loading}
        label={
          <Label htmlFor={name} className='flex-grow'
            error={!!error}>
            <span>{labelText || t('cgv.text')}</span>
            <Link target='blank' href={link}>
              {linkText || t(`${prefix}.link`)}
            </Link>
          </Label>
        }
        error={!!error}
        {...register(name)}
      />
      {error ? (
        <ErrorText data-testid='field-error'>{t(`${prefix}.error`)}</ErrorText>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CGUAgreed;
