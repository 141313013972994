import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import { buildUrl, UrlArg } from '@/utils/Api';
import DocumentType from '@/packages/back-end/document-type';
import { OptionType } from '@ui/types';
import { OWNER_TYPE } from './medical-record-field';
import { Thumbnails } from './document';
import Service from './service';
import User from './user';


export enum STATUS {
  ENABLED = 1,
  ARCHIVED = 2,
}

type PDFTemplate = Entity & {
  attributes: {
    _id : string;
    name: string;
    content: string;
    favorite?: boolean;
    isSynapse: boolean;
    status: STATUS;
    archived: boolean;
    ownerType: OWNER_TYPE;
    createdDate: string;
    thumbnails: Thumbnails;
    rights: {
      generate: boolean,
      update: boolean,
      delete: boolean,
    }
  },
  relationships: {
    _type?: Item<DocumentType>
    services?: Item<(Service | Relationship)[]>
    owner?: Item<User | Relationship>
  }
}

export default PDFTemplate;

export const GET_COLLECTION = (args ?: UrlArg) => buildUrl('pdf_templates', undefined, args);

export const PUT_ITEM = (id: string | number, args ?: UrlArg) => {
  return buildUrl('pdf_templates', id, args);
};

export const DELETE_ITEM = (id: string | number, args?: UrlArg) => {
  return buildUrl('pdf_templates', id, args);
};

export const DUPLICATE_ITEM = (id: string | number, args ?: UrlArg) => {
  return buildUrl(`pdf_templates/${id}/duplicate`, undefined, args);
};

export const buildOption = (template: PDFTemplate) : OptionType<PDFTemplate> => ({
  value: template.id,
  label: template.attributes.name,
  subtitle: template.relationships?._type?.data?.attributes?.name,
  data: template,
});