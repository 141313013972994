import React, { useState } from 'react';

import NotificationsUi from './notifications-ui/NotificationsUI';

import Notification, { GET_COLLECTION } from '@/packages/back-end/notification';
import usePaginatedData from '@/utils/hooks/usePaginatedData';


export type NotificationPropsType = {
  onClose: () => void,
  count: number,
  markAsRead: (item: Notification) => void,
}

const Notifications = ({
  onClose,
  count: notificationCount,
  markAsRead,
}: NotificationPropsType) => {
  const [activeTab, setActiveTab] = useState('active');

  const {
    loading,
    hasMorePages,
    data,
    setData,
    onPaginate,
    onUpdate,
    onDelete,
    setQueryParams,
  } = usePaginatedData<Notification>(
    GET_COLLECTION(),
    undefined,
    undefined,
    { preventUpdateUrl: true },
  );

  return (<NotificationsUi
    loading={loading && data.length === 0}
    hasMorePages={hasMorePages}
    archived={activeTab === 'archived'}
    unread={notificationCount}
    activeTab={activeTab}
    onTabChange={(tab) => {
      setQueryParams({ archived: tab === 'archived' });
      setActiveTab(tab);
    }}
    setData={setData}
    onUpdated={onUpdate}
    onDeleted={onDelete}
    onItemClick={markAsRead}
    onCloseClick={onClose}
    onLoadMore={onPaginate}
    items={data} />
  );
};

export default Notifications;
