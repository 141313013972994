import React, { useCallback } from 'react';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import SearchDocumentDrawer from './SearchDocumentDrawer';
import { useMessageContext } from '../MessageContext';

const useSearchDocumentDrawer = () => {
  const { openDrawer, closeDrawer } = useDrawerManager();
  const { setPatient, setDocuments } = useMessageContext();

  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onValidate = useCallback(({ patient,documents }) => {

    setPatient(patient);
    setDocuments((existing) => {
      return [...existing, ...documents].filter((doc, index, self) =>
        index === self.findIndex((d) => d.id === doc.id)
      );
    });

  },[setPatient,setDocuments]);


  const onOpen = useCallback(() => {
    openDrawer({
      content: (
        <SearchDocumentDrawer
          onClose={onClose}
          onValidate={onValidate}
        />
      ),
      size: 'medium',
    });
  }, [onClose, onValidate, openDrawer]);

  return { onOpen, onClose };
};

export default useSearchDocumentDrawer;
