import React, { useCallback } from 'react';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';

import ExportDataDrawer from './ExportDataDrawer';

const useExportDataDrawer = (onCloseCallback ?: (changed : boolean) => void) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onClose = useCallback((changed : boolean) => {
    closeDrawer();
    onCloseCallback && onCloseCallback(changed);

  },[closeDrawer,onCloseCallback]);

  const onClick = useCallback(() => {
    openDrawer({ content: <ExportDataDrawer onClose={onClose} />, size: 'medium' });
  }, [openDrawer, onClose]);

  return { onClick, onClose };
};

export default useExportDataDrawer;
