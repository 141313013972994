import React, { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import clsx from 'clsx';
import { Redirect, useHistory } from 'react-router-dom';

import { isDoctor, isDoctorAssistant } from '@/packages/back-end/user';
import { authAtom } from '@/services/store/store';

import useViewport from '@/utils/hooks/useViewport';
import Button from '@ui/atoms/buttons/button/Button';
import H1 from '@ui/atoms/texts/base/H1';
import NewEmailForm from '@/app/structure/page/mssante/NewEmailForm';
import CreateForm from '@/app/structure/page/mssante/CreateForm';

import EmailTemplate from './email-template';
import { useEmails } from './EmailsContext';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../body/routes/Discussions.utils';
import HeaderLeft from './left-components/HeaderLeft';
import MobileHeaderLeft from './left-components/MobileHeaderLeft';
import ReconnectionModal from './reconnection/ReconnectionModal';
import { DiscussionsContainer } from '@/app/structure/page/chat/Discussions';
import SelectDoctorForm from '@/app/structure/page/mssante/SelectDoctorForm';

const MSSante: FC = () => {
  const history = useHistory();
  const { xl, lg, md } = useViewport();
  const { user } = useRecoilValue(authAtom);
  const isDoctorUser = isDoctor(user);

  const { t } = useTranslation('ms_sante');

  const {
    isSecuredMessaging,
    isAddingEmail,
    setAddingEmail,
    selectedEmail,
    refreshLink,
    refreshCallback,
    setRefreshLink,
    setImpersonatedUser,
    hasMultipleDoctors,
    impersonatedUser,
    init,
  } = useEmails();

  useEffect(() => {
    if (selectedEmail?.attributes?._id) {
      history.replace(
        `${ROUTES.messagerie.mssante}#${selectedEmail?.attributes?._id}`
      );
    }
  }, [selectedEmail?.attributes?._id, history]);

  const isDesktop = (xl || lg);

  useEffect(() => {
    init();
  }, [init]);

  if(isDoctorAssistant(user) && !isSecuredMessaging) {
    return <Redirect to={ROUTES.messagerie.discussions} />;
  }

  return (
    <DiscussionsContainer
      lg={isDesktop}
      md={md}
      className='flex flex-col mt-8 mb-2 w-full h-full'>
      <div className='flex flex-row justify-between items-center mb-4'>
        <div className='flex flex-row gap-3'>
          {!isDesktop && <MobileHeaderLeft />}
          <H1 className='mb-2'>{t('email_title')}</H1>
        </div>
        <div className='flex flex-row gap-2' >
          {isSecuredMessaging && hasMultipleDoctors && impersonatedUser && (
            <Button
              icon='switchUser'
              color='secondary'
              onClick={() => setImpersonatedUser(undefined)}
            >
              {t('choose_doctor')}
            </Button>
          )}
          {isSecuredMessaging && (impersonatedUser || isDoctorUser) && (
            <NewEmailButton
              icon='message'
              color='primary'
              onClick={() => setAddingEmail(true)}
            >
              {t('new_email')}
            </NewEmailButton>
          )}
        </div>
      </div>

      <div className='flex overflow-hidden flex-col lg:flex-row flex-1 pb-2 w-full h-full'>
        {isDesktop && <HeaderLeft />}

        <div className='flex flex-grow lg:ml-4 w-full lg:w-2/4 h-[95%] lg:h-full'>
          {!isSecuredMessaging && isDoctorUser && <CreateForm />}
          <SelectDoctorForm />
          {isSecuredMessaging && (impersonatedUser || isDoctorUser) && (
            <>
              <div className={clsx('w-full h-full', isAddingEmail && 'hidden')}>
                <EmailTemplate key={selectedEmail?.id} />
              </div>
              {isAddingEmail && (
                <NewEmailForm impersonated={impersonatedUser} onClose={() => setAddingEmail(false)} />
              )}
            </>
          )}
        </div>
      </div>

      <ReconnectionModal
        link={refreshLink}
        impersonated={impersonatedUser}
        refreshCallback={refreshCallback}
        onClose={() => setRefreshLink(null)}
      />
    </DiscussionsContainer>
  );
};

export const NewEmailButton = styled(Button)`
  svg {
    path {
      fill: #ffffff;
    }
  }
  &:hover {
    svg {
      path {
        fill: #072337;
      }
    }
  }
`;

export default MSSante;
