import { buildUrl } from '@/utils/Api';
import { TYPE as SUBSCRIPTION_GROUP_TYPE } from '@/packages/back-end/subscription-group';

export const URLS = {
  SUBSCRIPTION: (type : SUBSCRIPTION_GROUP_TYPE) => `/subscriptions/${type}`
};

export const API = {
  GET_SUBSCRIPTION_GROUPS: buildUrl('subscription_groups', null,
    { 'include': 'subscription,subscription.discount,lastInvoice' }),
  GET_STRIPE_PROFILE: buildUrl('stripe/profiles','me'),
};
