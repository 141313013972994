import React, { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import { useTranslation } from 'react-i18next';
import User from '@/packages/back-end/user';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import Button from '@ui/atoms/buttons/button/Button';
import { useForm } from 'react-hook-form';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import H4 from '@ui/atoms/texts/base/H4';
import { PropertiesType } from '@/app/structure/page/admin/dashboard/export-data/ExportDataDrawer';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import useToast from '@/utils/hooks/useToast';
import { getError } from '@/components/form/utils';
import { attachErrorsToFields } from '@/services/request/utils';
import TextField from '@ui/atoms/fields/text/TextField';


type ChooseProperties = {
  user: User
  onCancel: () => void;
  onExport: () => void;
}

enum STATUS {
  LOADING = 'loading',
  PENDING = 'pending',
  EXPORTING = 'exporting',
  EXPORTED = 'exported',

}

type ExportPropertiesType = {
  variables: {
    id: string,
    name: string
  }[],
  entities: {
    id: string,
    name: string
  }[],
  email?: string | null
}

const Skeleton = () => {

  return (<>
    <Checkbox
      loading={true}
      containerClassName='w-[45%] flex'
      name='skeleton' />
    <Checkbox
      loading={true}
      containerClassName='w-[45%] flex'
      name='skeleton' />
    <Checkbox
      loading={true}
      containerClassName='w-[45%] flex'
      name='skeleton' />
    <Checkbox
      loading={true}
      containerClassName='w-[45%] flex'
      name='skeleton' />
    <Checkbox
      loading={true}
      containerClassName='w-[45%] flex'
      name='skeleton' />
  </>);
};



const ChooseProperties: FC<ChooseProperties> = ({ user,onCancel, onExport }) => {

  const { addToast } = useToast();
  const [status, setStatus] = useState(STATUS.LOADING);



  const { t } = useTranslation('admin', {
    keyPrefix: 'dashboard.export_data.choose_properties'
  });


  const { fetchData: exportData,error: backErrors,loading } = useJsonAPIRequest<null,PropertiesType>({
    url: buildUrl(`users/${user.attributes._id}/export`),
    method: 'post',
    skip: true
  });


  const { fetchData, data } = useRequest<ExportPropertiesType>({
    url: buildUrl(`users/${user.attributes._id}/export/properties`),
    method: 'get',
    skip: true
  });

  const { register,setError,formState: { errors },setValue, handleSubmit } = useForm<PropertiesType>();


  useEffect(() => {

    (async () => {

      setStatus(STATUS.LOADING);

      await fetchData({}, {
        url: buildUrl(`users/${user.attributes._id}/export/properties`)
      });

      setStatus(STATUS.PENDING);

    })();


  }, [fetchData,user]);



  const onSubmit = useCallback(async (data) => {
    setStatus(STATUS.EXPORTING);

    try {
      await exportData({
        email: data.email ? data.email : null,
        variables: data.variables ? data.variables : [],
        entities: data.entities ? data.entities : [],
      }, { throw: true, displayToastOnError: true });
      setStatus(STATUS.EXPORTED);
      addToast('success', t('toasts.exported'));
      onExport();

    } catch (_e) {
      setStatus(STATUS.PENDING);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast,t,onExport]);


  const onSelectAllVariables = useCallback((e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;

    const variables = data?.variables.map((value) => value.id);

    if(target.checked) {
      setValue('variables',variables ?? []);
    } else {
      setValue('variables',[]);
    }


  },[setValue,data]);

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
  }, [backErrors, setError]);

  return <RetractableItem
    retractableType={'hidden'}
    open={status === STATUS.PENDING || status === STATUS.EXPORTING}
    status={
      status === STATUS.EXPORTED ?
        'checked' : [ STATUS.LOADING,STATUS.EXPORTING ].includes(status) ?
          'loading' : undefined}
    onToggle={(open) => {
      if (open) {
        setStatus(STATUS.PENDING);
      }
    }}
    title={t('title')}
    subtitle={t(`subtitle.${status}`)}
  >
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-8 w-full'>
      <div>
        <H4>{t('variables')}</H4>
        <div className='mb-4' >
          <Checkbox
            name='select_all_variables'
            error={getError('variables',errors,t)}
            label={t('form.fields.select_all_variables.label')}
            onChange={onSelectAllVariables}
          />
        </div>
        <div className='flex flex-row flex-wrap gap-4 max-h-[500px]'>
          {status === STATUS.LOADING && <Skeleton />}
          {data?.variables && data.variables.map((value) => (<Checkbox
            key={value.id}
            id={value.id}
            value={value.id}
            {...register('variables')}
            label={value.name}
          />))}
        </div>
      </div>
      <div>
        <H4>{t('entities')}</H4>
        <div className='flex flex-row flex-wrap gap-4 max-h-[500px]'>
          {status === STATUS.LOADING && <Skeleton />}
          {data?.entities && data.entities.map((value) => (<Checkbox
            key={value.id}
            id={value.id}
            value={value.id}
            {...register('entities')}
            label={value.name}
          />))}
        </div>
      </div>

      <div>
        <TextField
          label={t('form.fields.email.label')}
          helpText={t('form.fields.email.help_text')}
          {...register('email')}
          error={getError('email',errors,t)}
          type='email'
          
        />
      </div>

      <ContainerFooter size='small'>
        <Button
          disabled={loading}
          onClick={onCancel}
        >{t('buttons.cancel')}</Button>
        <Button
          saving={loading}
          color='primary'
        >
          {t('buttons.export')}
        </Button>
      </ContainerFooter>
    </form>
  </RetractableItem>;

};

export default ChooseProperties;