import { OptionType } from '@ui/types';
import { Entity } from '../jsonapi';

type MsSanteDirectoryUser = Entity & {
  attributes: {
    firstName: string;
    lastName: string;
    email: string;
    title?: string;
    rpps?: string;
    specialty?: string;
    postalCode?: string;
    address?: string;
  };
};

export const buildOption = (
  item: MsSanteDirectoryUser
): OptionType<MsSanteDirectoryUser> => {
  return {
    value: item.attributes.email,
    label: `${item.attributes.firstName} ${item.attributes.lastName}`,
    subtitle: item.attributes.email,
    data: item,
  };
};

export default MsSanteDirectoryUser;
