import React, { useMemo, useState } from 'react';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import styled from 'styled-components';
import { MEDIUM_GRAY } from '../messages/MessageComposer';
import {
  MedicalDocumentsProps,
  MessageFiles,
} from '../messages/MessageContext';
import DocumentModal from '@/components/documents/DocumentModal';
import Button from '@ui/atoms/buttons/button/Button';

const DocumentToUploadContainer = styled.div`
  width: fit-content;
  #document-file-name {
    color: #4c75cd;
  }
`;

const DocumentToUpload = ({
  type,
  files,
  setFiles,
}: {
  type: 'attachments' | 'medicalDocuments';
  files: MessageFiles;
  setFiles: (files: MessageFiles) => void;
}) => {
  const [preview, setPreview] = useState<File | MedicalDocumentsProps | null>(null);

  const removeFiles = (idx: number) => {
    files[type].splice(idx, 1);
    setFiles({ ...files });
  };

  const previewUrl : string | undefined = useMemo(() => {

    if(!preview) {
      return undefined;
    }

    if(preview instanceof File) {
      return URL.createObjectURL(preview);
    }

    return preview.previewUrl;


  },[preview]);

  return (
    <>
      {files[type]?.map((toUpload, idx) => (
        <DocumentToUploadContainer
          key={`${toUpload.name}-${type}-message-composer`}
          className='flex flex-row items-center p-1 mr-0 space-x-2 rounded-lg border border-gray-100'
        >
          <button
            onClick={() => {
              setPreview(toUpload);
            }}
          >
            <Icon icon='attachments' width={12}
              height={12} />
            <p
              className='text-xs font-semibold whitespace-nowrap'
              id='document-file-name'
            >
              {toUpload?.name}
            </p>
          </button>
          <Button
            icon='close'
            iconSize={12}
            color='transparent'
            iconColor={MEDIUM_GRAY}
            onClick={() => removeFiles(idx)} />
        </DocumentToUploadContainer>
      ))}
      {preview && previewUrl && <DocumentModal
        onDismiss={() => setPreview(null)}
        title={preview?.name}
        url={previewUrl}
      />}
    </>
  );
};

export default DocumentToUpload;
