import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { StringMap } from 'i18next';
import { getEnv } from '@/utils/importMeta';


export type TranslatableComponentProps = {
  value: string
  options?: StringMap
}

export const PageTitle: FC<TranslatableComponentProps> = ({ value,options }) => {

  const { t } = useTranslation('page_title');

  const suffix = t('suffix');
  const prefix = getPrefix();

  document.title = `${prefix} ${t(value,options)} - ${suffix}`.trim();

  if(options) {
    document.querySelector('title')?.setAttribute('data-pii',JSON.stringify(options));
  }

  document.querySelector('title')?.setAttribute('data-prefix',prefix);
  document.querySelector('title')?.setAttribute('data-suffix',suffix);

  return (<Fragment />);

};



const getPrefix = () : string => {

  const env = getEnv('VITE_ENV');

  switch (env) {
    case 'development':
      return '[DEV] - ';
    case 'staging':
      return '[STAGING] - ';
    case 'test' :
      return '[TEST] - ';
    default:
      return '';
  }

};
