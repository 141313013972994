import React, { FC } from 'react';
import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import Iframe, { IframeProps } from '@/components/iframe/Iframe';
import { buildLegacyUrl } from '@/utils/Legacy';

const IframeSkeleton: FC = () => {

  return (
    <div className='flex flex-col gap-6 p-4 w-full'>
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
      <Skeleton size='base' className='flex w-full' />
    </div>
  );

};



type DocumentIframeProps = Omit<IframeProps, 'skeleton'>

const DocumentIframe: FC<DocumentIframeProps> = ({ url,...props }) => {

  return <Iframe
    skeleton={<IframeSkeleton />}
    url={url ? buildLegacyUrl(url) : url}
    {...props}
  />;
};

export default DocumentIframe;