import React, { useCallback } from 'react';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { buildUrl } from '@/utils/Api';
import { useForm } from 'react-hook-form';
import { OptionType } from '@ui/types';
import { useTranslation } from 'react-i18next';
import Drawer from '@/components/drawer/Drawer';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import useRequest, { ResponseError } from '@/services/request/useRequest';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import useToast from '@/utils/hooks/useToast';
import { useDiscussions } from '../ChatContext';
import Discussion from '@/packages/back-end/Discussion';
import User, { buildOptionWithInstitutionOrDateOfBirth } from '@/packages/back-end/user';
import useApiConstant from '@/utils/hooks/useApiConstant';

const ADD_PATIENTS_URL = (discussionId?: string) =>
  buildUrl(`${discussionId}/participants`);

const GET_DISCUSSION = (discussionId?: string) =>
  buildUrl(`${discussionId}`, undefined, {
    include: 'Document,User,owner,participants,lastMessage,CC,patientsTagged,patientsTagged.user',
  });

const AddParticipantsDrawer = ({ onClose, discussion, setDiscussion }: {
  onClose: () => void;
  discussion: Discussion | null;
  setDiscussion: (discussion: Discussion) => void;
}) => {
  const { t } = useTranslation('chat');
  const { control, watch, resetField } = useForm();
  const { addToast } = useToast();

  const { getConstant } = useApiConstant();

  const users : OptionType<User>[] | undefined = watch('user');

  const { loading, fetchData: addPatientsToDiscussion } = useRequest({
    url: ADD_PATIENTS_URL(discussion?.id),
    skip: true,
    method: 'POST',
  });

  const { loading: getDiscLoading, fetchData: getDiscussion } = useJsonAPIRequest<{ data: Discussion }>({
    url: GET_DISCUSSION(discussion?.id),
    skip: true,
    method: 'GET'
  });

  const buildParticipantOption = useCallback((item) => {
    return buildOptionWithInstitutionOrDateOfBirth(item,getConstant);
  },[getConstant]);

  const handleValidateUsers = () => {
    addPatientsToDiscussion(users?.map((el: OptionType<User>) => el?.data?.attributes._id), {
      throw: true,
      displayToastOnError: true
    }).then((e) => {
      if ((e as ResponseError)?.error) {
        return;
      } else {
        getDiscussion(undefined, {
          throw: true,
          displayToastOnError: true
        }).then(discussionRes => {
          if ((discussionRes as ResponseError)?.error) {
            return;
          }
          setDiscussion((discussionRes as { data: Discussion }).data);
          addToast('success', t('toasts.new_participant_added'));
          onClose();
        });
      }
    });
  };

  return (
    <Drawer
      title={t('header_right.add_participant')}
      onClose={onClose}
    >
      <div className='mx-4 mt-5'>
        <ApiSelectorField<User>
          url={buildUrl('users', undefined, {
            context: 'discussion',
            discussion: discussion?.attributes._id,
          })}
          name={'user'}
          showSelectAll={false}
          closeOnSelectWhenOneOption={true}
          disableCloseOnSelect={false}
          buildOption={buildParticipantOption}
          multiple={true}
          apiSearch={true}
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
        />
        <div className='flex flex-row justify-end mt-5 space-x-2 w-full'>
          <Button onClick={onClose} color='secondary'
            disabled={loading || getDiscLoading}>
            {t('cancel')}
          </Button>
          <Button onClick={handleValidateUsers} type='button'
            saving={loading || getDiscLoading}
            color='primary'>
            {t('save')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const useAddParticipantsDrawer = () => {
  const { openDrawer, closeDrawer } = useDrawerManager();
  const { selectedDiscussion, setSelectedDiscussion } = useDiscussions();
  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onClick = useCallback(
    () => {
      openDrawer({
        content: (
          <AddParticipantsDrawer
            discussion={selectedDiscussion}
            onClose={onClose}
            setDiscussion={setSelectedDiscussion}
          />
        ),
        size: 'basic',
      });
    },
    [onClose, openDrawer, selectedDiscussion, setSelectedDiscussion]
  );

  return { onClick };
};

const AddParticipants = () => {
  const { t } = useTranslation('chat');
  const { onClick } = useAddParticipantsDrawer();

  return (<Button
    title={t('header_right.add_participant')}
    onClick={() => onClick()}
    iconHoverColor='#072337'
    iconColor='#9ba6ae'
    iconSize={20}
    icon='addUser'
    color='transparent'
  />
  );
};

export default AddParticipants;
