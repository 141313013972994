import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@/components/drawer/Drawer';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import HeaderLeft, { HeaderLeftProps } from './HeaderLeft';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';


const useMobileHeaderLeftDrawer = (props: HeaderLeftProps) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onClick = useCallback(() => {
    openDrawer({
      content: <MobileHeaderLeftDrawer onClose={onClose} {...props} />,
      size: 'basic',
    });
  }, [onClose, openDrawer, props]);

  return { onClick };
};

const MobileHeaderLeftDrawer = ({
  onClose,
  ...props
}: Omit<HeaderLeftProps & { onClose: () => void }, 'closeDrawer'>) => {
  const { t } = useTranslation('chat');

  return (
    <Drawer title={t('left_drawer.title')} onClose={onClose}>
      <div className='mt-3 md:w-[50%]'>
        <HeaderLeft closeDrawer={onClose} {...props} />
      </div>
    </Drawer>
  );
};

const MobileHeaderLeft = (props: HeaderLeftProps) => {

  const { onClick } = useMobileHeaderLeftDrawer(props);

  return (
    <Button
      onClick={() => onClick()}
      color='secondary'
      icon='listAlt'
    />
  );
};

export default MobileHeaderLeft;
