import React, { FC, Suspense as BaseSuspense, SuspenseProps } from 'react';
import PageLoader from '@ui/atoms/loaders/page-loader/PageLoader';

const Suspense: FC<Omit<SuspenseProps, 'fallback'>> = ({ children }) => {

  return (<BaseSuspense fallback={<PageLoader fixed />}>
    {children}
  </BaseSuspense>);

};

export default Suspense;