import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Patient, { buildOption, GET_COLLECTION } from '@/packages/back-end/patient';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { getError } from '@/components/form/utils';
import Label from '@ui/atoms/labels/label/Label';
import P from '@ui/atoms/texts/base/P';
import H3 from '@ui/atoms/texts/base/H3';
import useDragToUpload from '@/utils/hooks/useDragToUpload';
import Button from '@ui/atoms/buttons/button/Button';
import { UseUploadAiDocumentReturn } from '@/app/structure/menu/AiDrawer/useUploadAiDocument';
import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import cls from 'classnames';


const UploadAiDocument: FC<Omit<UseUploadAiDocumentReturn,'onSubmit'|'saving'>> = (
  {
    setFiles,
    files,
    setValue,
    register,
    patient,
    watch,
    errors,
    control,
    resetField
  }
) => {

  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'right_bar.ai_drawer'
  });

  const onFileDropped = useCallback((files) => {
    setFiles((prevState) => [...prevState, ...files]);
  }, [setFiles]);

  const { isDragging, ...listeners } = useDragToUpload({
    onFileDropped
  });

  const disallowsSync = files.length >= 5;

  useEffect(() => {

    if(disallowsSync) {
      setValue('sync',false);
    }

  },[disallowsSync,setValue]);

  const selectedPatient = watch('patient');

  return (<div className='flex flex-col flex-grow gap-3 p-3 mb-5'>
    {!patient && (<ApiSelectorField<Patient>
      url={GET_COLLECTION({
        include: 'user'
      })}
      label={t('form.fields.patient')}
      name={'patient'}
      error={getError('patient', errors, t)}
      control={control}
      apiSearch={true}
      buildOption={buildOption}
      resetField={resetField}
    />)}

    {(!!patient || !!selectedPatient) && (<div>
      <Label htmlFor='file' className='mb-2'>{t('form.fields.file.label')}</Label>
      <label htmlFor='file'
        {...listeners}
        className='flex items-center p-8 min-h-[25vh] text-center rounded-md border border-gray-100 cursor-pointer'>
        {isDragging && (<div className='flex flex-col gap-2 w-full'>
          <H3>{t('form.fields.file.title_drag')}</H3>
          <P>{t('form.fields.file.subtitle_drag')}</P>
        </div>)}
        {!isDragging && (<div className='flex flex-col gap-2 w-full'>
          <H3>{t('form.fields.file.title')}</H3>
          <P>{t('form.fields.file.subtitle')}</P>
        </div>)}
      </label>
      <input type='file'
        accept='image/jpeg,image/png,application/pdf,application/zip'
        id='file'
        multiple={true}
        className='hidden'
        onChange={(e) => {
          if (e.target.files) {
            const newFiles = Array.from(e.target.files);
            setFiles((prevState) => [...prevState,...newFiles]);
          }
        }} />

      <div className='flex flex-wrap gap-2 mt-2'>
        {files.map((file) => (<div
          className='flex flex-row gap-4 justify-between items-center px-2 rounded-md border border-gray-100'
          key={file.name}
        >
          <P>{file.name}</P>
          <Button
            color='transparent'
            onClick={() => {
              setFiles((prevState) => [...prevState].filter(item => item !== file));
            }}
            icon='close'
          />
        </div>))}
      </div>
      <Checkbox
        className={cls( disallowsSync ? '!text-gray-500 cursor-not-allowed' : '','mt-4')}
        disabled={disallowsSync}
        helpText={disallowsSync ? t('form.fields.sync.title_disabled') : t('form.fields.sync.help_text')}
        {...register('sync')}
        label={t('form.fields.sync.label')}
      />

    </div>)}
  </div>);

};

export default UploadAiDocument;