import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@ui/atoms/icons/icon/Icon';

import DocumentModal from '@/components/documents/DocumentModal';

import cls from 'classnames';
import Badge from '@ui/atoms/badges/Badge';
import Button from '@ui/atoms/buttons/button/Button';
import { MEDIUM_GRAY } from '@/app/structure/page/chat/messages/MessageComposer';

interface AttachmentCardProps {
  classified?: boolean;
  title?: string;
  fileUrl: string;
  mine?: boolean,
  downloadUrl: string;
  onClick?: () => void;
  onRemove?: () => void;
}

export const AttachmentCard: FC<AttachmentCardProps> = ({
  classified,
  title,
  onRemove,
  mine,
  fileUrl,
  downloadUrl,
  onClick,
}) => {
  const { t } = useTranslation('ms_sante', {
    keyPrefix: 'attachment_template',
  });

  const [openPreview, setOpenPreview] = useState(false);

  const onDocumentClick = mine || !onClick ? () => setOpenPreview(true) : onClick;

  return (
    <>
      <div
        className={cls('py-1 flex max-w-[300px] px-2 bg-white ',
          'rounded-md self-end border border-gray-100 flex-row')}>
        <div className={cls('overflow-hidden flex-grow',onRemove ? 'flex flex-row' : '')}>
          <button onClick={onDocumentClick}>
            <p
              className='text-sm text-left truncate'
              title={title}
            >
              {title}
            </p>
          </button>
          {onRemove && ( <Button
            icon='close'
            iconSize={12}
            color='transparent'
            iconColor={MEDIUM_GRAY}
            onClick={() => onRemove?.()} />)}
          {!mine && (<div className='flex flex-row justify-between items-center'>
            <button onClick={onDocumentClick} className={'flex gap-2 mt-0.5 text-sm'}>
              {classified && (<Badge color='success'>{t('classified')}</Badge>)}
              {!classified && (<Badge color='danger'>{t('to_class')}</Badge>)}
            </button>
            <div className='flex-shrink-0'>
              <div className='flex justify-end items-center'>
                <Button
                  icon='eye'
                  title={t('view_button')}
                  color='transparent'
                  type='button'
                  onClick={(event) => {
                    event.stopPropagation();
                    onClick ? onClick() : setOpenPreview(true);
                  }}
                />
                <a
                  className='flex p-1'
                  download={title || true}
                  target='_blank'
                  title={t('download_button')}
                  href={downloadUrl}
                  onClick={(event) => event.stopPropagation()}
                  rel='noreferrer'
                >
                  <Icon
                    className='w-3 h-3'
                    icon='download'
                  />
                </a>
              </div>
            </div>
          </div>)}
        </div>
      </div>

      {openPreview && (
        <DocumentModal
          url={fileUrl}
          title={title || ''}
          onDismiss={() => setOpenPreview(false)}
        />
      )}
    </>
  );
};

export default AttachmentCard;
