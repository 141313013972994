import React, { FC, useEffect } from 'react';
import PageLoader from '@ui/atoms/loaders/page-loader/PageLoader';
import useDispatch from '@/auth/hooks/useDispatch';
import useLogIn from '@/auth/hooks/useLogIn';

// This is the home for /v2/
const Home : FC = () => {

  const { legacyLogIn } = useLogIn();

  const { dispatch } = useDispatch();

  useEffect(() => {

    legacyLogIn().then(() => {
      dispatch();
    });

  }, [legacyLogIn,dispatch]);

  return (<PageLoader />);

};

export default Home;