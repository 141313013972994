import {
  EventSourcePolyfill,
  MessageEvent,
  Event,
} from 'event-source-polyfill';

import { URL_MERCURE } from '@/constants';
import { useCallback, useEffect, useState } from 'react';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { buildUrl, parseId } from '@/utils/Api';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { MeUser } from '@/packages/back-end/user';
import useRequest from '@/services/request/useRequest';
import { attachRelationships } from './useJsonAPIRequest';

type onMessageType<T> = (data: T) => void;

const getMercureToken = (): string | null => {
  return storage.getItem(StorageKeys.MERCURE_TOKEN);
};

const storeMercureToken = (token: string): void => {
  storage.setJWT(StorageKeys.MERCURE_TOKEN,token);
};

const removeMercureToken = (): void => {
  storage.removeItem(StorageKeys.MERCURE_TOKEN);
};

export type TopicType = 'badges' | 'notifications' | 'messages' | 'discussions';

export const buildTopic = (userId: number, topic: TopicType | string | string[]): string | string[] => {
  let prefix = '';

  if(Array.isArray(topic)) {
    return topic.map((t) => buildTopic(userId, t)) as string[];
  }

  // Be able to override topic if needed
  if(topic.startsWith('/')) {
    return topic;
  }

  if (topic !== 'badges') {
    prefix = '/jsonapi';
  }

  return `${prefix}/users/${userId}/${topic}`;
};

const fetchData = async <T>(
  topic: string | string[],
  lastEventId: string | null,
  mercureToken: string,
  onMessage: (data: T, event: MessageEvent) => void,
  onError: (error: Event) => void,
) => {

  let url = `${URL_MERCURE}`;
  if(Array.isArray(topic)) {
    url += `?topic=${topic.join('&topic=')}`;
  } else {
    url += `?topic=${topic}`;
  }

  if (lastEventId) {
    url += `&Last-Event-ID=${lastEventId}`;
  }

  const source = new EventSourcePolyfill(url, {
    headers: {
      Authorization: `Bearer ${mercureToken}`,
    },
  });

  source.onmessage = (ev: MessageEvent) => {
    const parsedData = JSON.parse(ev.data);
    onMessage(
      parsedData.data ? attachRelationships(parsedData)?.data : parsedData,
      ev
    );
  };

  source.onerror = (ev: Event) => {
    onError(ev);
    // eslint-disable-next-line no-console
  };

  return () => {
    source.close();
  };
};

type MercureTokenType = {
  token: string;
};

const useMercure = <T>(topic: TopicType | string | string[], onMessage: onMessageType<T>) => {
  const { fetchData: fetchMercureToken } = useRequest<MercureTokenType>({
    url: buildUrl('mercure/token'),
    method: 'get',
    skip: true,
  });

  const [lastEventId, setLastEventId] = useState<string | null>(null);
  const [mercureToken, setMercureToken] = useState<string | null>(
    getMercureToken()
  );

  const { user } = useRecoilValue(authAtom) as { user: MeUser };

  const { id: userId } = user;

  useEffect(() => {
    if (mercureToken) {
      return;
    }

    (async () => {

      try {
        const response = await fetchMercureToken({ },{
          throw: true
        }) as MercureTokenType;

        setMercureToken(response.token);
        storeMercureToken(response.token);
      // eslint-disable-next-line no-empty
      } catch {}

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mercureToken]);

  const onError = useCallback((e) => {
    if (e.status === 401) {
      removeMercureToken();
      setMercureToken(null);
    }
  }, []);

  const onSuccess = useCallback(
    (data: T, event: MessageEvent) => {
      setLastEventId(event.lastEventId);
      onMessage(data);
    },
    [onMessage]
  );

  useEffect(() => {
    if (!mercureToken) {
      return;
    }

    if(!topic) {
      return;
    }

    const closingFunctionAsPromise = fetchData<T>(
      buildTopic(parseId(userId) as number, topic),
      lastEventId,
      mercureToken,
      onSuccess,
      onError,
    );

    return () => {
      closingFunctionAsPromise.then((closingFunction) => closingFunction());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mercureToken, topic, onSuccess, onError, userId]);
};

export default useMercure;
