import { Collection as BaseCollection, Item as BaseItem, Relationship } from '@/packages/back-end/jsonapi';
import Subscription from '@/packages/back-end/subscription';
import Service from '@/packages/back-end/service';
import { buildUrl, UrlArg } from '@/utils/Api';
import ServiceEntity from '@/packages/back-end/service-entity';
import Invoice from './invoice';
import TranslatedEntity from '@/packages/back-end/translated-entity';

export enum TYPE {
  core = 'core',
  patient_billing = 'patient_billing',
  lia_credit = 'lia',
  prescription_assistance_software = 'prescription_assistance_software',
  online_appointments = 'online_appointments',
}

export enum AVAILABILITY {
  available = 1,
  soon = 2,
  unavailable = 3,
  premium_required = 4
}

export type Attributes = {
  _id: string,
  name: string,
  canonical: TYPE,
  order?: number
  description: string
  url: string
  image: string
  available?: boolean
  availability: AVAILABILITY,
  credit?: boolean;
  remainingCredits?: number;
}

type SubscriptionGroup = ServiceEntity & TranslatedEntity & {
  attributes: Attributes,
  relationships?: {
    subscription?: {
      data: Relationship | Subscription
    },
    services?: {
      data: Relationship[] | Service[]
    }
    lastInvoice?: BaseItem<Invoice | Relationship>
  }
}

export default SubscriptionGroup;


export type Collection = BaseCollection & {
  data: SubscriptionGroup[],
}

export type Item = BaseItem & {
  data: SubscriptionGroup,
}

export const GET_COLLECTION = buildUrl('subscription_groups');

export const PUT_ITEM =
  (id : string|number, args ?:UrlArg) => { return buildUrl('subscription_groups', id,args);};
export const POST_COLLECTION =
  (args ?: UrlArg) => { return buildUrl('subscription_groups', null,args);};
