import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { attachErrorsToFields } from '@/services/request/utils';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import TextField from '@ui/atoms/fields/text/TextField';

import { getError } from '@/components/form/utils';

import { EnterEmailStepData } from './EnterEmailStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { AuthInfos, storeAuthInfos } from '@/auth/hooks/useLogIn.utils';
import { useBookMeeting } from '../../BookMeetingContext';

type FormDataType = {
  token: string;
};

const schema = yup.object({
  token: yup.string().required('field_required'),
});

export type EnterResetPasswordCodeStepData = {
  token?: string;
};

type EnterResetPasswordCodeStepProps = {
  stepNumber: number;
  open: boolean;
  enterEmailStepData: EnterEmailStepData;
  data: EnterResetPasswordCodeStepData;
  setData: React.Dispatch<React.SetStateAction<EnterResetPasswordCodeStepData>>;
  onToggle: (open: boolean) => void;
  onDone: () => void;
};

const EnterResetPasswordCodeStep: FC<EnterResetPasswordCodeStepProps> = ({
  stepNumber,
  open,
  enterEmailStepData,
  data,
  setData,
  onToggle,
  onDone,
}) => {
  const { email, isEnabled } = enterEmailStepData;

  const { isBookMeetingCreated } = useBookMeeting();

  const keyPrefix = isEnabled
    ? 'enter_reset_password_code_step'
    : 'enter_enable_account_code_step';

  const { stepWrapperId, onScrollToHideStep } = useScrollToHideStep(keyPrefix);

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { loading: requestResetPasswordLoading } = useJsonAPIRequest({
    skip: !!isEnabled,
    method: 'post',
    url: buildUrl('users/reset-password'),
    data: { sendCode: true, username: email },
  });

  const {
    fetchData: validateResetToken,
    loading: saving,
    error: backErrors,
  } = useJsonAPIRequest({
    method: 'post',
    skip: true,
    url: buildUrl('users/reset-password/validate'),
  });

  const {
    fetchData: enableAccount,
    loading: enableAccountLoading,
    error: enableAccountBackErrors,
  } = useJsonAPIRequest<AuthInfos>({
    method: 'post',
    skip: true,
    url: buildUrl('users/enable'),
  });

  const {
    formState: { errors },
    setError,
    register,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async ({ token }) => {
    try {
      if (isEnabled) {
        await validateResetToken(
          { token },
          { throw: true, displayToastOnError: true }
        );
      } else {
        const result = await enableAccount(
          { token },
          { throw: true, displayToastOnError: true }
        );

        storeAuthInfos(result as AuthInfos);

      }

      setData({ token });
      onScrollToHideStep();
      onDone();
      // eslint-disable-next-line no-empty
    } catch {}
  });

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
    attachErrorsToFields(setError, enableAccountBackErrors);
  }, [backErrors, enableAccountBackErrors, setError]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.token}
      status={data.token ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t(`${keyPrefix}.title`)}`}
      subtitle={!!data.token && t(`${keyPrefix}.subtitle`)}
      onToggle={onToggle}
      wrapperTestId='enter_code_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <p className='text-sm font-medium'>{t(`${keyPrefix}.description`)}</p>

        <TextField
          {...register('token')}
          loading={requestResetPasswordLoading}
          label={t(`${keyPrefix}.fields.token`)}
          placeholder={t(`${keyPrefix}.fields.token_placeholder`)}
          error={getError('token', errors, errorT)}
        />

        <Button
          type='submit'
          color='primary'
          loading={requestResetPasswordLoading}
          saving={saving || enableAccountLoading}
          data-testid='enter_code_step.continue'
        >
          {t(`${keyPrefix}.continue`)}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default EnterResetPasswordCodeStep;
