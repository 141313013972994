import React, { FC, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import cls from 'classnames';
import styled from 'styled-components';

import MenuUi from '@ui/organisms/left-menu/LeftMenu';

import useRequest, { isRequestError } from '@/services/request/useRequest';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { LegacyMe } from '@/packages/types';
import { getPathName } from '@/utils/router';

import { formatItems, BackItemsType, formatHeader } from './utils';

import UpgradeModal from '@/components/subscription/core/modals/upgrade/UpgradeModal';
import { useHistory, useLocation } from 'react-router-dom';
import { buildUrl } from '@/utils/Api';
import { ROUTES } from '@/app/structure/body/routes/Shared.utils';
import LiaCreditCounter from '@/app/structure/menu/LiaCreditCounter';
import useBadge from '@/utils/hooks/useBadge';
import useViewport from '@/utils/hooks/useViewport';

const DEFAULT_COLLAPSE_PAGE = [ROUTES.calendar];

interface Props {
  small: boolean;
}

const Container = styled.div<Props>`
  ${({ small }) => small ? 'width: 46px;' : '' }
`;


type MenuType = {
  showMobileMenu: boolean,
  onToggleMenu: () => void;
  user: LegacyMe;
  className?: string;
}

type ChangeInstitutionRequestType = {
  token: string;
  environmentType?: string;
  fullDomain: string;
}

type ChangeInstitutionRequestParamsType = {
  institution: string
}

const Menu: FC<MenuType> = ({ showMobileMenu, onToggleMenu, user, className }) => {
  const [ small, setSmall ] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  const { sm,md } = useViewport();

  const [upgradeModal,setUpgradeModal] = useState<boolean>(false);

  const { loading, data } = useRequest<BackItemsType[], null>({
    skip: false,
    method: 'get',
    abortable: true,
    url: buildUrl('menu')
  });

  const {
    fetchData: changeInstitution
  } = useRequest<ChangeInstitutionRequestType, ChangeInstitutionRequestParamsType>({
    skip: true,
    abortable: true,
    method: 'post',
    url: buildUrl('institutions/switch'),
  });

  const badges = useBadge();


  const items = useMemo(
    () => data ? formatItems(data,() => { setUpgradeModal(true); },history,
      onToggleMenu,
      badges
    ) : [],

    // We put location here to match the proper selected item once the url changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onToggleMenu,badges,location,history,data]);

  const onReduceClick = useCallback(() => {
    if (showMobileMenu) {
      return onToggleMenu();
    }

    setSmall(prevState => (!prevState));
  }, [showMobileMenu, onToggleMenu]);


  const onInstitutionChange = useCallback(({ value }) => {
    changeInstitution({ institution: value }, { throw: true })
      .then((result) => {
        if (!result || isRequestError(result)) {
          return false;
        }

        const { token, fullDomain } = result;

        storage.setJWT(StorageKeys.TOKEN,token);

        window.location.href = fullDomain + getPathName();
      });
  }, [changeInstitution]);

  const header = useMemo(() => {
    return {
      ...formatHeader(user),
      onInstitutionChange,
      onReduceClick
    };
  }, [user, onReduceClick, onInstitutionChange]);

  useLayoutEffect(() => {
    if (DEFAULT_COLLAPSE_PAGE.includes(history.location.pathname)) {
      setSmall(true);
    }
  }, [history]);

  const menuCls = cls(
    [
      'hidden',
      'h-screen',
      'w-menu-large',
      'sm:flex',
      'sm:w-menu-small',
      'z-10',
      'lg:w-menu-large',
      'hover:w-menu-large',
      'bg-gray-900'
    ],
    className,
    showMobileMenu ? '!flex' : ''
  );

  return (
    <Container
      className={menuCls}
      small={small}
      data-test-id='menu-container'
    >
      {upgradeModal && <UpgradeModal onDismiss={() => { setUpgradeModal(false); }} />}
      <MenuUi
        loading={loading}
        items={items}
        small={small}
        header={header}
        logoutLink={'/logout'}
      />
      {!small && !md && !sm && (<LiaCreditCounter />)}
    </Container>
  );
};

export default Menu;
