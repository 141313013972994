import React, { FC } from 'react';
import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import Iframe, { IframeProps } from '@/components/iframe/Iframe';

const SkeletonItem: FC = () => (<div
  className='flex flex-col flex-grow flex-shrink gap-4 items-center py-8 px-4 rounded-md border border-gray-50'>
  <Skeleton size='large' className='w-12 rounded-2xl' />
  <Skeleton size='base' className='w-40' />
</div>);


const IframeSkeleton: FC = () => {

  return (
    <div className='flex flex-col mt-4'>
      <div className='flex flex-wrap gap-3 items-baseline p-4 w-full bg-white border-0'>
        <Skeleton size='large' className='w-32' />
        <Skeleton size='large' className='w-32' />
        <Skeleton size='large' className='w-32' />
      </div>
      <div className='flex flex-wrap flex-grow gap-2 items-baseline p-4 w-full h-auto bg-white border-0'>
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
      </div>
    </div>
  );

};



type MetabaseIframeProps = Omit<IframeProps, 'skeleton'>

const MetabaseIframe: FC<MetabaseIframeProps> = (props) => {

  return <Iframe
    forceWidth={true}
    maxWidth={'100vw'}
    className='mt-4'
    skeleton={<IframeSkeleton />}
    {...props}
  />;
};

export default MetabaseIframe;