import React, { useCallback, useState } from 'react';
import { PatientTagged } from '../types';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import UserAvatar from 'instamed-styleguide/ui/molecules/user-avatar/UserAvatar';
import { formatDate } from '@/components/date-format/dateFormat';
import Link from 'instamed-styleguide/ui/atoms/texts/link/Link';
import { buildLegacyUrl } from '@/utils/Legacy';
import { buildUrl, parseId } from '@/utils/Api';
import User, { isMedicalStaff } from '@/packages/back-end/user';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';
import useRequest, { ResponseError } from '@/services/request/useRequest';
import { useDiscussions } from '../ChatContext';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import Modal from 'instamed-styleguide/ui/molecules/modal/Modal';
import { useTranslation } from 'react-i18next';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import useToast from '@/utils/hooks/useToast';
import Discussion from '@/packages/back-end/Discussion';

export const IconWithTitle = ({
  icon,
  title,
  className,
}: {
  icon: string;
  title: string;
  className?: string;
}) => (
  <div className={`flex flex-row w-3/12 ${className}`}>
    <Icon icon={icon} width={20}
      height={20} color='#9CA7AF' />
    <p className='ml-2 text-sm font-medium'>{title}</p>
  </div>
);

export const TitleList = ({
  participants,
  title,
  icon,
}: {
  participants: User[];
  title: string;
  icon: string;
}) => (
  <div className='flex flex-row mt-8 ml-4 w-full'>
    <IconWithTitle icon={icon} title={title} />
    <div className='ml-2 w-7/12'>
      {participants?.map((participant) =>
        !participant?.attributes ? (
          <></>
        ) : (
          <DiscussionsUser
            participant={participant}
            key={`${participant.id}-drawer-participants-row`}
          />
        )
      )}
    </div>
  </div>
);

export const PatientsTaggedList = ({
  participants,
  title,
  icon,
}: {
  participants: PatientTagged[];
  title: string;
  icon: string;
}) => {
  const renderTaggedPatients = useCallback(
    () =>
      participants?.map((patientTagged) => {
        const userTagged = patientTagged?.relationships?.user?.data?.attributes;
        return (
          <div
            key={`${userTagged.id}-drawer-participants-row`}
            className='flex flex-row pb-1'
          >
            <UserAvatar
              {...getAvatarProps({
                user: userTagged as UserType,
                colorLength: 10, })}
              size='xs'
              className='mr-2'
            />
            <div className='flex flex-col'>
              <Link
                href={buildLegacyUrl(
                  `/patients/user/${parseId(userTagged._id)}`
                )}
                title={userTagged.fullName}
                target='_blank'
              >
                <p className='text-sm !text-gray-900'>{userTagged.fullName}</p>
                {userTagged.dateOfBirth && (
                  <p className='text-sm !text-gray-900'>
                    {formatDate(userTagged.dateOfBirth, {
                      format: 'DD/MM/YYYY',
                    })}
                  </p>
                )}
              </Link>
            </div>
          </div>
        );
      }),
    [participants]
  );

  return (
    <div className='flex flex-row mt-8 ml-4 w-full'>
      <IconWithTitle icon={icon} title={title} />
      <div className='ml-2'>{renderTaggedPatients()}</div>
    </div>
  );
};

const DiscussionsUser = ({ participant }: { participant: User }) => {
  const {
    selectedDiscussion,
    discussions,
    setLoadingSelectedDiscussion,
    setSelectedDiscussion,
    setDiscussions,
  } = useDiscussions();
  const { t } = useTranslation('chat');
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { user } = useRecoilValue(authAtom);
  const { addToast } = useToast();
  const { fetchData: removeParticipant, loading } = useRequest({
    url: buildUrl(
      `${selectedDiscussion?.id}/participants/${participant.attributes._id}`
    ),
    method: 'DELETE',
    skip: true,
  });

  const handleRemoveParticipant = async () => {
    const res = await removeParticipant(null, {
      throw: true,
      displayToastOnError: true,
    });

    const err = res as ResponseError;
    if (err.error) {
      setOpenDeleteModal(false);
      return;
    } else {
      addToast('success', t('toasts.participant_deleted'));

      const newParticipantList =
        selectedDiscussion?.relationships.participants.data.filter(
          (tmpParticipant) => tmpParticipant.id !== participant.id
        );
      if (selectedDiscussion !== undefined) {
        (selectedDiscussion as Discussion).relationships.participants.data =
          newParticipantList as User[];
      }

      setSelectedDiscussion(selectedDiscussion as Discussion);
      const newDiscussions = discussions.filter(
        (tmpDiscussion) => tmpDiscussion.id !== selectedDiscussion?.id
      );
      newDiscussions.push(selectedDiscussion as Discussion);
      setDiscussions(newDiscussions);
      setLoadingSelectedDiscussion(true);
    }
    setOpenDeleteModal(false);
    setLoadingSelectedDiscussion(false);
  };

  const readIds = selectedDiscussion?.attributes.read;
  const receivedIds = selectedDiscussion?.attributes.received;

  const filteredReadParticipants = selectedDiscussion?.relationships.participants.data.filter(
    (participant) => readIds?.includes(participant.attributes._id as number) 
    && participant.attributes._id !== user?.attributes._id
  ) || [];

  const filteredReceivedParticipants = selectedDiscussion?.relationships.participants.data.filter(
    (participant) => receivedIds?.includes(participant.attributes._id as number) 
    && participant.attributes._id !== user?.attributes._id
  ) || [];

  return (
    <div className='flex flex-row items-center pb-1'>
      <UserAvatar
        {...getAvatarProps({
          user: participant?.attributes as UserType,
          colorLength: 10,
        })}
        src={
          participant?.attributes?.thumbnails?.profilePicture?.[
            '50x50'
          ] as string
        }
        size='xs'
        className='mr-2'
      />
      {isMedicalStaff(participant) ? (
        <p className='text-sm'>{participant.attributes.fullName}</p>
      ) : (
        <Link
          href={buildLegacyUrl(
            `/patients/user/${parseId(participant.attributes._id)}`
          )}
          title={participant.attributes.fullName}
          target='_blank'
        >
          <p className='text-sm text-gray-900'>
            {participant.attributes.fullName}
          </p>
        </Link>
      )}
      {isMedicalStaff(user) &&
        (selectedDiscussion?.relationships.participants.data.length ?? 0) > 2 &&
        participant?.attributes?.rights?.remove_participant && (
        <button
          className='px-[2px] ml-2 h-[15px] bg-gray-900 rounded-full'
          onClick={() => setOpenDeleteModal(true)}
        >
          <Icon icon='close' className='w-[10px] h-[10px]'
            color='#ffffff' />
        </button>
      )}
      {participant?.id !== user?.id && <Icon
        className='ml-1'
        title={filteredReadParticipants?.length ? t('message_receiver.read') : ''}
        icon={filteredReadParticipants?.length ? 'readNotification' 
          : filteredReceivedParticipants?.length ? 'check' : 'inbox'}
        color={'#97a2ad'}
        
      />}
      {openDeleteModal && (
        <Modal title={t('delete_participant.title')}>
          <div className='flex flex-col'>
            <p>{t('delete_participant.subtitle')}</p>
            <div className='flex flex-row justify-end mt-3'>
              <Button
                color='secondary'
                disabled={loading}
                title={t('cancel')}
                className='mr-2'
                onClick={() => setOpenDeleteModal(false)}
              >
                <p>{t('cancel')}</p>
              </Button>
              <Button
                color='primary'
                saving={loading}
                title={t('yes')}
                onClick={handleRemoveParticipant}
              >
                <p>{t('yes')}</p>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
