import get from 'lodash/get';

import { AVATAR_BACKGROUNDS, UserAvatarType } from '@ui/molecules/user-avatar/UserAvatar';

import { LegacyMe } from '@/packages/types';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';

export const sizeThumb = {
  xs: '50x50',
  base: '50x50',
  xl: '50x50',
  xxl: '100x100'
};


type argOfGetUserAvatarType = {
  user: LegacyMe,
  size: string
}

export type getUserAvatarInfosType = (args: argOfGetUserAvatarType) => UserAvatarType;

export const getUserAvatarInfos: getUserAvatarInfosType = ({ user, size }) => {
  const avatarProps = getAvatarProps({ user, colorLength: AVATAR_BACKGROUNDS.length }) as UserAvatarType;

  return {
    ...avatarProps,
    src: get(user, `thumbnails.profilePicture.${get(sizeThumb, size)}`) ?? user.profilePicture,
  };
};
