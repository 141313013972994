import React, { useCallback } from 'react';

import Patient from '@/packages/back-end/patient';

import { onCloseType } from '@/components/drawer/useUpdateDrawer';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import SearchPatientDrawer from './SearchPatientDrawer';

const useSearchPatientDrawer = ({
  onCloseCallback,
}: {
  onCloseCallback?: onCloseType<Patient>;
} = {}) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onOpen = useCallback(() => {

    openDrawer({
      content: (
        <SearchPatientDrawer
          onClose={closeDrawer}
          onCloseCallback={onCloseCallback}
        />
      ),
      size: 'medium',
    });
  }, [
    openDrawer,
    closeDrawer,
    onCloseCallback,
  ]);

  return { onOpen, closeDrawer };
};

export default useSearchPatientDrawer;
