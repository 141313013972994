export const ROUTES = {
  admin: {
    dashboard: '/v2/admin/dashboard',
    medical_record_fields: '/v2/admin/medical_record_fields',
    mobile_app_versions: '/v2/admin/mobile_app_versions',
    openai_playground: '/v2/admin/openai_playground',
    patient_shared: '/v2/admin/patients_shared',
    subscription_groups: '/v2/admin/subscription_groups',
    audit_trail: '/v2/admin/audit_trail',
    specialties: '/v2/admin/specialties',
    medical_record_tabs: '/v2/admin/medical_record_tabs',
    medical_record_sections: '/v2/admin/medical_record_sections',
    research_create: '/v2/admin/users/research',
    event_types: '/v2/admin/event_types',
    consultation_locations: '/v2/admin/consultation_locations',
    treatments: '/v2/admin/treatments',
  }
};