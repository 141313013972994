import React, { useCallback, useState } from 'react';

import Notification from '@/packages/back-end/notification';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';

import Notifications from './Notifications';
import useMercure from '@/services/request/useMercure';
import useRequest from '@/services/request/useRequest';
import { addUrlParameters, buildUrl } from '@/utils/Api';
import { redirectToPath } from '@/auth/hooks/useDispatch';
import { useHistory } from 'react-router-dom';

function isDifferentDomain(url : string) {
  try {
    const targetUrl = new URL(url);
    return targetUrl.hostname !== window.location.hostname;
  } catch (_e) {
    return false;
  }
}

export type UseNotificationsType = (count: number) => {
  onClick: () => void,
  markAsRead: (item: Notification) => void,
  floatingNotification: Notification | null
};

const useNotifications: UseNotificationsType = (count) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const history = useHistory();

  const { fetchData: markNotificationAsRead } = useRequest({
    skip: true,
    url: '/notifications',
    method: 'PUT',
  });

  const [floatingNotification, setFloatingNotification] = useState<Notification | null>(null);

  const onMercureMessage = useCallback((notification: Notification) => {
    setFloatingNotification(notification);

    setTimeout(() => {
      setFloatingNotification(null);
    }, 5000);

  }, []);

  useMercure<Notification>('notifications', onMercureMessage);


  const markAsRead = useCallback(async (item: Notification) => {
    const id = item.attributes._id;
    const url = item.attributes.data.url;

    if (!item.attributes.read) {
      await markNotificationAsRead({ read: true }, {
        url: buildUrl('notifications', id)
      });
    }

    const realUrl = addUrlParameters(url, { notification: buildUrl('notifications', id) });

    redirectToPath(realUrl,history,isDifferentDomain(url));
    closeDrawer();
  }, [closeDrawer,history,markNotificationAsRead]);


  const onClick = useCallback(() => {
    openDrawer({ content: <Notifications
      markAsRead={markAsRead}
      count={count}
      onClose={closeDrawer} />, size: 'small' });
  }, [markAsRead,count, openDrawer, closeDrawer]);

  return { onClick, floatingNotification, markAsRead };
};

export default useNotifications;
