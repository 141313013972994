import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Control,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form/dist/types/form';

import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import TextField from '@ui/atoms/fields/text/TextField';

import ConstantSelector from '@/components/form/fields/constant-selector';
import { getError, removeId } from '@/components/form/utils';
import AddressField from '@/components/address-field/AddressField';
import { ResponseData } from '@/components/form/UpdateForm';
import PhoneInput from '@/components/form/fields/phone/PhoneInput';
import DateOfBirthField from '@/components/form/fields/date-of-birth/DateOfBirthField';
import ServiceField from '@/components/form/fields/service/ServiceField';
import SocialSecurityNumberField from '@/components/form/fields/social-security-number/SocialSecurityNumberField';
import {
  parseMultipleSelectorValue,
  parseSimpleSelectorValue,
} from '@/utils/form';
import ApiSelectorField from '@/components/form/fields/api-selector';
import MedicProfile, {
  GET_COLLECTION,
  buildOptionWithUserIdAsValue
} from '@/packages/back-end/medic-profile';
import { ROLE } from '@/packages/types';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { OptionType } from '@ui/organisms/selectors/types';

export type PatientFormDataType = {
  title?: OptionType;
  email?: string;
  firstName?: string;
  lastName?: string;
  givenName?: string;
  phones?: string;
  externalIds?: Record<string, string>;
  medicalTeam?: OptionType[] | null;
  services?: OptionType[] | null;
  dateOfBirth?: string;
  address?: {
    id?: string;
    address?: string;
    addressExtension?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
  };
  socialSecurityNumber?: string;
  sendInvitationEmail?: boolean;
  sendInvitationSMS?: boolean;
  blockAllCommunications?: boolean;
};

export const parsePatientData = (data: PatientFormDataType) => {

  const address = removeId(data.address);

  const addresses = address?.address ? [address] : [];

  return {
    user: {
      title: parseSimpleSelectorValue(data.title),
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      externalIds: data.externalIds,
      givenName: data.givenName,
      phones: [{ number: data.phones }],
      services: parseMultipleSelectorValue(data.services),
      dateOfBirth: data.dateOfBirth,
    },
    addresses,
    medicalTeam: parseMultipleSelectorValue(data.medicalTeam),
    socialSecurityNumber: data.socialSecurityNumber?.replaceAll(' ', ''),
    sendInvitationEmail: data.sendInvitationEmail,
    sendInvitationSMS: data.sendInvitationSMS,
    blockAllCommunications: data.blockAllCommunications,
  };
};

type PatientFormFieldsProps = {
  errors: FieldErrors;
  control: Control;
  loadingPatientSettings?: boolean;
  register: UseFormRegister<PatientFormDataType>;
  resetField: UseFormResetField<PatientFormDataType>;
  watch: UseFormWatch<PatientFormDataType>;
  setValue: UseFormSetValue<PatientFormDataType>;
};

const PatientFormFields: FC<PatientFormFieldsProps> = ({
  control,
  loadingPatientSettings,
  errors,
  resetField,
  register,
  watch,
  setValue,
}) => {

  const { user } = useRecoilValue(authAtom);

  const [displayMedicalTeamField,setDisplayMedicalTeamField] = useState(
    [ROLE.PARAMEDICAL,ROLE.COORDINATOR].includes(user?.attributes.role as ROLE));

  const { t: errorT } = useTranslation('patient', {
    keyPrefix: 'form_errors',
  });
  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.form.fields',
  });

  return (
    <div className='flex flex-col flex-grow gap-6'>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
        <ConstantSelector
          label={t('title')}
          entity='user'
          property='title'
          name='title'
          error={getError('title', errors, errorT)}
          control={control}
          resetField={resetField}
        />
        <TextField
          label={t('first_name')}
          {...register('firstName')}
          error={getError('firstName', errors, errorT)}
        />
        <TextField
          label={t('last_name')}
          {...register('lastName')}
          error={getError('lastName', errors, errorT)}
        />
        <TextField
          label={t('given_name')}
          {...register('givenName')}
          error={getError('givenName', errors, errorT)}
        />
      </div>

      <DateOfBirthField
        name='dateOfBirth'
        label={t('date_of_birth')}
        error={getError('dateOfBirth', errors, errorT)}
        control={control}
      />

      <SocialSecurityNumberField
        label={t('social_security_number')}
        name='socialSecurityNumber'
        error={getError('socialSecurityNumber', errors, errorT)}
        control={control}
      />

      <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
        <TextField
          label={t('email')}
          {...register('email')}
          error={getError('email', errors, errorT)}
        />
        <PhoneInput
          label={t('phone')}
          className='w-full'
          {...register('phones')}
          error={getError('phones', errors, errorT)}
        />
      </div>
      <div className='grid grid-cols-12 gap-5'>
        <AddressField
          className='md:!col-span-6'
          alwaysShowAddressExtension
          t={t}
          loading={false}
          name='address'
          errors={errors}
          addressExtension={watch('address.addressExtension')}
          watch={watch as unknown as UseFormWatch<ResponseData>}
          register={register as unknown as UseFormRegister<ResponseData>}
          setValue={setValue as unknown as UseFormSetValue<ResponseData>}
        />
      </div>
      {displayMedicalTeamField && (<ApiSelectorField<MedicProfile>
        name='medicalTeam'
        multiple={true}
        label={t('medical_team')}
        buildOption={buildOptionWithUserIdAsValue}
        url={GET_COLLECTION({
          mine: true,
          include: 'user'
        })}
        onLoaded={(values) => {
          if (values.length < 2) {
            setDisplayMedicalTeamField(false);
          }
        }}
        control={control}
        error={getError('medicalTeam', errors, errorT)}
        resetField={resetField}
      />)}

      {user?.attributes.editableProviders?.map((item) =>
        (<TextField
          key={item}
          label={t('external_id', {
            id: item
          })}
          {...register(`externalIds.${item}`)}
          error={getError(`externalIds.${item}`, errors, errorT)}
        />)
      )}

      <ServiceField
        name='services'
        label={t('services')}
        hideIfSingleValue={true}
        control={control}
        error={getError('services', errors, errorT)}
        resetField={resetField}
      />
      <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center -mt-2'>
        <div className='mt-2 text-xs font-semibold text-gray-900'>
          {t('notifications')}
        </div>
        <Checkbox
          //** Id is set like this, else it may collide with the ids in the "main" page
          id={'patient-send-invitation-email'}
          loading={loadingPatientSettings}
          label={t('send_activation_email')}
          error={getError('sendInvitationEmail', errors, errorT)}
          {...register('sendInvitationEmail')}
        />
        <Checkbox
          id={'patient-send-invitation-sms'}
          loading={loadingPatientSettings}
          label={t('send_activation_sms')}
          error={getError('sendInvitationSMS', errors, errorT)}
          {...register('sendInvitationSMS')}
        />
      </div>
      <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 sm:items-center -mt-2'>
        <Checkbox
          id={'patient-block-all-communications'}
          loading={loadingPatientSettings}
          label={t('block_all_communications')}
          error={getError('blockAllCommunications', errors, errorT)}
          helpText={t('block_all_communications_help')}
          {...register('blockAllCommunications')}
        />
      </div>
    </div>
  );
};

export default PatientFormFields;
