import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook that builds on useLocation to parse
 * the query string for you.
 **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useURLParams = <T extends Record<string, any>>(args: string[]): T => {
  const { search } = useLocation();

  return useMemo(
    () => {
      const searchParams = new URLSearchParams(search);

      return args.reduce(
        (o, k) => ({ ...o, [k]: searchParams.get(k) }),
        {}
      ) as T;
    },
    [search, args]
  );
};

export default useURLParams;
