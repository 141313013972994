import React, { FC, useEffect, useState } from 'react';

import { MeUser } from '@/packages/back-end/user';

import ConfirmPhoneNumberSteps from './ConfirmPhoneNumberSteps';

import { flatProfileMeRelationships } from '../utils';
import LoginAccountSteps, { LoginAccountStepsData } from './LoginAccountSteps';
import { useBookMeeting } from '../BookMeetingContext';

const checkShouldShowAccountSteps = (params: {
  initMeUser: MeUser | undefined;
  meUserAfterLogin: MeUser | undefined;
}) => {
  const { initMeUser, meUserAfterLogin } = params;

  const { phoneNumberMe } = flatProfileMeRelationships(meUserAfterLogin);

  const shouldLoginAccountSteps = !initMeUser;

  const shouldConfirmPhoneSteps =
    !phoneNumberMe || !phoneNumberMe.attributes.confirmed;

  return {
    should: shouldLoginAccountSteps || shouldConfirmPhoneSteps,
    shouldLoginAccountSteps,
    shouldConfirmPhoneSteps,
  };
};

export type AccountStepsData = {
  createAccountStepData?: LoginAccountStepsData;
};

type AccountStepsProps = {
  startStepNumber: number;
  onDone: () => void;
};

const AccountSteps: FC<AccountStepsProps> = ({ startStepNumber, onDone }) => {
  const {
    initMeUser,
    meUserAfterLogin,
    setMeUserAfterLogin,
    setNumOfAccountSteps,
    isCreatedNewAccount,
    isForceHideConfirmPhoneNumberStep,
  } = useBookMeeting();

  const {
    should: shouldShowAccountSteps,
    shouldLoginAccountSteps,
    shouldConfirmPhoneSteps,
  } = checkShouldShowAccountSteps({
    initMeUser,
    meUserAfterLogin,
  });

  const [doneAccountSteps, setDoneAccountSteps] = useState(!shouldLoginAccountSteps);
  const [numOfLoginAccountSteps, setNumOfLoginAccountStep] = useState(0);

  useEffect(() => {
    let count = 0;

    if (shouldLoginAccountSteps) {
      count += numOfLoginAccountSteps;
    }

    if (shouldConfirmPhoneSteps) {
      if (!isCreatedNewAccount) {
        count += 1;
      }
      if (!isForceHideConfirmPhoneNumberStep) {
        count += 1;
      }
    }
    setNumOfAccountSteps(count);
  }, [
    shouldLoginAccountSteps,
    shouldConfirmPhoneSteps,
    numOfLoginAccountSteps,
    setNumOfAccountSteps,
    isCreatedNewAccount,
    isForceHideConfirmPhoneNumberStep,
  ]);

  const showConfirmPhoneSteps =
    shouldConfirmPhoneSteps && (!shouldLoginAccountSteps || doneAccountSteps);

  const startConfirmPhoneStepsNumber = shouldLoginAccountSteps
    ? startStepNumber + numOfLoginAccountSteps
    : startStepNumber;

  if (!shouldShowAccountSteps) {
    return null;
  }

  return (
    <>
      {shouldLoginAccountSteps && (
        <LoginAccountSteps
          startStepNumber={startStepNumber}
          setNumOfSteps={setNumOfLoginAccountStep}
          onDone={(newMeUser) => {
            setDoneAccountSteps(true);
            setMeUserAfterLogin(newMeUser);

            const newShould = checkShouldShowAccountSteps({
              initMeUser: newMeUser,
              meUserAfterLogin: newMeUser,
            });
            if (!newShould.shouldConfirmPhoneSteps) {
              onDone();
            }
          }}
        />
      )}

      {showConfirmPhoneSteps && (
        <ConfirmPhoneNumberSteps
          startStepNumber={startConfirmPhoneStepsNumber}
          onDone={onDone}
        />
      )}
    </>
  );
};

export default AccountSteps;
