import React, { FC, useEffect, useState } from 'react';

import EnterPhoneNumberStep, {
  EnterPhoneStepData,
} from './EnterPhoneNumberStep';
import ConfirmPhoneNumberStep, {
  ConfirmPhoneStepData,
} from './ConfirmPhoneNumberStep';
import { useBookMeeting } from '../../BookMeetingContext';
import { flatProfileMeRelationships } from '../../utils';

type ConfirmPhoneNumberStepsProps = {
  startStepNumber: number;
  onDone: () => void;
};

const ConfirmPhoneNumberSteps: FC<ConfirmPhoneNumberStepsProps> = ({
  startStepNumber,
  onDone,
}) => {
  const {
    isCreatedNewAccount,
    meUserAfterLogin,
    isForceHideConfirmPhoneNumberStep,
    setIsForceHideConfirmPhoneNumberStep,
  } = useBookMeeting();

  const [openEnterPhoneStep, setOpenEnterPhoneStep] = useState(true);
  const [openConfirmPhoneStep, setOpenConfirmPhoneStep] = useState(false);

  const [enterPhoneStepData, setEnterPhoneStepData] =
    useState<EnterPhoneStepData>({});
  const [confirmPhoneStepData, setConfirmStepData] =
    useState<ConfirmPhoneStepData>({});
  
  useEffect(() => {
    if (isCreatedNewAccount && meUserAfterLogin) {
      const { phoneNumberMe } = flatProfileMeRelationships(meUserAfterLogin);
      setEnterPhoneStepData({ phone: phoneNumberMe });
      setOpenConfirmPhoneStep(true);
    }
  }, [isCreatedNewAccount, meUserAfterLogin]);

  return (
    <>
      {!isCreatedNewAccount && (
        <EnterPhoneNumberStep
          stepNumber={startStepNumber}
          open={openEnterPhoneStep}
          onToggle={setOpenEnterPhoneStep}
          confirmPhoneStepData={confirmPhoneStepData}
          data={enterPhoneStepData}
          setData={setEnterPhoneStepData}
          onDone={() => {
            setOpenEnterPhoneStep(false);
            if (confirmPhoneStepData.confirmed) {
              setOpenConfirmPhoneStep(false);
              setIsForceHideConfirmPhoneNumberStep(true);
              onDone();
            } else {
              setOpenConfirmPhoneStep(true);
            }
          }}
        />
      )}

      {!isForceHideConfirmPhoneNumberStep && !!enterPhoneStepData.phone && (
        <ConfirmPhoneNumberStep
          stepNumber={startStepNumber + (isCreatedNewAccount ? 0 : 1)}
          open={openConfirmPhoneStep}
          onToggle={setOpenConfirmPhoneStep}
          data={confirmPhoneStepData}
          setData={setConfirmStepData}
          enterPhoneStepData={enterPhoneStepData}
          setEnterPhoneStepData={setEnterPhoneStepData}
          onDone={() => {
            setOpenConfirmPhoneStep(false);
            onDone();
          }}
        />
      )}
    </>
  );
};

export default ConfirmPhoneNumberSteps;
