import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Item } from '@/packages/back-end/jsonapi';
import EventType from '@/packages/back-end/event-types';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import BaseSkeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import { buildUrl } from '@/utils/Api';

import OptionCard from './OptionCard';
import { flatMedicProfileRelationships } from '../utils';
import { useBookMeeting } from '../BookMeetingContext';
import { useScrollToHideStep } from '../useScrollToHideStep';
import H3 from '@ui/atoms/texts/base/H3';
import Button from '@ui/atoms/buttons/button/Button';

const Empty: FC<{ goToPreviousStep?: () => void }> = ({ goToPreviousStep }) => {

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.select_consultation_location.empty',
  });

  return (<div className='flex flex-col gap-4 justify-center px-8 mt-4 text-center'>
    <H3>{t('title')}</H3>
    <p>{t('subtitle')}</p>
    {goToPreviousStep && (<div className='flex justify-center' >
      <Button onClick={goToPreviousStep} color='primary'>{t('button')}</Button>
    </div>)}
  </div>);

};

const Skeleton: FC = () => {
  return (
    <div className='flex flex-col gap-4 w-full'>
      <BaseSkeleton className='w-full h-[66px]' />
      <BaseSkeleton className='w-full h-[66px]' />
      <BaseSkeleton className='w-full h-[66px]' />
    </div>
  );
};

export type SelectEventStepData = {
  eventType?: EventType;
};


type SelectEventStepProps = {
  stepNumber: number;
  open: boolean;
  onToggle: (open: boolean) => void;
  data: SelectEventStepData;
  setData: React.Dispatch<React.SetStateAction<SelectEventStepData>>;
  onDone: () => void;
};

const SelectEventStep: FC<SelectEventStepProps> = ({
  stepNumber,
  open,
  onToggle,
  data,
  setData,
  onDone,
}) => {
  const { defaultData,profileDoctor,consultationLocation, isBookMeetingCreated,setDefaultData } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('select_event_step');

  const { user } = flatMedicProfileRelationships(profileDoctor);

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { loading, data: bookableEventTypesData,fetchData } = useJsonAPIRequest<
    Item<EventType[]>
  >({
    method: 'GET',
    url: buildUrl(`users/${user?.attributes?._id}/bookable_event_types`,undefined,{
      consultation_location: consultationLocation?.id,
      availability: defaultData?.bookingSlot?.relationships?.availability?.data?.id,
    }),
  });

  const bookableEventTypes = bookableEventTypesData?.data || [];

  const resetSelection = useCallback(() => {
    setDefaultData((prev) => ({
      publicProfile: prev?.publicProfile,
      bookingSlot: undefined,
      consultationLocation: prev?.consultationLocation,
      eventType: undefined,
      publicProfileSearch: undefined,
    }));

    fetchData();

  },[setDefaultData,fetchData]);

  const hasOtherEventTypes = bookableEventTypes.length < (profileDoctor?.attributes?.eventTypeCount ?? 0);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.eventType}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={data.eventType ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('select_event_step.title')}`}
      subtitle={data.eventType?.attributes?.name}
      onToggle={onToggle}
      wrapperTestId='select_event_step'
    >
      <div className='flex flex-col gap-4 w-full'>
        {loading ? (
          <Skeleton />
        ) : (
          bookableEventTypes.map((eventType) => (
            <OptionCard
              key={eventType.id}
              selected={eventType.id === data.eventType?.id}
              label={eventType.attributes.name}
              onClick={() => {
                setData({ ...data, eventType });
                onScrollToHideStep();
                onDone();
              }}
            />
          ))
        )}

        {!loading && !!defaultData?.publicProfileSearch && hasOtherEventTypes && (
          <button onClick={resetSelection} className='text-xs font-semibold text-left text-primary-500 cursor-pointer'>
            <span>{t('select_event_step.note',{
              name: defaultData?.publicProfileSearch?.attributes?.fullName
            })}</span>
          </button>
        )}

        {!loading && !bookableEventTypes.length && <Empty />}
      </div>
    </RetractableItem>
  );
};

export default SelectEventStep;
