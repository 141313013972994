import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, {
  MouseEventHandler,
  useCallback,
  useEffect, useMemo,
  useRef,
  useState,
} from 'react';
import cls from 'classnames';
import { useWavesurfer } from '@wavesurfer/react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/components/date-format/dateFormat';
import { useMessageContext } from '../messages/MessageContext';

type AudioProps = {
  downloadUrl?: string;
  fileUrl: string;
  ownerName?: string;
  uploadedDate?: string;
};
export const AudioPlayer = ({
  audio,
  width = 150,
  height = 20,
  iconSize = 15,
  className,
}: {
  audio: AudioProps;
  primaryColor?: string;
  width?: number;
  height?: number;
  iconSize?: number;
  className?: string;
}) => {
  const containerRef = useRef(null);
  const [playbackRate, setPlaybackRate] = useState(1);
  const { t } = useTranslation('chat');
  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: height,
    width: width,
    waveColor: '#bec4ca',
    progressColor: '#072338',
    url: audio?.fileUrl,
    cursorWidth: 0,
    barWidth: 2,
    barHeight: 10,
    barGap: 2,
  });

  const onPlayPause = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  }, [wavesurfer]);

  useEffect(() => {
    if (currentTime === wavesurfer?.getDuration()) {
      wavesurfer.stop();
      wavesurfer.setTime(0);
    }
  });

  function formatTime() {
    const time = wavesurfer?.getDuration();
    if (time) {
      let seconds = Math.floor(time);

      const minutes = Math.round(seconds / 60);
      seconds = seconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedMinutes}:${formattedSeconds}`;
    }
    return '00:00';
  }

  const handleChangePlaybackRate = () => {
    if (playbackRate === 2) {
      const newValue = 1;
      setPlaybackRate(newValue);
      wavesurfer?.setPlaybackRate(newValue);
    } else {
      const newValue = playbackRate + 0.25;
      wavesurfer?.setPlaybackRate(newValue);
      setPlaybackRate(newValue);
    }
    onPlayPause();
  };

  const handleDownloadFile: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (audio?.downloadUrl) {
      e.preventDefault();
      const link = document.createElement('a');
      const ownerName = audio?.ownerName?.replaceAll(' ', '_');
      const uploadedDate = formatDate(audio?.uploadedDate, {
        format: 'DD-MM-YYYY',
      });

      link.href = audio?.downloadUrl;
      link.download = t('vocal_message.vocal_note_of', {
        ownerName,
        date: uploadedDate,
        duration: formatTime(),
      });

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(audio?.downloadUrl);
    }
  };

  return (
    <div className={cls('py-1 px-2 my-3 bg-white rounded-md border border-[#bec4ca]', className)}>
      <div className='flex flex-row justify-between items-center my-auto mr-3 w-full rounded-md'>
        <button
          type='button'
          onClick={onPlayPause}
          onContextMenu={handleDownloadFile}
          className='flex flex-row items-center'
        >
          <Icon
            icon={isPlaying ? 'stop' : 'play'}
            width={iconSize}
            height={iconSize}
            color={'#517085'}
          />
          <div ref={containerRef} className='ml-2' />
        </button>
        <div className='flex flex-col justify-end ml-3'>
          <button
            type='button'
            className='p-[2px] w-8 rounded-md border border-gray-700'
            onClick={handleChangePlaybackRate}
          >
            <p className='px-[2px] text-[10px] text-center text-gray-600'>{`${playbackRate}x`}</p>
          </button>
          <p className='text-[10px] text-gray-600'>{formatTime()}</p>
        </div>
      </div>
    </div>
  );
};

export const AudioToUpload = () => {
  const { files, setFiles } = useMessageContext();

  const blobs = useMemo(() => {
    return files.audio.map((item) => URL.createObjectURL(item));
  },[files]);

  const removeFileAudio = (idx: number) => {
    files.audio.splice(idx, 1);
    setFiles({ ...files, audio: [...files.audio] });
  };

  return (
    <div className='flex flex-row'>
      {blobs.map((fileUrl, idx) => (
        <div
          className='flex relative flex-row items-center px-2 m-2 w-min rounded-lg'
          key={`audio-row-${idx}`}
        >
          <AudioPlayer
            audio={{ fileUrl }}
            iconSize={12}
            height={12}
            className='!m-0'
          />
          <button
            onClick={() => removeFileAudio(idx)}
            className='absolute top-[-5px] right-[-5px] p-[2px] bg-gray-900 rounded-xl'
          >
            <Icon icon='close' width={8}
              height={8} color='#ffffff' />
          </button>
        </div>
      ))}
    </div>
  );
};
