import React, { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import getAssetURL from '@/utils/getAssetURL';
import LandingPage from '@/components/landing-page/LandingPage';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

const IframeError: FC = () => {

  const { t } = useTranslation(undefined, {
    keyPrefix: 'errors'
  });

  return (<LandingPage
    className='bg-white'
    title={t('iframe.title')}
    subtitle={t('iframe.subtitle')}
    image={getAssetURL('/assets/images/error.svg')}
  />);


};


export type IframeProps = {
  url?: string,
  title?: string
  maxHeight?: string,
  maxWidth?: string,
  skeleton: ReactElement
  className?: string
  forceWidth?: boolean
  error?: boolean
}

const Iframe: FC<IframeProps> =
  ({ url, skeleton,forceWidth = false,maxWidth = '80vw',maxHeight = '80vh',className, error, title, ...rest }) => {

    const [height,setHeight] = useState<string>('100vh');
    const [width,setWidth] = useState<string>('100vh');
    const [isLoading, setIsLoading] = useState(true);

    const ref = useRef<HTMLIFrameElement | null>(null);

    const onLoad = useCallback(() => {
      setIsLoading(false);
    },[]);

    useEffect(() => {
      window.addEventListener('message',({ data }) => {

        if(data.origin && data.origin === 'preview') {
          setHeight(data.height + 'px');
          setWidth(data.width + 'px');
        }

      });

    }, []);

    if (error) {
      return (<IframeError />);
    }

    if (!url) {
      return skeleton;
    }

    return (
      <>
        {isLoading && skeleton}
        <iframe
          ref={ref}
          style={{
            maxHeight,
            height,
            width: forceWidth ? undefined : width,
            maxWidth: width ? maxWidth : undefined,
          }}
          {...rest}
          src={url}
          title={title}
          onLoad={() => onLoad()}
          className={cls(
            isLoading ? 'h-[1px] w-[1px] border-none transparent' : '',
            className,'flex flex-grow w-full h-auto border-0')} />
      </>
    );
  };

export default Iframe;