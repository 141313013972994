import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import { ROLE } from '@/packages/types';
import Specialty from '@/packages/back-end/specialty';
import { Collection, Item } from '@/packages/back-end/jsonapi';
import PublicProfileSearch from '@/packages/back-end/public-profile-search';
import PublicProfile, { PUBLIC_PROFILES_SEARCH_INCLUDES } from '@/packages/back-end/PublicProfile';
import useJsonAPIRequest, { attachRelationships } from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import Picto from '@ui/atoms/icons/picto/Picto';
import TextField from '@ui/atoms/fields/text/TextField';
import SelectorOption from '@ui/molecules/selector-option/SelectorOption';
import Empty from '@ui/molecules/empty/Empty';
import SkeletonBase from '@ui/atoms/loaders/skeleton/Skeleton';
import Drawer from '@/components/drawer/Drawer';

import { BookMeetingStep } from '../../page/public/doctor-profile/book-meeting-drawer/BookMeetingDrawer';
import DoctorInformationCard
  from '../../page/public/doctor-profile/book-meeting-drawer/common-steps/DoctorInformationCard';
import { flatMedicProfileRelationships } from '../../page/public/doctor-profile/book-meeting-drawer/utils';
import {
  BookMeetingDefaultData,
  BookMeetingProvider,
} from '../../page/public/doctor-profile/book-meeting-drawer/BookMeetingContext';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { BOOK_MEETING_STEPS_WRAPPER_ID }
  from '../../page/public/doctor-profile/book-meeting-drawer/useScrollToHideStep';
import DateFormat from '@/components/date-format/dateFormat';

const Skeleton = () => {
  return (
    <>
      <div className='flex flex-col gap-2 px-2'>
        <SkeletonBase className='w-1/2 h-5' />
        <div className='flex flex-col gap-2 pl-4'>
          <SkeletonBase className='w-full h-10' />
          <SkeletonBase className='w-full h-10' />
        </div>
      </div>
      <div className='flex flex-col gap-2 px-2'>
        <SkeletonBase className='w-1/2 h-5' />
        <div className='flex flex-col gap-2 pl-4'>
          <SkeletonBase className='w-full h-10' />
          <SkeletonBase className='w-full h-10' />
        </div>
      </div>
    </>
  );
};

type SearchDoctorStepData = {
  doctorProfile?: PublicProfile;
};

type ProfileSelectOptionProps = {
  title: string;
  search: string;
  selectedItem?: PublicProfileSearch;
  items: PublicProfileSearch[];
  onSelect: (item: PublicProfileSearch) => void;
};

const ProfileOptionGroup: FC<ProfileSelectOptionProps> = ({
  title,
  search,
  selectedItem,
  items,
  onSelect,
}) => {
  return (
    <div
      key={title}
      className='flex flex-col gap-2'
    >
      <p className='px-2 text-xs font-semibold'>{title}</p>

      <div data-testid='search_doctor_step.history_doctor_list'>
        {items.map((profile) => {
          const specialties =
            (profile.relationships?.specialties?.data as Specialty[]) || [];

          const { initial } = getAvatarProps({
            user: {
              id: profile?.id,
              profilePicture: profile?.attributes.profilePicture,
              firstName: profile?.attributes.firstName,
              fullName: profile?.attributes.fullName,
              thumbnails: profile?.attributes.thumbnails,
              fullLastName: profile?.attributes.lastName,
            } as UserType,
            colorLength: 10,
          });

          return (
            <SelectorOption
              className={cls(
                'cursor-pointer !pl-6 !my-0',
                selectedItem?.id === profile.id
                  ? 'bg-primary-100'
                  : 'hover:bg-gray-50'
              )}
              key={profile.id}
              inputValue={search}
              option={{
                label: profile.attributes.fullName,
                subtitle: specialties
                  .map((spec) => spec.attributes.name)
                  .join(' - '),
                value: profile.attributes.canonical,
                avatar: {
                  alt: profile.attributes.fullName,
                  initial,
                  src: profile?.attributes.thumbnails?.profilePicture?.['50x50'] as string | undefined,
                },
              }}
              onClick={() => onSelect(profile)}
            />
          );
        })}
      </div>
    </div>
  );
};

type SearchDoctorBookMeetingProps = {
  hideSearch?: boolean
  defaultData?: BookMeetingDefaultData
  onClose: () => void;
};

const SearchDoctorBookMeetingDrawer: FC<SearchDoctorBookMeetingProps> = ({
  hideSearch,
  defaultData,
  onClose,
}) => {
  const { t: publicProfileT } = useTranslation('public', {
    keyPrefix: 'public_profile',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.search_doctor_book_meeting_drawer',
  });

  const { user: meUser } = useRecoilValue(authAtom);

  const [isBookMeetingCreated, setBookMeetingCreated] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(true);

  const [openSearchDoctorStep, setOpenSearchDoctorStep] = useState(true);

  const [searchDoctorStepData, setSearchDoctorStepData] =
    useState<SearchDoctorStepData>({});

  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [selectedProfile, setSelectedProfile] = useState<PublicProfileSearch | undefined>();
  const [timeoutId, setTimeoutId] = React.useState<
    NodeJS.Timeout | undefined
  >();

  const { loading: loadingProfiles, data: profilesData } = useJsonAPIRequest<({
    profiles: Collection<PublicProfileSearch>
  })
    >({
      skip: !!defaultData?.publicProfileSearch,
      url: buildUrl('online_appointments/search', undefined, {
        include: 'specialties',
        search,
      }),
    });

  const { loading: loadingPublicProfile, data: publicProfileData } =
    useJsonAPIRequest<Item<PublicProfile>>({
      skip: !selectedProfile || !!defaultData?.publicProfile,
      url: buildUrl('public_profiles', selectedProfile?.attributes.canonical),
      include: PUBLIC_PROFILES_SEARCH_INCLUDES,
    });

  const profileGroups = useMemo(() => {
    const profiles = (profilesData?.profiles ?
      attachRelationships(profilesData?.profiles as Collection<PublicProfileSearch>).data
      : []) as PublicProfileSearch[];

    if (!profiles.length) {
      return [];
    }

    if (!search) {
      return [
        {
          title: t('search_doctor_step.groups.medical_team'),
          items: profiles.filter((profile) => profile.attributes.inMedicalTeam),
        },
        {
          title: t('search_doctor_step.groups.history'),
          items: profiles.filter(
            (profile) => !profile.attributes.inMedicalTeam
          ),
        },
      ];
    }

    return [
      {
        title: t('search_doctor_step.groups.doctors'),
        items: profiles.filter(
          (profile) => profile.attributes.role === ROLE.MEDECIN
        ),
      },
      {
        title: t('search_doctor_step.groups.paramedical'),
        items: profiles.filter(
          (profile) => profile.attributes.role !== ROLE.MEDECIN
        ),
      },
    ];
  }, [search, profilesData, t]);

  const handleSearch = (value: string) => {
    setSearchInput(value);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        setSearch(value);
        setDisplayDrawer(true);
      }, 300)
    );
  };

  useEffect(() => {
    if (defaultData?.publicProfileSearch) {
      setSelectedProfile(defaultData?.publicProfileSearch);
    }
    if (defaultData?.publicProfile) {
      setSearchDoctorStepData({ doctorProfile: defaultData?.publicProfile });
      setOpenSearchDoctorStep(false);
    }
  }, [defaultData?.publicProfileSearch, defaultData?.publicProfile]);

  const { user: doctoUser } = flatMedicProfileRelationships(publicProfileData?.data);

  const realDisplayDrawer = displayDrawer && (profileGroups.length || loadingProfiles || (search && searchInput));

  return (
    <Drawer
      isDynamicHeight
      title=''
      titleText={
        searchDoctorStepData.doctorProfile
          ? publicProfileT('book_meeting_drawer.title', {
            name: doctoUser?.attributes?.fullName || '',
          })
          : t('title')
      }
      onClose={onClose}
      errorMessage={null}
    >
      <div
        id={BOOK_MEETING_STEPS_WRAPPER_ID}
        className='overflow-auto h-full'
        data-testid='book_meeting.wrapper'
      >
        <RetractableItem
          status={searchDoctorStepData.doctorProfile ? 'checked' : undefined}
          withSeparator={!!searchDoctorStepData.doctorProfile}
          open={openSearchDoctorStep && !isBookMeetingCreated}
          blocked={isBookMeetingCreated}
          retractableType='hidden'
          title={`${t('step')} 1 : ${t(`search_doctor_step.${!hideSearch ? 'title_searchable' : 'title'}`)}`}
          subtitle={
            searchDoctorStepData.doctorProfile
              ? selectedProfile?.attributes?.fullName
              : undefined
          }
          onToggle={setOpenSearchDoctorStep}
          wrapperTestId='search_doctor_step'
        >
          <div className='flex flex-col w-full'>
            {!hideSearch && (
              <p className='text-xs font-semibold'>
                {t('search_doctor_step.description')}
              </p>
            )}
            {!hideSearch && (
              <div className='overflow-hidden mt-1 mb-6 w-full rounded-t rounded-b-2xl border border-gray-100'>
                <div className={cls(realDisplayDrawer ? 'border-b' : '', 'flex items-center pl-3 border-gray-100')}>
                  <Picto
                    className='text-gray-500'
                    icon='search'
                    iconSize={20}
                  />
                  <TextField
                    className='w-full'
                    borderless
                    onClick={() => {
                      if (profileGroups.length) {
                        setDisplayDrawer(true);
                      }
                    }}
                    placeholder={t('search_doctor_step.search_placeholder')}
                    value={searchInput}
                    onChange={(event) => handleSearch(event.target.value)}
                  />
                </div>

                {realDisplayDrawer &&
                  (<div className='flex overflow-y-auto flex-col gap-3 py-3 max-h-[50vh]'>
                    {loadingProfiles && <Skeleton />}

                    {!loadingProfiles && !profileGroups.length && searchInput && (
                      <Empty message={t('no_result_found')} />
                    )}

                    {!loadingProfiles &&
                    profileGroups.map(({ title, items }) => {
                      if (!items?.length) {
                        return null;
                      }

                      return (
                        <ProfileOptionGroup
                          key={title}
                          title={title}
                          search={search}
                          selectedItem={selectedProfile}
                          items={items}
                          onSelect={(item) => {
                            setSelectedProfile(item);
                            setDisplayDrawer(false);
                            setSearchDoctorStepData({ doctorProfile: undefined });
                          }}
                        />
                      );
                    })}
                  </div>)}
              </div>
            )}

            <div className='flex flex-col gap-4'>
              <DoctorInformationCard
                loading={loadingPublicProfile}
                profileDoctor={defaultData?.publicProfile || publicProfileData?.data}
              />
              {!!defaultData?.bookingSlot?.attributes?.dateBegin && (
                <div className='flex items-center text-xs'>
                  {loadingPublicProfile ? (<SkeletonBase size='small' className='w-60' />) : (<>
                    <div className='flex justify-center w-6'>
                      <Picto
                        className='text-gray-700'
                        icon='outlineEvent'
                      />
                    </div>
                    <span className='flex flex-col ml-2'>
                      <DateFormat value={defaultData?.bookingSlot?.attributes?.dateBegin} />
                    </span>
                  </>)}
                </div>
              )}

              {!!selectedProfile && (
                <div className='flex items-center text-xs'>
                  {loadingPublicProfile ? (<SkeletonBase size='small' className='w-60' />) : (<>
                    <div className='flex justify-center w-6'>
                      <Picto
                        className='text-gray-700'
                        icon='location'
                        iconSize={20}
                      />
                    </div>
                    <span className='ml-2' data-testid='confirm_meeting_step.location'>
                      {[selectedProfile?.attributes.address, selectedProfile?.attributes.city].join(', ')}
                    </span>
                  </>)}
                </div>
              )}
            </div>

            {!!publicProfileData?.data && (
              <Button
                className={cls(selectedProfile && 'mt-6')}
                type='submit'
                color='primary'
                data-testid='search_doctor_step.continue'
                onClick={() => {
                  setOpenSearchDoctorStep(false);
                  setSearchDoctorStepData({
                    doctorProfile: publicProfileData.data,
                  });
                }}
              >
                {t('search_doctor_step.continue')}
              </Button>
            )}
          </div>
        </RetractableItem>

        {!!searchDoctorStepData.doctorProfile && (
          <BookMeetingProvider
            defaultData={defaultData}
            initMeUser={meUser}
            profileDoctor={publicProfileData?.data}
            onBookMeetingCreated={() => setBookMeetingCreated(true)}
          >
            <BookMeetingStep
              hideWrapper
              startStepNumber={2}
              onClose={onClose}
            />
          </BookMeetingProvider>
        )}
      </div>
    </Drawer>
  );
};

export default SearchDoctorBookMeetingDrawer;
