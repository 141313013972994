import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDiscussions } from '../ChatContext';
import styled from 'styled-components';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';

const NoDiscussionSelected = () => {
  const { t } = useTranslation('chat');
  const { setNewDiscussion } = useDiscussions();

  return (
    <div className='flex flex-col justify-center items-center p-8 w-full h-full bg-white rounded-md'>
      <h2 className='mb-3 text-lg text-center'>
        {t('no_discussion_selected')}
      </h2>
      <NewMessageButton
        icon='instaEdit'
        iconColor='#072337'
        color='secondary'
        onClick={() => setNewDiscussion({ selected: true })}
      >
        {t('new_message')}
      </NewMessageButton>
    </div>
  );
};

export const NewMessageButton = styled(Button)`
  &:hover {
    svg {
      path {
        fill: #072337;
      }
    }
  }
`;

export default NoDiscussionSelected;
