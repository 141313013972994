import React, { lazy } from 'react';

import { ROUTES } from '@/app/structure/body/routes/Shared.utils';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import { ALL_ROLES } from '@/packages/types';
import { PatientProvider } from '@/app/structure/page/patients/PatientContext';

const Calendar = lazy(() => import('@/app/structure/page/calendar/Calendar'));

const getRoutes = () => {

  const data = [];

  data.push(<PrivateRoute
    exact
    roles={ALL_ROLES}
    key={ROUTES.calendar}
    path={ROUTES.calendar}>
    <PatientProvider>
      <Calendar />
    </PatientProvider>
  </PrivateRoute>);

  data.push(<PrivateRoute
    exact
    roles={ALL_ROLES}
    key={ROUTES.user_calendar}
    path={ROUTES.user_calendar}>
    <PatientProvider>
      <Calendar />
    </PatientProvider>
  </PrivateRoute>);


  return data;
};

export default getRoutes;