import React, { useCallback, useState } from 'react';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { POST_COLLECTION, TYPE } from '@/packages/back-end/task';
import Patient from '@/packages/back-end/patient';
import { Item } from '@/packages/back-end/jsonapi';
import { useTranslation } from 'react-i18next';
import useToast from '@/utils/hooks/useToast';
import User from '@/packages/back-end/user';
import { buildUrl } from '@/utils/Api';
import ConflictModal from '@/components/conflict-modal/ConflictModal';


type CallbackType = () => Promise<Item<Patient>>;

type ConflictData = {
  field: string;
  value: string;
  hasAccess: boolean;
  object: string | null;
  objectName: string | null;
}

const useConflictModal = () => {

  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.conflict_modal'
  });

  const { addToast } = useToast();

  const [conflictData, setConflictData] = useState<ConflictData | undefined>();
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [callback, setCallback] = useState<CallbackType | undefined>();

  const [loading, setLoading] = useState(false);


  const { fetchData: mergeUsers } = useJsonAPIRequest<
    Item<User>
  >({
    skip: true,
    method: 'post',
    url: buildUrl('users/merge'),
  });

  const { fetchData: createMergeRequest } = useJsonAPIRequest({
    url: POST_COLLECTION(),
    method: 'POST',
    skip: true
  });

  const onYes = useCallback(async () => {

    if (!callback || !conflictData) {
      return;
    }

    setLoading(true);
    const response = await callback();

    if(conflictData.hasAccess) {
      await mergeUsers({
        user1: conflictData.object,
        user2: response.data.relationships.user.data.id
      },{
        throw: true,
        displayToastOnError: true
      });
    } else {
      await createMergeRequest({
        type: TYPE.MERGE_REQUEST,
        subject: response.data.relationships.user.data.id,
        field: conflictData.field,
        fieldValue: conflictData.value
      }, {
        throw: true,
        displayToastOnError: true
      });
    }
    setLoading(false);
    setShowConflictModal(false);
    if(conflictData.hasAccess) {
      addToast('success', t('toasts.success_merge'));
    } else {
      addToast('success', t('toasts.success'));
    }


    return response;

  }, [mergeUsers,createMergeRequest, callback, t, addToast, conflictData]);


  const modal = showConflictModal ? (<ConflictModal
    onAccept={onYes}
    hasAccess={conflictData?.hasAccess}
    loading={loading}
    onCancel={() => setShowConflictModal(false)}
  />) : null;

  const openModal = useCallback((conflictData : ConflictData,callback: CallbackType) => {
    setConflictData(conflictData);

    setCallback(() => callback);
    setShowConflictModal(true);
    setLoading(false);
  }, []);


  return {
    modal,
    openModal,
  };

};

export default useConflictModal;