import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type TSearchParams = Record<
  string,
  string | string[] | null | Record<string, string | string[] | null>
>;

export const parseSearchString = <T extends TSearchParams>(
  search: string
): T => {
  const params: TSearchParams = {};
  const searchParams = new URLSearchParams(search);

  Array.from(searchParams.keys()).forEach((searchKey) => {
    if (searchKey.endsWith('[]')) {
      params[searchKey.slice(0, -2)] = searchParams.getAll(searchKey);
    } else if (/\[.*\]$/.test(searchKey)) {
      const [newSearchKey, invalidSubSearchKey] = searchKey.split('[');
      const [subSearchKey] = invalidSubSearchKey.split(']');
      params[newSearchKey] = {
        ...(params[newSearchKey] as Record<string, string | string[] | null>),
        [subSearchKey]: searchParams.get(searchKey),
      };
    } else {
      params[searchKey] = searchParams.get(searchKey);
    }
  });

  return params as T;
};

const useSearchParams = <T extends TSearchParams>(): T => {
  const { search } = useLocation();
  return useMemo(() => parseSearchString(search), [search]);
};

export default useSearchParams;
