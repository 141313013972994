import React from 'react';
import { useDiscussions } from '../ChatContext';
import { useTranslation } from 'react-i18next';
import Discussion from '@/packages/back-end/Discussion';
import useUpdateDiscussion from '@/app/structure/page/chat/components/useUpdateDiscussion';
import Button from '@ui/atoms/buttons/button/Button';


type MarkAsUnreadProps = {
  isMultipleEdit?: boolean;
  discussion?: Discussion;
  iconSize?: number
};

const MarkAsUnread = ({ discussion, isMultipleEdit, iconSize = 20 }: MarkAsUnreadProps) => {
  const { selectedDiscussion } = useDiscussions();
  const { t } = useTranslation('chat');

  const { update, loading } = useUpdateDiscussion({
    discussion: (discussion || selectedDiscussion) as Discussion,
    isMultipleEdit
  });

  return (
    <Button
      color='transparent'
      icon='messageCloud'
      iconHoverColor='#072337'
      iconColor='#9ba6ae'
      iconSize={iconSize}
      saving={loading}
      title={t('header_right.mark_as_unread')}
      onClick={() =>
        update({ read: false }, 'toasts.discussion_as_unread')
      }
    />
  );
};

export default MarkAsUnread;
