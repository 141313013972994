import React, { FC, useCallback, useEffect, useState } from 'react';
import ApiSelector from '@/components/form/fields/api-selector';
import { useForm } from 'react-hook-form';
import User, { GET_COLLECTION, buildOption, isDoctor } from '@/packages/back-end/user';
import { useTranslation } from 'react-i18next';
import { LoadingEmail } from '@/app/structure/page/mssante/components/EmailsLoader';
import cls from 'classnames';
import H2 from '@ui/atoms/texts/base/H2';
import { OptionType } from '@ui/organisms/selectors/types';
import { useEmails } from '@/app/structure/page/mssante/EmailsContext';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';



const SelectDoctorForm: FC = () => {

  const { setImpersonatedUser,impersonatedUser, setHasMultipleDoctors } = useEmails();

  const [loaded, setLoaded] = useState(false);

  const { t } = useTranslation('ms_sante', {
    keyPrefix: 'select_doctor'
  });

  const { user } = useRecoilValue(authAtom);

  const { control, watch,setValue, resetField } = useForm();

  const onLoaded = useCallback((options : OptionType<User>[]) => {

    setLoaded(true);
    if (options && options.length === 1) {
      setValue('user',options[0]);
    }
    setHasMultipleDoctors(options.length > 1);

  },[setValue,setHasMultipleDoctors]);



  const selectedUser : OptionType<User> = watch('user');

  useEffect(() => {

    setImpersonatedUser(selectedUser?.value);

  }, [selectedUser,setImpersonatedUser]);


  useEffect(() => {

    if(!impersonatedUser) {
      setValue('user',null);
    }

  }, [setValue,impersonatedUser]);

  if(impersonatedUser || isDoctor(user)) {
    return null;
  }

  return (<>

    {!loaded && (<LoadingEmail />)}

    <div className={cls(loaded && !selectedUser ? '' : 'hidden',
      'p-8 flex-col h-full w-full bg-white flex flex-grow rounded-md')} >

      <H2>{t('select_doctor_to_continue')}</H2>

      <ApiSelector
        className='mt-4'
        label={t('fields.user')}
        buildOption={buildOption}
        url={GET_COLLECTION({
          context: 'MY_MEDICS',
          exclude_me: true
        })}

        onLoaded={onLoaded}
        name={'user'}
        control={control}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resetField={resetField}
      />
    </div>

  </>);

};

export default SelectDoctorForm;