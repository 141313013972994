import React from 'react';
import { useTranslation } from 'react-i18next';
import NewDiscussion from '../NewDiscussion';
import { useDiscussions } from '../ChatContext';
import styled from 'styled-components';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import { UrlArg } from '@/utils/Api';

type NoDiscussionFoundProps = {
  queryParams: UrlArg,
  setQueryParams : (params : UrlArg) => void,
}

const NoDiscussionFound = ({ queryParams,setQueryParams }: NoDiscussionFoundProps) => {
  const { t } = useTranslation('chat');
  const { newDiscussion, setNewDiscussion } = useDiscussions();

  return (
    <div className='flex flex-col justify-center items-center w-full h-full'>
      <h2 className='mb-3 text-lg'>
        {t('no_discussion_found', {
          filter: '_read' in queryParams && !queryParams._read
            ? t('filters.not_read')
            : queryParams.archived
              ? t('filters.archived')
              : t('filters.all'),
        })}
      </h2>
      <NewMessageButton
        icon='instaEdit'
        color='primary'
        onClick={() => setNewDiscussion({ selected: true })}
      >
        {t('new_message')}
      </NewMessageButton>
      {newDiscussion && <NewDiscussion setQueryParams={setQueryParams} />}
    </div>
  );
};

export const NewMessageButton = styled(Button)`
  &:hover {
    svg {
      path {
        fill: #072337;
      }
    }
  }
`;

export default NoDiscussionFound;
