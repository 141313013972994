import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const host = window.location.hostname;

export const supportedLngs = ['fr', 'en'];

export const getFirstSupportedLanguage = () => {
  return navigator.languages.find((lang) => supportedLngs.includes(lang)) || supportedLngs[0];
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    fallbackLng: 'fr',
    supportedLngs,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    ns: ['translation', 'auth'],
    defaultNS: 'translation',

    react: {
      useSuspense: false
    },

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: host.includes('localhost') ? '/locales/{{lng}}/{{ns}}.json' : '/v2/locales/{{lng}}/{{ns}}.json'
    }
  });

// setup global "language", then stylyguide can access it;
window.i18nLanguage = i18n.language;

i18n.on('languageChanged', (language) => {
  window.i18nLanguage = language;
});

export default i18n;
