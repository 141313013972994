import React from 'react';
import { useDiscussions } from '../ChatContext';
import { useTranslation } from 'react-i18next';
import Discussion from '@/packages/back-end/Discussion';
import useUpdateDiscussion from '@/app/structure/page/chat/components/useUpdateDiscussion';
import Button from '@ui/atoms/buttons/button/Button';

type MarkAsReadProps = {
  isMultipleEdit?: boolean;
  discussion?: Discussion;
  iconSize?: number
};

const MarkAsRead = ({ discussion, isMultipleEdit,iconSize }:MarkAsReadProps) => {

  const { selectedDiscussion,discussions,setDiscussions } = useDiscussions();

  const { t } = useTranslation('chat');

  const { update, loading } = useUpdateDiscussion({
    discussion: (discussion || selectedDiscussion) as Discussion,
    isMultipleEdit
  });

  return (
    <Button
      color='transparent'
      icon='check'
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      iconSize={iconSize}
      saving={loading}
      title={t('header_right.mark_as_read')}
      onClick={async () => {
        const result = await update({ read: true }, 'toasts.discussion_as_read');
        if(isMultipleEdit) {
          const ids = result.map((item) => item.id);
          const newDiscussions = discussions.filter(item => !ids.includes(item.id));
          setDiscussions([...newDiscussions]);
        }
      }}
    />
  );
};

export default MarkAsRead;
