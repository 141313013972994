import React, { FC } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { isMedicalStaff, MeUser } from '@/packages/back-end/user';
import { LegacyMe, ROLE } from '@/packages/types';
import { getCurrentInstitution } from '@/app/structure/page/admin/dashboard/Dashboard';
import storage, { StorageKeys } from '@/services/request/helpers/storage';



const Intercom: FC = () => {

  const { user } = useRecoilValue(authAtom) as { user : MeUser, legacy_user : LegacyMe };

  const { boot } = useIntercom();

  if(isMedicalStaff(user)) {
    boot({
      name: user.attributes.fullName,
      email: user.attributes.email,
      userId: user.attributes._id?.toString(),
      createdAt: (new Date(user.attributes.createdDate).getTime()/1000).toString(),
      userHash: user.attributes.intercomHash,
      customAttributes: {
        subdomain: getCurrentInstitution(user)?.attributes.domain ?? '',
        external_id: user.attributes._id,
        role: user.attributes.role,
      }
    });
  } else {
    const role = storage.getItem(StorageKeys.LATEST_ROLE_USED);
    const name = storage.getItem(StorageKeys.LATEST_NAME_USED) ?? undefined;
    const email = storage.getItem(StorageKeys.USERNAME) ?? undefined;

    if(role && role !== ROLE.PATIENT) {

      boot({
        name,
        email,
        customAttributes: {
          role,
        }
      });
    }

  }


  {return (<div></div>);}

};

export default Intercom;