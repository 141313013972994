/* eslint-disable tailwindcss/no-custom-classname */
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import User, { isMedicalStaff, isPatient } from '@/packages/back-end/user';
import useInfosDrawer from './InfoDrawer';
import { HeaderRightLoader } from '../components/DiscussionsLoader';
import { useDiscussions } from '../ChatContext';
import DeleteDiscussionModal from './DeleteDiscussionModal';
import ArchiveButton from './ArchiveButton';
import AddParticipants from './AddParticipants';
import SearchField from '../components/SearchField';
import PatientRow from '../components/PatientRow';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import MarkAsUnread from '../components/MarkAsUnread';
import MarkAsReadForMedicalTeam from '@/app/structure/page/chat/components/MarkAsReadForMedicalTeam';
import Button from '@ui/atoms/buttons/button/Button';
import Link from '@/components/link/Link';
import { buildPatientProfileUrl } from '@/utils/Legacy';
import RestoreDiscussionModal from './RestoreDiscussionModal';
import useURLParams from '@/utils/hooks/useURLParams';

type HeaderRightProps = {
  me?: User;
  loading: boolean;
  searchCallback: (search?: string) => void;
};

const getUserLink = (user: User): ReactNode => {
  const lastName = user.attributes.givenName
    ? user.attributes.givenName
    : user.attributes.lastName;

  const name = `${user.attributes.firstName?.[0] ?? ''}. ${lastName}`;

  if (isPatient(user)) {
    return (
      <Link
        href={buildPatientProfileUrl(user.attributes._id as string)}
        target='_blank'
      >
        {name}
      </Link>
    );
  } else {
    return name;
  }
};

const HeaderRight = ({ me, loading, searchCallback }: HeaderRightProps) => {
  const { t } = useTranslation('chat');
  const { onClick: openInfosDrawer } = useInfosDrawer();
  const { user } = useRecoilValue(authAtom);
  const { selectedDiscussion } = useDiscussions();
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const [search, setSearch] = useState(undefined);
  const { trash } = useURLParams<{
    trash: boolean | undefined;
  }>(['trash']);

  const owner = selectedDiscussion?.relationships?.owner?.data;

  useEffect(() => {
    if (!showSearchInput) {
      setSearch(undefined);
      searchCallback();
    }
  }, [searchCallback, showSearchInput]);

  const onSearch = (value: string) => {
    if (value && value.length > 2) {
      searchCallback(value);
    } else {
      searchCallback();
    }
  };

  const participants = useMemo(() => {
    const newParticipants: (string | ReactNode)[] = [];
    selectedDiscussion?.relationships?.participants?.data.forEach(
      (participant) => {
        if (participant.id !== owner?.id && participant.id !== me?.id) {
          newParticipants.push(getUserLink(participant));
        }
      }
    );
    newParticipants.push(t('me'));
    return newParticipants;
  }, [
    selectedDiscussion?.relationships?.participants?.data,
    me?.id,
    owner?.id,
    t,
  ]);

  const taggedPatients =
    selectedDiscussion?.relationships?.patientsTagged?.data;

  const renderTaggedPatients = useCallback(
    () =>
      taggedPatients?.map((patientTagged, index) => {
        const userTagged = patientTagged?.relationships?.user?.data?.attributes;
        return (
          <div
            className='flex flex-row items-end ml-2'
            key={`${userTagged?.id || userTagged?._id}-patient-tagged-${index}`}
          >
            <PatientRow patient={patientTagged} />
          </div>
        );
      }),
    [taggedPatients]
  );

  return (
    <div className='w-full'>
      <div className='flex flex-row justify-between items-start pt-4 lg:pt-0 lg:w-[97%] xl:w-full'>
        {loading ? (
          <HeaderRightLoader />
        ) : (
          <div>
            <p className='mb-1 text-[16px] font-medium text-gray-900'>
              {selectedDiscussion?.attributes?.title}
            </p>
            <p className='text-sm text-gray-900'>
              <span>{t('from')}</span>
              <span className='font-medium'>
                {owner?.id === me?.id ? t('me') : getUserLink(owner as User)}
              </span>
            </p>
          </div>
        )}
        <div className='flex relative flex-row gap-x-3 justify-between items-center'>
          {isMedicalStaff(user) && <MarkAsReadForMedicalTeam />}
          <MarkAsUnread />
          <AddParticipants />
          <Button
            onClick={() => setShowSearchInput(!showSearchInput)}
            title={t('header_right.search_in_messages')}
            iconHoverColor='#072337'
            iconColor='#9ba6ae'
            iconSize={20}
            icon='search'
            color='transparent'
          />
          <ArchiveButton />
          <span>{isMedicalStaff(user) ? (
            trash ? (
              <RestoreDiscussionModal />
            ) : (
              <DeleteDiscussionModal />
            )
          ) : (
            <></>
          )}</span>
          <button
            onClick={() => openInfosDrawer()}
            title={t('discussion_infos')}
          >
            <Icon icon='threeDots' color='#9ba6ae' />
          </button>
          {showSearchInput && (
            <div className='absolute top-[100%] p-1 mt-2 w-full bg-white'>
              <SearchField
                name='search'
                onSearch={onSearch}
                defaultValue={search}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className='flex flex-row items-center w-[97%]'>
          <p className='text-sm text-gray-900 truncate'>
            <span>{t('to')}</span>
            <span className='font-medium'>
              {participants.map((participant, key) => (
                <span className='font-medium' key={key}>
                  {participant}
                  {key !== participants.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>
          </p>
          <button
            className='px-2'
            onClick={() => openInfosDrawer()}
            title={t('discussion_infos')}
          >
            <Icon icon='chevronUp' width={15} />
          </button>
        </div>
        {taggedPatients ? (
          <div className='flex flex-row items-center w-full'>
            <p className='text-sm text-gray-900 truncate'>
              <span>
                {taggedPatients.length > 1
                  ? t('tagged_patients')
                  : t('tagged_patient')}
              </span>
            </p>
            <div className='flex overflow-x-auto flex-row space-x-2 no-scrollbar'>
              {renderTaggedPatients()}
            </div>
            <button
              className='px-2 mx-2'
              onClick={() => openInfosDrawer()}
              title={t('discussion_infos')}
            >
              <Icon icon='chevronUp' width={15} />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default HeaderRight;
