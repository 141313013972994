import React, { FC, MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { authAtom } from '@/services/store/store';
import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import Icon from '@ui/atoms/icons/icon/Icon';
import { useRecoilValue } from 'recoil';
import { formatDate, getParticipants, renderHighlightedText } from '../utils';
import { useTranslation } from 'react-i18next';
import { DiscussionProps } from '../types';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import { useDiscussions } from '../ChatContext';
import Discussion from '@/packages/back-end/Discussion';
import DeleteDiscussionModal from '../right-components/DeleteDiscussionModal';
import ArchiveButton from '../right-components/ArchiveButton';
import MarkAsUnread from '../components/MarkAsUnread';
import MarkAsRead from '@/app/structure/page/chat/components/MarkAsRead';
import CounterIndicator from '@ui/atoms/containers/counter-indicator/CounterIndicator';
import cls from 'classnames';
import RestoreDiscussionModal from '../right-components/RestoreDiscussionModal';

type RowProps = {
  className?: string;
  discussion: Discussion;
  isFocused: boolean;
  search?: string;
  closeDrawer?: () => void;
  isTrash?: boolean;
};


export const Pelet : FC<{ className ?: string}> = ({ className }) =>
  (<div className={cls(className,'mt-2 w-[10px] h-[10px] bg-[#f85657] rounded-xl')} />);


const DiscussionsRow = ({
  className,
  discussion,
  isFocused,
  search,
  isTrash,
  closeDrawer,
}: RowProps) => {
  const { t } = useTranslation('chat');
  const [isRowHover, setIsRowHover] = useState(false);
  const { user } = useRecoilValue(authAtom);
  const { discussions, setDiscussions, setSelectedDiscussion,setNewDiscussion } =
    useDiscussions();

  const unreadMessagesCount = useMemo(
    () => discussion.attributes.unreadMessagesCount,
    [discussion]
  );
  const isRead = useMemo(
    () =>
      user?.attributes?._id
        ? discussion?.attributes?.read?.includes(user.attributes._id as number)
        : false,
    [discussion, user]
  );
  const [checked, setChecked] = useState<boolean>(discussion.selected);

  const { fetchData: modifyDiscussion } = useJsonAPIRequest<{
    data: Discussion;
  }>({
    url: buildUrl(discussion.id),
    method: 'PUT',
    skip: true,
  });

  const background = isFocused
    ? 'bg-[#ebf2fb]'
    : isRead
      ? 'bg-[#ecedef]'
      : 'bg-white';

  useEffect(() => {
    setChecked(discussion?.selected);
  }, [discussion?.selected]);

  const onChangeCheckbox = () => {
    setChecked(!checked);
    discussions.forEach((el: Discussion) => {
      if (el.id === discussion.id) {
        discussion.selected = !discussion.selected;
        setDiscussions([...discussions]);
      }
    });
  };

  const renderDate = useMemo(() => formatDate(discussion.attributes.updatedDate), [discussion.attributes.updatedDate]);

  const markAsRead: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    closeDrawer && closeDrawer();
    setNewDiscussion(null);
    setSelectedDiscussion(discussion);

    if (!isRead) {
      const res = await modifyDiscussion({ read: true });
      if ((res as { data: DiscussionProps })?.data) {
        const discModified = (res as { data: DiscussionProps })?.data;
        const index: number = discussions.findIndex(
          (d) => d.id === discModified.id
        );
        discussions[index] = {
          ...discussions[index],
          attributes: {
            ...discussions[index]?.attributes,
            read: discModified.attributes.read,
          },
        };
        setDiscussions([...discussions]);
      }
    }
  };

  const participants = getParticipants({ discussion, me: user, t });

  return (
    <div
      className={`flex flex-row justify-between w-full min-h-[82px] ${background} ${
        checked ? '!bg-gray-300' : background
      } p-2 ${className}`}
      onMouseEnter={() => setIsRowHover(true)}
      onMouseLeave={() => setIsRowHover(false)}
      data-testid='discussion_row'
    >
      <div className='flex flex-row justify-start w-full'>
        <div className='flex flex-col items-center mt-[-5px] mr-3'>
          <Checkbox
            name={`row-checkbox-${discussion.id}`}
            checked={checked}
            onChange={onChangeCheckbox}
          />
          {!isRead &&
            (unreadMessagesCount ? (<CounterIndicator className='mt-2' counter={unreadMessagesCount} />) : (
              <Pelet />
            ))}
        </div>
        <div className='flex overflow-hidden flex-row w-full'>
          <button
            className='flex flex-col w-8/12 xl:w-9/12'
            onClick={markAsRead}
          >
            <p
              title={participants}
              className={`${
                isRead ? 'font-base' : 'font-semibold'
              } truncate text-left w-full`}
              data-testid='discussion_row_participants'
            >
              {participants}
            </p>
            {renderHighlightedText(
              discussion.attributes.title,
              search,
              `text-sm ${isRead ? 'font-base' : 'font-medium'} text-left !mb-0`
            )}
            {renderHighlightedText(
              discussion.relationships.lastMessage?.data?.attributes?.content,
              search,
              'text-sm text-left truncate'
            )}
          </button>
          <div className='flex flex-col items-end w-4/12 xl:w-3/12'>
            <button className='flex flex-col items-end' onClick={markAsRead}>
              <p className='text-xs text-gray-900'>{renderDate}</p>
              {discussion.attributes.documentsCount ? (
                <div className='mt-1' style={{ transform: 'rotate(39deg)' }}>
                  <Icon
                    icon='attachment'
                    width={18}
                    height={18}
                    color='#072338'
                  />
                </div>
              ) : (
                <></>
              )}
            </button>
            {!isTrash ? <div className={cls('flex mt-1',isRowHover ? '' : 'invisible')} >
              <DeleteDiscussionModal iconSize={16} discussion={discussion} />
              <ArchiveButton iconSize={16} discussion={discussion} />
              {isRead && (<MarkAsUnread iconSize={16} discussion={discussion} />)}
              {!isRead && (<MarkAsRead iconSize={16} discussion={discussion} />)}
            </div> : <div className={cls('flex mt-1',isRowHover ? '' : 'invisible')}>
              <RestoreDiscussionModal iconSize={16} discussion={discussion} />
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscussionsRow;
