import { useCallback, useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Collection, Entity } from '@/packages/back-end/jsonapi';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl, UrlArg } from '@/utils/Api';
import useURLParams from '@/utils/hooks/useURLParams';

import useChangeSearchParams from './useChangeSearchParams';


export type UseGetPaginatedCollectionReturn<TItemType = Entity> = {
  onReload: () => Promise<void>;
  data : TItemType[],
  setData: (newData: TItemType[] | ((prevData: TItemType[]) => TItemType[])) => void;
  onUpdate : (item : TItemType) => void,
  reloading: boolean,
  loading : boolean,
  isUpdatedData : boolean,
  onDelete : (item : TItemType) => void,
  currentPage: number,
  hasMorePages: boolean,
  reset: () => void,
  totalItems: number | undefined,
  queryParams : UrlArg,
  called: boolean | undefined,
  maxPage : number,
  setQueryParams: (params : UrlArg) => void,
  onPaginate : () => boolean,

}

const defaultParamsKeys = ['search'];

const useGetPaginatedCollection = <TItemType extends Entity = Entity>(
  path: string,
  initialQueryParams?: UrlArg,
  lockedQueryParamsProp?: UrlArg,
  options?: { skip?: boolean, preventUpdateUrl?: boolean, ignoreQueryParams?: string[] },
  initialParamsKeys = defaultParamsKeys,
)
    : UseGetPaginatedCollectionReturn<TItemType> => {

  const initialParams = useURLParams(initialParamsKeys) as UrlArg;

  const { onUpdateSearchParams } = useChangeSearchParams();

  const keepRef = useRef({
    page: 1,
    queryParams: lockedQueryParamsProp,
    lockedQueryParams: lockedQueryParamsProp,
    ignoreQueryParams: options?.ignoreQueryParams,
  });

  const [data, setData] = useState<TItemType[]>([]);
  const [reloading, setReloading] = useState(false);
  const [isUpdatedData, setUpdatedData] = useState(false);

  const [queryParams, setQueryParams] = useState<UrlArg>(initialQueryParams ?? initialParams ?? {});
  const [lockedQueryParams, setLockedQueryParams] = useState(lockedQueryParamsProp);

  const [page, setPage] = useState<number>(1);
  const [maxPage, setMaxPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number|undefined>();

  // This is used because sometimes there was a problem with loading
  const [localLoading, setLocalLoading] = useState(false);

  const url = buildUrl(path);

  const { data: backData,called,fetchData, loading } = useJsonAPIRequest<Collection<TItemType>>({
    url: url,
    skip: true,
  });


  const { meta, data: values } = backData ?? { meta: undefined };

  useEffect(() => {

    if (!meta) {
      return;
    }

    if (!meta.itemsPerPage) {
      setTotalItems(undefined);
      return;
    }

    if(typeof meta.totalItems !== 'undefined') {
      setTotalItems(meta.totalItems);
      setMaxPage(Math.ceil(meta.totalItems / meta.itemsPerPage));
    } else if(values?.length === meta.itemsPerPage) {
      setTotalItems((page - 1) * meta.itemsPerPage + values.length);
      setMaxPage(page + 1);
    }
  }, [meta,values,page]);


  useEffect(() => {
    if (backData) {
      setUpdatedData(true);
    }

    setData((prevState) => [
      ...prevState.filter((value) => !backData?.data.find((item: TItemType) => item.id === value.id))
      , ...backData?.data ?? []
    ]);

  }, [backData]);


  const setNewQueryParams = useCallback((newQueryParams: UrlArg) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ...newQueryParams
    }));
    setPage(1);
    setData([]);
    setLocalLoading(true);

  }, []);


  const onPaginate = useCallback(() => {

    if(loading || localLoading) {
      return false;
    }

    let result = true;
    setPage((page) => {

      // "maxPage" can be 0, but "page" has to larger than 0
      const newResult = Math.min(page + 1, Math.max(maxPage, 1));

      if(newResult === page) {
        result = false;
      }

      return newResult;
    });

    return result;

  }, [maxPage,loading,localLoading]);

  const onDelete = useCallback((item: TItemType) => {
    setData((prevState) => prevState.filter((value) => value.id !== item.id));
  }, []);

  const onUpdate = useCallback((item: TItemType) => {

    setData((prevState) => {
      const index = prevState.findIndex((obj) => obj.id === item.id);

      if(index === -1) {
        return [
          item,...prevState
        ];
      }

      return prevState.map((it) => {
        if (it.attributes._id === item.attributes._id) {
          return item;
        }

        return it;
      });


    });
  }, []);

  const onReload = useCallback(async () => {
    // make sure always call [fetchData] with newest data
    // Ex: call [onReload] in hook but missing dependence
    const { queryParams, lockedQueryParams } = keepRef.current;

    setReloading(true);
    // make sure [reloading] updated before clear data
    setTimeout(() => {
      setPage(1);
      setData([]);
    }, 0);

    await fetchData({
      page: 1,
      ...queryParams,
      ...lockedQueryParams
    }).finally(() => {
      setReloading(false);
    });
  },[fetchData]);

  useEffect(() => {
    keepRef.current.page = page;
    keepRef.current.queryParams = queryParams;
    keepRef.current.ignoreQueryParams = options?.ignoreQueryParams;
  }, [page, queryParams, options?.ignoreQueryParams]);

  useEffect(() => {
    if (JSON.stringify(keepRef.current.lockedQueryParams) !== JSON.stringify(lockedQueryParamsProp)) {
      keepRef.current.lockedQueryParams = lockedQueryParamsProp;
      setLockedQueryParams(lockedQueryParamsProp);
    }
  }, [lockedQueryParamsProp]);

  useEffect(() => {

    if (options?.skip) {
      return;
    }

    setLocalLoading(true);

    const newParams: Record<string, unknown> = {
      page,
      ...queryParams,
      ...lockedQueryParams,
    };

    keepRef.current.ignoreQueryParams?.forEach(paramKey => {
      newParams[paramKey] = undefined;
    });

    fetchData(newParams).then(() => {
      setLocalLoading(false);

    });
  },[options?.skip, fetchData,page,lockedQueryParams,queryParams]);


  useEffect(() => {
    if (!options?.preventUpdateUrl) {
      onUpdateSearchParams(cloneDeep(queryParams));
    }
  },[options?.preventUpdateUrl, queryParams, onUpdateSearchParams]);

  const reset = useCallback(() => {

    setData([]);
    setPage(1);
    setMaxPage(1);
  },[]);

  return {
    onReload,
    data,
    onUpdate,
    reloading,
    loading: localLoading || loading,
    isUpdatedData,
    onDelete,
    currentPage: page,
    queryParams,
    reset,
    hasMorePages: page < maxPage,
    totalItems,
    called,
    maxPage,
    setData,
    setQueryParams: setNewQueryParams,
    onPaginate
  };

};

export default useGetPaginatedCollection;