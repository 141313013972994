import { usePlacesWidget } from 'react-google-autocomplete';
import React, { FC, ReactNode, Ref, useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@ui/atoms/fields/text/TextField';
import Link from '@ui/atoms/texts/link/Link';
import { useTranslation } from 'react-i18next';
import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import styled from 'styled-components';
import { getEnv } from '@/utils/importMeta';
import LabelSkeleton from '@ui/atoms/loaders/label-skeleton/LabelSkeleton';


const StyledFormField = styled(TextField)`
  border-radius: 0.5rem 0.5rem 0 0;
`;

const StyledLink = styled(Link)`
    display: block;
    border: 1px solid rgba(214, 219, 222, var(--tw-border-opacity));
    border-top: 0;
    padding: 10px 10px;
    box-shadow: 0px 5px 14px 0px rgb(0 0 0 / 20%);
    border-radius: 0 0 0.5rem 0.5rem;
    font-weight: 600;
}
`;

export type PlaceType = {
  address: string,
  fullAddress: string,
  addressExtension?: string,
  postalCode: string,
  city: string,
  country: string,
  latitude: number,
  longitude: number,
  id: string
}


type AddressElement = {
  long_name: string
  short_name: string
  types: string[]
};

const getType = (
  addressElements: AddressElement[]|undefined,
  type: string,
  addressType: 'short_name' | 'long_name' = 'long_name'
): undefined | string => {

  let element;

  if(!addressElements) {
    return undefined;
  }

  addressElements.forEach((addressElement) => {
    if (addressElement.types.indexOf(type) !== -1) {
      element = addressElement[addressType];
    }
  });


  return element;

};

export type GoogleMapsPlaceType = {
  formatted_address: string,
  place_id: string,
  geometry: {
    location: {
      lat: () => number,
      lng: () => number
    }
  }
  address_components: AddressElement[]|undefined
}

export const parsePlace = (place: GoogleMapsPlaceType): PlaceType => {

  const cmp = place.address_components;

  return {
    id: place.place_id as string,
    fullAddress: place.formatted_address,
    address: `${getType(cmp, 'street_number')} ${getType(cmp, 'route')}`,
    country: getType(cmp, 'country', 'short_name') as string,
    postalCode: getType(cmp, 'postal_code') as string,
    city: getType(cmp, 'locality') as string,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  };

};


type GooglePlacesAutocompleteProps = {
  onChange: (place: PlaceType) => void,
  onManualSelection?: (value : string) => void,
  label?: string,
  loading?: boolean
  types?: string[]
  saving?: boolean
  value?: string
  placeholder?: string
  defaultValue?: string
  error?: string
  iconClass?: string;
  icon?: string;
  iconLeft?: boolean;
  suffix?: ReactNode;
  inputClass?: string;
}


const GooglePlacesAutocomplete: FC<GooglePlacesAutocompleteProps> = ({
  onChange,
  types = [],
  label,
  onManualSelection,
  loading = false,
  value,
  defaultValue,
  error,
  saving = false,
  iconClass,
  icon,
  placeholder,
  iconLeft,
  suffix,
  inputClass,
}) => {

  const onChangeRef = useRef(onChange);

  const [displayField, setDisplayField] = useState<boolean>(true);

  const [displayEmptyPanel, setDisplayEmptyPanel] = useState<boolean>(false);

  const { t } = useTranslation('patient_billing', {
    'keyPrefix': 'subscription_drawer.shipping_address.form'
  });

  // Update the ref each time the function changes
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const { ref } = usePlacesWidget({
    apiKey: getEnv('VITE_GOOGLE_MAPS_API_KEY'),
    options: {
      types,
      componentRestrictions: { country: 'fr' },
    },
    onPlaceSelected: (place) => {
      setDisplayEmptyPanel(false);
      onChangeRef.current && onChangeRef.current(parsePlace(place as GoogleMapsPlaceType));
    }
  });

  // Delete container if there is one from a previous mount
  useEffect(() => {
    document.querySelector('.pac-container')?.remove();
  },[]);

  const input = ref.current as null | HTMLInputElement;


  useEffect(() => {

    if(input && defaultValue) {
      input.value = defaultValue;
    }

  },[input,defaultValue]);

  useEffect(() => {
    if(input && value !== undefined) {
      input.value = value || '';
    }
  },[input,value]);

  const onKeyup = useCallback(() => {

    const container = document.querySelector('.pac-container');

    if (!input || !input.value) {
      setDisplayEmptyPanel(false);
      return;
    }

    if (!container) {
      setDisplayEmptyPanel(false);
      return;
    }

    setTimeout(() => {

      if(!input.value) {
        setDisplayEmptyPanel(false);
        return;
      }

      setDisplayEmptyPanel(container?.querySelectorAll('.pac-item').length === 0);
    }, 500);

  }, [input]);



  if (!displayField) {
    return null;
  }



  // We have to display the text field all the time else there's a bug with #ref
  return (<div>
    <StyledFormField
      ref={ref as unknown as Ref<HTMLInputElement>}
      className={loading ? 'hidden' : ''}
      name='google_maps_field'
      placeholder={placeholder ?? t('placeholders.address')}
      label={label}
      onChange={onKeyup}
      defaultValue={defaultValue}
      error={error}
      readOnly={loading || saving}
      iconClass={iconClass}
      icon={icon}
      iconLeft={iconLeft}
      suffix={suffix}
      inputClass={inputClass}
    />
    {loading && (<><LabelSkeleton /><Skeleton field size='large' /></>)}
    {displayEmptyPanel && onManualSelection && (<StyledLink
      className='text-xs'
      onClick={() => {
        setDisplayEmptyPanel(false);
        setDisplayField(false);
        onManualSelection(input?.value as string);
      }}>{t('display_address_link')}
    </StyledLink>)}
  </div>
  );
}
;



export default GooglePlacesAutocomplete;
