import React, { useEffect } from 'react';
import LiaMenuFooter from '@ui/molecules/lia-menu-footer/LiaMenuFooter';
import { useHistory } from 'react-router-dom';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { Item } from '@/packages/back-end/jsonapi';
import SubscriptionGroup from '@/packages/back-end/subscription-group';
import { buildUrl } from '@/utils/Api';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { isDoctor } from '@/packages/back-end/user';

const TOTAL = 50;

const SESSION_STORAGE_KEY = 'lia_credit_counter';

const LiaCreditCounter = () => {

  const { user } = useRecoilValue(authAtom);

  const { t } = useTranslation('lia_credit', {
    keyPrefix: 'menu'
  });

  const { loading, data, fetchData } = useJsonAPIRequest<
    Item<SubscriptionGroup>
  >({
    method: 'get',
    skip: true,
    url: buildUrl('subscription_groups', 'lia')
  });

  useEffect(() => {
    if(!isDoctor(user) || !user?.attributes?.enabledModules?.consultation) {
      return;
    }

    fetchData();


  }, [fetchData,user]);

  const hidden = sessionStorage.getItem(SESSION_STORAGE_KEY);

  const history = useHistory();

  const count = data?.data?.attributes?.remainingCredits ?? 0;

  useEffect(() => {

    const callback = () => {
      sessionStorage.setItem(SESSION_STORAGE_KEY,'true');
    };

    document.querySelector('body')?.addEventListener('buy_credit',callback);

    return () => {
      document.querySelector('body')?.removeEventListener('buy_credit',callback);
    };
    
  }, []);

  if (loading || !data || (count) >= TOTAL || hidden) {
    return null;
  }

  return (<LiaMenuFooter
    title={t('title')}
    remaining={count}
    onClose={() => {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
    }}
    button={t('button')}
    total={TOTAL}
    color='dark'
    subtitle={t(count ? 'subtitle' : 'subtitle_empty', {
      count
    })}
    onButtonClick={() => {
      history.push('/v2/subscriptions/lia');
    }}

  />);

};


export default LiaCreditCounter;