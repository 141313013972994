import React, { FC, useEffect, useState } from 'react';

import { MeUser, TwoFactorMethod } from '@/packages/back-end/user';
import { RequestError } from '@/services/request/useRequest';

import useLogIn from '@/auth/hooks/useLogIn';
import useToast from '@/utils/hooks/useToast';

import EnterEmailStep, { EnterEmailStepData } from './EnterEmailStep';
import CreateAccountStep, { CreateAccountStepData } from './CreateAccountStep';
import EnterPasswordStep, { EnterPasswordStepData } from './EnterPasswordStep';
import TwoFactorStep from './TwoFactorStep';
import EnterResetPasswordCodeStep, {
  EnterResetPasswordCodeStepData,
} from './EnterResetPasswordCodeStep';
import ResetPasswordStep, { ResetPasswordStepData } from './ResetPasswordStep';
import { useBookMeeting } from '../../BookMeetingContext';

export type LoginAccountStepsData = {
  enterEmailStepData?: EnterEmailStepData;
  createAccountStepData?: CreateAccountStepData;
};

type LoginAccountStepsProps = {
  startStepNumber: number;
  setNumOfSteps: (num: number) => void;
  onDone: (newMeUser: MeUser) => void;
};

const LoginAccountSteps: FC<LoginAccountStepsProps> = ({
  startStepNumber,
  setNumOfSteps,
  onDone,
}) => {
  const { addToast } = useToast();

  const { setIsCreatedNewAccount } = useBookMeeting();

  const { loading: loginLoading, logIn } = useLogIn();

  const [openEnterEmailStep, setOpenEnterEmailStep] = useState(true);
  const [openCreateAccountStep, setOpenCreateAccountStep] = useState(true);
  const [openEnterPasswordStep, setOpenEnterPasswordStep] = useState(true);
  const [openEnterResetPasswordCodeStep, setOpenEnterResetPasswordCodeStep] =
    useState(true);
  const [openResetPasswordStep, setOpenResetPasswordStep] = useState(true);
  const [openTwoFactorStep, setOpenTwoFactorStep] = useState(true);

  const [enterEmailStepData, setEnterEmailStepData] =
    useState<EnterEmailStepData>({});
  const [enterPasswordStepData, setEnterPasswordStepData] =
    useState<EnterPasswordStepData>({});
  const [enterResetPasswordCodeStepData, setEnterResetPasswordCodeStepData] =
    useState<EnterResetPasswordCodeStepData>({});
  const [resetPasswordStepData, setResetPasswordStepData] =
    useState<ResetPasswordStepData>({});

  const onLogin = async (password: string) => {
    try {
      const newMeUser = await logIn(
        { username: enterEmailStepData.email || '', password },
        { preventDispatch: true }
      );

      onDone(newMeUser);
      setOpenEnterPasswordStep(false);
      setOpenResetPasswordStep(false);
      return { success: true };
    } catch (e) {
      const error = e as RequestError;
      const token = error?.response?.data?.token;
      const twoFactorMethod = error?.response?.headers?.[
        'www-authenticate'
      ] as TwoFactorMethod;

      if (error?.code === 406 && token && twoFactorMethod) {
        setOpenTwoFactorStep(true);
        setOpenEnterPasswordStep(false);
        setOpenResetPasswordStep(false);
        return { success: false, token, twoFactorMethod };
      }

      addToast('danger', error.message);
      return { success: false, error };
    }
  };

  const showCreateAccoutStep =
    !!enterEmailStepData.email && !enterEmailStepData.isExisting;

  const showEnterPasswordStep =
    !!enterEmailStepData.email &&
    !!enterEmailStepData.isExisting &&
    !!enterEmailStepData.isEnabled;

  const showEnterResetPasswordCodeStep =
    showEnterPasswordStep && enterPasswordStepData.isResetPassword;

  const showEnterEnableAccountCodeStep =
    !!enterEmailStepData.email &&
    !!enterEmailStepData.isExisting &&
    !enterEmailStepData.isEnabled;

  const showResetPasswordStep =
    (showEnterResetPasswordCodeStep || showEnterEnableAccountCodeStep) &&
    !!enterResetPasswordCodeStepData.token;

  const showTwoFactorStep =
    (showEnterPasswordStep && !!enterPasswordStepData.twoFactorConfigs) ||
    (showResetPasswordStep && !!resetPasswordStepData.twoFactorConfigs);

  let enterResetPasswordCodeStepStartNumber = 1;
  if (showEnterPasswordStep) {
    enterResetPasswordCodeStepStartNumber = 2;
  }

  let twoFactorSteStartNumber = 2;
  if (showResetPasswordStep) {
    twoFactorSteStartNumber += 2;
  }

  useEffect(() => {
    let count = 1;
    if (showCreateAccoutStep) {
      count += 1;
    }
    if (showEnterPasswordStep) {
      count += 1;
    }
    if (showEnterResetPasswordCodeStep || showEnterEnableAccountCodeStep) {
      count += 1;
    }
    if (showResetPasswordStep) {
      count += 1;
    }
    if (showTwoFactorStep) {
      count += 1;
    }
    setNumOfSteps(count);
  }, [
    showCreateAccoutStep,
    showEnterPasswordStep,
    showTwoFactorStep,
    showEnterResetPasswordCodeStep,
    showEnterEnableAccountCodeStep,
    showResetPasswordStep,
    setNumOfSteps,
  ]);

  return (
    <>
      <EnterEmailStep
        stepNumber={startStepNumber}
        open={openEnterEmailStep}
        onToggle={setOpenEnterEmailStep}
        data={enterEmailStepData}
        setData={setEnterEmailStepData}
        onDone={() => {
          setOpenEnterEmailStep(false);
          setOpenCreateAccountStep(true);
        }}
      />

      {showCreateAccoutStep && (
        <CreateAccountStep
          stepNumber={startStepNumber + 1}
          open={openCreateAccountStep}
          onToggle={setOpenCreateAccountStep}
          enterEmailStepData={enterEmailStepData}
          setEnterEmailStepData={setEnterEmailStepData}
          onDone={(newMeUser) => {
            setIsCreatedNewAccount(true);
            setOpenCreateAccountStep(false);
            onDone(newMeUser);
          }}
        />
      )}

      {showEnterPasswordStep && (
        <EnterPasswordStep
          stepNumber={startStepNumber + 1}
          open={openEnterPasswordStep}
          enterEmailStepData={enterEmailStepData}
          data={enterPasswordStepData}
          setData={setEnterPasswordStepData}
          onToggle={setOpenEnterPasswordStep}
          loginLoading={loginLoading}
          onLogin={onLogin}
          onForgotPassword={() => {
            setOpenEnterPasswordStep(false);
            setOpenEnterResetPasswordCodeStep(true);
          }}
        />
      )}

      {(showEnterResetPasswordCodeStep || showEnterEnableAccountCodeStep) && (
        <EnterResetPasswordCodeStep
          stepNumber={startStepNumber + enterResetPasswordCodeStepStartNumber}
          open={openEnterResetPasswordCodeStep}
          onToggle={setOpenEnterResetPasswordCodeStep}
          enterEmailStepData={enterEmailStepData}
          data={enterResetPasswordCodeStepData}
          setData={setEnterResetPasswordCodeStepData}
          onDone={() => {
            setOpenEnterResetPasswordCodeStep(false);
            setOpenResetPasswordStep(true);
          }}
        />
      )}

      {showResetPasswordStep && (
        <ResetPasswordStep
          stepNumber={
            startStepNumber + enterResetPasswordCodeStepStartNumber + 1
          }
          open={openResetPasswordStep}
          enterEmailStepData={enterEmailStepData}
          enterResetPasswordCodeStepData={enterResetPasswordCodeStepData}
          setData={setResetPasswordStepData}
          onToggle={setOpenResetPasswordStep}
          loginLoading={loginLoading}
          onLogin={onLogin}
        />
      )}

      {showTwoFactorStep && (
        <TwoFactorStep
          stepNumber={startStepNumber + twoFactorSteStartNumber}
          open={openTwoFactorStep}
          onToggle={setOpenTwoFactorStep}
          enterEmailStepData={enterEmailStepData}
          twoFactorConfigs={
            enterPasswordStepData.twoFactorConfigs ||
            resetPasswordStepData.twoFactorConfigs
          }
          onDone={(newMeUser) => {
            setOpenTwoFactorStep(false);
            onDone(newMeUser);
          }}
        />
      )}
    </>
  );
};

export default LoginAccountSteps;
