import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { MeUser } from '@/packages/back-end/user';
import { authAtom } from '@/services/store/store';

import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import CreatePatientForm from '@/auth/pages/create-patient/CreatePatientForm';
import useLogIn, { LoginResponse } from '@/auth/hooks/useLogIn';

import { EnterEmailStepData } from './EnterEmailStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { useBookMeeting } from '../../BookMeetingContext';

export type CreateAccountStepData = {
  email?: string;
};

type CreateAccountStepProps = {
  stepNumber: number;
  open: boolean;
  enterEmailStepData: EnterEmailStepData;
  setEnterEmailStepData: React.Dispatch<
    React.SetStateAction<EnterEmailStepData>
  >;
  onToggle: (open: boolean) => void;
  onDone: (meUser: MeUser) => void;
};

const CreateAccountStep: FC<CreateAccountStepProps> = ({
  stepNumber,
  open,
  enterEmailStepData,
  onToggle,
  onDone,
}) => {
  const { user: meUser } = useRecoilValue(authAtom);
  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } = useScrollToHideStep(
    'create_account_step'
  );

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { loading, legacyLogIn } = useLogIn();

  const keepRef = useRef({ onDone });
  keepRef.current.onDone = onDone;

  const handleLoginSuccess = async (loginResponse?: LoginResponse) => {
    const newMeUser = await legacyLogIn({
      token: loginResponse?.token || '',
      refreshToken: loginResponse?.refresh_token || '',
    });

    onDone(newMeUser);
    onScrollToHideStep();
  };

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!meUser}
      status={meUser ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('create_account_step.title')}`}
      subtitle={!!meUser && t('create_account_step.subtitle')}
      onToggle={onToggle}
      wrapperTestId='create_account_step'
    >
      <CreatePatientForm
        preventNavigate
        reloadingUserMe={loading}
        email={enterEmailStepData.email}
        onSuccess={handleLoginSuccess}
      />
    </RetractableItem>
  );
};

export default CreateAccountStep;
