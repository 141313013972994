import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AxiosResponseHeaders } from 'axios';
import { useRecoilValue } from 'recoil';

import { Item } from '@/packages/back-end/jsonapi';
import { MeUser } from '@/packages/back-end/user';
import Phone from '@/packages/back-end/phone';
import { attachErrorsToFields } from '@/services/request/utils';
import useJsonAPIRequest, {
  UseJsonApiRequestProps,
} from '@/services/request/useJsonAPIRequest';
import { authAtom } from '@/services/store/store';

import { buildUrl } from '@/utils/Api';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import PhoneInput from '@/components/form/fields/phone/PhoneInput';
import { getError } from '@/components/form/utils';

import { flatProfileMeRelationships } from '../../utils';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { ConfirmPhoneStepData } from './ConfirmPhoneNumberStep';
import { useBookMeeting } from '../../BookMeetingContext';

export const getRetryValues = (headers?: AxiosResponseHeaders) => {
  const retryAfter = headers?.['retry-after'];

  if (isNaN(Number(retryAfter))) {
    return { retryAt: undefined, retryAfter: undefined };
  } else {
    const retryAt = new Date();
    retryAt.setSeconds(retryAt.getSeconds() + Number(retryAfter));

    return { retryAt, retryAfter: Number(retryAfter) };
  }
};

export const getPhoneRequestData = (
  meUser?: MeUser
): UseJsonApiRequestProps => {
  const { phoneNumberMe } = flatProfileMeRelationships(meUser);
  return {
    skip: true,
    method: phoneNumberMe ? 'put' : 'post',
    url: buildUrl(
      phoneNumberMe ? `mobile_phones/${phoneNumberMe?.attributes?._id}` : 'mobile_phones'
    ),
  };
};

type FormDataType = {
  number: string;
};

const schema = yup.object({
  number: yup.string().required('field_required'),
});

const getDefaultValues = (meUser?: MeUser) => {
  const { phoneNumberMe } = flatProfileMeRelationships(meUser);
  return {
    number: (phoneNumberMe?.attributes?.number || '').replaceAll(' ', ''),
  };
};

const parseData = (data: FormDataType, meUser?: MeUser) => {
  return {
    user: meUser?.id,
    number: data.number,
  };
};

export type EnterPhoneStepData = {
  retryAfter?: number;
  retryAt?: Date;
  phone?: Phone;
};

type EnterPhoneNumberStepProps = {
  stepNumber: number;
  open: boolean;
  confirmPhoneStepData: ConfirmPhoneStepData;
  data: EnterPhoneStepData;
  setData: React.Dispatch<React.SetStateAction<EnterPhoneStepData>>;
  onToggle: (open: boolean) => void;
  onDone: () => void;
};

const EnterPhoneNumberStep: FC<EnterPhoneNumberStepProps> = ({
  stepNumber,
  open,
  confirmPhoneStepData,
  data,
  setData,
  onToggle,
  onDone,
}) => {
  const { user: meUser } = useRecoilValue(authAtom);
  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('enter_phone_step');

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const {
    fetchData: savePhone,
    loading: saving,
    error: backErrors,
  } = useJsonAPIRequest<Item<Phone>>(getPhoneRequestData(meUser));

  const {
    headers: sendValidationResponseHeaders,
    fetchData: sendValidationPhone,
    loading: sendingValidation,
    error: sendValidationBackErrors,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('phones/:id/send_validation'),
  });

  const {
    formState: { errors },
    control,
    setError,
    reset,
    handleSubmit,
  } = useForm<FormDataType>({
    defaultValues: getDefaultValues(meUser),
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const updatedPhone = (await savePhone(parseData(data, meUser), {
        throw: true,
        displayToastOnError: true,
      })) as Item<Phone>;

      if (!confirmPhoneStepData.confirmed) {
        await sendValidationPhone(
          {},
          {
            url: buildUrl(
              `phones/${updatedPhone.data.attributes._id}/send_validation`
            ),
            throw: true,
            displayToastOnError: true,
          }
        );
      }

      setData({ phone: updatedPhone.data });
      onScrollToHideStep();
      onDone();
      // eslint-disable-next-line no-empty
    } catch {}
  });

  useEffect(() => {
    if (sendValidationResponseHeaders) {
      setData((oldState) => ({
        ...oldState,
        ...getRetryValues(sendValidationResponseHeaders),
      }));
    }
  }, [sendValidationResponseHeaders, setData]);

  useEffect(() => {
    reset(getDefaultValues(meUser));
  }, [meUser, reset]);

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
    attachErrorsToFields(setError, sendValidationBackErrors);
  }, [backErrors, sendValidationBackErrors, setError]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.phone}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={data.phone ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('enter_phone_step.title')}`}
      subtitle={data.phone?.attributes.number}
      onToggle={onToggle}
      wrapperTestId='enter_phone_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <p className='text-sm font-medium'>{t('enter_phone_step.description')}</p>
        <Controller
          name='number'
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <PhoneInput
              className='w-full'
              name={name}
              onChange={onChange}
              helpText={t('enter_phone_step.fields.number.help_text')}
              value={value}
              error={getError('number', errors, errorT)}
            />
          )}
        />

        <Button
          type='submit'
          color='primary'
          saving={saving || sendingValidation}
          data-testid='enter_phone_step.continue'
        >
          {t('enter_phone_step.continue')}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default EnterPhoneNumberStep;
