import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { UrlArg, addUrlParameters } from '../Api';

import useSearchParams from './useSearchParams';

const useChangeSearchParams = () => {
  const history = useHistory();
  const searchParams = useSearchParams();

  const keepRef = useRef({
    searchParams,
    hash: history.location.hash,
    pathname: history.location.pathname,
  });
  keepRef.current.searchParams = searchParams;
  keepRef.current.hash = history.location.hash;
  keepRef.current.pathname = history.location.pathname;

  const onUpdateSearchParams = useCallback(
    (params: UrlArg) => {
      const { pathname, searchParams, hash } = keepRef.current;

      const url = addUrlParameters(pathname, { ...searchParams, ...params });
      history.replace(url + hash);
    },
    [history]
  );

  const onRemoveSearchParams = useCallback(
    (paramKeys: string[]) => {
      const { pathname, searchParams, hash } = keepRef.current;
      const newSearchParams = { ...searchParams };

      paramKeys.forEach((paramKey) => delete newSearchParams[paramKey]);

      const url = addUrlParameters(pathname, newSearchParams);
      history.replace(url + hash);
    },
    [history]
  );

  const onResetSearchParams = useCallback(
    () => {
      const { pathname, hash } = keepRef.current;

      const url = addUrlParameters(pathname);
      history.replace(url + hash);
    },
    [history]
  );

  return { onUpdateSearchParams, onRemoveSearchParams, onResetSearchParams,searchParams };
};

export default useChangeSearchParams;
