import React, { lazy } from 'react';
import Route from '@/components/router/Route';
import { ROUTES } from '@/app/structure/body/routes/Public.utils';

const PublicProfile = lazy(() => import('@/app/structure/page/public/doctor-profile/PublicProfile'));

import { SearchDoctorsProvider } from '@/app/structure/page/public/doctor-list/SearchDoctorsContext';

const Home = lazy(() => import('@/app/structure/page/public/home/Home'));
const DoctorList = lazy(() => import('@/app/structure/page/public/doctor-list/DoctorList'));

const getRoutes = () => {

  const data = [];

  data.push(<Route
    exact={true}
    key={ROUTES.public.home}
    path={ROUTES.public.home} >
    <SearchDoctorsProvider isSearchPage={false}>
      <Home />
    </SearchDoctorsProvider>
  </Route>
  );

  data.push(<Route
    key={ROUTES.public.doctors_list}
    exact={true}
    path={[
      ROUTES.public.doctors_list_all,
      ROUTES.public.doctors_list,
      ROUTES.public.doctors_list_page,
      ROUTES.public.doctors_list_specialty,
      ROUTES.public.doctors_list_specialty_page,
    ]} >
    <SearchDoctorsProvider isSearchPage={false}>
      <DoctorList />
    </SearchDoctorsProvider>
  </Route>
  );

  data.push(<Route
    key={ROUTES.public.doctors_directory}
    exact={true}
    path={[
      ROUTES.public.doctors_directory,
      ROUTES.public.doctors_directory_page
    ]} >
    <SearchDoctorsProvider isDirectoryPage={true}>
      <DoctorList />
    </SearchDoctorsProvider>
  </Route>
  );

  data.push(<Route
    key={ROUTES.public.doctors_search}
    exact={true}
    path={[
      ROUTES.public.doctors_search,
    ]} >
    <SearchDoctorsProvider isSearchPage={true}>
      <DoctorList />
    </SearchDoctorsProvider>
  </Route>
  );

  data.push(<Route
    key={ROUTES.public.doctor_profile}
    path={[
      ROUTES.public.doctor_profile,
      ROUTES.public.doctor_profile_legacy,
    ]} >
    <PublicProfile />
  </Route>
  );


  return data;

};


export default getRoutes;