import React from 'react';
import { useTranslation } from 'react-i18next';

import DateFormat from '@/components/date-format/dateFormat';

import { useEmails } from '../EmailsContext';
import ReadButton from '../action-buttons/ReadButton';
import ArchiveButton from '../action-buttons/ArchiveButton';
import DeleteButton from '../action-buttons/DeleteButton';

const HeaderRight = () => {
  const { t } = useTranslation('ms_sante');
  const { selectedEmail } = useEmails();

  return (
    <div className='w-full'>
      <div className='flex flex-row gap-4 justify-between items-start lg:w-[97%] xl:w-full'>
        <div className='overflow-hidden'>
          <p className='font-medium text-gray-900'>
            {selectedEmail?.attributes?.subject}
          </p>
          <p className='flex gap-1 text-sm text-gray-900'>
            <span className='flex-shrink-0'>{t('from')}</span>
            <span
              className='font-medium truncate'
              title={selectedEmail?.attributes?.from.name ? selectedEmail?.attributes?.from.name
                : selectedEmail?.attributes?.from.email}
            >
              {selectedEmail?.attributes?.from.name ? selectedEmail?.attributes?.from.name
                : selectedEmail?.attributes?.from.email}
            </span>
          </p>
          <p className='flex gap-1 text-sm text-gray-900'>
            <span className='flex-shrink-0'>{t('to')}</span>
            <span
              className='font-medium truncate'
              title={selectedEmail?.attributes?.to.map((item) => item.name).join(', ')}
            >
              {selectedEmail?.attributes?.to.map((item) => item.name).join(', ')}
            </span>
          </p>
          {!!selectedEmail?.attributes?.cc.length && (<p className='flex gap-1 text-sm text-gray-900'>
            <span className='flex-shrink-0'>{t('cc')}</span>
            <span
              className='font-medium truncate'
              title={selectedEmail?.attributes?.cc.map((item) => item.name).join(', ')}
            >
              {selectedEmail?.attributes?.cc.map((item) => item.name).join(', ')}
            </span>
          </p>)}
          {!!selectedEmail?.attributes?.createdDate && (
            <p className='flex gap-1 text-sm text-gray-900'>
              <span>{t('send_date')}</span>
              <span className='font-medium'>
                <DateFormat value={selectedEmail.attributes.createdDate} />
              </span>
            </p>
          )}
        </div>
        <div className='flex relative flex-row flex-shrink-0 gap-x-3 justify-between items-center'>
          <ReadButton email={selectedEmail} />
          <ArchiveButton email={selectedEmail} />
          <DeleteButton email={selectedEmail} />
        </div>
      </div>
    </div>
  );
};

export default HeaderRight;
