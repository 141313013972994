import React from 'react';
import { useDiscussions } from '../ChatContext';
import { useTranslation } from 'react-i18next';
import Discussion from '@/packages/back-end/Discussion';
import useUpdateDiscussion from '@/app/structure/page/chat/components/useUpdateDiscussion';
import Button from '@ui/atoms/buttons/button/Button';

type ArchiveButtonProps = {
  isMultipleEdit?: boolean;
  refresh?: () => void;
  discussion?: Discussion;
  iconSize?: number
};

const ArchiveButton = ({
  isMultipleEdit = false,
  refresh,
  discussion,
  iconSize = 20,

}: ArchiveButtonProps) => {


  const { selectedDiscussion,discussions,setDiscussions } = useDiscussions();
  const { t } = useTranslation('chat');

  const updatedDiscussion = discussion || selectedDiscussion as Discussion;

  const { update, loading } = useUpdateDiscussion({
    discussion: updatedDiscussion,
    refresh,
    isMultipleEdit
  });

  return (
    <Button
      color='transparent'
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      iconSize={iconSize}
      icon={updatedDiscussion?.attributes.archived ? 'disarchive' : 'archive'}
      saving={loading}
      title={updatedDiscussion?.attributes.archived
        ? t('header_right.unarchive')
        : t('header_right.archive')
      }
      onClick={(async () => {
        const result = await update({ archived: !updatedDiscussion?.attributes.archived },
          `toasts.discussion_${updatedDiscussion?.attributes.archived ? 'disarchived' : 'archived'}`
        );

        const ids = result.map((item) => item.id);

        if(ids.length <= 5 || !refresh) {
          const newDiscussions = discussions.filter(item => !ids.includes(item.id));
          setDiscussions([...newDiscussions]);
        }


      })}
    />
  );

};

export default ArchiveButton;
