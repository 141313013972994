import { buildUrl, UrlArg } from '@/utils/Api';
import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import Specialty from '@/packages/back-end/specialty';
import User from '@/packages/back-end/user';
import Address from '@/packages/back-end/address';
import { OptionType } from '@ui/types';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';

type MedicProfile = Entity & {
  attributes: {
    cps: string;
    cpsNumber: string;
    emailApiCrypt: string;
    emailMsSante: string;
    website: string;
    siret: string;
    finess: string;
    fax: string | null;
    idCard: string | null;
    signature: string | null;
    businessStamp: string | null;
    companyName: string;
    calendarMinHour: string;
    calendarMaxHour: string;
    calendarInterval?: number | null;
    calendarDisplayDates?: number[];
    _id: number;
    status: number;
    RPPS: string;
    ADELI: string;
    thumbnails: {
      idCard: {
        '200x200': string | null;
        '200x*': string | null;
      };
      signature: {
        '200x200': string | null;
        '200x*': string | null;
      };
      businessStamp: {
        '200x200': string | null;
        '200x*': string | null;
      };
      cps: {
        '200x200': string | null;
        '200x*': string | null;
      };
    };
  };
  relationships: {
    specialties: Item<(Specialty | Relationship)[]>
    user: Item<User | Relationship>;
    professionalAddress: Item<Address | Relationship>;
  };
}

export const POST_COLLECTION = buildUrl('medics');
export const GET_COLLECTION = (args?: UrlArg) =>
  buildUrl('medics', undefined, args);
export const PUT_ITEM = (id: string | number, isDoctor = true) => {
  return buildUrl(`${isDoctor ? 'medics' : 'nurses'}/${id}`);
};


export const buildOption = (item: MedicProfile): OptionType<MedicProfile> => {

  return {
    value: item.id,
    data: item,
    label: item.relationships.user.data.attributes?.fullName as string,
    avatar: getAvatarProps({ user: item.relationships.user.data.attributes as UserType, colorLength: 10 })
  };

};


export const buildOptionWithUserIdAsValue = (item: MedicProfile): OptionType<MedicProfile> => {

  return {
    value: item.relationships.user.data.id,
    data: item,
    label: item.relationships.user.data.attributes?.fullName as string,
    avatar: getAvatarProps({ user: item.relationships.user.data.attributes as UserType, colorLength: 10 })
  };

};

export default MedicProfile;