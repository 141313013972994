import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PublicProfile from '@/packages/back-end/PublicProfile';

import UserAvatar from '@ui/molecules/user-avatar/UserAvatar';
import UserType from '@/utils/sharing/types/user';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import { buildWebappUrl } from '@/utils/router';
import Link from '@ui/atoms/texts/link/Link';
import SkeletonBase from '@ui/atoms/loaders/skeleton/Skeleton';
import { ROUTES } from '@/app/structure/body/routes/Public.utils';

import { flatMedicProfileRelationships } from '../utils';
import ConsultationLocation from '@/packages/back-end/consultation-location';
import Address from '@/packages/back-end/address';
import useApiConstant from '@/utils/hooks/useApiConstant';
import Specialty from '@/packages/back-end/specialty';

const Skeleton = () => {
  return (
    <div className='flex gap-6 items-center py-6 px-8 rounded-2xl border border-gray-100'>
      <div className='w-20'>
        <SkeletonBase className='w-20 h-20 rounded-full' />
      </div>
      <div className='w-full'>
        <SkeletonBase className='w-1/2 h-7' />
        <SkeletonBase className='mt-4 w-1/2 h-3.5' />
        <SkeletonBase className='mt-1 w-1/2 h-3.5' />
        <SkeletonBase className='mt-1 w-1/2 h-3.5' />
        <SkeletonBase className='mt-1 w-1/2 h-3.5' />
      </div>
    </div>
  );
};

type DoctorInformationCardProps = {
  loading?: boolean;
  profileDoctor?: PublicProfile;
  consultationLocation?: ConsultationLocation
};

const DoctorInformationCard: FC<DoctorInformationCardProps> = ({
  loading,
  consultationLocation,
  profileDoctor,
}) => {
  const { user } = flatMedicProfileRelationships(profileDoctor);

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { getConstant } = useApiConstant();

  const medicAddress = useMemo(() => {

    if(consultationLocation) {
      return consultationLocation?.relationships.address.data as Address | undefined;
    }

    const favorite = profileDoctor?.relationships.consultationLocations?.data?.find((location) => {
      return location.attributes.favorite;
    });

    if(favorite) {
      return favorite.relationships.address.data as Address | undefined;
    }

    return profileDoctor?.relationships?.consultationLocations?.data[0]
      ?.relationships?.address?.data as Address | undefined;



  },[consultationLocation,profileDoctor]);

  const { address, postalCode, city, fullCountry } = medicAddress?.attributes || {};

  if (loading) {
    return <Skeleton />;
  }

  const specialties = (profileDoctor?.relationships?.medic?.
    data?.relationships?.specialties?.data ?? []) as Specialty[];


  if (!profileDoctor) {
    return null;
  }

  return (
    <div
      className='flex gap-6 items-center py-6 px-8 rounded-2xl border border-gray-100'
      data-testid='book_meeting.doctor_info_card'
    >
      <UserAvatar
        className='w-20 h-20 text-2xl'
        src={user?.attributes.profilePicture as string}
        {...getAvatarProps({
          user: user?.attributes as UserType,
          colorLength: 10,
        })}
      />

      <div className='grid grid-cols-3 gap-2 w-full'>
        <div className='col-span-2' >
          <p className='mb-3 text-lg font-bold' data-testid='doctor_info_card.full_name'>
            {user?.attributes?.fullName}
          </p>
          <p className='text-xs'>{user?.attributes?.fullName}</p>
          {!!specialties.length && (<p className='text-xs'>
            {specialties.map((item) => item.attributes.name).join(', ')}
          </p>)}
          {!!medicAddress && <p className='text-xs' data-testid='doctor_info_card.address'>
            {address}
          </p>}
          {!!medicAddress && (
            <p className='text-xs' data-testid='doctor_info_card.address'>
              {`${postalCode} ${city} ${fullCountry}`}
            </p>
          )}
          {!!profileDoctor?.attributes.conventionSector && (
            <p className='text-xs' data-testid='doctor_info_card.convention_sector'>
              {getConstant('publicProfile','conventionSector',profileDoctor?.attributes.conventionSector)}
            </p>
          )}
        </div>

        <div className='flex items-end h-full'>
          <Link
            target='_blank'
            className='text-xs font-bold'
            href={buildWebappUrl(ROUTES.public.doctor_profile, {
              canonical: profileDoctor?.attributes?.canonical,
            })}
          >
            {t('confirm_meeting_step.view_profile')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DoctorInformationCard;
