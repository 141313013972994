import { useEffect, useState } from 'react';


export const BREAKPOINTS = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1200
};

type ViewPortValues = {
  width: number;
  height: number;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  custom: boolean;
}

const getViewPortValues = (width: number, height: number, customBreakpoint?: number) : ViewPortValues => (
  {
    width,
    height,
    xs: width < BREAKPOINTS.xs,
    sm: width >= BREAKPOINTS.xs && width < BREAKPOINTS.sm,
    md: width >= BREAKPOINTS.sm && width < BREAKPOINTS.md,
    lg: width >= BREAKPOINTS.md && width < BREAKPOINTS.lg,
    xl: width >= BREAKPOINTS.lg,
    custom: !!customBreakpoint && width <= customBreakpoint,
  });

const useViewport = (options?: { customBreakpoint?: number }) : ViewPortValues => {
  const [viewport, setViewport] = useState(
    getViewPortValues(
      window.innerWidth,
      window.innerHeight,
      options?.customBreakpoint
    )
  );

  useEffect(() => {
    const handleResize = () => {
      setViewport(getViewPortValues(window.innerWidth, window.innerHeight, options?.customBreakpoint));
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [options?.customBreakpoint]);

  return viewport;
};

export default useViewport;