import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Notification, {
  CLEAR_ALL,
  ARCHIVE_ALL,
} from '@/packages/back-end/notification';
import useRequest from '@/services/request/useRequest';

import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import Separator from '@ui/atoms/containers/separator/Separator';
import DrawerHeader from '@ui/molecules/drawer-header/DrawerHeader';
import Tabs from '@ui/organisms/tabs/Tabs';
import NotificationsSkeleton from '@ui/molecules/skeletons/notifications/NotificationsSkeleton';
import Button from '@ui/atoms/buttons/button/Button';
import Picto from '@ui/atoms/icons/picto/Picto';
import useToast from '@/utils/hooks/useToast';

import NotificationsList, {
  NotificationsListType,
} from '../notifications-list/NotificationsList';
import useBadge from '@/utils/hooks/useBadge';

export type NotificationsType = NotificationsListType & {
  loading?: boolean;
  hasMorePages?: boolean;
  onPushClick?: () => void;
  onCloseClick: () => void;
  unread: number;
  onLoadMore?: () => void;
  activeTab?: string;
  onTabChange?: (tab: string) => void;
  onUpdated?: (item: Notification) => void;
  onDeleted?: (item: Notification) => void;
  setData?: (
    newData: Notification[] | ((prevData: Notification[]) => Notification[])
  ) => void;
};

const Notifications: FC<NotificationsType> = ({
  loading,
  hasMorePages,
  archived,
  items,
  onPushClick,
  onItemClick,
  onCloseClick,
  onLoadMore,
  onTabChange,
  onUpdated,
  onDeleted,
  setData,
  activeTab,
  unread = 0,
}) => {
  const { addToast } = useToast();

  const { t } = useTranslation();
  const { t: tUi } = useTranslation('ui', { keyPrefix: 'notifications' });

  const { loading: loadingClearAll, fetchData: readAll } = useRequest({
    method: 'post',
    skip: true,
    url: CLEAR_ALL(),
  });

  const { loading: loadingArchiveAll, fetchData: archiveAll } = useRequest({
    method: 'post',
    skip: true,
    url: ARCHIVE_ALL(),
  });

  const { notifications: badges } = useBadge();

  const title = useMemo(() => {
    if (!onPushClick) {
      return t('ui.notifications.title');
    }

    return (
      <span className='flex gap-2'>
        {t('ui.notifications.title')}
        <Picto
          className='text-base'
          icon='notificationPush'
          onIconClick={onPushClick}
        />
      </span>
    );
  }, [onPushClick, t]);

  const onAllReadClick = useCallback(async () => {
    try {
      await readAll(undefined, {
        displayToastOnError: true,
        throw: true,
      });

      setData?.((prevState) =>
        prevState.map((notification) => {
          notification.attributes.read = true;
          return notification;
        })
      );
      // eslint-disable-next-line no-empty
    } catch {}
  }, [readAll, setData]);

  const onArchiveAllReadClick = useCallback(async () => {
    try {
      await archiveAll(undefined, {
        displayToastOnError: true,
        throw: true,
      });

      setData?.((prevState) =>
        prevState.filter((notification) => !notification.attributes.read)
      );

      addToast('success', tUi('archive_all_read_success'));
      // eslint-disable-next-line no-empty
    } catch {}
  }, [archiveAll, setData, addToast, tUi]);

  return (
    <div className='flex flex-col h-full bg-primary-50'>
      <DrawerHeader
        title={title}
        subtitle={
          loading ? (
            <Skeleton className='flex-grow my-1 w-1/2 h-4' />
          ) : (
            `${unread} ${t('ui.notifications.unReadText')}`
          )
        }
        className='p-3'
        onCloseClick={onCloseClick}
        size='small'
      />

      <Separator />

      <div className='px-2'>
        <Tabs
          tabs={{
            active: {
              label: tUi('tabs.active'),
              count: badges.unread ? badges.unread : undefined
            },
            archived: { label: tUi('tabs.archive') },
          }}
          initialActiveTab={activeTab}
          onChange={onTabChange}
        />
      </div>

      {!archived && (<div className='flex gap-2 px-3 mb-2'>
        <Button
          disabled={loading}
          saving={loadingArchiveAll}
          onClick={onArchiveAllReadClick}
          data-testid='archive_all_read'
        >
          {tUi('archive_all_read')}
        </Button>

        <Button
          disabled={loading}
          saving={loadingClearAll}
          onClick={onAllReadClick}
          data-testid='mark_all_read'
        >
          {tUi('mark_all_read')}
        </Button>
      </div> )}

      {loading ? (
        <NotificationsSkeleton />
      ) : (
        <NotificationsList
          archived={archived}
          hasMorePages={hasMorePages}
          items={items}
          onUpdated={onUpdated}
          onDeleted={onDeleted}
          onItemClick={onItemClick}
          onLoadMore={onLoadMore}
          className='p-3'
        />
      )}
    </div>
  );
};

export default Notifications;
