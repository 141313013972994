import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import React, { lazy } from 'react';
import { ROLE } from '@/packages/types';
import { ROUTES } from '@/app/structure/body/routes/Developer.utils';

const Dashboard = lazy(() => import('@/app/structure/page/developer/dashboard/Dashboard'));


const getRoutes = () => {

  const data = [];

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.DEVELOPER,ROLE.ADMIN]}
    key={ROUTES.dashboard}
    path={ROUTES.dashboard}>
    <Dashboard />
  </PrivateRoute>
  );

  return data;

};


export default getRoutes;