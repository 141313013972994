import React, { ReactNode, useContext, useMemo } from 'react';
import noop from 'lodash/noop';

import { Item } from '@/packages/back-end/jsonapi';
import { GET_ME } from '@/packages/back-end/profile';
import Patient, {
  POST_COLLECTION,
  PUT_ITEM,
} from '@/packages/back-end/patient';
import useJsonAPIRequest, {
  UseJsonApiRequestProps,
} from '@/services/request/useJsonAPIRequest';
import { FetchDataType, RequestError } from '@/services/request/useRequest';
import User from '@/packages/back-end/user';
import Phone from '@/packages/back-end/phone';

const INCLUDED = [
  'professionalAddress',
  'specialties',
  'user',
  'user.phones',
  'user.phoneNumber',
];

const getRequestData = (
  entity: null | undefined | Patient
): UseJsonApiRequestProps => {
  return {
    skip: true,
    method: entity ? 'put' : 'post',
    include: INCLUDED,
    url: entity ? PUT_ITEM(entity.attributes._id) : POST_COLLECTION(),
  };
};

const PatientProfileContext = React.createContext<{
  profile: Patient | null;
  userProfile: User | null;
  userProfilePhones: Phone[];
  called?: boolean;
  loading: boolean;
  reloading: boolean;
  saving: boolean;
  error?: RequestError;
  updateErrors?: RequestError;
  fetchProfile: FetchDataType<unknown, Item<Patient>>;
  updateProfile: FetchDataType<unknown, Item<Patient>>;
}>({
  profile: null,
  userProfile: null,
  userProfilePhones: [],
  called: false,
  loading: false,
  reloading: false,
  saving: false,
  updateProfile: noop as unknown as FetchDataType<unknown, Item<Patient>>,
  fetchProfile: noop as unknown as FetchDataType<unknown, Item<Patient>>,
});

export const PatientProfileProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    called,
    data: profile,
    fetchData,
    loading,
    error,
  } = useJsonAPIRequest<Item<Patient>>({
    url: GET_ME,
    method: 'get',
    include: INCLUDED,
  });

  const {
    fetchData: updateProfile,
    error: updateErrors,
    loading: saving,
  } = useJsonAPIRequest<Item<Patient>>(getRequestData(profile?.data));

  const userProfile = useMemo(() => {
    return (profile?.data?.relationships?.user?.data as User) || null;
  }, [profile]);

  const userProfilePhones = useMemo(() => {
    return (userProfile?.relationships?.phones?.data as Phone[]) || [];
  }, [userProfile]);

  return (
    <PatientProfileContext.Provider
      value={{
        called,
        updateProfile,
        saving,
        updateErrors,
        profile: profile?.data || null,
        userProfile,
        userProfilePhones,
        loading: !called && loading,
        reloading: !!called && loading,
        error,
        fetchProfile: fetchData,
      }}
    >
      {children}
    </PatientProfileContext.Provider>
  );
};

export const usePatientProfile = () => {
  return useContext(PatientProfileContext);
};
