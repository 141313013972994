import { formatDate } from '@/components/date-format/dateFormat';
import Patient from '@/packages/back-end/patient';
import { parseId } from '@/utils/Api';
import { buildLegacyUrl } from '@/utils/Legacy';
import cls from 'classnames';
import UserAvatar from 'instamed-styleguide/ui/molecules/user-avatar/UserAvatar';
import React from 'react';
import Link from 'instamed-styleguide/ui/atoms/texts/link/Link';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import { useTranslation } from 'react-i18next';
import { MEDIUM_GRAY } from '../messages/MessageComposer';
import { Thumbnails } from '@/packages/back-end/user';
import UserType from '@/utils/sharing/types/user';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import { PatientTagged } from '@/packages/back-end/Discussion';

type PatientRowProps = {
  patient: Patient|PatientTagged;
  removePatient?: () => void;
  className?: string;
};

const PatientRow = ({ patient, removePatient, className }: PatientRowProps) => {
  const userTagged = patient?.relationships?.user?.data?.attributes;
  const { t } = useTranslation('chat');

  return (
    <div
      className={cls(
        'flex flex-row items-center py-1 px-2 w-min bg-white rounded-md border border-gray-100',
        className,
        {
          'min-h-[50px]': !userTagged?.dateOfBirth
        }
      )}
    >
      <UserAvatar
        src={(userTagged?.thumbnails as Thumbnails)?.profilePicture['50x50'] as string}
        {...getAvatarProps({
          user: userTagged as UserType,
          colorLength: 10,
        })}
        size='xs'
        className='mr-1'
      />
      <div className='overflow-x-hidden'>
        <Link
          href={buildLegacyUrl(`/patients/user/${parseId(userTagged?._id as string)}`)}
          title={userTagged?.fullName as string|undefined}
          target='_blank'
        >
          <p className='text-sm text-gray-900 truncate'>
            {userTagged?.fullName as string|undefined}
          </p>
          {userTagged?.dateOfBirth && (
            <p className='text-[12px] text-gray-400-09 truncate'>
              {formatDate(userTagged?.dateOfBirth as string, {
                format: 'DD/MM/YYYY',
              })}
            </p>
          )}
        </Link>
      </div>
      {removePatient && (
        <button
          onClick={removePatient}
          title={t('tag_patient.remove')}
          className='pl-4'
        >
          <Icon icon='close' color={MEDIUM_GRAY} />
        </button>
      )}
    </div>
  );
};

export default PatientRow;
