import React from 'react';
import { useDiscussions } from '../ChatContext';
import { useTranslation } from 'react-i18next';
import Discussion from '@/packages/back-end/Discussion';
import useUpdateDiscussion from '@/app/structure/page/chat/components/useUpdateDiscussion';
import Button from '@ui/atoms/buttons/button/Button';

const MarkAsReadForMedicalTeam = ({ discussion,isMultipleEdit }:
  { discussion?: Discussion, isMultipleEdit ?: boolean}) => {

  const { selectedDiscussion } = useDiscussions();
  const { t } = useTranslation('chat');

  const { update, loading } = useUpdateDiscussion({
    discussion: (discussion || selectedDiscussion) as Discussion,
    isMultipleEdit
  });


  return (
    <Button
      color='transparent'
      icon='readNotification'
      iconHoverColor='#072337'
      iconColor='#9ba6ae'
      iconSize={20}
      saving={loading}
      title={t('header_right.read_for_team')}
      onClick={() =>
        update({ readForMedicalTeam: true }, 'toasts.discussion_read_for_team')
      }
    />
  );
};

export default MarkAsReadForMedicalTeam;
