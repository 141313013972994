import UserAvatar from '@ui/molecules/user-avatar/UserAvatar';
import React, { FC } from 'react';
import cls from 'classnames';


type ElementSelectorProps = {
  onClick: () => void;
  colorKey : number;
  initial : string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  subtitle: string
}


const ElementSelector : FC<ElementSelectorProps> = ({
  onClick,
  colorKey,
  selected,
  disabled,
  initial,
  title,
  subtitle
}) => {

  return (<button
    type='button'
    disabled={disabled}
    onClick={onClick}
    className={cls(disabled ? 'bg-gray-50' : '','',
      selected ? 'bg-primary-100' : '',
      'flex flex-row gap-4 items-center p-2 w-full hover:bg-gray-50 rounded-lg border border-gray-50')}
  >
    <UserAvatar
      colorKey={colorKey}
      alt={title}
      initial={initial}
    />
    <span className='flex flex-col items-start'>
      <span className='text-sm font-bold text-left'>{title}</span>
      <span className='text-xs text-left text-gray-500'>{subtitle}</span>
    </span>
  </button>);

};

export default ElementSelector;