import React from 'react';

import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';

export const EmailsLoader = () => {
  const emails = Array.from(Array(10).keys());
  return (
    <div className='px-3 w-full'>
      {emails.map((idx) => (
        <div
          className='flex flex-col mb-2'
          key={`${idx}-emails-row-loader`}
        >
          <div className='flex flex-row justify-between mt-3 h-[20px]' >
            <Skeleton
              className='w-8/12 h-[20px]'
            />
            <Skeleton
              className='w-3/12 h-[20px]'
            />
          </div>
          <Skeleton
            className=' mt-2 w-full h-[10px]'
          />
          <Skeleton
            className=' mt-2 w-full h-[10px]'
          />
        </div>
      ))}
    </div>
  );
};

export const HeaderRightLoader = () => {
  return (
    <div className='flex flex-col w-full'>
      <Skeleton className='mb-1 w-2/6 h-[20px]' />
      <Skeleton className='mb-1 w-3/6 h-[17px]' />
      <Skeleton className='mb-1 w-3/6 h-[17px]' />
      <Skeleton className='mb-1 w-3/6 h-[17px]' />
      <Skeleton className='mb-1 w-3/6 h-[17px]' />
    </div>
  );
};

export const LoadingEmail = () => (
  <div className='flex flex-col w-full h-full'>
    <HeaderRightLoader />
    <Skeleton className='mt-3 w-full h-full' />
  </div>
);
