import React, { FC } from 'react';
import BaseLink, { LinkProps } from '@ui/atoms/texts/link/Link';
import { useHistory } from 'react-router-dom';
import { isWebappUrl } from '@/utils/router';


const Link: FC<LinkProps & { target ?: '_blank'|'_self'}> = ({ href, onClick,target, ...rest }) => {

  const history = useHistory();

  if(!onClick && href && isWebappUrl(href) && target !== '_blank') {
    onClick = (e) => {
      e.preventDefault();
      history.push(href);
    };
  }

  return (<BaseLink
    onClick={onClick}
    target={target}
    href={href}
    {...rest} />);
};


export default Link;