import React, { FC, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import Checkbox from 'instamed-styleguide/ui/atoms/fields/checkbox/Checkbox';
import Dropdown from 'instamed-styleguide/ui/molecules/dropdown/Dropdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import EmailsRow from './Row';

import { EmailsLoader } from '../components/EmailsLoader';
import SearchField from '../../chat/components/SearchField';
import { useEmails } from '../EmailsContext';
import Button from '@ui/atoms/buttons/button/Button';
import useToast from '@/utils/hooks/useToast';
import { RequestError } from '@/services/request/utils';
import ArchiveButton from '../action-buttons/ArchiveButton';
import MarkAsRead from '../action-buttons/ReadButton';
import FilterDropdown from '@/app/structure/page/mssante/left-components/FilterDropdown';
import P from '@ui/atoms/texts/base/P';
import SpinnerLoader from '@ui/atoms/loaders/spinner/SpinnerLoader';
import { isDoctor } from '@/packages/back-end/user';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { UrlArg } from '@/utils/Api';
import Email from '@/packages/back-end/mssante/email';

export const DropdownFilter = styled(Dropdown)`
    border: 0;

    span {
        padding: 0;
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: #9ca7af;
        }
    }
`;

export type HeaderLeftProps = {
  onCloseDrawer?: () => void;
};

type EmptyTextProps = {
  searchValue?: string
  queryParams?: UrlArg,
  selectedEmail?: Email | null
  impersonatedUser: string | number | undefined
}

const EmptyText: FC<EmptyTextProps> = ({ searchValue,queryParams, selectedEmail, impersonatedUser }) => {

  const { t } = useTranslation('ms_sante');

  const { user } = useRecoilValue(authAtom);

  const userIsDoctor = isDoctor(user);

  let text = 'no_emails';

  if (selectedEmail) {
    if (!userIsDoctor) {
      text = 'no_emails_assistant';
    }
  } else {
    if (userIsDoctor) {
      text = 'please_link_account';
    } else {
      text = 'no_emails_assistant';
    }
  }

  if (!impersonatedUser && !userIsDoctor) {
    text = 'please_select_doctor';
  }

  if(false === queryParams?.read) {
    text = 'no_unread_emails';
  }

  if(true === queryParams?.archived) {
    text = 'no_archived_emails';
  }

  if(true === queryParams?.sent) {
    text = 'no_sent_emails';
  }

  if(false === queryParams?.classified) {
    text = 'no_unclassified_emails';
  }

  if (searchValue) {
    //   text = 'no_emails_found';
  }

  return (<P className='m-3'>
    {t(text)}
  </P>);

};

function HeaderLeft({ onCloseDrawer }: HeaderLeftProps) {
  const {
    emails,
    setEmails,
    selectedEmail,
    loadingEmails,
    currentPageEmails,
    queryParamsEmail,
    refreshing,
    setQueryParamsEmails,
    updateQueryParamsEmails,
    onLoadMoreEmails,
    onRefresh,
    impersonatedUser,
    onResetEmails,
  } = useEmails();

  const { user } = useRecoilValue(authAtom);

  const keepRef = useRef({ updateQueryParamsEmails });
  keepRef.current.updateQueryParamsEmails = updateQueryParamsEmails;

  const [checked, setChecked] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('ms_sante');
  const { addToast } = useToast();

  const onSearch = useCallback((value: string) => {
    setSearchValue(value);
    if (value && value.length > 3) {
      keepRef.current.updateQueryParamsEmails({ search: value });
    } else {
      keepRef.current.updateQueryParamsEmails({ search: undefined });
    }
  }, []);

  const handleChangeCheckbox = () => {
    const newEmails = emails.map((email) => ({ ...email, selected: !checked }));
    setChecked(!checked);
    setEmails(newEmails);
  };

  const renderEmails = useMemo(() => {
    if (!loadingEmails && !emails?.length) {
      return (<EmptyText
        queryParams={queryParamsEmail}
        selectedEmail={selectedEmail}
        impersonatedUser={impersonatedUser}
        searchValue={searchValue}
      />);
    }

    return emails.map((email, index: number) => (
      <div
        key={`${email.id}-email-row`}
        className={`${
          index < emails.length ? 'border-t border-gray-100' : ''
        } w-full`}
      >
        <EmailsRow
          isFocused={selectedEmail?.id === email.id}
          email={email}
          search={searchValue}
          onClick={onCloseDrawer}
        />
      </div>
    ));
  }, [selectedEmail,queryParamsEmail, loadingEmails,
    impersonatedUser, emails, searchValue, onCloseDrawer]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e?.target as HTMLElement;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && !loadingEmails) {
      onLoadMoreEmails();
    }
  };

  useEffect(() => {
    setChecked(emails.some(({ selected }) => selected));
  }, [emails]);

  return (
    <div
      className={'flex flex-col ml-[0.5px] lg:ml-0 w-full lg:w-1/4 min-w-[280px]'}
    >
      <div className='flex overflow-hidden flex-col h-full bg-white rounded-lg'>
        <div
          className={'flex flex-row justify-center items-center p-2 space-x-2'}
        >
          <div
            className={`flex flex-row sm:gap-x-1 xl:gap-x-2 ${
              checked ? 'w-full justify-between overflow-y-auto' : 'w-1/3'
            }`}
          >
            <Checkbox
              name='select-all-emails-checkbox'
              className='pr-1'
              checked={checked}
              onChange={handleChangeCheckbox}
            />
            {(!checked && (impersonatedUser || isDoctor(user))) && (
              <Button
                color='transparent'
                icon='rewind'
                iconColor='#9ca7af'
                iconHoverColor='#072337'
                iconSize={20}
                className='pr-1'
                type='button'
                saving={loadingEmails || refreshing}
                onClick={async () => {
                  try {
                    const { count } = await onRefresh();
                    addToast('success', t(count
                      ? (count === 1 ? 'toasts.email_sync' : 'toasts.emails_sync')
                      : 'toasts.email_sync_not_found', { count: count }));

                    if (count === 0) {
                      return;
                    }

                    onResetEmails();
                    updateQueryParamsEmails({});
                  } catch (e) {
                    addToast('danger', (e as RequestError).message);
                  }
                }}
                title={t('refresh')}
              />)}
            {!checked && (<FilterDropdown setQueryParams={setQueryParamsEmails} queryParams={queryParamsEmail} />)}
          </div>

          <div className='flex-grow'>
            {!checked && (
              <SearchField
                name='search'
                defaultValue={(queryParamsEmail.search as string | undefined) ?? null}
                onSearch={onSearch}
              />
            )}
            {checked && (
              <div className='flex flex-row gap-4 justify-end'>
                <ArchiveButton isMultipleEdit />
                <MarkAsRead isMultipleEdit />
              </div>
            )}
          </div>
        </div>
        <div
          className={clsx('overflow-y-auto')}
          onScroll={handleScroll}
        >
          {loadingEmails && currentPageEmails <= 1 ? (
            <EmailsLoader />
          ) : (
            renderEmails
          )}
        </div>
        {currentPageEmails > 1 && loadingEmails ? (
          <p className='flex gap-2 justify-center p-2 text-sm text-center border-t border-gray-100'>
            <SpinnerLoader />
            <span>{t('loading_messages')}</span>
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default HeaderLeft;
