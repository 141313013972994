import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Email from '@/packages/back-end/mssante/email';
import Document from '@/packages/back-end/document';
import useRequest, { RequestError } from '@/services/request/useRequest';
import { StorageKeys } from '@/services/request/helpers/storage';

import { yupResolver } from '@hookform/resolvers/yup';
import { buildUrl, UrlArg } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import { parseMultipleSelectorValue } from '@/utils/form';
import { OptionType } from '@ui/types';
import Icon from '@ui/atoms/icons/icon/Icon';
import TextareaField from '@ui/atoms/fields/textarea/TextareaField';
import Dropdown from '@ui/molecules/dropdown/Dropdown';
import SpinnerLoader from '@ui/atoms/loaders/spinner/SpinnerLoader';

import { getError } from '@/components/form/utils';
import useSearchDocumentDrawer from '../search-document-drawer/useSearchDocumentDrawer';
import useAttachmentsDrawer, {
  InstaFileInfosProps,
} from '../../chat/documents/AttachDocumentDrawer';
import DocumentToUpload from '../../chat/documents/DocumentToUpload';
import {
  SettingsButton,
  SignatureButton,
} from '../../chat/messages/DropdownButtons';
import {
  EMAIL_INCLUDE_PARAMS,
  initialFileState,
  useEmails,
} from '../EmailsContext';
import { MessageFiles } from '../../chat/messages/MessageContext';
import { uploadFilesAndGetDocuments } from '../../chat/messageComposer/createMessageToSend';
import UploadedFile from '@/packages/back-end/uploaded-file';
import DocumentRow from '@/app/structure/page/chat/documents/DocumentRow';
import { EMAIL_URL } from '@/app/structure/page/mssante/email-template';
import { Item } from '@/packages/back-end/jsonapi';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

export const MEDIUM_GRAY = '#9CA7AF';

export type FormDataType = {
  bodyText: string;
  documents?: OptionType[] | null;
  attachments: { path: string; name: string }[];
};

const schema = yup.object({
  bodyText: yup.string().required('field_required'),
});

type EmailComposerProps = {
  loading?: boolean;
  disableSend?: boolean;
  email?: Email;
  filesFromDnD?: File[] | null;
  containerClassName?: string;
  textAreaClasses?: string;
  onCreateEmail?: (params: {
    data: FormDataType;
    callback?: () => void;
  }) => Promise<void>;
  isNew?: boolean;
};

export const NEW_EMAIL_URL = (args ?: UrlArg) =>
  buildUrl('ms_sante_emails', undefined, { include: EMAIL_INCLUDE_PARAMS,...args });

const UPLOAD_FILE_URL = () => buildUrl('upload', undefined);

const parseBodyText = (bodyText : string, signature ?: string | null) =>
  `${bodyText ?? ''}${signature ? `\n\n${signature}` : ''}`;

const parseData = ({
  bodyText,
  documents,
  attachments,
  signature,
  email,
}: {
  bodyText: string;
  documents: OptionType[] | null;
  attachments: { name: string; path: string }[];
  signature?: string | null;
  email?: Email;
}) => {

  return {
    parent: EMAIL_URL({ email }),
    bodyText: parseBodyText(bodyText,signature),
    attachments,
    documents: parseMultipleSelectorValue(documents),
  };
};

const EmailComposer = ({
  loading,
  disableSend,
  email,
  filesFromDnD,
  containerClassName,
  textAreaClasses,
  onCreateEmail,
}: EmailComposerProps) => {

  const { t } = useTranslation('ms_sante');
  const { t: tError } = useTranslation('ms_sante', {
    keyPrefix: 'form_errors',
  });

  const { addToast } = useToast();

  const { setRefreshLink,impersonatedUser } = useEmails();

  const { signature, setSignature, setSelectedEmail, onUpdateEmail } =
    useEmails();

  const { fetchData: sendEmail } = useJsonAPIRequest({
    url: NEW_EMAIL_URL({
      impersonated: impersonatedUser
    }),
    skip: true,
    method: 'POST',
  });

  const { fetchData: uploadFiles } = useRequest<UploadedFile[]>({
    url: UPLOAD_FILE_URL(),
    headers: { 'Content-Type': 'multipart/form-data' },
    skip: true,
    method: 'POST',
  });

  const {
    formState: { errors },
    register,
    setValue,
    resetField,
    watch,
  } = useForm({
    shouldUseNativeValidation: true,
    resolver: yupResolver(schema),
  });

  const { onClick: openAttachmentsDrawer } = useAttachmentsDrawer({
    onCloseCallback: () =>setAttachments(null),
    displayDocumentFields: false
  });

  const { onOpen: onOpenSearchDocumentDrawer } = useSearchDocumentDrawer({
    onSubmit: ({ documents }) => setValue('documents', documents),
  });

  const bodyText = watch('bodyText') as string;
  const documents = watch('documents') as OptionType<Document>[];

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [saving, setSaving] = useState(false);
  const [attachments, setAttachments] = useState<File[] | null>(null);
  const [files, setFiles] = useState<MessageFiles>(initialFileState);
  const [instaFileInfos, setInstaFileInfos] = useState<InstaFileInfosProps>();

  const handleSendEmail = async () => {

    setSaving(true);
    const attachmentsResponse = await uploadFilesAndGetDocuments({
      files,
      instaFileInfos,
      uploadFiles,
    });

    const attachments = attachmentsResponse.map(({ title, fileUrl }) => ({
      name: title || '',
      path: fileUrl || '',
    }));

    try {

      if (onCreateEmail) {
        await onCreateEmail({
          data: { bodyText: parseBodyText(bodyText,signature), documents, attachments },
          callback: () => {
            resetField('bodyText');
            setFiles(initialFileState);
          },
        });
      } else {
        const response = await sendEmail(
          parseData({ bodyText, documents, attachments, signature, email }),
          { throw: true, displayToastOnError: true }
        ) as Item<Email>;
        setSelectedEmail(response.data);
        onUpdateEmail(response.data);
        resetField('bodyText');
        setFiles(initialFileState);
        addToast('success', t('toasts.email_created'));
      }
    } catch (e) {
      const error = e as RequestError;
      
      if (error.code === 428) {
        setRefreshLink(error.resolveUrl || null);
      }
    } finally {
      setSaving(false);
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const enterToSend = localStorage.getItem(StorageKeys.EMAIL__ENTER_TO_SEND);
    if (e.key === 'Enter' && !e.shiftKey && enterToSend) {
      handleSendEmail();
    }
  };

  useEffect(() => {
    if (attachments) {
      openAttachmentsDrawer({
        attachments: attachments,
        uploadFile: (
          newFiles: File[],
          newInstaFileInfos: InstaFileInfosProps
        ) => {
          setInstaFileInfos(newInstaFileInfos);
          setFiles({
            ...files,
            attachments: [...files.attachments, ...newFiles],
          });
        },
      });
    }
  }, [attachments, files, openAttachmentsDrawer, setFiles]);

  useEffect(() => {
    if (filesFromDnD) {
      setAttachments(filesFromDnD);
    }
  }, [filesFromDnD]);

  const disabled = disableSend || loading || saving || !bodyText;

  return (
    <div
      className={cls(
        'flex flex-col overflow-hidden m-3 bg-white rounded-md border border-gray-100 max-h-full',
        containerClassName
      )}
    >
      <TextareaField
        className='overflow-hidden h-full max-h-full'
        inputContainerClassName='overflow-hidden h-full max-h-full'
        textAreaClasses={cls(
          'max-h-[300px] min-h-[90px] rounded-xl text-[14px]',
          textAreaClasses
        )}
        placeholder={t('message_composer.write_message')}
        borderless
        resizable={false}
        {...register('bodyText')}
        error={getError('bodyText', errors, tError)}
        onKeyPress={handlePressEnter}
      />
      <div
        className='flex-shrink-0 flex flex-row justify-between items-center p-1 py-2 px-3
      bg-white rounded-b-md border-t border-gray-100'
      >
        <div className='flex overflow-y-auto flex-grow items-center'>
          <div className='flex overflow-x-auto flex-row gap-2 items-end max-w-[80%]'>
            <DocumentToUpload
              type='attachments'
              files={files}
              setFiles={setFiles}
            />
            <DocumentToUpload
              type='medicalDocuments'
              files={files}
              setFiles={setFiles}
            />
          </div>
          {!!documents?.length && (
            <div className='flex overflow-auto flex-shrink-0 gap-4 pr-3'>
              {documents.map(({ data: document,value }) => (
                document ? (<DocumentRow
                  key={document.id}
                  removeDocument={() => {
                    setValue('documents',documents.filter((item) => item.value !== value));
                  }}
                  document={document}
                />) : null
              ))}
            </div>
          )}
        </div>
        <div className='flex flex-row items-center'>
          <div className='flex flex-row items-center'>
            <SettingsButton entity='email' />
            <SignatureButton
              signature={signature}
              onChange={setSignature}
            />
            <div
              className='flex justify-between items-center py-2 px-1'
              title={t('message_composer.add_documents')}
            >
              <Dropdown
                className='!h-5 !bg-transparent !border-0'
                popperClassName='z-[9999]'
                innerClassName='!px-1.5'
                items={[
                  {
                    children: t('attachment_dropdown.upload_document'),
                    onClick: () => inputFileRef?.current?.click(),
                  },
                  {
                    children: t('attachment_dropdown.search_patient_documents'),
                    onClick: onOpenSearchDocumentDrawer,
                  },
                ]}
              >
                <Icon
                  icon='attachment'
                  width={20}
                  height={20}
                  color={MEDIUM_GRAY}
                />
                <input
                  ref={inputFileRef}
                  id='file'
                  type='file'
                  className='hidden'
                  onChange={(e) =>
                    setAttachments(Array.from(e.target.files as FileList))
                  }
                  multiple
                />
              </Dropdown>
            </div>
          </div>
          <div
            className={cls(
              'flex flex-row items-center py-1 px-2 ml-[8px] bg-gray-900 rounded-md',
              {
                'bg-gray-700': disabled,
              }
            )}
          >
            <button
              className='flex items-center text-sm font-medium text-white'
              disabled={disabled}
              type='button'
              title={t('message_composer.send_message')}
              onClick={() => handleSendEmail()}
            >
              {t('send')}
              {(loading || saving) && (
                <SpinnerLoader
                  className='mr-0 ml-2 w-3 h-3'
                  size={10}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailComposer;
