import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import React, { lazy } from 'react';
import { ROLE } from '@/packages/types';
import { ROUTES } from '@/app/structure/body/routes/Admin.utils';

const Dashboard = lazy(() => import('@/app/structure/page/admin/dashboard/Dashboard'));
const SubscriptionGroups = lazy(() => import('@/app/structure/page/admin/subscription-groups/SubscriptionGroups'));
const AuditTrail = lazy(() => import('@/app/structure/page/audit_trail/AuditTrail'));
const Specialties = lazy(() => import('@/app/structure/page/admin/specialties/Specialties'));

const MedicalRecordTabs = lazy(() => import('@/app/structure/page/admin/medical-record-tabs/MedicalRecordTabs'));
const MrSections = lazy(() => import('@/app/structure/page/admin/medical-record-sections/MedicalRecordSections'));
const Treatments = lazy(() => import('@/app/structure/page/admin/treatments/Treatments'));
const OpenAiPlayground = lazy(() => import('@/app/structure/page/admin/openai-playground/OpenAiPlayground'));
const MedicalRecordFields = lazy(() => import('@/app/structure/page/admin/medical-record-fields/MedicalRecordFields'));
const PatientShared = lazy(() => import('@/app/structure/page/admin/patient-shared/PatientShared'));
const MobileAppVersions = lazy(() => import('@/app/structure/page/admin/mobile-app-versions/MobileAppVersions'));
const ResearchCreate = lazy(() => import('@/app/structure/page/admin/users/create/Research'));

const EventTypes = lazy(() => import('@/app/structure/page/admin/event-types/EventTypes'));
const ConsultationLocations = lazy(
  () =>
    import(
      '@/app/structure/page/admin/consultation-locations/ConsultationLocations'
    )
);


const getRoutes = () => {

  const data = [];

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN, ROLE.INSTITUTION_MANAGER]}
    key={ROUTES.admin.dashboard}
    path={ROUTES.admin.dashboard}>
    <Dashboard />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN, ROLE.INSTITUTION_MANAGER]}
    key={ROUTES.admin.treatments}
    path={ROUTES.admin.treatments}>
    <Treatments />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN,ROLE.DEVELOPER]}
    key={ROUTES.admin.openai_playground}
    path={ROUTES.admin.openai_playground}>
    <OpenAiPlayground />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN,ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.mobile_app_versions}
    path={ROUTES.admin.mobile_app_versions}>
    <MobileAppVersions />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.medical_record_tabs}
    path={ROUTES.admin.medical_record_tabs}>
    <MedicalRecordTabs />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.medical_record_sections}
    path={ROUTES.admin.medical_record_sections}>
    <MrSections />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.medical_record_fields}
    path={ROUTES.admin.medical_record_fields}>
    <MedicalRecordFields />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN, ROLE.SUPPORT]}
    key={ROUTES.admin.specialties}
    path={ROUTES.admin.specialties} >
    <Specialties />
  </PrivateRoute>
  );



  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN]}
    key={ROUTES.admin.research_create}
    path={ROUTES.admin.research_create} >
    <ResearchCreate />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.subscription_groups}
    path={ROUTES.admin.subscription_groups} >
    <SubscriptionGroups />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.audit_trail}
    path={ROUTES.admin.audit_trail} >
    <AuditTrail />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.patient_shared}
    path={ROUTES.admin.patient_shared} >
    <PatientShared />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN,ROLE.SUPPORT]}
    key={ROUTES.admin.event_types}
    path={ROUTES.admin.event_types}>
    <EventTypes />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.ADMIN, ROLE.HOSPITAL_ADMIN]}
    key={ROUTES.admin.consultation_locations}
    path={ROUTES.admin.consultation_locations}>
    <ConsultationLocations />
  </PrivateRoute>
  );

  return data;

};


export default getRoutes;