import { useTranslation } from 'react-i18next';
import Button from '@ui/atoms/buttons/button/Button';
import P from '@ui/atoms/texts/base/P';
import Modal from '@ui/molecules/modal/Modal';
import React, { FC } from 'react';


type ConflictModalProps = {
  onAccept: () => void;
  onCancel: () => void;
  loading: boolean;
  hasAccess?: boolean;
}

const ConflictModal: FC<ConflictModalProps> = ({ onAccept, loading, hasAccess, onCancel }) => {


  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.conflict_modal',
  });


  return (<Modal
    data-testid={'conflict-user-modal'}
    title={t('title')}
    onDismiss={onCancel}
    footer={(
      <div className='flex flex-row gap-4'>
        <Button disabled={loading} onClick={onCancel}>{t('buttons.no')}</Button>
        <Button saving={loading} color='primary'
          onClick={() => onAccept()}>{t('buttons.yes')}</Button>
      </div>
    )}
  >
    <div className='flex flex-col gap-2'>
      {hasAccess ?
        (<>
          <P>{t('content_found_1')}</P>
          <P>{t('content_found_2')}</P>
        </>)
        :
        (<>
          <P>{t('content_1')}</P>
          <P>{t('content_2')}</P>
          <P>{t('content_3')}</P>
        </>)}

    </div>
  </Modal>);

};

export default ConflictModal;
