import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import { useTranslation } from 'react-i18next';
import { dataURLtoFile, getRadianAngle, rotateSize } from './files';
import styled from 'styled-components';
import { Control, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getError, optionTypeValidator } from '@/components/form/utils';
import { OptionType } from 'instamed-styleguide/ui/types';
import DateField from '@/components/form/fields/date/DateField';
import Drawer from '@/components/drawer/Drawer';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import DocumentType, { buildOption, GET_COLLECTION } from '@/packages/back-end/document-type';
import ApiSelectorField from '@/components/form/fields/api-selector';

const PREVIEW_TYPES = ['image/png', 'image/jpeg'];

export type InstaFileInfosProps = {
  name: string | null;
  type: OptionType<DocumentType>;
  createdDate: string;
};

const RotateButton = styled(Button)`
    transform: scaleX(-1);
`;

type AttachDocumentDrawerProps = {
  attachment: File[];
  onClose: () => void;
  displayDocumentFields: boolean,
  uploadFile: (newFile: File[], infos: InstaFileInfosProps) => void;
};

const AttachDocumentDrawer = ({
  attachment,
  onClose,
  displayDocumentFields,
  uploadFile,
}: AttachDocumentDrawerProps) => {
  const { t } = useTranslation('chat');
  const [previewsURL, setPreviewsURL] = useState<string[]>([]);
  const areMultipleFiles = useMemo(() => attachment.length > 1, [attachment]);

  const schema = displayDocumentFields ? yup.object({
    name: yup.string().nullable(),
    createdDate: yup.string().required(),
    type: optionTypeValidator().required(),
  }) : yup.object({
    name: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    control,
  } = useForm<InstaFileInfosProps>({
    shouldUseNativeValidation: true,
    resolver: yupResolver(schema),
    defaultValues: {
      createdDate: new Date().toISOString(),
      name: areMultipleFiles ? null : attachment?.[0]?.name,
    },
  });

  useEffect(() => {
    if (attachment) {
      const newAttachments: string[] = [];
      attachment.forEach((el) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          newAttachments.push(reader.result as string);
        };
        reader.readAsDataURL(el);
      });
      setPreviewsURL(newAttachments);
    }
  }, [areMultipleFiles, attachment]);

  const rotate = (rotation: number) => {
    const image = new Image();
    image.src = previewsURL[0];
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);
    const rotatedImageUrl = canvas.toDataURL(attachment[0].type);
    setPreviewsURL([rotatedImageUrl]);
  };

  const validateDocuments = useCallback(
    (data) => {
      let newInfos = {
        ...data,
        createdDate: new Date(data.createdDate).toISOString()
      };

      if (data.type) {
        newInfos = { ...newInfos, type: data.type };
      }

      const newfiles: File[] = [];
      for (let i = 0; i < attachment?.length; i++) {
        const preview = previewsURL[i];
        const attFile = dataURLtoFile(preview, attachment[i], attachment.length === 1 && data.name && data.name);
        newfiles.push(attFile);
      }
      uploadFile(newfiles, newInfos);
      onClose();
    },
    [attachment, onClose, previewsURL, uploadFile]
  );

  return (
    <Drawer
      title={areMultipleFiles ? t('add_documents') : t('add_document')}
      onClose={onClose}
      onSubmit={handleSubmit(validateDocuments)}
      className='p-4'
      footer={
        <div className='flex justify-end pb-8'>
          <Button
            type='button'
            size='base'
            className='mr-3'
            onClick={onClose}
          >
            <span>{t('cancel')}</span>
          </Button>
          <Button type='submit' color='primary'
            size='base'
            data-testid='attach_document_submit_button'
          >
            <span>{t('save')}</span>
          </Button>
        </div>
      }
    >
      <div className='flex flex-col m-2 space-y-4' data-testid='attach_document_form'>
        <div className='flex flex-col items-center w-full'>
          {<div className='flex overflow-x-auto flex-row items-center px-2 space-x-2'>
            {previewsURL.map((el, index) =>
              PREVIEW_TYPES.includes(attachment[index].type) ? (
                <img
                  key={`img-prev-${index}`}
                  src={el}
                  alt={t('document_to_upload')}
                  className='max-h-[300px]'
                />
              ) : <></>
            )}
          </div>
          }
          {!areMultipleFiles && PREVIEW_TYPES.includes(attachment?.[0]?.type) && (
            <div className='flex flex-row mt-3 space-x-3'>
              <RotateButton icon='rewind' onClick={() => rotate(90)}
                type='button' />
              <Button icon='rewind' onClick={() => rotate(-90)}
                type='button' />
            </div>
          )}
        </div>
        {!areMultipleFiles && (
          <TextField
            placeholder={t('document_drawer.name')}
            label={t('document_drawer.name_label')}
            defaultValue={attachment?.[0]?.name}
            {...register('name')}
          />
        )}
        {displayDocumentFields && (<DateField
          control={control as unknown as Control}
          label={t('document_drawer.date', { count: attachment?.length })}
          error={getError('createdDate', errors, t)}
          name='createdDate'
        />)}
        {displayDocumentFields && (<ApiSelectorField
          url={GET_COLLECTION({ include: 'parent' })}
          control={control as unknown as Control}
          error={getError('type', errors, t)}
          buildOption={buildOption}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          name='type'
          label={t('document_drawer.type_label')}
          placeholder={t('document_drawer.type')}
          required={true}
        />)}
      </div>
    </Drawer>
  );
};

const useAttachmentsDrawer = ({
  onCloseCallback,
  displayDocumentFields,
}: {
  onCloseCallback: () => void;
  displayDocumentFields: boolean;
    
}) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onClose = useCallback(() => {
    onCloseCallback();
    closeDrawer();
  }, [closeDrawer, onCloseCallback]);

  const onClick = useCallback(
    ({ attachments, uploadFile }) => {
      openDrawer({
        content: (
          <AttachDocumentDrawer
            onClose={onClose}
            displayDocumentFields={displayDocumentFields}
            uploadFile={uploadFile}
            attachment={attachments}
          />
        ),
        size: 'medium',
      });
    },
    [onClose, displayDocumentFields, openDrawer]
  );

  return { onClick };
};

export default useAttachmentsDrawer;
