import React, { FC } from 'react';
import cls from 'classnames';
import 'react-phone-number-input/style.css';

import fr from 'react-phone-number-input/locale/fr.json';

import PhoneInput from 'react-phone-number-input';

import { SizeType } from '@ui/types';
import InputContainer from '@ui/atoms/containers/input/InputContainer';
import { getFieldClasses } from '@ui/atoms/fields/utils';
import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import { getCountry } from '@ui/atoms/fields/phone/utils';

import { CountryCode,E164Number } from 'libphonenumber-js/core';
import styled from 'styled-components';


const InputField = styled(PhoneInput)`
.PhoneInputInput:focus {
  border: none;
  outline: none;
 }
&.PhoneInput--focus {
  border-color: rgba(2, 89, 202, var(--tw-border-opacity));
 }
.PhoneInputInternationalIconPhone {
 color: #a7a7a7;
}
`;

const sizes = {
  small: 'p-2',
  base: 'p-3',
  large: 'p-4'
};

export type PhoneInputChangeType = {
  target : {
    value : E164Number|undefined
  }
}

export type PhoneFieldProps = React.HTMLAttributes<HTMLInputElement> & {
  id?: string;
  name: string;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  label?: string;
  value?: string;
  error?: string;
  placeholder?: string;
  helpText?: string;
  readOnly?: boolean;
  onChange: (event: PhoneInputChangeType) => void;
  loading?: boolean;
  borderless?: boolean;
  helpTitle?: string;
  defaultCountry?: CountryCode;
  size?: Exclude<SizeType,'extra_large'>;
};

export const PhoneFieldSkeleton: FC = () => <Skeleton size='large' field />;

const PhoneField:React.ForwardRefRenderFunction<HTMLInputElement, PhoneFieldProps> = (props, ref) => {
  const {
    className,
    id,
    name,
    placeholder,
    label,
    onChange,
    error,
    helpText,
    readOnly,
    loading,
    defaultCountry,
    containerClassName,
    inputClassName,
    borderless = false,
    size = 'base',
    helpTitle,
    ...rest
  } = props;

  const inputCls = React.useMemo(
    () => cls(
      getFieldClasses({ active: !readOnly, borderless }),
      {
        'text-danger-500 border-danger-500': !!error && !readOnly,
        'text-gray-300': readOnly
      }
    ),
    [readOnly, error, borderless]
  );

  const country = defaultCountry ? defaultCountry : getCountry('FR');

  {return (
    <div className={cls('flex relative flex-row', containerClassName)}>
      <InputContainer
        htmlFor={id || name}
        error={error}
        label={label}
        loading={loading}
        helpText={helpText}
        helpTitle={helpTitle}
        data-testid={`textfield-phone-${name}`}
        className={className}
        skeleton={PhoneFieldSkeleton}
      >
        <InputField
          {...rest}
          countrySelectProps={{ unicodeFlags: true }}
          id={id || name}
          labels={fr}
          defaultCountry={country}
          international={true}
          ref={ref}
          name={name}
          readOnly={readOnly}
          className={cls(inputCls, sizes[size], inputClassName)}
          placeholder={placeholder}
          onChange={(value : E164Number|undefined) => {
            onChange && onChange({ target: { value } });}
          }
        />
      </InputContainer>
    </div>
  );}
};

export default React.forwardRef(PhoneField);
