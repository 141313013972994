import { buildOption as buildOptionPatients } from '@/packages/back-end/patient';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { buildUrl, UrlArg } from '@/utils/Api';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import { OptionType } from 'instamed-styleguide/ui/types';
import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MessageComposer from './messages/MessageComposer';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import Patient from '@/packages/back-end/patient';
import { useDiscussions } from './ChatContext';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import User, {
  buildOptionWithInstitution,
  buildOptionWithInstitutionOrDateOfBirth,
  GET_ITEM
} from '@/packages/back-end/user';
import Discussion, { DISCUSSIONS_URL } from '@/packages/back-end/Discussion';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import { ROLE } from '@/packages/types';
import { useMessageContext } from './messages/MessageContext';
import CircleLoader from 'instamed-styleguide/ui/atoms/loaders/circle-loader/CircleLoader';
import useURLParams from '@/utils/hooks/useURLParams';
import { Item } from '@/packages/back-end/jsonapi';
import { generateUniqueId } from '@/app/structure/page/chat/utils';
import useApiConstant from '@/utils/hooks/useApiConstant';

type NewDiscussionProps = {
  setQueryParams : (params : UrlArg) => void,
}


const NewDiscussion : FC<NewDiscussionProps> = ({ setQueryParams }) => {
  const { t } = useTranslation('chat');
  const { newDiscussion, setNewDiscussion } = useDiscussions();
  const { setPatient, sendingMessage } = useMessageContext();
  const { user: me } = useRecoilValue(authAtom);
  const isPatient = me?.attributes?.role === ROLE.PATIENT;

  const { getConstant } = useApiConstant();

  const { register,setValue, resetField, watch, control } = useForm({
    shouldUseNativeValidation: true,
  });

  const { participant } = useURLParams<{participant : string | undefined}>(['participant']);

  const { fetchData: createDiscussion, loading } = useJsonAPIRequest({
    url: DISCUSSIONS_URL(),
    skip: true,
    method: 'POST',
  });

  const { fetchData: fetchParticipant } = useJsonAPIRequest<Item<User>>({
    url: GET_ITEM(participant),
    skip: true,
  });


  useEffect(() => {

    (async () => {

      const user = await fetchParticipant({},{
        url: GET_ITEM(participant),
        throw: true }
      ) as Item<User>;

      if(user?.data?.attributes) {
        setValue('participants', [
          buildOptionWithInstitution(user.data)
        ]);
      }

    })();

  }, [participant,setValue,fetchParticipant]);


  const participants = watch('participants');
  const patientTagged = watch('patientTagged');
  const subject = watch('subject');

  useEffect(() => {
    if (participants?.length) {
      setNewDiscussion({
        ...(newDiscussion || {}),
        participants: participants.map((par: OptionType<User>) => par.data),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants]);

  useEffect(() => {
    if (patientTagged) {
      setPatient(patientTagged.data);
    }
  }, [patientTagged, setPatient]);

  const handleCreateDiscussion = useCallback(async () => {

    const tmpId = generateUniqueId();

    const res = await createDiscussion({
      participants: participants.map((par: OptionType<User>) => par.value),
      title: subject,
      tmpId,
    }, {
      throw: true,
      displayToastOnError: true,
    }) as Item<Discussion>;

    setQueryParams({ participant: null });

    return res.data;
  },[createDiscussion,setQueryParams,subject,participants]);

  const closeNewDiscussion = () => {
    setNewDiscussion(null);
    setPatient(null);
  };

  const buildParticipantOption = useCallback((item) => {
    return buildOptionWithInstitutionOrDateOfBirth(item,getConstant);
  },[getConstant]);

  const patient = participants?.find((par: OptionType<User>) => par?.data?.attributes.role === ROLE.PATIENT);

  return (
    <div
      className='flex overflow-hidden relative flex-col px-2 mt-8 lg:mt-0 w-full h-full bg-white rounded-lg'
      data-testid='new_discussion_form'
    >
      <div className='pb-2 w-full border-b border-b-gray-50'>
        <div className='flex justify-end'>
          <button onClick={() => closeNewDiscussion()}>
            <Icon icon='close' className='mt-2 w-[25px] h-[25px]' />
          </button>
        </div>
      </div>
      <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='mr-2 text-sm text-gray-900 whitespace-nowrap'>{t('to')}</p>
        <SelectorField<User>
          url={buildUrl('users', undefined, {
            context: 'discussion'
          })}
          className='w-full'
          name={'participants'}
          multiple
          showSelectAll={false}
          disableCloseOnSelect={false}
          apiSearch={true}
          control={control}
          buildOption={buildParticipantOption}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
        />
      </div>
      <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='mr-2 text-sm text-gray-900 truncate'>{t('subject')}</p>
        <TextFieldInput {...register('subject')} className='w-[85%] lg:w-[90%]' />
      </div>
      {!isPatient && !patient && <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='mr-2 text-sm text-gray-900 whitespace-nowrap'>{t('tagged_patient')}</p>
        <SelectorField<Patient>
          url={buildUrl('patients', undefined, {
            include: 'user',
          })}
          className='w-full'
          showSelectAll={false}
          closeOnSelectWhenOneOption
          name={'patientTagged'}
          apiSearch={true}
          control={control}
          buildOption={buildOptionPatients}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
        />
      </div>}
      {(loading || sendingMessage) && <div className='absolute top-0 right-0 bottom-0 left-0 z-50 bg-[#d6dbde80]'>
        <CircleLoader className='h-full' />
      </div>}
      <MessageComposer
        containerClassName='!m-0 bg-white rounded-md !border-0 h-full'
        textAreaClasses='!min-h-[95%] !max-h-[100%] !px-0'
        newConversationProps={{
          creatingDiscussion: loading,
          disableCreatingMessage: !participants?.length || !subject
        }}
        createDiscussion={handleCreateDiscussion}
      />
    </div>
  );
};

const SelectorField = styled(ApiSelectorField)`
  fieldset {
    border-width: 0 !important;
    :focus {
      border-width: 0 !important;
    }
  }
  .MuiIconButton-root {
    display: none !important;
  }
` as typeof ApiSelectorField;

const TextFieldInput = styled(TextField)`
  input {
    font-size: 14px;
    border-width: 0px !important;
    :focus {
      border-width: 0px !important;
    }
  }
`;

export default NewDiscussion;
