import React, { useCallback, useEffect, useState } from 'react';

import Patient from '@/packages/back-end/patient';

import useSearchParams from '@/utils/hooks/useSearchParams';
import useChangeSearchParams from '@/utils/hooks/useChangeSearchParams';
import { onCloseType } from '@/components/drawer/useUpdateDrawer';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import AddPatientDrawer from './AddPatientDrawer';

const SEARCH_PARAM_KEYS = ['action', 'entity', 'patient'];

const useAddPatientDrawer = ({
  onAdded,
  onCloseCallback,
}: {
  onAdded?: (
    patient: Patient | null,
    options: { hasEmail: boolean }
  ) => { shouldClose?: boolean };
  onCloseCallback?: onCloseType<Patient>;
}) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const searchParams = useSearchParams<{ action: string; entity: string }>();
  const { onUpdateSearchParams, onRemoveSearchParams } =
    useChangeSearchParams();

  const [isMounted, setMounted] = useState(false);
  const [isCheckedSearchParams, setCheckedSearchParams] = useState(false);

  const onClose = useCallback(() => {
    onRemoveSearchParams(SEARCH_PARAM_KEYS);
    closeDrawer();
  }, [onRemoveSearchParams, closeDrawer]);

  const onOpen = useCallback(() => {
    onUpdateSearchParams({ action: 'create', entity: 'patient' });

    openDrawer({
      content: (
        <AddPatientDrawer
          onAdded={onAdded}
          onClose={onClose}
          onCloseCallback={onCloseCallback}
        />
      ),
      size: 'extra_large',
      onClickOverlay: () => onRemoveSearchParams(SEARCH_PARAM_KEYS),
    });
  }, [
    onAdded,
    openDrawer,
    onClose,
    onUpdateSearchParams,
    onRemoveSearchParams,
    onCloseCallback,
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted && !isCheckedSearchParams) {
      setCheckedSearchParams(true);

      if (
        searchParams.action === 'create' &&
        searchParams.entity === 'patient'
      ) {
        onOpen();
      }
    }
  }, [isMounted, isCheckedSearchParams, searchParams, onOpen]);

  return { onOpen, onClose };
};

export default useAddPatientDrawer;
