import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import BaseDateField, { DateFieldProps as BaseDateFieldProps } from '@ui/atoms/fields/date/DateField';

type DateFieldProps = BaseDateFieldProps & {
  control?: Control
  format?: string;
  onChange?: (value : Date | null) => void;
  value?: string | null;
  minDate ?: Date,
  disabled?: boolean;
}

const DateField: FC<DateFieldProps> = ({
  name,
  onChange,
  value,
  control,
  ...props
}) => {


  if(!control) {
    return (<BaseDateField
      name={name}
      onChange={onChange}
      value={typeof value === 'undefined' ? null : value}
      {...props}
    />);
  }

  return (
    <Controller
      control={control}
      render={({ field: { value, onChange, name } }) => {
        return (
          <BaseDateField
            name={name}
            onChange={onChange}
            value={typeof value === 'undefined' ? null : value}
            {...props}
          />
        ); }
      }
      name={name}
    />
  );

};

export default DateField;