import React, { FC, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import PublicProfile from '@/packages/back-end/PublicProfile';
import { authAtom } from '@/services/store/store';

import BaseSkeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import Drawer from '@/components/drawer/Drawer';

import SeenDoctorStep, {
  SeenDoctorStepData,
} from './common-steps/SeenDoctorStep';
import SelectEventStep, {
  SelectEventStepData,
} from './common-steps/SelectEventStep';
import SelectSlotStep, {
  SelectSlotStepData,
} from './common-steps/SelectSlotStep';
import ConfirmMeetingStep, {
  ConfirmMeetingStepData,
} from './common-steps/ConfirmMeetingStep';
import ReviewMeetingStep from './common-steps/ReviewMeetingStep';

import { flatMedicProfileRelationships } from './utils';
import AccountSteps from './account-steps/AccountSteps';
import { BookMeetingProvider, useBookMeeting } from './BookMeetingContext';
import { BOOK_MEETING_STEPS_WRAPPER_ID } from './useScrollToHideStep';

import SelectConsultationLocationStep
  from '@/app/structure/page/public/doctor-profile/book-meeting-drawer/common-steps/SelectConsultationLocationStep';
import ConsultationLocation from '@/packages/back-end/consultation-location';

const togglePreventReload = (prevent: boolean) => {
  document.dispatchEvent(
    new CustomEvent('public-app_prevent_reload', { detail: prevent })
  );
};

const Skeleton: FC = () => {
  return (
    <div className='flex flex-col gap-4 py-6 px-4 w-full'>
      <BaseSkeleton className='w-full h-10' />
      <BaseSkeleton className='w-full h-10' />
      <BaseSkeleton className='w-full h-10' />
      <BaseSkeleton className='w-full h-10' />
      <BaseSkeleton className='w-full h-10' />
    </div>
  );
};

type BookMeetingStepProps = {
  startStepNumber?: number;
  hideWrapper?: boolean;
  onClose: () => void;
};

type BookMeetingStepBaseProps = BookMeetingStepProps & {
  loading: boolean;
};

const BookMeetingStepBase: FC<BookMeetingStepBaseProps> = ({
  loading,
  startStepNumber = 1,
  onClose,
}) => {
  const { profileDoctor,consultationLocation,hasInitialConsultationLocation, numOfAccountSteps } = useBookMeeting();

  const { defaultData,initMeUser,setConsultationLocation } = useBookMeeting();

  const [openSeenDoctorStep, setOpenSeenDoctorStep] = useState(true);
  const [openSelectEventStep, setOpenSelectEventStep] = useState(true);
  const [openSelectConsultationLocationStep, setOpenConsultationLocationStep] = useState(true);
  const [openSelectSlotStep, setOpenSelectSlotStep] = useState(true);
  const [openConfirmMeetingStep, setOpenConfirmMeetingStep] = useState(true);
  const [openReviewMeetingStep, setOpenReviewMeetingStep] = useState(true);

  const [seenDoctorStepData, setSeenDoctorStepData] =
    useState<SeenDoctorStepData>({});

  const [selectEventStepData, setSelectEventStepData] =
    useState<SelectEventStepData>({});
  const [selectSlotStepData, setSelectSlotStepData] =
    useState<SelectSlotStepData>({});
  const [confirmMeetingStepData, setConfirmMeetingStepData] =
    useState<ConfirmMeetingStepData>({});

  const [doneAccountSteps, setDoneAccountSteps] = useState(false);

  const newPatientForMedic =
    !profileDoctor?.attributes.hasPreviousAppointments &&
    !profileDoctor?.attributes.allowNewPatients;

  const additionalSelectConsultationLocationStepNumber = newPatientForMedic
    ? startStepNumber
    : startStepNumber - 1;

  // check show steps
  const showSelectEventStep =
    !loading && (!newPatientForMedic || !!seenDoctorStepData.seenDoctor)
    && (!!consultationLocation || (!!defaultData?.publicProfileSearch && !!defaultData?.bookingSlot));

  const showConsultationLocation = (!newPatientForMedic || !!seenDoctorStepData.seenDoctor)
    && !hasInitialConsultationLocation && (profileDoctor?.relationships?.consultationLocations?.data.length ?? 2) > 1;


  const showSelectSlotStep =
    showSelectEventStep && !!selectEventStepData.eventType;

  const showAccountSteps =
    showSelectSlotStep && !!selectSlotStepData.bookingSlot;


  const showConfirmMeetingStep =
    showSelectSlotStep &&
    !!selectSlotStepData.bookingSlot &&
    (numOfAccountSteps === 0 || doneAccountSteps);

  const showReviewMeetingStep =
    showConfirmMeetingStep && !!confirmMeetingStepData.event;

  let additionalStepNumber = additionalSelectConsultationLocationStepNumber;
  if (showConsultationLocation) {
    additionalStepNumber += 1;
  }

  useEffect(() => {
    if (defaultData?.consultationLocation) {
      setOpenConsultationLocationStep(false);
    }
  }, [defaultData?.consultationLocation]);

  useEffect(() => {
    if (defaultData?.eventType) {
      setSelectEventStepData({ eventType: defaultData?.eventType });
      setOpenSelectEventStep(false);
    }
  }, [defaultData?.eventType]);

  useEffect(() => {
    if (defaultData?.bookingSlot) {
      setSelectSlotStepData({ bookingSlot: defaultData?.bookingSlot });
      setOpenSelectSlotStep(false);
    }
  }, [defaultData?.bookingSlot]);

  useEffect(() => {
    if (!initMeUser) {
      togglePreventReload(true);
    }
    return () => {
      togglePreventReload(false);
    };
  }, [initMeUser]);


  useEffect(() => {
    if(profileDoctor?.relationships?.consultationLocations?.data?.length === 1) {
      setConsultationLocation(profileDoctor.relationships.consultationLocations.data[0]);
    }

  }, [setConsultationLocation,profileDoctor]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      {newPatientForMedic && !loading && (
        <SeenDoctorStep
          startStepNumber={startStepNumber}
          open={openSeenDoctorStep}
          consultationLocation={consultationLocation}
          onToggle={setOpenSeenDoctorStep}
          data={seenDoctorStepData}
          setData={setSeenDoctorStepData}
          onClose={onClose}
          onDone={() => {
            setOpenSeenDoctorStep(false);
            setOpenSelectEventStep(true);
            setOpenConsultationLocationStep(true);
          }}
        />
      )}

      {showConsultationLocation && (
        <SelectConsultationLocationStep
          stepNumber={1 + additionalSelectConsultationLocationStepNumber}
          profileDoctor={profileDoctor}
          open={openSelectConsultationLocationStep}
          onToggle={setOpenConsultationLocationStep}
          onDone={() => {
            setOpenConsultationLocationStep(false);
            setOpenSelectEventStep(true);
          }}
        />
      )}
      {showSelectEventStep && (
        <SelectEventStep
          stepNumber={1 + additionalStepNumber}
          open={openSelectEventStep}
          onToggle={setOpenSelectEventStep}
          data={selectEventStepData}
          setData={setSelectEventStepData}
          onDone={() => {
            setOpenSelectEventStep(false);
            if (!defaultData?.publicProfileSearch) {
              setOpenSelectSlotStep(true);
            }
          }}
        />
      )}

      {showSelectSlotStep && (
        <SelectSlotStep
          stepNumber={2 + additionalStepNumber}
          open={openSelectSlotStep}
          onToggle={setOpenSelectSlotStep}
          selectEventStepData={selectEventStepData}
          data={selectSlotStepData}
          onGoToPreviousStep={() => {
            setSelectEventStepData({});
            setOpenSelectEventStep(true);
            setOpenSelectSlotStep(false);
          }}
          setData={setSelectSlotStepData}
          onDone={() => {
            setOpenSelectSlotStep(false);
          }}
        />
      )}

      {showAccountSteps && (
        <AccountSteps
          startStepNumber={3 + additionalStepNumber}
          onDone={() => {
            setDoneAccountSteps(true);
            setOpenConfirmMeetingStep(true);
          }}
        />
      )}

      {showConfirmMeetingStep && (
        <ConfirmMeetingStep
          stepNumber={3 + additionalStepNumber + numOfAccountSteps}
          open={openConfirmMeetingStep}
          onToggle={setOpenConfirmMeetingStep}
          selectEventStepData={selectEventStepData}
          selectSlotStepData={selectSlotStepData}
          data={confirmMeetingStepData}
          setData={setConfirmMeetingStepData}
          onDone={() => {
            setOpenConfirmMeetingStep(false);
            setOpenReviewMeetingStep(true);
          }}
        />
      )}

      {showReviewMeetingStep && (
        <ReviewMeetingStep
          stepNumber={4 + additionalStepNumber + numOfAccountSteps}
          open={openReviewMeetingStep}
          onToggle={setOpenReviewMeetingStep}
          confirmMeetingStepData={confirmMeetingStepData}
        />
      )}
    </>
  );
};

export const BookMeetingStep: FC<BookMeetingStepProps> = ({
  hideWrapper,
  onClose,
  ...props
}) => {
  const { profileDoctor } = useBookMeeting();

  const keepRef = useRef({ profileDoctor });
  keepRef.current.profileDoctor = profileDoctor;

  if (hideWrapper) {
    return (
      <BookMeetingStepBase
        {...props}
        loading={false}
        onClose={onClose}
      />
    );
  }

  return (
    <div
      id={BOOK_MEETING_STEPS_WRAPPER_ID}
      className='overflow-auto h-full'
      data-testid='book_meeting.wrapper'
    >
      <BookMeetingStepBase
        {...props}
        loading={false}
        onClose={onClose}
      />
    </div>
  );
};

type BookMeetingDrawerProps = BookMeetingStepProps & {
  profileDoctor?: PublicProfile;
  consultationLocation?: ConsultationLocation;
};

const BookMeetingDrawer: FC<BookMeetingDrawerProps> = ({
  profileDoctor,
  consultationLocation,
  onClose,
  ...props
}) => {
  const { user: meUser } = useRecoilValue(authAtom);
  const { user } = flatMedicProfileRelationships(profileDoctor);

  return (
    <Drawer
      title='title'
      titleValues={{ name: user?.attributes?.fullName || '' }}
      translationDomain='public'
      keyPrefix='public_profile.book_meeting_drawer'
      onClose={onClose}
    >
      <BookMeetingProvider
        initMeUser={meUser}
        consultationLocation={consultationLocation}
        profileDoctor={profileDoctor}
      >
        <BookMeetingStep
          {...props}
          onClose={onClose}
        />
      </BookMeetingProvider>
    </Drawer>
  );
};

export default BookMeetingDrawer;
