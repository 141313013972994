import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import Modal from 'instamed-styleguide/ui/molecules/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageContext } from './MessageContext';
import cls from 'classnames';
import Message from '@/packages/back-end/message';

const DELETE_MESSAGE_URL = (messageId: number | string) => buildUrl(`messages/${messageId}`);

const DeleteMessageModal = ({ message,className }: { message: Message ,className : string }) => {
  const { removeMessage,addMessage } = useMessageContext();
  const { t } = useTranslation('chat');
  const { addToast } = useToast();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { loading, fetchData } = useRequest({
    url: DELETE_MESSAGE_URL(message.attributes._id),
    method: 'DELETE',
    skip: true,
  });

  const handleDeleteMessage = async () => {
    try {
      removeMessage(message);
      setOpenDeleteModal(false);
      await fetchData(null, {
        throw: true,
        displayToastOnError: true
      });
      addToast('success', t('toasts.message_deleted'));
      removeMessage(message);
    } catch (e) {
      addMessage(message);
      // Do nothing
    }
    setOpenDeleteModal(false);
  };

  return (
    <>
      <Button
        icon='trash'
        color='transparent'
        iconColor='#9ba6ae'
        iconHoverColor='#000000'
        iconSize={16}
        onClick={() => setOpenDeleteModal(true)}
        className={cls(className,'mr-1 mb-2')}
      />
      {openDeleteModal && (
        <Modal title={t('delete_message.title')}>
          <div className='flex flex-col'>
            <p>{t('delete_message.subtitle')}</p>
            <div className='flex flex-row justify-end mt-3'>
              <Button
                color='secondary'
                disabled={loading}
                title={t('cancel')}
                className='mr-2'
                onClick={() => setOpenDeleteModal(false)}
              >
                <p>{t('cancel')}</p>
              </Button>
              <Button
                color='primary'
                saving={loading}
                title={t('yes')}
                onClick={handleDeleteMessage}
              >
                <p>{t('yes')}</p>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteMessageModal;