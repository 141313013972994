import React, { FC } from 'react';
import cls from 'classnames';

interface OptionCardProps {
  selected: boolean;
  label: string;
  subtitle?: string | null;
  onClick: () => void;
}

const OptionCard: FC<OptionCardProps> = ({ selected, subtitle, label, onClick }) => {
  return (
    <button
      className={cls(
        subtitle ? 'py-3' : 'py-5',
        'flex gap-4 items-center px-6 w-full hover:bg-gray-50 rounded-lg border border-gray-100'
      )}
      onClick={onClick}
    >
      <div
        className={cls(
          'flex items-center justify-center w-6 h-6 rounded-full border',
          selected ? 'border-primary-500' : 'border-gray-100'
        )}
      >
        {selected && <div className='w-4 h-4 bg-primary-500 rounded-full' />}
      </div>
      <div className='flex flex-col gap-1 text-left' >
        <strong className='text-sm font-medium'>{label}</strong>
        {subtitle && (<span className='text-xs text-gray-500'>{subtitle}</span>)}
      </div>
    </button>
  );
};

export default OptionCard;
