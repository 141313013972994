import React, { useCallback } from 'react';
import useNotifications from '@/components/notifications/useNotifications';
import NotificationItem from '@/components/notifications/notification-item/NotificationItem';
import { Badges } from '@/packages/types';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { redirectToLegacy, URLS } from '@/utils/Legacy';
import useUpgradeModal from '@/utils/hooks/useUpgradeModal';
import useAddPatientDrawer from '@/app/structure/menu/AddPatientDrawer/useAddPatientDrawer';
import useAiDrawer from '@/app/structure/menu/AiDrawer/useAiDrawer';
import useFeatures from '@/utils/hooks/useFeatures';
import useSearchDoctorBookMeetingDrawer
  from '@/app/structure/menu/SearchDoctorBookMeetingDrawer/useSearchDoctorBookMeetingDrawer';
import { isPatient } from '@/packages/back-end/user';
import useSearchPatientDrawer from '@/app/structure/menu/SearchPatientDrawer/useSearchPatientDrawer';


type useNavigationBarProps = {
    badges?: Badges
}

const useNavigationBar = ({ badges } : useNavigationBarProps) => {

  const { onOpen: openAddNewPatientDrawer } = useAddPatientDrawer({});
  const { onOpen: openSearchPatientDrawer } = useSearchPatientDrawer();

  const { isOn } = useFeatures();

  const { onOpen: onOpenAiDrawer } = useAiDrawer();

  const { onOpen: onOpenSearchDoctorBookMeetingDrawer }= useSearchDoctorBookMeetingDrawer();

  const { user: me } = useRecoilValue(authAtom);

  const { upgradeModal, showUpgradeModal } = useUpgradeModal();

  const {
    onClick: onNotificationClick,
    floatingNotification,
    markAsRead
  } = useNotifications(badges?.notifications?.unread || 0);


  const notification = floatingNotification && !floatingNotification.attributes.read && (
    <NotificationItem
      floating={true}
      archived={false}
      onItemClick={markAsRead}
      item={floatingNotification}
    />
  );


  const notifications = {
    counter: badges?.notifications?.unread,
    onClick: onNotificationClick
  };

  const onNewConsultation = useCallback(() => {
    if(me?.attributes.enabledModules.consultation) {
      redirectToLegacy(URLS.new_consultation);
    } else {
      showUpgradeModal();
    }
  },[showUpgradeModal,me]);


  const onAddNewPatient = useCallback(() => {

    if(me?.attributes.enabledModules.patients_management) {
      openAddNewPatientDrawer();
    } else {
      showUpgradeModal();
    }

  },[openAddNewPatientDrawer,showUpgradeModal,me]);

  if(isPatient(me)) {
    return {
      notifications,
      notification,
      showUpgradeModal,
      onOpenSearchDoctorBookMeetingDrawer: isOn('meeting_appointments') ?
        onOpenSearchDoctorBookMeetingDrawer : undefined,
    };
  }

  return {
    notifications,
    notification,
    onOpenAiDrawer: isOn('document_sort_ai') && me?.attributes?.enabledModules?.consultation ?
      onOpenAiDrawer : undefined,
    onPatientSearch: openSearchPatientDrawer,
    onAddNewPatient: me?.attributes?.rights?.create_patient ? onAddNewPatient : undefined,
    upgradeModal,
    onNewConsultation,
    showUpgradeModal,
  };


};

export default useNavigationBar;
