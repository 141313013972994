import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';

import Email from '@/packages/back-end/mssante/email';
import MsSanteDirectoryUser, {
  buildOption as buildDirectoryUserOption,
} from '@/packages/back-end/mssante/directory-user';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { attachErrorsToFields, RequestError } from '@/services/request/utils';

import { yupResolver } from '@hookform/resolvers/yup';
import useToast from '@/utils/hooks/useToast';
import { buildUrl } from '@/utils/Api';
import TextField from '@ui/atoms/fields/text/TextField';
import { OptionType } from '@ui/organisms/selectors/types';
import Icon from '@ui/atoms/icons/icon/Icon';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { getError, optionTypeValidator } from '@/components/form/utils';

import EmailComposer, {
  FormDataType as ComposerFormDataType, NEW_EMAIL_URL,
} from './email-composer';
import ReconnectionModal from './reconnection/ReconnectionModal';
import { useEmails } from './EmailsContext';
import { parseMultipleSelectorValue } from '@/utils/form';

type NewEmailFormProps = {
  onClose: () => void;
  impersonated?: string | number | undefined;
};

type FormDataType = ComposerFormDataType & {
  subject: string;
  to: OptionType[] | null;
  cc?: OptionType[] | null;
};

const schema = yup.object({
  subject: yup.string().required('field_required'),
  to: optionTypeValidator('field_required').required(),
});

const parseData = (data: FormDataType) => {
  return {
    ...data,
    to: parseMultipleSelectorValue(data.to),
    cc: parseMultipleSelectorValue(data.cc),
    documents: parseMultipleSelectorValue(data.documents),
  };
};

const getDefaultValue = () => {
  return {
    subject: '',
    bodyText: '',
    to: [],
    cc: [],
    documents: [],
    attachments: [],
  };
};

const NewEmailForm: FC<NewEmailFormProps> = ({ onClose,impersonated }) => {
  const { addToast } = useToast();

  const { onUpdateEmail, setAddingEmail, setSelectedEmail,impersonatedUser } = useEmails();

  const {
    fetchData: sendEmail,
    loading: sending,
    error: backErrors,
  } = useJsonAPIRequest({
    url: NEW_EMAIL_URL({
      impersonated: impersonatedUser
    }),
    method: 'POST',
    skip: true,
  });

  const { t } = useTranslation('ms_sante');
  const { t: tError } = useTranslation('ms_sante', {
    keyPrefix: 'form_errors',
  });

  const {
    control,
    formState: { errors },
    watch,
    setError,
    register,
    resetField,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValue(),
  });

  const subject = watch('subject');
  const to = watch('to');

  const [refreshLink, setRefreshLink] = useState<string | null>(null);

  const onSubmit = (composerParams: {
    data: ComposerFormDataType;
    callback?: () => void;
  }) => {
    return handleSubmit(async (data) => {
      try {
        const parsedData = parseData({ ...data, ...composerParams.data });

        const response = await sendEmail(parsedData, { throw: true,displayToastOnError: true });
        const newEmail = (response as { data: Email }).data;
        onUpdateEmail(newEmail);
        setAddingEmail(false);
        setSelectedEmail(newEmail);

        addToast('success', t('toasts.email_created'));
        composerParams.callback?.();
      } catch (e) {
        const error = e as RequestError;

        if (error.code === 428) {
          setRefreshLink(error.resolveUrl || null);
        }
      }
    });
  };

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
  }, [backErrors, setError]);

  return (
    <div
      className={'flex overflow-hidden relative flex-col px-2 mt-8 lg:mt-0 w-full h-full bg-white rounded-lg'}
    >
      <div className='flex justify-end pb-2 w-full border-b border-b-gray-50'>
        <button onClick={onClose}>
          <Icon
            icon='close'
            className='mt-2 w-[25px] h-[25px]'
          />
        </button>
      </div>

      <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='flex-shrink-0 mr-2 text-sm text-gray-900'>{t('to')}</p>
        <StyledApiSelectorField<MsSanteDirectoryUser>
          className='flex-grow'
          allowFreeText={true}
          freeTextEmptyText={t('search_user')}
          multiple
          disableCloseOnSelect={false}
          showSelectAll={false}
          apiSearch
          url={buildUrl('ms_sante_directory_users')}
          name='to'
          error={getError('to', errors, tError)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          control={control as any}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          buildOption={buildDirectoryUserOption}
        />
      </div>

      <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='flex-shrink-0 mr-2 text-sm text-gray-900'>
          {t('subject')}
        </p>
        <StyledTextField
          className='flex-grow'
          error={getError('subject', errors, tError)}
          {...register('subject')}
        />
      </div>

      <div className='flex flex-row items-center w-full border-b border-b-gray-50'>
        <p className='flex-shrink-0 mr-2 text-sm text-gray-900'>{t('cc')}</p>
        <StyledApiSelectorField<MsSanteDirectoryUser>
          className='flex-grow'
          allowFreeText={true}
          freeTextEmptyText={t('search_user')}
          multiple
          apiSearch
          disableCloseOnSelect={false}
          showSelectAll={false}
          url={buildUrl('ms_sante_directory_users')}
          name='cc'
          error={getError('cc', errors, tError)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          control={control as any}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
          buildOption={buildDirectoryUserOption}
        />
      </div>

      <EmailComposer
        loading={sending}
        disableSend={!subject || !to?.length}
        containerClassName='!m-0 bg-white rounded-md !border-0 h-full'
        textAreaClasses='!min-h-[95%] !max-h-[100%] !px-0'
        onCreateEmail={(params) => onSubmit(params)()}
      />

      <ReconnectionModal
        link={refreshLink}
        impersonated={impersonated}
        onClose={() => setRefreshLink(null)}
      />
    </div>
  );
};

const StyledApiSelectorField = styled(ApiSelectorField)`
  fieldset {
    border-width: 0px !important;
    :focus {
      border-width: 0px !important;
    }
  }
  .MuiIconButton-root {
    display: none !important;
  }
` as typeof ApiSelectorField;

const StyledTextField = styled(TextField)`
  input {
    font-size: 14px;
    border-width: 0px !important;
    :focus {
      border-width: 0px !important;
    }
  }
` as typeof TextField;

export default NewEmailForm;
