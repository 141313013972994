import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { GET_COLLECTION } from '@/packages/back-end/patient';
import Patient from '@/packages/back-end/patient';

import Drawer from '@/components/drawer/Drawer';
import { onCloseType } from '@/components/drawer/useUpdateDrawer';

import SearchField from '@/app/structure/page/chat/components/SearchField';
import useGetPaginatedCollection from '@/utils/hooks/usePaginatedData';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';
import User from '@/packages/back-end/user';
import { isFakeEmail } from '@/utils/form';
import Empty from '@ui/molecules/empty/Empty';
import { useTranslation } from 'react-i18next';
import UserCardList from '@ui/molecules/user-card-list/UserCardList';
import { UserCardType } from '@ui/molecules/user-card/UserCard';
import { buildPatientProfileUrl } from '@/utils/Legacy';
import i18n from '@/i18n';

type AddPatientDrawerProps = {
  onClose: () => void;
  onCloseCallback?: onCloseType<Patient>;
};


const SearchPatientDrawer: FC<AddPatientDrawerProps> = ({
  onClose,
}) => {

  const { t } = useTranslation('medical_staff',{
    keyPrefix: 'search_patient'
  });

  const [loading, setLoading] = useState(false);

  const { data, queryParams,setQueryParams, onReload } = useGetPaginatedCollection<Patient>(GET_COLLECTION({
    include: 'user',
  }), {
    favorites: true,
  }, {}, {
    skip: true,
  });

  const onSearch = useCallback(async (search: string) => {

    setQueryParams({
      search,
      favorites: !search,
    });
    setLoading(true);
    await onReload();
    setLoading(false);
  }, [setQueryParams, onReload]);

  useEffect(() => {

    onReload();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const users : UserCardType[] = useMemo(() => {

    return data.map((patient) => {

      const user = patient.relationships.user.data as User;


      const dob = user.attributes.dateOfBirth ? new Date(user.attributes.dateOfBirth) : null;

      const email = isFakeEmail(user.attributes.email) ? null : user.attributes.email;
      
      return {
        user: getAvatarProps({
          user: user.attributes as UserType,
          colorLength: 10,
        }),
        onClickUser: buildPatientProfileUrl(user.id),
        name: user.attributes.fullName,
        subtitle: [dob?.toLocaleDateString(i18n.language),email].filter(Boolean).join(' - '),
      };

    });
  },[data]);

  return (
    <Drawer
      isDynamicHeight
      title={'search_patient.title'}
      translationDomain='medical_staff'
      onClose={onClose}
      errorMessage={null}
    >
      <div className='max-w-[656px]'>
        <div className='p-4'>
          <SearchField name='search'
            onSearch={onSearch} />
        </div>
        <div className='flex flex-col mt-4'>
          {!loading && !queryParams.search && !!data.length &&
            ( <h4 className='px-4 mb-2 text-sm font-semibold text-black' >{t('recent_patients')}</h4>)}
          {<UserCardList
            loadingCount={8}
            loading={loading}
            users={users} />}
          {!loading && !data.length && (
            <div className='px-4 pt-8' >
              <Empty
                message={t(queryParams.search ?
                  'not_found_description' :
                  'empty_description'
                )}
              />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SearchPatientDrawer;
