import React, { FC, useMemo } from 'react';

import Email, { Attachment } from '@/packages/back-end/mssante/email';
import Document from '@/packages/back-end/document';

import AttachmentCard from './AttachmentCard';
import useClassifyAttachmentDrawer from './useClassifyAttachmentDrawer';

type AttachmentTemplateProps = {
  email?: Email;
  impersonated?: string | number;
  attachment: Attachment;
  attachments: Attachment[];
  setAttachments: (attachments: Attachment[]) => void;
};

const AttachmentTemplate: FC<AttachmentTemplateProps> = ({
  impersonated,
  email,
  attachment,
  attachments,
  setAttachments,
}) => {
  const { onOpen: onOpenClassifyDocumentDrawer } = useClassifyAttachmentDrawer({
    attachments,
    setAttachments,
  });

  const document = useMemo(
    () =>
      (attachment as Attachment).relationships?.document?.data as
        | Document
        | undefined,
    [attachment]
  );

  const handleOpenClassifyDocumentDrawer = () => {
    onOpenClassifyDocumentDrawer({ attachment, email,impersonated });
  };

  return (
    <AttachmentCard
      classified={!!document}
      mine={email?.attributes.emailType === 'sent'}
      title={attachment.attributes.name}
      fileUrl={attachment.attributes.previewUrl}
      downloadUrl={attachment.attributes.downloadUrl}
      onClick={!document ? handleOpenClassifyDocumentDrawer : undefined}
    />
  );
};

export default AttachmentTemplate;
