import { atom, selector } from 'recoil';
import _ from 'lodash';

import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { Badges, InstitutionType, LegacyMe } from '@/packages/types';
import { MeUser } from '@/packages/back-end/user';
import Institution from '@/packages/back-end/Institution';
import { ConstantItem } from '@/components/form/fields/constant-selector';

export enum LoadableState {
  LOADING = 'loading',
  SUCCESS = 'hasValue',
  ERROR = 'hasError'
}

type Auth = {
  username?: string;
  isConnected?: boolean;
  isImpersonating?: boolean;
  isLoading?: boolean;
  legacy_user?: LegacyMe;
  user?: MeUser;
}

type ParseUser = (data?: MeUser) => Auth;

const parseUser: ParseUser = (user) => ({
  username: storage.getItem(StorageKeys.USERNAME) || undefined,
  isImpersonating: !!storage.getItem(StorageKeys.IMPERSONATION_KEY),
  isConnected: !!user,
  user: user,
  legacy_user: !user || _.isEmpty(user) ? undefined : parseLegacyUser(user),
}) as Auth;

export type ConstantItemKeyType = 'call' | 'health_profile' | 'documentType' | 'alert' | 'specialty'
  | 'alertType' | 'patient' | 'user' | 'medicalRecordField' | 'task' | 'medicalRecordSection' | 'subscription'
  | 'subscriptionGroup' | 'medicalDocument' | 'mobileAppVersion' | 'publicProfile' | 'cerfaTemplate'
  | 'eventType' | 'consultationLocation' | 'event' | 'availability' | 'aiSuggestion' | 'medicAssociation'
  | 'document' | 'identityDocument' | 'eventTypeOptions' | 'record' | 'pdfTemplate';

type ConstantEntityItem = {
  properties: {
    [key: string]: ConstantItem
  }
}

export type ConstantsItems = {
  [key in ConstantItemKeyType]: ConstantEntityItem;
};

export type StoredConstantsItems = ConstantsItems & {
  expiry: number;
}

export type ConstantsState = {
  isLoading: boolean;
  constants: ConstantsItems | null;
}

const getInitialConstants = () => {
  const constants = storage.getJSON(StorageKeys.CONSTANTS) as StoredConstantsItems;
  if (!constants?.expiry || constants?.expiry < Date.now()) {
    return null;
  }

  return constants;

};

type Specialty = {
  _id: string,
  name: string,
  specialistName: string,
  canonical: string
}

export type City = {
  _id: string,
  name: string,
  postalCode: string,
  latitude: number,
  longitude: number,
  cityName: string,
  canonical: string
}

export type OnlineAppointmentContextType = {
  cities: City[],
  city?: City,
  subCities?: City[],
  specialties: Specialty[],
  specialty?: Specialty
}


type OnlineAppointmentState = {
  isLoading: boolean;
  data: OnlineAppointmentContextType;
  city: string | undefined,
  doctorName?: string,
  specialty: string | undefined,
}

export const searchContextAtom = atom<OnlineAppointmentState>({
  key: 'searchContext',
  default: {
    isLoading: false,
    city: undefined,
    specialty: undefined,
    data: {
      cities: [],
      specialties: []
    }
  }
});

export const constantsAtom = atom<ConstantsState>({
  key: 'constantsAtom',
  default: {
    isLoading: false,
    constants: getInitialConstants()
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue.constants) {

          const value = { ...newValue.constants } as StoredConstantsItems;
          // Keep in cache maximum 6 hours
          value.expiry = Date.now() + 1000 * 60 * 60 * 6;
          storage.setJSON(StorageKeys.CONSTANTS, value);
        }
      });
    },
  ],
});


export type FeaturesType = {
  public_profile: boolean,
  document_sort_ai: boolean
  meeting_appointments: boolean
  ms_sante : boolean
}


export type StoredFeaturesType = FeaturesType & {
  expiry: number;
}


export type FeaturesState = {
  isLoading: boolean;
  features: FeaturesType | null;
}


export type ModalType = 'auth' | 'payment';
export type ProductType = 'core' | 'lia';

export type RequestErrorState = {
  modalType?: ModalType;
  redirectUrl?: string;
  productType?: ProductType;
}


export const requestErrorAtom = atom<RequestErrorState>({
  key: 'requestErrorAtom',
  default: {
    modalType: undefined,
    redirectUrl: undefined,
    productType: undefined,
  },
});


const getInitialFeatures = () => {
  const features = storage.getJSON(StorageKeys.FEATURES) as StoredFeaturesType;
  if (!features?.expiry || features?.expiry < Date.now()) {
    return null;
  }

  return features;

};

export const featuresAtom = atom<FeaturesState>({
  key: 'featuresAtom',
  default: {
    isLoading: false,
    features: getInitialFeatures()
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue.features) {

          const value = { ...newValue.features } as StoredFeaturesType;

          // Keep in cache maximum 6 hours
          value.expiry = Date.now() + 1000 * 60 * 60 * 6;
          storage.setJSON(StorageKeys.FEATURES, value);
        }
      });
    },
  ],
});

export const authAtom = atom<Auth>({
  key: 'authAtom',
  default: { isLoading: true }
});


export const MeState = selector<MeUser | undefined>({
  key: 'MeState',
  get: ({ get }) => get(authAtom)?.user,
  set: ({ set }, user) => {
    set(authAtom, parseUser(user as MeUser | undefined));
  }
});

export const defaultBadgeState = {
  discussions: {
    ungrouped: 0,
    grouped: 0,
    archived: 0,
    trash: 0,
    total: 0
  },
  mssante: {
    total: 0
  },
  events: {
    today: 0,
  },
  notifications: {
    unread: 0,
  },
  alerts: {
    total: 0,
  },
  faqs: {
    unread: 0
  },
  tips: {
    unread: 0
  },
  answers_groups: {
    total: 0,
    patients: 0
  }
};

export const badgeState = atom<Badges>({
  key: 'badgesAtom',
  default: defaultBadgeState
});



export const parseLegacyUser = (user: MeUser): LegacyMe => {

  const institutions = user?.relationships?.institutions?.data as Institution[] ?? [];

  return {
    id: user.attributes._id as number,
    firstName: user.attributes.firstName,
    fullLastName: user.attributes.fullLastName,
    fullName: user.attributes.fullName,
    aliasName: user.attributes.aliasName,
    email: user.attributes.email,
    CGUAgreed: user.attributes.CGUAgreed,
    role: user.attributes.role,
    thumbnails: user.attributes.thumbnails,
    profilePicture: user.attributes.profilePicture,
    phones: [],
    institutions: institutions.map((institution): InstitutionType => {
      return {
        id: institution.attributes._id as number,
        name: institution.attributes.name,
        current: institution.attributes.current || false,
        public: institution.attributes.public || false,
        logo: institution.attributes.logo,
        thumbnails: institution.attributes.thumbnails,
        domain: institution.attributes.domain
      };
    })
  };

};