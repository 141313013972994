import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import Email, { GET_REFRESH_URL } from '@/packages/back-end/mssante/email';

import { MessageFiles } from '../chat/messages/MessageContext';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import useGetPaginatedCollection from '@/utils/hooks/usePaginatedData';
import { buildUrl, UrlArg } from '@/utils/Api';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';
import useRequest, { RequestError } from '@/services/request/useRequest';
import { isDoctorAssistant } from '@/packages/back-end/user';
import storage, { StorageKeys } from '@/services/request/helpers/storage';

type RefreshCallbackType = (() => Promise<unknown>) | undefined;

type ChatContextProps = {
  isSecuredMessaging: boolean;
  setSecuredMessaging: (value: boolean) => void;
  isPageRefreshing: boolean;
  setPageRefreshing: (value: boolean) => void;
  isAddingEmail: boolean;
  setAddingEmail: (value: boolean) => void;
  emails: Email[];
  setEmails: (emails: Email[]) => void;
  selectedEmail: Email | null;
  setSelectedEmail: (email: Email | null) => void;
  loadingSelectedEmail: boolean;
  setLoadingSelectedEmail: (loading: boolean) => void;
  signature: string | null;
  setSignature: (value: string | null) => void;
  refreshLink: string | null;
  setRefreshLink: (value: string | null) => void;
  setImpersonatedUser: (value: number | string | undefined) => void;
  impersonatedUser?: number | string | undefined;
  setHasMultipleDoctors: (value: boolean) => void;
  hasMultipleDoctors: boolean;
  loadingEmails: boolean;
  currentPageEmails: number;
  queryParamsEmail: UrlArg;
  setQueryParamsEmails: (url: UrlArg) => void;
  updateQueryParamsEmails: (url: UrlArg) => void;
  onReloadEmails: () => void;
  onRefresh: () => Promise<{ count: number; }>,
  refreshing: boolean;
  onResetEmails: () => void;
  refreshCallback?: RefreshCallbackType;
  setRefreshCallback: (callback: RefreshCallbackType) => void;
  onLoadMoreEmails: () => void;
  onDeleteEmail: (email: Email) => void;
  init: () => void;
  onUpdateEmail: (email: Email) => void;
};

export const initialFileState: MessageFiles = {
  medicalDocuments: [],
  attachments: [],
  audio: [],
};

const EmailsContext = createContext<ChatContextProps>({
  isSecuredMessaging: false,
  setSecuredMessaging: () => {
    throw new Error('setSecuredMessaging not implemented');
  },
  isPageRefreshing: false,
  setPageRefreshing: () => {
    throw new Error('setPageRefreshing not implemented');
  },

  isAddingEmail: false,
  setAddingEmail: () => {
    throw new Error('setAddingEmail not implemented');
  },

  emails: [] as Email[],
  setEmails: () => {
    throw new Error('setEmails not implemented');
  },

  loadingSelectedEmail: false,
  setLoadingSelectedEmail: () => {
    throw new Error('setLoadingSelectedEmail not implemented');
  },

  selectedEmail: null,
  setSelectedEmail: () => {
    throw new Error('setSelectedEmail not implemented');
  },

  signature: null,
  setSignature: () => {
    throw new Error('setSignature not implemented');
  },

  impersonatedUser: undefined,
  setImpersonatedUser: () => {
    throw new Error('setImpersonatedUser not implemented');
  },

  refreshLink: null,
  setRefreshLink: () => {
    throw new Error('setRefreshLink not implemented');
  },

  setRefreshCallback: () => {
    throw new Error('setRefreshCallback not implemented');
  },
  hasMultipleDoctors: false,
  setHasMultipleDoctors: () => {
    throw new Error('setHasMultipleDoctors not implemented');
  },

  loadingEmails: false,
  refreshing: false,
  currentPageEmails: 1,
  queryParamsEmail: {},
  onReloadEmails: () => {
    throw new Error('onReloadEmails not implemented');
  },
  onRefresh: async () => {
    throw new Error('onReloadEmails not implemented');
  },
  onResetEmails: () => {
    throw new Error('onResetEmails not implemented');
  },
  setQueryParamsEmails: () => {
    throw new Error('setQueryParamsEmails not implemented');
  },
  updateQueryParamsEmails: () => {
    throw new Error('updateQueryParamsEmails not implemented');
  },
  onLoadMoreEmails: () => {
    throw new Error('onLoadMoreEmails not implemented');
  },
  onDeleteEmail: () => {
    throw new Error('onDeleteEmail not implemented');
  },
  init: () => {
    throw new Error('init not implemented');
  },
  onUpdateEmail: () => {
    throw new Error('onUpdateEmail not implemented');
  },
});

const initialParamsKeys = ['read', 'archived', 'sent','classed'];

export const EMAIL_INCLUDE_PARAMS =
  'attachments,attachments.document,attachments.matchingPatients,attachments.matchingPatients.user';

export const EmailsProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { user } = useRecoilValue(authAtom);

  const [isSecuredMessaging, setSecuredMessaging] = useState(
    !!user?.attributes.enabledModules.secured_messaging
  );

  const [isInit, setInit] = useState(false);

  const [isPageRefreshing, setPageRefreshing] = useState(false);
  const [isAddingEmail, setAddingEmail] = useState(false);

  const [emails, setEmails] = useState<Email[]>([]);
  const [loadingSelectedEmail, setLoadingSelectedEmail] = useState(true);
  const [selectedEmail, setSelectedEmail] = useState<Email | null>(null);
  const [signature, setSignature] = useState<string | null>(null);

  const [refreshLink, setRefreshLink] = useState<string | null>(null);
  const [refreshCallback, setRefreshCallback] = useState<RefreshCallbackType>();

  const [impersonatedUser, setImpersonatedUser] = useState<number | string | undefined>(
    isDoctorAssistant(user) ? (storage.getItem(StorageKeys.EMAIL__IMPERSONATED_USER) ?? undefined)
      : user?.attributes._id
  );
  const [hasMultipleDoctors, setHasMultipleDoctors] = useState(false);


  const { fetchData: refreshData, loading: refreshing } = useRequest<{ count: number }>({
    url: GET_REFRESH_URL({ impersonated: impersonatedUser }),
    method: 'post',
    skip: true
  });

  const onRefresh = useCallback(async () => {

    try {
      return await refreshData({}, { throw: true }) as { count: number };
    } catch (e) {
      const error = e as RequestError;
      if (error.code === 428) {
        setRefreshLink(error.resolveUrl || null);
      }
      throw e;
    }

  }, [refreshData]);


  useEffect(() => {
    setSecuredMessaging(!!user?.attributes.enabledModules.secured_messaging);
  }, [user]);


  const {
    data: emailsData,
    loading: loadingEmails,
    currentPage: currentPageEmails,
    setData,
    onReload: onReloadEmails,
    reset: onResetEmails,
    queryParams: queryParamsEmail,
    setQueryParams: setQueryParamsEmails,
    onPaginate: onLoadMoreEmails,
    onUpdate,
    onDelete,
  } = useGetPaginatedCollection<Email>(
    buildUrl('ms_sante_emails', undefined, { include: EMAIL_INCLUDE_PARAMS }),
    { archived: false, search: undefined },
    {
      impersonated: impersonatedUser,
    },
    { skip: ((!isSecuredMessaging && !impersonatedUser) || !isInit), preventUpdateUrl: true },
    initialParamsKeys
  );

  const filteredEmails = useMemo(() => {
    return emails.filter((email) => {
      if (!isNil(queryParamsEmail.archived)) {
        return email.attributes.archived === queryParamsEmail.archived;
      }
      if (!isNil(queryParamsEmail.read)) {
        return email.attributes.read === !!queryParamsEmail.read;
      }
      return email;
    });
  }, [queryParamsEmail, emails]);

  const handleUpdateQueryParams = useCallback(
    (params: UrlArg) => {
      if (JSON.stringify(queryParamsEmail) !== JSON.stringify(params)) {
        setQueryParamsEmails({ ...params });
      }
    },
    [queryParamsEmail, setQueryParamsEmails]
  );

  // close Add screen when select a email
  useEffect(() => {
    setAddingEmail(false);
  }, [selectedEmail]);

  useEffect(() => {
    setEmails(emailsData);
  }, [emailsData]);

  useEffect(() => {
    setSelectedEmail((oldSelectedEmail) => {
      const existing = filteredEmails.some(
        ({ id }) => id === oldSelectedEmail?.id
      );
      if (existing) {
        return oldSelectedEmail;
      }
      return filteredEmails[0] || null;
    });
  }, [filteredEmails, setSelectedEmail]);

  const onDeleteEmail = useCallback((item) => {

    onDelete(item);

    if (item.id === selectedEmail?.id) {
      setSelectedEmail(null);
    }

  }, [onDelete, selectedEmail, setSelectedEmail]);

  useEffect(() => {
    if (!loadingEmails && !selectedEmail?.id && filteredEmails.length) {
      const email = filteredEmails.find(
        (item) => String(item.attributes._id) === location.hash.slice(1)
      );
      setSelectedEmail(email || filteredEmails[0] || null);
    }
  }, [
    location.hash,
    loadingEmails,
    selectedEmail?.id,
    filteredEmails,
    setSelectedEmail,
  ]);


  const setRealImpersonatedUser = useCallback((value) => {

    setImpersonatedUser(value);
    storage.setItem(StorageKeys.EMAIL__IMPERSONATED_USER, value);
    setData([]);
    setSelectedEmail(null);


  }, [setSelectedEmail, setImpersonatedUser, setData]);

  const init = useCallback(() => {
    setInit(true);
  }, []);

  const onUpdateEmail = useCallback((item : Email) => {

    onUpdate(item);

    // Refresh the selected email
    if(selectedEmail?.id === item.id) {
      setSelectedEmail(item);
    }

  },[selectedEmail,onUpdate]);

  return (
    <EmailsContext.Provider
      value={{
        isSecuredMessaging,
        setSecuredMessaging,
        isPageRefreshing,
        setPageRefreshing,
        isAddingEmail,
        setAddingEmail,
        refreshCallback,
        setRefreshCallback,
        emails: filteredEmails,
        setImpersonatedUser: setRealImpersonatedUser,
        impersonatedUser,
        setHasMultipleDoctors,
        hasMultipleDoctors,
        setEmails,
        selectedEmail,
        setSelectedEmail,
        loadingSelectedEmail,
        setLoadingSelectedEmail,
        signature,
        setSignature,
        refreshLink,
        setRefreshLink,
        onRefresh,
        refreshing,
        loadingEmails,
        currentPageEmails,
        queryParamsEmail,
        setQueryParamsEmails,
        updateQueryParamsEmails: handleUpdateQueryParams,
        onReloadEmails,
        onResetEmails,
        onLoadMoreEmails,
        onUpdateEmail,
        onDeleteEmail,
        init
      }}
    >
      {children}
    </EmailsContext.Provider>
  );
};

export const useEmails = () => useContext(EmailsContext);
