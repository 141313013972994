import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Email from '@/packages/back-end/mssante/email';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import useToast from '@/utils/hooks/useToast';
import Button from '@ui/atoms/buttons/button/Button';

import { useEmails } from '../EmailsContext';
import { EMAIL_URL } from '../email-template';

interface ReadButtonProps {
  email?: Email | null;
  iconSize?: number;
  isMultipleEdit?: boolean;
}

const ReadButton: FC<ReadButtonProps> = ({
  email,
  iconSize = 20,
  isMultipleEdit,
}) => {
  const { t } = useTranslation('ms_sante');
  const { addToast } = useToast();
  const { emails, queryParamsEmail, setEmails, impersonatedUser: impersonated, onUpdateEmail } = useEmails();

  const isRead = isMultipleEdit ? queryParamsEmail.read !== false : !!email?.attributes?.read;

  const { loading, fetchData: updateEmail } = useJsonAPIRequest({
    url: isMultipleEdit ? EMAIL_URL({ id: 'bulk',impersonated }) :
      EMAIL_URL({ email,impersonated }),
    method: 'PUT',
    skip: true,
  });

  const handleUpdateRead = async () => {
    const ids = emails.filter((item) => item.selected).map(item => item.attributes._id);
    const body = { read: !isRead };
    const bodyMultiple = { data: { read: !isRead }, ids };
    const res = await updateEmail(isMultipleEdit ? bodyMultiple : body);
    const updatedEmail = (res as { data: Email })?.data;

    if (isMultipleEdit) {
      setEmails(emails.map((item) => {
        if (!ids.includes(item.attributes._id)) {
          return { ...item, selected: false };
        }
        return {
          ...item,
          selected: false,
          attributes: { ...item.attributes, read: !isRead }
        };
      }));
    } else if (updatedEmail) {
      onUpdateEmail(updatedEmail);
      const message = t(
        isRead ? 'toasts.email_as_unread' : 'toasts.email_as_read'
      );
      addToast('success', message);
    }
  };

  // You can't set an email sent as unread
  if(email?.attributes.emailType === 'sent') {
    return null;
  }

  return (
    <Button
      color='transparent'
      icon={isRead ? 'messageCloud' : 'check'}
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      iconSize={iconSize}
      saving={loading}
      title={t(
        isRead ? 'header_right.mark_as_unread' : 'header_right.mark_as_read'
      )}
      onClick={(event) => {
        event.stopPropagation();
        handleUpdateRead();
      }}
    />
  );
};

export default ReadButton;
