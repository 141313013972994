import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MEDIUM_GRAY } from '../messages/MessageComposer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import Drawer from '@/components/drawer/Drawer';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import { buildUrl } from '@/utils/Api';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { useForm } from 'react-hook-form';
import { ResponseData } from '@/components/form/UpdateForm';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { OptionType } from 'instamed-styleguide/ui/types';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import { formatDate } from '@/components/date-format/dateFormat';
import { useDiscussions } from '../ChatContext';
import { UserWithId } from '../types';
import useRequest from '@/services/request/useRequest';
import PreviewDocument from '../right-components/PreviewDocument';
import useSidebarWidth from '@/components/dashboard-staff/AiDrawer/useSidebarWidth';
import {
  MedicalDocumentsProps,
  useMessageContext,
} from '../messages/MessageContext';
import DocumentType, {
  buildOption as buildDocumentTypeOption,
  GET_COLLECTION as GET_DOCUMENT_TYPE_COLLECTION,
} from '@/packages/back-end/document-type';
import User, { isMedicalStaff, isPatient } from '@/packages/back-end/user';
import PDFTemplate, { GET_COLLECTION as GET_TEMPLATES_COLLECTION, buildOption as buildTemplateOption }
  from '@/packages/back-end/pdf-template';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import useUpgradeModal from '@/utils/hooks/useUpgradeModal';

const ButtonAction = styled.button`
    display: flex;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    padding: 3px;
`;

const SHOW_DOC_PREVIEW = buildUrl('/api/v2/medical_documents/preview');

const GET_PATIENT_FROM_USER = (userId: string) => buildUrl(`${userId}/profile`);

type CreateDocumentDrawerProps = {
  setFiles: (file: MedicalDocumentsProps) => void;
  patientUser: UserWithId;
  onClose: () => void;
  setShowSidebar: (value: boolean) => void;
};


type PreviewResult = {
  previewUrl: string,
  key : string,
  mimeType : string
}

const CreateDocumentDrawer = ({
  setShowSidebar,
  setFiles,
  patientUser,
  onClose,
}: CreateDocumentDrawerProps) => {
  const { t } = useTranslation('chat');
  const { sideBarWidth } = useSidebarWidth(true);
  const [previewResult, setPreviewResult] = useState<PreviewResult | null>(null);

  const [isPreviewing, setPreviewing] = useState(false);

  const { fetchData: showPreview, loading } = useRequest<PreviewResult>({
    url: SHOW_DOC_PREVIEW,
    skip: true,
    method: 'POST',
  });

  const { data: patientProfile } = useJsonAPIRequest<{ data: { id: string } }>({
    url: GET_PATIENT_FROM_USER(patientUser?.id),
    method: 'GET',
  });

  const { register, handleSubmit, resetField, control, setValue, watch } =
    useForm<ResponseData>({
      shouldUseNativeValidation: true,
    });

  const documentType = watch('documentType') as
    | OptionType<DocumentType>
    | undefined;
  const template = watch('template') as OptionType | undefined;
  const documentTitle = watch('documentTitle') as string;

  const typeCanonical = useMemo(() => {
    if (!documentType?.data) {
      return undefined;
    }

    if (
      documentType?.data?.relationships?.parent?.data?.attributes?.canonical
    ) {
      return documentType.data.relationships.parent.data.attributes.canonical;
    }

    return documentType.data.attributes.canonical;
  }, [documentType]) as string | undefined;

  const setTitle = useCallback(() => {
    const patientName = patientUser.attributes.fullName;
    if (typeCanonical === 'report') {
      const date = formatDate(new Date().toISOString(), {
        format: 'DD/MM/YYYY à HH:mm',
      });
      setValue(
        'documentTitle',
        t('create_document.report_title', { patientName, date })
      );
    } else if (typeCanonical === 'prescription') {
      const date = formatDate(new Date().toISOString(), {
        format: 'DD/MM/YYYY',
      });
      setValue(
        'documentTitle',
        t('create_document.prescription_title', { patientName, date })
      );
    } else {
      setValue(
        'documentTitle',
        t('create_document.generic_title', {
          docLabel: template?.label,
          patientName,
        })
      );
    }
  }, [
    typeCanonical,
    template?.label,
    patientUser?.attributes.fullName,
    setValue,
    t,
  ]);

  useEffect(() => {
    if (documentType && template) {
      setTitle();
    } else if (!documentType) {
      resetField('template');
      resetField('documentTitle');
    }
  }, [documentType, template, setTitle, resetField]);

  const documentPreview = async () => {

    setPreviewing(true);

    const response = await showPreview({
      type: documentType?.value,
      template: template?.value,
      patient: patientProfile?.data.id,
      name: documentTitle,
    }, {
      throw: true,
      displayToastOnError: true
    }) as PreviewResult;

    setShowSidebar(true);
    setPreviewResult(response);

  };

  const onSubmit = useCallback(
    async (data) => {
      if (previewResult) {
        setFiles({
          name: data.documentTitle,
          type: data.documentType.data,
          template: data.template.data,
          patient: patientProfile?.data.id as string,
          fileUrl: previewResult.key,
          mimeType: previewResult.mimeType,
          previewUrl: previewResult.previewUrl,
        });
        onClose();
        return;
      }

      const res = await showPreview({
        type: documentType?.value,
        template: template?.value,
        patient: patientProfile?.data.id,
        name: documentTitle,
      },{ throw: true, displayToastOnError: true }) as PreviewResult;

      setFiles({
        name: data.documentTitle,
        type: data.documentType.data,
        template: data.template.data,
        patient: patientProfile?.data.id as string,
        mimeType: res.mimeType,
        fileUrl: res.key,
        previewUrl: res.previewUrl as string,
      });

      onClose();

    },
    [
      template?.value,
      documentTitle,
      documentType?.value,
      onClose,
      patientProfile,
      setFiles,
      showPreview,
      previewResult,
    ]
  );

  return (
    <Drawer
      title={t('create_document.title')}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      sidebar={
        previewResult ? (
          <PreviewDocument
            previewUrl={previewResult.previewUrl}
            title={documentTitle}
            onCloseSidebar={() => {
              setPreviewResult(null);
              setShowSidebar(false);
            }}
          />
        ) : (
          <></>
        )
      }
      sidebarWidth={previewResult ? sideBarWidth : 0}
    >
      <div className='flex flex-col justify-between m-4 mt-6 h-[95%]'>
        <div>
          <ApiSelectorField<DocumentType>
            url={GET_DOCUMENT_TYPE_COLLECTION({
              context: 'generate_document',
              include: 'parent',
            })}
            name={'documentType'}
            className='mb-6'
            required
            label={t('create_document.type.title')}
            placeholder={t('create_document.type.label')}
            apiSearch={true}
            control={control}
            buildOption={buildDocumentTypeOption}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetField={resetField}
          />
          <ApiSelectorField<PDFTemplate>
            fixedUrl={false}
            className='mb-6'
            url={GET_TEMPLATES_COLLECTION({
              context: 'generate_document',
              include: '_type',
              type: documentType?.value,
            })}
            name={'template'}
            placeholder={t('create_document.model.label')}
            onChange={(option) => {

              const opt = option as OptionType<PDFTemplate> | undefined;

              if (!opt || !opt.data) {
                return;
              }
              if (documentType) {
                return;
              }
              const template = opt.data as PDFTemplate;

              setValue('documentType', buildDocumentTypeOption(template.relationships._type?.data as DocumentType));


            }}
            required
            label={t('create_document.model.title')}
            apiSearch={true}
            control={control}
            buildOption={buildTemplateOption}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetField={resetField}
          />
          <TextField
            {...register('documentTitle')}
            label={t('create_document.doc_title.title')}
            placeholder={t('create_document.doc_title.label')}
          />
        </div>
        <div className='flex flex-row space-x-4 w-full'>
          <Button
            className='w-1/2'
            onClick={documentPreview}
            type='button'
            saving={loading && isPreviewing}
            disabled={(loading && !isPreviewing) || !template}
          >
            {t('create_document.preview')}
          </Button>
          <Button
            className='w-1/2'
            color='primary'
            disabled={(loading && isPreviewing) || !template}
            saving={loading && !isPreviewing}
            type='submit'
          >
            {t('create_document.join')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const useCreateDocumentDrawer = () => {
  const { updateData, openDrawer, closeDrawer } = useDrawerManager();
  const { sideBarWidth, setShowSidebar } = useSidebarWidth(false);

  useEffect(() => {
    updateData({ sidebarWidth: sideBarWidth });
  }, [updateData, sideBarWidth]);

  const onCloseDrawer = useCallback(() => {
    setShowSidebar(false);
    closeDrawer();
  }, [closeDrawer, setShowSidebar]);

  const onClick = useCallback(
    ({ patient, setFiles }) => {
      openDrawer({
        content: (
          <CreateDocumentDrawer
            onClose={onCloseDrawer}
            patientUser={patient}
            setShowSidebar={setShowSidebar}
            setFiles={setFiles}
          />
        ),
        size: 'basic',
        sidebarWidth: sideBarWidth,
      });
    },
    [onCloseDrawer, openDrawer, setShowSidebar, sideBarWidth]
  );

  return { onClick };
};

const CreateDocumentButton = ({ isNew = false }: { isNew?: boolean }) => {

  const { upgradeModal, showUpgradeModal } = useUpgradeModal();

  const { t } = useTranslation('chat');
  const { user } = useRecoilValue(authAtom);
  const { selectedDiscussion, newDiscussion } = useDiscussions();
  const { files, setFiles } = useMessageContext();
  const participants = (
    isNew
      ? newDiscussion?.participants
      : selectedDiscussion?.relationships?.participants?.data
  ) as User[];
  const patients = participants?.filter(
    (user: UserWithId) => !isMedicalStaff(user)
  );
  const { onClick } = useCreateDocumentDrawer();

  const onButtonClick = useCallback(() => {

    if (!user?.attributes?.enabledModules?.consultation) {
      showUpgradeModal();
      return;
    }

    onClick({
      patient: patients[0],
      setFiles: (createdDocument: MedicalDocumentsProps) =>
        setFiles({
          ...files,
          medicalDocuments: [...files.medicalDocuments, createdDocument],
        }),
    });
  }, [onClick, setFiles, user, showUpgradeModal, files, patients]);

  if (!user?.attributes.enabledModules.consultation) {
    return null;
  }

  if(isPatient(user)) {
    return null;
  }

  if (!patients || !patients?.length || patients.length > 1) {
    return null;
  }

  return (<>
    {upgradeModal}
    <ButtonAction
      title={t('message_composer.document_create')}
      onClick={onButtonClick}
    >
      <Icon icon='newDocument' width={20}
        height={20} color={MEDIUM_GRAY} />
    </ButtonAction>
  </>
  );
};

export default CreateDocumentButton;
