import React, { FC } from 'react';
import RightBarUi from '@ui/organisms/right-bar/RightBar';
import { DrawerManagerType } from '@/app/structure/drawer-manager/DrawerManager';
import { URL_HELP,URL_HELP_PATIENT } from '@/constants';
import useNavigationBar from '@/utils/hooks/useNavigationBar';
import { isPatient } from '@/packages/back-end/user';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';

const RightBar : FC<DrawerManagerType> = ({ badges, user }) => {


  const { upgradeModal,
    onNewConsultation,
    onOpenAiDrawer,
    onPatientSearch,
    onOpenSearchDoctorBookMeetingDrawer,
    onAddNewPatient
    ,notification,
    notifications
  } = useNavigationBar({ badges });

  const { user: userData } = useRecoilValue(authAtom);


  return (
    <>
      {upgradeModal}
      {notification}
      <RightBarUi
        helpLink={isPatient(userData) ? URL_HELP_PATIENT : URL_HELP}
        onNewConsultation={onNewConsultation}
        onNewPatient={onAddNewPatient}
        onPatientSearch={onPatientSearch}
        onAiDrawer={onOpenAiDrawer}
        onBookMeeting={onOpenSearchDoctorBookMeetingDrawer}
        user={user}
        notifications={notifications}
      />
    </>
  );

};
export default RightBar;