import LandingPage from '@/components/landing-page/LandingPage';
import { useTranslation } from 'react-i18next';
import getAssetURL from '@/utils/getAssetURL';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import LinkButton from '@/components/link/LinkButton';
import { buildLegacyUrl, URLS } from '@/utils/Legacy';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';


const Http404 : FC = () => {

  const { user } = useRecoilValue(authAtom);

  const { pathname } = useLocation();

  const { t } = useTranslation('common',{
    keyPrefix: 'errors.404'
  });

  return (<LandingPage
    contentClassName='h-[calc(100%-90px)]'
    title={t('title')}
    subtitle={t('subtitle',{
      url: pathname
    })}
    withLogo={!user}
    image={getAssetURL('/assets/images/404.svg')}
    data-testid='http-404'
  >
    {<LinkButton className='mt-4' href={buildLegacyUrl(URLS.home)} >{t('button')}</LinkButton>}
  </LandingPage>
  );

};

export default Http404;