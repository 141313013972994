import { buildUrl, parseId, UrlArg } from '@/utils/Api';
import { Entity, Item } from '@/packages/back-end/jsonapi';
import Medic from '@/packages/back-end/medic';
import MedicSkill from '@/packages/back-end/medic-skill';
import ConsultationLocationTemplate from '@/packages/back-end/consultation-location';


export const PUBLIC_PROFILES_INCLUDES = [
  'rates',
  'degrees',
  'associations',
  'experiences',
  'skills',
  'publications',
  'consultationLocations',
  'consultationLocations.address',
  'consultationLocations.phone',
  'medicalTeam',
  'medicalTeam.medic',
  'medicalTeam.medic.user',
  'medicalTeam.medic.specialties',
  'medic',
  'medic.rates',
  'medic.user',
  'medic.user.phoneNumber',
  'medic.professionalAddress',
  'medic.specialties',
];


export const PUBLIC_PROFILES_SEARCH_INCLUDES = [
  'consultationLocations',
  'consultationLocations.address',
  'consultationLocations.phone',
  'medic',
  'medic.user',
  'medic.specialties',
];


type RateType = Entity & {
  attributes: {
    _id: string;
    code: string | null;
    description: string;
    minAmount: number;
    maxAmount: number | null;
  }
}

export enum ACHIEVEMENT_TYPE {
  degree = 'degree',
  experience = 'experience',
  publication = 'publication'
}

export type Achievement = Entity & {
  attributes: {
    _id: string;
    year: string
    maxYear: string | null;
    link: string | null;
    description: string;
    type: ACHIEVEMENT_TYPE
  }
}


export type Association = Entity & {
  attributes: {
    _id: string;
    jobTitle: string
    name: string;
  }
}


type PublicProfile = Entity & {
  attributes: {
    enabled: boolean;
    _id: string;
    canonical: string;
    website: string | null;
    description: string | null;
    profileAlert: string | null;
    conventionSector: number;
    languages: string[];
    thirdPartyPaymentType: string | null;
    paymentMethods: number[];
    allowHealthCard: boolean;
    hasFutureAvailabilities: boolean;
    hasBookableConsultationLocations: boolean;
    hasBookableEventTypes: boolean;
    enableOnlineAppointments: boolean;
    eventTypeCount?: number;
    allowNewPatients: boolean;
    displayEmail: boolean;
    displayPhoneNumber: boolean;
  },
  relationships: {
    rates: Item<RateType[]>
    degrees: Item<Achievement[]>
    publications: Item<Achievement[]>
    experiences: Item<Achievement[]>
    associations: Item<Association[]>
    skills: Item<MedicSkill[]>
    medic: Item<Medic>
    consultationLocations: Item<ConsultationLocationTemplate[]>
  }
}

export default PublicProfile;

export const GET_ITEM = (id: string, args ?: UrlArg) => buildUrl('public_profiles', id, args);
export const GET_USER_ITEM = (id: string, args ?: UrlArg) => buildUrl(`users/${parseId(id)}/public_profile`,
  undefined,
  args);
export const GET_ME = (args ?: UrlArg) => buildUrl('public_profiles', 'me', args);
export const POST_COLLECTION = (args ?: UrlArg) => buildUrl('public_profiles', undefined, args);

export const GET_IMPORT_FROM_DOCTOLIB = (args ?: UrlArg) => buildUrl('public_profile', 'import_from_doctolib', args);