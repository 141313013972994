import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { Item } from '@/packages/back-end/jsonapi';
import Patient from '@/packages/back-end/patient';
import { buildUrl } from '@/utils/Api';


const PatientContext = React.createContext<{
    patient: Patient | undefined;
    loading: boolean;
}>({
  patient: undefined,
  loading: false,
});

export const PatientProvider = ({ children } : { children : ReactNode }) => {

  const [patient, setPatient] = useState<Patient | undefined>();

  // get patientId from the url
  const { patientId } = useParams<{ patientId: string | undefined }>();

  // fetch patient from the backend
  const { fetchData,loading } = useJsonAPIRequest<Item<Patient>>({
    url: buildUrl('patients',patientId),
    method: 'get',
    skip: true,
    include: [
      'user'
    ]
  });

  useEffect(() => {

    if(!patientId) {
      setPatient(undefined);
      return;
    }

    fetchData({}, {
      displayToastOnError: true,
    }).then((data) => {

      // fckin typescript
      const element = data as Item<Patient>;
      const patient = element?.data as Patient | undefined;

      if(patient) {
        setPatient(patient);
      }
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
      setPatient(undefined);
    });


  }, [fetchData,patientId]);


  return (
    <PatientContext.Provider value={{ patient,loading }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => {
  return useContext(PatientContext);
};