import React, { useCallback, useMemo, useState } from 'react';
import UpgradeModal from '@/components/subscription/core/modals/upgrade/UpgradeModal';


const useUpgradeModal = () => {

  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState<boolean>(false);

  const upgradeModal = useMemo(() => {
    if (!isUpgradeModalVisible) {
      return null;
    }

    return (<UpgradeModal onDismiss={() => {
      setIsUpgradeModalVisible(false);
    }} />);

  }, [isUpgradeModalVisible]);

  const showUpgradeModal = useCallback(() => {
    setIsUpgradeModalVisible(true);
  },[]);

  return {
    upgradeModal,
    showUpgradeModal,
  };


};

export default useUpgradeModal;
