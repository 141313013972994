import useRequest, { UseRequestProps, Response } from '@/services/request/useRequest';

import { Profile as LegacyProfile } from '@/packages/types';
import { URLS } from '@/constants';
import useJsonAPIRequest, { UseJsonApiRequestProps } from '@/services/request/useJsonAPIRequest';
import { GET_ME } from '@/packages/back-end/profile';
import { MeMedic } from '@/packages/back-end/medic';
import { Item } from '@/packages/back-end/jsonapi';

export type UseMeProfileLegacy = (args?: Omit<UseRequestProps, 'url'>) => Response<unknown, LegacyProfile>;

const useMeProfileLegacy: UseMeProfileLegacy = (props = {}) => {
  return useRequest<LegacyProfile>({
    ...props,
    url: URLS.ME_PROFILE
  });
};

export default useMeProfileLegacy;

export type UseMeProfile = (args?: Omit<UseJsonApiRequestProps, 'url'>) => Response<unknown, Item<MeMedic>>;

export const useMeProfile: UseMeProfile = (props = {}) => {
  return useJsonAPIRequest<Item<MeMedic>>({
    ...props,
    url: GET_ME
  });
};

