import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import Drawer from '@/components/drawer/Drawer';
import { buildUrl } from '@/utils/Api';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiSelectorField from '@/components/form/fields/api-selector';
import User, {
  buildOptionWithInstitutionOrDateOfBirth, isPatient,
} from '@/packages/back-end/user';
import useApiConstant from '@/utils/hooks/useApiConstant';
import { useForm } from 'react-hook-form';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { getError, optionTypeValidator } from '@/components/form/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { OptionType } from '@ui/organisms/selectors/types';

export const DOCUMENTS_URL = (id: string) =>
  buildUrl(`${id}/documents`, undefined, {
    include: 'owner,_type',
  });

type InfoDrawerProps = {
  onClose: () => void;
  updateFilters: (users: User[]) => void;
  users?: User[];
};

const schema = yup.object({
  users: optionTypeValidator('filter_drawer.by_user_error').required(),
});


const FilterUsersDrawer = ({ onClose, updateFilters, users }: InfoDrawerProps) => {
  const { t } = useTranslation('chat');

  const { user } = useRecoilValue(authAtom);


  const { getConstant } = useApiConstant();

  const buildParticipantOption = useCallback(
    (item: User) => {
      return buildOptionWithInstitutionOrDateOfBirth(item, getConstant);
    },
    [getConstant]
  );

  const defaultUsers = useMemo(() => {
    return (users?.map(buildParticipantOption) ?? []) as OptionType<User>[];
  },[users,buildParticipantOption]);

  const { control,formState: { errors },handleSubmit, resetField } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      users: defaultUsers
    }
  });


  const buildUrlSelector = buildUrl('users', undefined, {
    context: 'discussion',
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit = useCallback(({ users }) => {

    if (window.location.hash) {
      window.history.replaceState(
        null,
        '',
        window.location.origin +
        window.location.pathname +
        window.location.search
      );
    }

    updateFilters(
      users?.map((el: { data: User }) => el.data) || []
    );

    onClose();
  },[onClose,updateFilters]);


  return (
    <Drawer title={t('filter_drawer.by_user_title')}
      onSubmit={handleSubmit(onSubmit)}
      onClose={handleClose}>
      <div className='mx-4 mt-5'>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <ApiSelectorField<User>
          url={buildUrlSelector}
          name={'users'}
          showSelectAll={false}
          closeOnSelectWhenOneOption={true}
          disableCloseOnSelect={false}
          helpText={isPatient(user) ? undefined : t('filter_drawer.by_user_help')}
          placeholder={t('filter_drawer.by_user_placeholder')}
          buildOption={buildParticipantOption}
          multiple={true}
          error={getError('users',errors,t)}
          apiSearch={true}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          control={control}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
        />

        <div className='flex flex-row justify-end mt-5 space-x-2 w-full'>
          <Button onClick={onClose} type='button'
            color='secondary'>
            {t('cancel')}
          </Button>
          <Button
            type='submit'
            color='primary'
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const useFilterUsersDrawer = () => {

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const { openDrawer, closeDrawer } = useDrawerManager();

  const onClick = useCallback(
    ({ updateFilters }) => {

      openDrawer({
        content: (
          <FilterUsersDrawer
            onClose={closeDrawer}
            updateFilters={(users : User[]) => {
              updateFilters(users.map((el) => el.id));
              setSelectedUsers(users);
            }}
            users={selectedUsers}
          />
        ),
        size: 'basic',
      });
    },
    [selectedUsers,closeDrawer, openDrawer]
  );

  return { onClick };
};

export default useFilterUsersDrawer;
