import React, { FC, ReactNode } from 'react';

import { LayoutProvider } from './LayoutContext';
import AuthLayout from './AuthLayout';
import MainLayout from './MainLayout';
import Toasts from '@/components/toasts/Toasts';

interface LayoutProps {
  type: 'auth' | 'main' | 'public';
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ type, children }) => {
  return (
    <LayoutProvider>
      <Toasts />
      {type === 'auth' && <AuthLayout>{children}</AuthLayout>}
      {type === 'main' && <MainLayout>{children}</MainLayout>}
      {type === 'public' && <MainLayout publicLayout>{children}</MainLayout>}
    </LayoutProvider>
  );
};

export default Layout;
