import React from 'react';
import { useTranslation } from 'react-i18next';
import TablerSearch, { TablerSearchType } from '@ui/molecules/tabler-search/TablerSearch';

const SearchField = (props : TablerSearchType) => {
  const { t } = useTranslation('chat');
  return (
    <TablerSearch
      placeholder={t('search')}
      {...props}
    />
  );
};

export default SearchField;
