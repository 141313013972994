import { useCallback } from 'react';

export const BOOK_MEETING_STEPS_WRAPPER_ID = 'book-meeting-steps-wrapper';

export const useScrollToHideStep = (stepWrapperId: string) => {
  const onScrollToHideStep = useCallback(() => {
    // setTimeout: wait for "Step" updated state
    setTimeout(() => {
      const wrapperElement = document.getElementById(
        BOOK_MEETING_STEPS_WRAPPER_ID
      );
      const stepWrapperElement = document.getElementById(stepWrapperId);

      if (wrapperElement && stepWrapperElement) {
        const wrapperRect = wrapperElement.getBoundingClientRect();
        const childRect = stepWrapperElement.getBoundingClientRect();
        const scrollAmount = childRect.bottom - wrapperRect.top;

        wrapperElement.scrollBy({ top: scrollAmount, behavior: 'smooth' });
      }
    }, 100);
  }, [stepWrapperId]);

  return { stepWrapperId, onScrollToHideStep };
};
