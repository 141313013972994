import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import Event from '@/packages/back-end/event';
import { Item } from '@/packages/back-end/jsonapi';
import Address from '@/packages/back-end/address';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { authAtom } from '@/services/store/store';

import { buildUrl } from '@/utils/Api';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import Button from '@ui/atoms/buttons/button/Button';
import Picto from '@ui/atoms/icons/picto/Picto';
import DateFormat from '@/components/date-format/dateFormat';

import DoctorInformationCard from './DoctorInformationCard';
import { SelectEventStepData } from './SelectEventStep';
import { SelectSlotStepData } from './SelectSlotStep';
import { flatMedicProfileRelationships } from '../utils';
import { useBookMeeting } from '../BookMeetingContext';
import BaseSkeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import { useScrollToHideStep } from '../useScrollToHideStep';
import useToast from '@/utils/hooks/useToast';
import { RequestError } from '@/services/request/utils';

export type ConfirmMeetingStepData = {
  event?: Event;
};


const Skeleton = () => {
  return (
    <div className='flex flex-col gap-4 w-full'>
      <DoctorInformationCard loading={true} />
      <div className='flex flex-col gap-2 mt-2 mb-4 ml-2'>
        <div className='flex gap-4 items-center text-xs'>
          <BaseSkeleton className='w-6' round={true}
            size='base' />
          <BaseSkeleton className='w-60' size='small' />
        </div>
        <div className='flex gap-4 items-center text-xs'>
          <BaseSkeleton className='w-6' round={true}
            size='base' />
          <BaseSkeleton className='w-60' size='small' />
        </div>
        <div className='flex gap-4 items-center text-xs'>
          <BaseSkeleton className='w-6' round={true}
            size='base' />
          <BaseSkeleton className='w-60' size='small' />
        </div>
        <div className='flex gap-4 items-center text-xs'>
          <BaseSkeleton className='w-6' round={true}
            size='base' />
          <BaseSkeleton className='w-60' size='small' />
        </div>
        <div className='flex justify-center mt-4'>
          <BaseSkeleton className='w-[80%] rounded' size='base' />
        </div>
      </div>
    </div>);
};

type ConfirmMeetingStepProps = {
  stepNumber: number;
  open: boolean;
  onToggle: (open: boolean) => void;
  currentEvent?: Event;
  data: ConfirmMeetingStepData;
  setData: React.Dispatch<React.SetStateAction<ConfirmMeetingStepData>>;
  selectEventStepData: SelectEventStepData;
  selectSlotStepData: SelectSlotStepData;
  onDone: (data: ConfirmMeetingStepData) => void;
};

const ConfirmMeetingStep: FC<ConfirmMeetingStepProps> = ({
  stepNumber,
  open,
  currentEvent,
  onToggle,
  data,
  setData,
  selectEventStepData,
  selectSlotStepData,
  onDone,
}) => {
  const {
    profileDoctor,
    consultationLocation,
    isBookMeetingCreated,
    onBookMetingCreated,
  } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } = useScrollToHideStep(
    'confirm_meeting_step'
  );

  const { addToast } = useToast();

  const { user: meUser } = useRecoilValue(authAtom);
  const { user: doctorUser } = flatMedicProfileRelationships(profileDoctor);

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { eventType } = selectEventStepData;
  const { bookingSlot } = selectSlotStepData;

  const { fetchData: bookMeetingPhone, loading: bookingMeeting } =
    useJsonAPIRequest({
      skip: true,
      method: currentEvent ? 'put' : 'post',
      url: buildUrl('appointments', currentEvent?.id, {
        include: '_type,consultationLocation.address,consultationLocation,participants',
      }),
    });

  const onSubmit = async () => {

    try {
      const eventData = (await bookMeetingPhone(
        {
          event_type: eventType?.id,
          owner: doctorUser?.id,
          dateBegin: bookingSlot?.attributes?.dateBegin,
        },
        { throw: true }
      )) as Item<Event>;

      setData({ event: eventData?.data });
      onScrollToHideStep();
      onBookMetingCreated();
      onDone({ event: eventData?.data });
    } catch(e) {

      const error = e as RequestError;

      if(error?.violations?.dateBegin) {
        addToast('danger',error?.violations?.dateBegin);
      } else {
        addToast('danger', error.message);
      }
    }
  };

  const consultationLocationAddress = consultationLocation?.relationships
    ?.address?.data as Address | undefined;

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.event}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={data.event ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('confirm_meeting_step.title')}`}
      subtitle={!!data.event && t('confirm_meeting_step.subtitle')}
      onToggle={onToggle}
      wrapperTestId='confirm_meeting_step'
    >
      {profileDoctor && (<div className='flex flex-col gap-4 w-full'>
        <DoctorInformationCard consultationLocation={consultationLocation} profileDoctor={profileDoctor} />

        <div className='flex flex-col gap-4 mt-2 mb-4 ml-2'>
          <div className='flex items-center text-xs'>
            <div className='flex justify-center w-6'>
              <Picto
                icon='user'
                iconColor='#5f717e'
              />
            </div>
            <span className='ml-2'>{t('confirm_meeting_step.for')}</span>
            <span className='ml-1' data-testid='confirm_meeting_step.patient_name'>
              {meUser?.attributes?.fullName}
            </span>
          </div>

          <div className='flex items-center text-xs'>
            <div className='flex justify-center w-6'>
              <Picto
                className='text-gray-700'
                icon='groupWork'
              />
            </div>
            <span className='ml-2' data-testid='confirm_meeting_step.event_type_name'>
              {eventType?.attributes?.name}
            </span>
          </div>

          <div className='flex items-center text-xs'>
            <div className='flex justify-center w-6'>
              <Picto
                className='text-gray-700'
                icon='outlineEvent'
              />
            </div>
            <span className='flex flex-col ml-2' data-testid='confirm_meeting_step.date'>
              <DateFormat value={bookingSlot?.attributes?.dateBegin} />
              {currentEvent && (<span className='text-gray-500 line-through'>
                <DateFormat value={currentEvent?.attributes?.dateBegin} />
              </span>)}
            </span>
          </div>

          {!!consultationLocationAddress && (
            <div className='flex items-center text-xs'>
              <div className='flex justify-center w-6'>
                <Picto
                  className='text-gray-700'
                  icon='location'
                  iconSize={20}
                />
              </div>
              <span className='ml-2' data-testid='confirm_meeting_step.location'>
                {consultationLocationAddress.attributes.displayAddress}
              </span>
            </div>
          )}
        </div>

        {!data.event && (
          <Button
            saving={bookingMeeting}
            color='primary'
            icon='outlineEvent'
            onClick={onSubmit}
            data-testid='confirm_meeting_step.confirm_button'
          >
            {t('confirm_meeting_step.confirm_button')}
          </Button>
        )}
      </div>)}
      {!profileDoctor && (<Skeleton />)}
    </RetractableItem>
  );
};

export default ConfirmMeetingStep;
