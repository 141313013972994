import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, { useState } from 'react';
import { AudioPlayer } from '../audio/AudioPlayer';
import Image from 'instamed-styleguide/ui/atoms/images/image/Image';
import Document from '@/packages/back-end/document';
import { Thumbnails } from '@/packages/back-end/document';
import User from '@/packages/back-end/user';
import { Item, Relationship } from '@/packages/back-end/jsonapi';
import cls from 'classnames';
import DocumentModal from '@/components/documents/DocumentModal';
import Button from '@ui/atoms/buttons/button/Button';

const PDF_TYPE = 'application/pdf';

type DocumentRowProps = {
  document: Document;
  removeDocument?: () => void;
  owner?: Item<User> | Item<Relationship>;
};

export const DocumentThumbnail = ({
  document,
  onClick,
  className,
}: {
  document: {
    fileName: string;
    thumbnails?: Thumbnails;
  };
  onClick: () => void;
  className?: string;
}) => {
  const src = document?.thumbnails?.fileUrl?.['300x*'];
  if (!src) {
    return <></>;
  }
  return (
    <button onClick={onClick} type='button'
      className={cls('overflow-hidden', className)}>
      <Image alt={document.fileName as string} src={src}
        className='object-fill max-w-full max-h-full'
      />
    </button>
  );
};

const DocumentRow = ({
  document,
  removeDocument,
  owner
}: DocumentRowProps) => {
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const documentData = document.attributes;
  if (documentData.mimeType.indexOf('audio/') > -1) {
    return (
      <AudioPlayer
        key={`message-row-document-${document.id}`}
        audio={documentData}
      />
    );
  }

  return (
    <div className={cls('flex flex-col justify-start')}
      onDragStart={(e) => {
        e.preventDefault();
      }}>
      <div className={cls('py-1 max-w-[300px] px-2 bg-white rounded-md ' +
        'self-end border border-gray-100 flex gap-1')}>
        {/* Do not display in the sending mode if not a PDF */}
        {(documentData.mimeType === PDF_TYPE || !removeDocument) && (<DocumentThumbnail
          document={documentData}
          onClick={() => setOpenPreview(true)}
          className={cls('my-1 w-max-[75%] max-h-[100px] rounded-t-lg', {
            'w-max h-[50px] mr-3 border-gray-100 border rounded-none flex-shrink-0': documentData.mimeType === PDF_TYPE
          })}
        />)}
        <div className='overflow-hidden' >
          <button
            onClick={() => setOpenPreview(true)} type='button'
            className={'text-sm text-gray-900 truncate'}>
            {documentData.title}
          </button>
          <div className='flex flex-row justify-between items-center'>
            {owner && <p className={'text-[12px] !text-left text-gray-400-09 truncate'}>
              {owner?.data?.attributes?.fullName} • {document.relationships?._type?.data?.attributes?.name}
            </p>}
            <div className='flex flex-row items-center'>
              {documentData.downloadUrl && (<a className='py-1 px-1' download
                href={documentData.downloadUrl}>
                <Icon icon='download' color='#9ca7af' />
              </a>)}
              <button className='py-2 px-1'
                onClick={() => setOpenPreview(true)}>
                <Icon icon='eye' color='#9ca7af' />
              </button>
            </div>
            {openPreview && (
              <DocumentModal
                onDismiss={() => setOpenPreview(false)}
                url={documentData.previewUrl}
                title={documentData.title}
              />
            )}
          </div>
        </div>
        {!!removeDocument && (<Button
          icon='close'
          color='transparent'
          onClick={removeDocument}
        />)}
      </div>
    </div>
  );
};

export default DocumentRow;
