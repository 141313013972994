import React, { FC, useMemo } from 'react';
import cls from 'classnames';

import Notification from '@/packages/back-end/notification';

import OverflowContainer from '@ui/atoms/containers/overflow-container/OverflowContainer';

import NotificationItem from '../notification-item/NotificationItem';
import LoadMoreSpinner from '@ui/molecules/load-more/LoadMoreSpinner';
import Empty from '@ui/molecules/empty/Empty';

export type NotificationsListType = {
  archived: boolean;
  hasMorePages?: boolean;
  items: Notification[];
  onLoadMore?: () => void;
  onUpdated?: (item: Notification) => void;
  onDeleted?: (item: Notification) => void;
  onItemClick: (item: Notification) => void;
  className?: string;
};

const NotificationsList: FC<NotificationsListType> = ({
  archived,
  hasMorePages,
  items,
  onUpdated,
  onDeleted,
  onItemClick,
  onLoadMore,
  className,
}) => {
  const itemsList = useMemo(() => {
    if (items.length === 0) {
      return <Empty message='ui.notifications.noData' />;
    }

    return items.map((item) => {
      return (
        <NotificationItem
          key={item.id}
          archived={archived}
          item={item}
          onUpdated={onUpdated}
          onDeleted={onDeleted}
          onItemClick={onItemClick}
        />
      );
    });
  }, [items, archived, onItemClick, onUpdated, onDeleted]);

  return (
    <OverflowContainer className={cls('flex flex-col gap-2 h-full', className)}>
      {itemsList}
      {hasMorePages && !!onLoadMore ? <LoadMoreSpinner onLoadMore={onLoadMore} /> : null}
    </OverflowContainer>
  );
};

export default NotificationsList;
