import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import Patient from '@/packages/back-end/patient';
import { buildUrl, UrlArg } from '@/utils/Api';

export type Attachment = Entity & {

  attributes: {
    _id: string,
    name: string,
    mimeType: string,
    path: string,
    previewUrl: string
    downloadUrl: string
  },
  relationships : {
    document ?: Item<Relationship|Document>
    matchingPatients ?: Item<Patient[]>|Item<Relationship[]>
  }
}

type Recipient = {
  name : string;
  email : string
}


type Email = Entity & {
  selected: boolean;
  attributes: {
    createdDate: string;
    subject: string;
    from: Recipient;
    emailType : 'sent' | 'received';
    attachmentCount: number;
    to: Recipient[];
    cc: Recipient[];
    bcc?: Recipient[];
    archived: boolean,
    read: boolean,
    bodyHTML?: string
    bodyText?: string
  },
  relationships: {
    attachments: Item<Relationship[] | Attachment[]>
  }
}

export default Email;

export const GET_REFRESH_URL = (args ?: UrlArg) => buildUrl('ms_sante_emails','refresh',args);