import React, { FC, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { MeUser } from '@/packages/back-end/user';
import { authAtom } from '@/services/store/store';

import i18n from '@/i18n';

dayjs.extend(utc);
dayjs.extend(timezone);

export const correctFormatDateByLocale = (format = '') => {
  if (i18n.language === 'en' || i18n.language.startsWith('en-')) {
    return format?.replace(/\[\s?à\s?\]|\sà\s|à/g, ' [at] ');
  }

  return format;
};

export const formatDate = (
  value?: Date | string | number | null,
  options?: {
    format?: string;
    user?: MeUser;
  }
) => {
  if (!value) {
    return '';
  }

  const locale = i18n.language || 'fr';
  const format = correctFormatDateByLocale(options?.format || 'dddd DD MMMM à HH[h]mm');
  const user = options?.user;
  if (locale) {
    dayjs.locale(locale);
  }

  return dayjs(value)
    .tz(
      user?.attributes?.timezone ||
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    .format(format);
};

type DateFormatProps = HTMLAttributes<HTMLSpanElement> & {
  value?: Date | string | number | null;
  format?: string;
};

const DateFormat: FC<DateFormatProps> = ({
  value,
  format = 'dddd DD MMMM à HH[h]mm',
  ...props
}) => {
  const { user } = useRecoilValue(authAtom);

  let displayValue = formatDate(value, { format, user });

  if (displayValue) {
    displayValue =
      displayValue.slice(0, 1).toUpperCase() + displayValue.slice(1);
  }

  return <span {...props}>{displayValue}</span>;
};

export default DateFormat;
