import React, { FC } from 'react';
import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import HeaderPage from '@/components/header-page/HeaderPage';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import MetabaseIframe from '@/components/iframe/MetabaseIframe';
import { MeUser } from '@/packages/back-end/user';
import Institution from '@/packages/back-end/Institution';
import { Item } from '@/packages/back-end/jsonapi';
import P from '@ui/atoms/texts/base/P';
import { ROLE } from '@/packages/types';
import Button from '@ui/atoms/buttons/button/Button';
import useExportDataDrawer from '@/app/structure/page/admin/dashboard/export-data/useExportDataDrawer';

type ResponseType = {
  url: string
}

const URL = buildUrl('admins/statistics');


export const getCurrentInstitution = (user : MeUser) : Institution | undefined => {

  const institutions = user.relationships.institutions as Item<Institution[]>;

  return institutions.data.find((institution : Institution) => institution.attributes.current);

};

const Dashboard: FC = () => {


  const { t } = useTranslation('admin', {
    keyPrefix: 'dashboard'
  });

  const { onClick } = useExportDataDrawer();

  const { data,error } = useRequest<ResponseType>({
    url: URL,
    method: 'GET'
  });

  const { user } = useRecoilValue(authAtom);

  const institution = getCurrentInstitution(user as MeUser);

  return (
    <>
      <HeaderPage
        options={{
          user: user?.attributes.firstName
        }}
        title={t('header.title', {
          user: user?.attributes.firstName
        })}>
        {user?.attributes.role === ROLE.ADMIN && (<Button
          icon='download'
          onClick={onClick}>{(t('buttons.export_data'))}</Button>)}
      </HeaderPage>

      {institution && (<P>{t('subtitle',{
        institution: institution?.attributes.name
      })}</P>)}

      <MetabaseIframe
        url={data?.url}
        error={!!error}
        title={t('header.title', {
          user: user?.attributes.firstName
        }) as string}
      />
    </>
  );

};

export default Dashboard;

Dashboard.displayName = 'Dashboard';