import React, {
  ReactNode,
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps } from '@ui/atoms/buttons/button/Button';
import { NotConnectedNavigationBarProps } from '@ui/organisms/navigation-bar/NotConnectedNavigationBar';

type LogoProps = NotConnectedNavigationBarProps['logo'];

type LayoutContextProps = {
  hideLayout?: boolean;
  setHideLayout: (hideLayout: boolean) => void;
  actions?: ReactNode;
  setActions: (actions?: ReactNode) => void;
  logoProps?: LogoProps;
  setLogoProps: (logoProps?: LogoProps) => void;
  cancelBtnProps?: ButtonProps;
  setCancelBtnProps: (cancelBtnProps?: ButtonProps) => void;
};

const LayoutContext = createContext<LayoutContextProps>({
  hideLayout: undefined,
  setHideLayout: () => {
    throw new Error('setHideLayout not implemented');
  },
  actions: undefined,
  setActions: () => {
    throw new Error('setActions not implemented');
  },
  logoProps: undefined,
  setLogoProps: () => {
    throw new Error('setLogoProps not implemented');
  },
  cancelBtnProps: undefined,
  setCancelBtnProps: () => {
    throw new Error('setCancelBtnProps not implemented');
  },
});

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [hideLayout, setHideLayout] = useState(false);
  const [actions, setActions] = useState<ReactNode | undefined>();
  const [logoProps, setLogoProps] = useState<LogoProps | undefined>();
  const [cancelBtnProps, setCancelBtnProps] = useState<
    ButtonProps | undefined
  >();

  return (
    <LayoutContext.Provider
      value={{
        hideLayout,
        setHideLayout,
        actions,
        setActions,
        logoProps,
        setLogoProps,
        cancelBtnProps,
        setCancelBtnProps,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = (options?: {
  hide?: boolean;
  actions?: ReactNode;
  logo?: LogoProps;
  cancelBtn?: ButtonProps;
}) => {
  const { ready } = useTranslation('public', {
    keyPrefix: 'search_doctors.search_bar',
  });

  const context = useContext(LayoutContext);
  const { setHideLayout, setActions, setLogoProps, setCancelBtnProps } =
    context;

  const keepRef = useRef({ options });
  keepRef.current.options = options;

  useLayoutEffect(() => {
    if (keepRef.current.options?.hide) {
      setHideLayout(true);
    }

    return () => {
      setHideLayout(false);
    };
  }, [setHideLayout]);

  useLayoutEffect(() => {
    if (ready) {
      setActions(options?.actions);
    }

    return () => {
      setActions();
    };
  }, [options?.actions, ready, setActions]);

  useLayoutEffect(() => {
    if (ready) {
      setLogoProps(options?.logo);
    }

    return () => {
      setLogoProps();
    };
  }, [options?.logo, ready, setLogoProps]);

  useLayoutEffect(() => {
    if (keepRef.current.options?.cancelBtn) {
      setCancelBtnProps(keepRef.current.options?.cancelBtn);
    }

    return () => {
      setCancelBtnProps();
    };
  }, [setCancelBtnProps]);

  return context;
};
