import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  Dispatch, SetStateAction
} from 'react';
import Patient from '@/packages/back-end/patient';
import Message from '@/packages/back-end/message';
import DocumentType from '@/packages/back-end/document-type';
import PDFTemplate from '@/packages/back-end/pdf-template';
import { buildUrl } from '@/utils/Api';
import useGetPaginatedCollection from '@/utils/hooks/usePaginatedData';
import { addElementToCollection, sortCollection } from '@/app/structure/page/chat/utils';
import Document from '@/packages/back-end/document';

export type MedicalDocumentsProps = {
  name: string;
  createdDate?: Date | null;
  patient: string;
  fileUrl : string;
  mimeType: string;
  type: DocumentType;
  template: PDFTemplate;
  previewUrl: string;
};

export const initialFileState = {
  medicalDocuments: [],
  attachments: [],
  audio: []
};

export type MessageFiles = {
  medicalDocuments: MedicalDocumentsProps[];
  audio: Blob[];
  attachments: File[];
};

type MessagesContextProps = {
  // discussion's messages
  messages: Message[];
  addMessage: (message : Message) => boolean;
  removeMessage: (message : Message) => void;
  setMessages: Dispatch<SetStateAction<Message[]>>
  onReload: () => void;
  // files to send
  files: MessageFiles;
  setFiles: (files: MessageFiles) => void;
  // if tagged patient
  loading?: boolean,
  patient: Patient | null;
  setPatient: (value: Patient | null) => void;
  // if signature true, send signature each message
  setSignature: (value: string | null) => void;
  signature: string | null;
  //
  setSendingMessage: (value: boolean) => void;
  documents : Document[],
  setDocuments: Dispatch<SetStateAction<Document[]>>
  onPaginate: () => void,
  search?: string,
  setSearch: (value : string | undefined) => void;
  currentPage?: number,
  sendingMessage: boolean;
}

const MessagesContext = createContext<MessagesContextProps>({
  addMessage(): boolean {
    throw new Error('addMessage not implemented');
  }, removeMessage(): void {
    throw new Error('removeMessage not implemented');
  },
  onReload() : void {
    throw new Error('onReload is not implemented');
  },
  onPaginate() : void {
    throw new Error('onPaginate is not implemented');
  },
  setSearch() : void {
    throw new Error('setSearch is not implemented');
  } ,
  files: initialFileState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFiles: (_documents: MessageFiles) => {
    throw new Error('setCreateDocuments not implemented');
  },
  messages: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMessages: (): void => {
    throw new Error('setMessages not implemented');
  },
  patient: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPatient: (_value: Patient | null) => {
    throw new Error('setPatient not implemented');
  },
  signature: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSignature: (_value: string | null) => {
    throw new Error('setSignature not implemented');
  },
  sendingMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSendingMessage: (_value: boolean) => {
    throw new Error('setSendingMessage not implemented');
  },
  documents: [],
  setDocuments: () => {
    throw new Error('setDocuments not implemented');
  }

});

export const MessagesProvider = ({ children,discussionId }: { children: ReactNode, discussionId ?: string }) => {
  const [files, setFiles] = useState<MessageFiles>(initialFileState);
  const [sendingMessage, setSendingMessage] = useState<boolean>(false);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [documents,setDocuments] = useState<Document[]>([]);

  const [search, setSearch] = useState<string | undefined>();

  const MESSAGES_URL = useCallback(() =>
    buildUrl(`${discussionId}/messages`, undefined, {
      include:
        'owner,patient,patient.user,documents,documents._type',
      search,
      '_order[createdDate]': 'DESC',
    }),[search,discussionId]);

  const {
    onPaginate,
    loading,
    currentPage,
    setData: setMessages,
    data,
    onReload,
  } = useGetPaginatedCollection<Message>(
    MESSAGES_URL(),
    {},
    {},
    { skip: !discussionId },
    []
  );

  useEffect(() => {
    if(!discussionId) {
      return;
    }
    onReload();
  }, [onReload,discussionId]);


  const addMessage = useCallback((message : Message) => {

    if(discussionId !== (message.relationships.discussion?.data.id)) {
      return false;
    }

    setMessages((prev) => addElementToCollection(prev,message));

    return true;

  },[discussionId,setMessages]);



  const removeMessage = useCallback((message : Message) => {

    const newMessages = data.filter((item : Message) => {
      if(item.id === message.id) {
        return false;
      }

      if(!message.attributes.tmpId) {
        return true;
      }

      return message.attributes.tmpId !== item.attributes.tmpId;
    });

    setMessages(newMessages);

    addElementToCollection(data,message);
  },[setMessages,data]);


  const sortedData = useMemo(() => {
    return sortCollection(data,'createdDate','asc');
  },[data]);

  return (
    <MessagesContext.Provider
      value={{
        files,
        addMessage,
        removeMessage,
        loading,
        onReload,
        setFiles,
        messages: sortedData,
        onPaginate,
        setSearch,
        search,
        currentPage,
        patient,
        setPatient,
        setMessages,
        signature,
        setSignature,
        sendingMessage, 
        setSendingMessage,
        documents,
        setDocuments
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessageContext = () => useContext(MessagesContext);
