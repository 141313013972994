import { OptionType } from '@ui/organisms/selectors/types';


export function parseSimpleSelectorValue(fieldData: undefined | null | OptionType[] | OptionType, asInt = false)
  : string | number | null {

  if (typeof fieldData === 'undefined' || null === fieldData) {
    return null;
  }


  if (Array.isArray(fieldData)) {

    if (!fieldData?.[0]?.value) {
      return null;
    }

    return asInt ? parseInt(fieldData?.[0]?.value) : fieldData?.[0]?.value;
  }

  if (!fieldData?.value) {
    return null;
  }

  return asInt ? parseInt(fieldData?.value) : fieldData?.value;

}


export function parseNullableString(string: string | undefined): string | null {
  return string === '' ? null : string?.toString() ?? null;
}


export const slugify = (text: string | undefined): string => {

  if (typeof text === 'undefined') {
    return '';
  }

  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '_');
};


export function parseMultipleSelectorValue
<T extends string | number>(fieldData: undefined | null | OptionType[], asInt = false)
  : T[] {
  if (!fieldData || fieldData.length === 0) {
    return [];
  }

  return fieldData.map((item) => asInt ? parseInt(item.value) : item.value) as T[];
}

export function parseTextFieldValueToNumber(fieldData: null | undefined | string): null | number {

  if (null === fieldData || undefined === fieldData) {
    return null;
  }

  fieldData = parseNullableString(fieldData);

  if (null === fieldData) {
    return null;
  }

  const value = parseFloat(fieldData);

  return isNaN(value) ? null : value;
}

/**
 *
 * @param email
 * @return {*}
 */
export function isFakeEmail(email : string | null | undefined) : boolean {

  if (email === null || email === undefined) {
    return true;
  }

  return email.match(/^[0-9]+@instamed\.fr/) !== null;
}
