import React from 'react';

import Modal from '@ui/molecules/modal/Modal';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { buildLegacyUrl, URLS as LEGACY_URLS } from '@/utils/Legacy';
import { TYPE } from '@/packages/back-end/subscription-group';
import P from '@ui/atoms/texts/base/P';
import LinkButton from '@/components/link/LinkButton';

type UpgradeModalProps = {
  onDismiss : () => void;
  type ?: TYPE
}

const UpgradeModal : FC<UpgradeModalProps> = ({ onDismiss, type = TYPE.core }) => {

  const { t } = useTranslation('subscription',{
    keyPrefix: 'upgrade_modal'
  });

  return (
    <Modal data-testid='upgrade-modal' onDismiss={onDismiss}
      title={t('title')}

      footer={<LinkButton
        data-testid='invoice_link'
        color='primary'
        className='w-full'
        href={buildLegacyUrl(LEGACY_URLS.doctor.update_subscription)}
      >
        {t('button')}</LinkButton>}
    >
      <P>{t(`subtitle.${type}`)}</P>
    </Modal>);

};

export default UpgradeModal;
