import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { attachErrorsToFields, RequestError } from '@/services/request/utils';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import TextField from '@ui/atoms/fields/text/TextField';

import { getError } from '@/components/form/utils';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { useBookMeeting } from '../../BookMeetingContext';

type FormDataType = {
  email: string;
};

const schema = yup.object({
  email: yup.string().required('field_required'),
});

export type EnterEmailStepData = {
  email?: string;
  isExisting?: boolean;
  isEnabled?: boolean;
};

type EnterEmailStepProps = {
  stepNumber: number;
  open: boolean;
  data: EnterEmailStepData;
  setData: React.Dispatch<React.SetStateAction<EnterEmailStepData>>;
  onToggle: (open: boolean) => void;
  onDone: () => void;
};

const EnterEmailStep: FC<EnterEmailStepProps> = ({
  stepNumber,
  open,
  data,
  setData,
  onToggle,
  onDone,
}) => {
  const { addToast } = useToast();

  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('enter_email_step');

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const {
    fetchData: checkEmail,
    loading: saving,
    error: backErrors,
  } = useJsonAPIRequest({
    method: 'post',
    skip: true,
    url: buildUrl('users/check_email'),
  });

  const {
    formState: { errors },
    setError,
    register,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await checkEmail({ email }, { throw: true });

      setData({ email, isExisting: false, isEnabled: false });
      onScrollToHideStep();
      onDone();
    } catch (e) {
      const error = e as RequestError;
      const errorData = error.response?.data;
      if (error.code === 409) {
        setData({ email, isExisting: true, isEnabled: !!errorData.enabled });
        onScrollToHideStep();
        onDone();
      } else {
        addToast('danger', error.message);
      }
    }
  });

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
  }, [backErrors, setError]);


  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.email}
      status={(data.email) ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('enter_email_step.title')}`}
      subtitle={data.email}
      onToggle={onToggle}
      wrapperTestId='enter_email_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <TextField
          {...register('email')}
          placeholder={t('enter_email_step.fields.email_placeholder')}
          error={getError('email', errors, errorT)}
        />

        <Button
          type='submit'
          color='primary'
          saving={saving}
          data-testid='enter_email_step.continue'
        >
          {t('enter_email_step.continue')}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default EnterEmailStep;
