import React, { useCallback } from 'react';


import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';

import SearchDoctorBookMeetingDrawer from './SearchDoctorBookMeetingDrawer';
import { BookMeetingDefaultData } from '../../page/public/doctor-profile/book-meeting-drawer/BookMeetingContext';

const useSearchDoctorBookMeetingDrawer = (options?: { hideSearch?: boolean }) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onOpen = useCallback(
    (defaultData?: BookMeetingDefaultData) => {
      openDrawer({
        content: (
          <SearchDoctorBookMeetingDrawer
            defaultData={defaultData}
            hideSearch={options?.hideSearch}
            onClose={closeDrawer}
          />
        ),
        size: 'medium',
        overlayClickClose: false,
      });
    },
    [options, openDrawer, closeDrawer]
  );

  return { onOpen, closeDrawer };
};

export default useSearchDoctorBookMeetingDrawer;
