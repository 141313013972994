import User, { MeUser } from '@/packages/back-end/user';
import { MessageFiles } from '../messages/MessageContext';
import Patient from '@/packages/back-end/patient';
import { FetchDataType } from '@/services/request/useRequest';
import UploadedFile from '@/packages/back-end/uploaded-file';
import { InstaFileInfosProps } from '@/app/structure/page/chat/documents/AttachDocumentDrawer';
import Document, { VISIBILITY } from '@/packages/back-end/document';
import DocumentType from '@/packages/back-end/document-type';
import { Item } from '@/packages/back-end/jsonapi';
import Message from '@/packages/back-end/message';

export type DocumentForMessage = {
  title: string;
  visibility: number;
  mimeType: string;
  type?: DocumentType;
  previewUrl ?: string;
  fileUrl?: string;
  createdDate?: string;
  blob: Blob;
};

type UploadFilesAndGetDocumentsProps = {
  files : MessageFiles,
  uploadFiles : FetchDataType<FormData, UploadedFile[]>
  instaFileInfos ?: InstaFileInfosProps
}

export async function uploadFilesAndGetDocuments({
  files,
  uploadFiles,
  instaFileInfos
} : UploadFilesAndGetDocumentsProps) {
  let documents : DocumentForMessage[] = [];

  if (files.audio?.length) {
    const formData = new FormData();
    files.audio.forEach(audio => formData.append('file[]', audio, 'vocalMessageRecord.wav'));
    const uploadedRes = await uploadFiles(formData, { throw: true, displayToastOnError: true }) as UploadedFile[];

    if (uploadedRes) {
      uploadedRes.forEach((doc, idx) => {
        documents.push({
          title: doc.name,
          visibility: 2,
          mimeType: doc.mimeType,
          previewUrl: doc.previewUrl,
          fileUrl: doc.key,
          createdDate: new Date().toISOString(),
          blob: files.audio[idx],
        });
      });
    }
  }

  if (files.attachments?.length) {
    const formData = new FormData();
    files.attachments.forEach(doc => formData.append('file[]', doc));
    const uploadedRes = await uploadFiles(formData) as UploadedFile[];

    if (uploadedRes) {
      const promises = uploadedRes.map(async (doc, idx) => {
        const imgBuffer = await files.attachments[idx].arrayBuffer();
        const imgBlob = new Blob([imgBuffer]);

        return {
          title: doc.name,
          visibility: VISIBILITY.PRIVATE,
          mimeType: doc.mimeType,
          type: instaFileInfos?.type?.data,
          previewUrl: doc.previewUrl,
          fileUrl: doc.key,
          createdDate: instaFileInfos?.createdDate,
          blob: imgBlob,
        };
      });

      documents = [...documents, ...(await Promise.all(promises))];
    }
  }

  return documents;
}


type CreateMessageToSendProps = {
  discussionId : string,
  content : string,
  signature?: string | null,
  user ?: MeUser,
  files : MessageFiles,
  tmpId : string,
  patient ?: Patient | null,
  createdDate?: string | null,
  newDocuments?: DocumentForMessage[],
  documents: Document[],
}

const createMessageToSend = ({
  discussionId,
  documents = [],
  content,
  signature,
  user,
  tmpId,
  patient,
  files,
  createdDate,
  newDocuments = []
} : CreateMessageToSendProps) => {
  const message = {
    tmpId,
    discussion: discussionId,
    medicalDocuments: files.medicalDocuments?.map(({ type,template,...rest }) => ({
      type: type ? type.id : undefined,
      template: template.id,
      ...rest,
    })),
    content: `${content ?? ''}${signature ? `\n\n${signature}` : ''}`,
    owner: user?.id,
    patient: patient?.id,
    createdDate,
    documents: [
      ...documents.map((item) => item.id),
      ...newDocuments?.map(({ type,...rest }) => ({
        type: type ? type.id : undefined,
        ...rest,
      }))
    ],
  };

  const hasDocuments = files.audio.length || files.attachments.length;


  const proxy = {
    id: tmpId,
    type: 'message',
    attributes: {
      tmpId,
      _id: tmpId,
      automatic: false,
      read: [],
      scheduled: !!createdDate,
      content: message.content,
      createdDate: createdDate ?? new Date().toISOString(),
    },
    relationships: {
      ...(patient && { patient: { data: patient } }),
      owner: { data: user } as Item<User>,
      discussion: {
        data: {
          type: 'discussion',
          id: discussionId
        }
      },
      documents: {
        data: [
          ...documents,
          ...newDocuments.map(({ type,...doc }) => ({
            attributes: { ...doc, fileUrl: URL.createObjectURL(doc.blob) },
            relationships: {
              _type: type ? { data: type } : undefined
            }
          } as unknown as Document))],
      } as Item<Document[]>,
    },
  } as Message;

  if(message.medicalDocuments) {

    files.medicalDocuments.forEach(({ type,...rest }) => {
      proxy.relationships.documents?.data.push({
        attributes: {
          title: rest.name,
          mimeType: rest.mimeType,
          previewUrl: rest.previewUrl,
          visibility: VISIBILITY.PUBLIC,
          createdDate: rest.createdDate?.toISOString(),
          fileUrl: rest.fileUrl,
          blob: new Blob()
        },
        relationships: {
          _type: type ? { data: type } : undefined
        }
      } as unknown as Document);
    });

  }


  return { message, proxy, hasDocuments };
};

export default createMessageToSend;