import React from 'react';
import Skeleton from 'instamed-styleguide/ui/atoms/loaders/skeleton/Skeleton';

export const DiscussionLoader = () => {
  return <div
    className='flex flex-col mb-2'>
    <div className='flex flex-row justify-between mt-3 h-[20px]' >
      <Skeleton
        className='w-8/12 h-[20px]'
      />
      <Skeleton
        className='w-3/12 h-[20px]'
      />
    </div>
    <Skeleton
      className=' mt-2 w-full h-[10px]'
    />
    <Skeleton
      className=' mt-2 w-full h-[10px]'
    />
  </div>;
};

export const DiscussionsLoader = () => {
  const discussions = Array.from(Array(10).keys());
  return (
    <div className='px-3 w-full'>
      {discussions.map((idx) => (
        <DiscussionLoader key={`${idx}-discussions-row-loader`} />
      ))}
    </div>
  );
};

export const HeaderRightLoader = () => {
  return (
    <div className='flex flex-col w-full'>
      <Skeleton className='mb-1 w-2/6 h-[24px]' />
      <Skeleton className='mb-1 w-3/6 h-[21px]' />
    </div>
  );
};

export const LoadingDiscussion = () => (
  <div data-testid='loading_discussion' className='flex flex-col w-full'>
    <HeaderRightLoader />
    <Skeleton className='w-full h-full' />
  </div>
);
