import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import BaseTimeField, {
  TimeFieldProps as BaseTimeFieldProps,
} from '@ui/atoms/fields/time/TimeField';

type TimeFieldProps = BaseTimeFieldProps & {
  control: Control;
};

const TimeField: FC<TimeFieldProps> = ({ name, control, ...props }) => {
  return (
    <Controller
      control={control}
      render={({ field: { value, onChange, name } }) => {
        return (
          <BaseTimeField
            name={name}
            onChange={onChange}
            value={typeof value === 'undefined' ? null : value}
            {...props}
          />
        );
      }}
      name={name}
    />
  );
};

export default TimeField;
