import { useRecoilState } from 'recoil';
import { featuresAtom, FeaturesState, FeaturesType } from '@/services/store/store';
import { buildUrl } from '@/utils/Api';
import { useCallback, useEffect } from 'react';
import useRequest from '@/services/request/useRequest';


export type FeatureKey = keyof FeaturesType;

let isFeatureLoading = false;

const useFeatures = () => {

  const [{ isLoading, features }, setFeatures] = useRecoilState(featuresAtom);

  const { data, fetchData } = useRequest<FeaturesType>({
    'url': buildUrl('features'),
    method: 'get',
    skip: true
  });

  const { fetchData: updateFeatures, loading: isUpdating } = useRequest({
    url: buildUrl('features'),
    method: 'PUT',
    skip: true
  });


  const update = useCallback(async (feature : FeatureKey, enable : boolean) => {

    await updateFeatures({
      enable,
    },{
      url: buildUrl('features',feature),
    });

    setFeatures((prevValues : FeaturesState) : FeaturesState => {

      const features = prevValues.features as FeaturesType;

      return {
        ...prevValues,
        features: {
          ...features,
          [feature]: enable
        }
      };
    });

  },[updateFeatures,setFeatures]);


  useEffect(() => {
    if(features || isLoading) {
      return;
    }
    if(isFeatureLoading) {
      return;
    }
    isFeatureLoading = true;

    setFeatures({
      isLoading: true,
      features: null,
    });

    fetchData().then((value) => {
      setFeatures({
        isLoading: false,
        features: value as FeaturesType,
      });

      isFeatureLoading = false;

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFeatures,data]);


  const isOn = useCallback((key : FeatureKey) : boolean => {

    if(!features) {
      return false;
    }

    return features[key] ?? false;


  },[features]);


  return {
    loading: !features,
    update,
    isUpdating,
    isOn
  };

};

export default useFeatures;