import React, { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLayout } from '@/layout/LayoutContext';
import H1 from 'instamed-styleguide/ui/atoms/texts/base/H1';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import P from 'instamed-styleguide/ui/atoms/texts/base/P';
import useLogIn from '@/auth/hooks/useLogIn';
import { useTranslation } from 'react-i18next';
import { URLS } from '@/auth/constants';

const CreatePatientValidation: FC = () => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const { logIn, loading } = useLogIn();
  const location = useLocation();
  const params = location.state as { email:string, password:string};

  const cancelBtn = useMemo(
    () => ({
      children: t('form.action.cancel'),
      onClick: () => history.push(URLS.CREATE),
    }),
    [history, t]
  );

  useLayout({ cancelBtn });

  return (
    <div
      className={
        'flex flex-col md:justify-center mx-6 mt-12 md:mt-0 w-full md:w-[70%] h-full'
      }
    >
      <div className={'lg:mx-auto xl:mx-1'}>
        <div className={'mx-auto'} data-testid='validation-div'>
          <H1 className='mt-2 mb-10 font-medium text-gray-900' >
            {t('form.create.patient.create_account.validated_signup')}
          </H1>

          <P className='mt-[2px] mb-6 text-sm text-gray-900'>
            {t('form.create.patient.create_account.welcome_title')}
          </P>
          <P className='mt-[2px] mb-6 text-sm text-gray-900'>
            {t('form.create.patient.create_account.welcome_subtitle')}
          </P>

          <P className='mt-[2px] mb-6 text-sm text-gray-900 whitespace-pre-wrap'>
            {t('form.create.patient.create_account.welcome_description')}
          </P>
          

          <Button
            onClick={() => logIn({ username: params.email, password: params.password })}
            className='mt-8 w-full'
            type='submit'
            color='primary'
            saving={loading}
            data-testid='submit'
          >
            {t('form.create.patient.create_account.access_my_account')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePatientValidation;
