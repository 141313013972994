import DrawerHeader from '@ui/molecules/drawer-header/DrawerHeader';
import cls from 'classnames';
import Alert from '@ui/atoms/alerts/Alert';
import React, { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OverflowContainer from '@ui/atoms/containers/overflow-container/OverflowContainer';
import { IconType } from '@ui/atoms/icons/icons';
import { ColorType } from '@ui/types';
import useViewport from '@/utils/hooks/useViewport';

type DrawerProps = {
  className?: string,
  sidebar?: ReactNode,
  isDynamicHeight?: boolean,
  sidebarWidth?: number,
  titleText?: string;
  title: string,
  titleValues?: Record<string, unknown>,
  translationDomain?: string | string[],
  headerIcon?: IconType,
  headerSubtitle?: ReactNode,
  headerIconColor?: ColorType,
  keyPrefix?: string,
  onClose: () => void,
  children: ReactNode,
  errorMessage?: ReactNode
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
  footer?: ReactNode
}

type DivOrFormProps = HTMLAttributes<HTMLDivElement|HTMLFormElement>;

const DivOrForm : FC<DivOrFormProps> = ({ onSubmit, children, ...rest }) => {
  if (onSubmit) {
    return <form onSubmit={onSubmit} {...rest}>{children}</form>;
  }

  return <div {...rest}>{children}</div>;
};


const Drawer: FC<DrawerProps> = ({
  className,
  title,
  titleText,
  titleValues,
  sidebar,
  sidebarWidth,
  onSubmit,
  footer,
  translationDomain,
  keyPrefix,
  onClose,
  headerIcon,
  headerSubtitle,
  headerIconColor,
  children,
  errorMessage,
}) => {

  const { t } = useTranslation(translationDomain, {
    keyPrefix
  });

  const [headerHeight, setHeaderHeight] = useState(113);
  const [headerElement, setHeaderElement] = useState<HTMLDivElement | null>(null);

  const { xs,sm,width,height } = useViewport();

  useEffect(() => {
    if (!headerElement) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      setHeaderHeight(entry.contentRect.height || 113);
    });

    observer.observe(headerElement);

    return () => {
      observer.disconnect();
    };
  }, [headerElement]);

  return (
    <div className={cls('flex fixed flex-col w-inherit h-full', className)}>
      <div className='flex'>
        {!!sidebar && (
          <>
            <div className='flex-shrink-0' style={{ width: sidebarWidth }}>
              {sidebar}
            </div>
            <div className='w-[1px] bg-gray-50' />
          </>
        )}
        <div className='flex-grow'>
          <DrawerHeader
            icon={headerIcon}
            iconColor={headerIconColor}
            subtitle={headerSubtitle}
            ref={setHeaderElement}
            title={titleText || t(title, titleValues)}
            onCloseClick={onClose}
            size='large'
          />
          <DivOrForm
            onSubmit={onSubmit}
            className='flex flex-col flex-grow justify-between w-inherit'
            style={{
              width: xs || sm ? width : undefined,
              height: height - (headerHeight - 113) - 140
            }}>
            <OverflowContainer>
              {children}
            </OverflowContainer>
            {errorMessage && (
              <div data-testid='drawer_error' className='p-2'><Alert color='danger'>{errorMessage}</Alert></div>
            )}
            {footer}
          </DivOrForm>
        </div>
      </div>
    </div>
  );

};

export default Drawer;