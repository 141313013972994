import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Email from '@/packages/back-end/mssante/email';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import useToast from '@/utils/hooks/useToast';

import Button from '@ui/atoms/buttons/button/Button';

import { useEmails } from '../EmailsContext';
import { EMAIL_URL } from '../email-template';

interface ArchiveButtonProps {
  email?: Email | null;
  iconSize?: number;
  isMultipleEdit?: boolean;
}

const ArchiveButton: FC<ArchiveButtonProps> = ({
  email,
  iconSize = 20,
  isMultipleEdit,
}) => {
  const { t } = useTranslation('ms_sante');
  const { addToast } = useToast();
  const { emails, setEmails, queryParamsEmail,impersonatedUser: impersonated, onUpdateEmail } = useEmails();

  const { loading, fetchData: updateEmail } = useJsonAPIRequest({
    url: isMultipleEdit ? EMAIL_URL({ id: 'bulk',impersonated }) :
      EMAIL_URL({ email,impersonated }),
    method: 'PUT',
    skip: true,
  });

  const archived = !!email?.attributes?.archived;

  const archiveEmail = async () => {
    const ids = emails.filter((item) => item.selected).map(item => item.attributes._id);
    const body = { archived: !archived };
    const bodyMultiple = { data: { archived: !queryParamsEmail.archived }, ids };
    const res = await updateEmail(isMultipleEdit ? bodyMultiple : body);
    const updatedEmail = (res as { data: Email })?.data;

    if (isMultipleEdit) {
      setEmails(emails.map((item) => {
        if (!ids.includes(item.attributes._id)) {
          return item;
        }
        return {
          ...item,
          selected: false,
          attributes: { ...item.attributes, archived: !queryParamsEmail.archived }
        };
      }));
    } else if (updatedEmail) {
      onUpdateEmail(updatedEmail);

      const message = t(
        email?.attributes?.archived
          ? 'toasts.email_disarchived'
          : 'toasts.email_archived'
      );
      addToast('success', message);
    }
  };

  if(email?.attributes.emailType === 'sent') {
    return null;
  }

  return (
    <Button
      color='transparent'
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      iconSize={iconSize}
      icon={archived ? 'disarchive' : 'archive'}
      title={t(archived ? 'header_right.disarchive' : 'header_right.archive')}
      saving={loading}
      onClick={(event) => {
        event.stopPropagation();
        archiveEmail();
      }}
    />
  );
};

export default ArchiveButton;
