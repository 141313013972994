import useRequest, { Response } from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import UploadedFile from '@/packages/back-end/uploaded-file';


export const buildFormData = (files : FileList | File | File[]) : FormData => {

  const formData = new FormData();


  if(files instanceof File) {
    formData.append('file', files);
    formData.append('name', files.name);
    return formData;
  }


  if(files.length === 0) {
    throw new Error('No file selected');
  }

  if (files.length > 1) {
    [...files].forEach((file) => {
      formData.append('file[]', file);
      formData.append('name[]', file?.name);
    });
  } else {
    formData.append('file', files[0]);
    formData.append('name', files[0].name);
  }

  return formData;

};


const useUploadRequest = <ResponseType = UploadedFile, DataType = FormData>() : Response<DataType, ResponseType> => {

  const headers : Record<string, string> = {
    'Content-Type': 'multipart/form-data',
  };

  const method = 'post';
  const url = buildUrl('upload');

  return useRequest<ResponseType, DataType>({
    url,
    skip: true,
    method,
    headers
  }
  );

};

export default useUploadRequest;
