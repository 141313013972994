/**
 * Open an iframe to preview a document.
 * Give either the document URL, or the result of URL.createObjectURL() for Blob.
 *
 * @param url
 * @returns {Promise<void>}
 */
export async function printDocument(url : string|undefined) : Promise<void> {

  if (!url) {
    return;
  }

  const data = await fetch(url);
  const blobUrl = URL.createObjectURL(await data.blob());

  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = blobUrl;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe?.contentWindow?.print();
      URL.revokeObjectURL(blobUrl);
    }, 1);
  };
}
