import React, { FC, useState } from 'react';

import PatientSchema from '@/packages/back-end/patient';
import Patient from '@/packages/back-end/patient';

import Drawer from '@/components/drawer/Drawer';
import { onCloseType } from '@/components/drawer/useUpdateDrawer';

import AddPatientForm from './AddPatientForm';

type AddPatientDrawerProps = {
  onAdded?: (
    patient: Patient | null,
    options: { hasEmail: boolean }
  ) => { shouldClose?: boolean };
  onClose: () => void;
  onCloseCallback?: onCloseType<Patient>;
};

const AddPatientDrawer: FC<AddPatientDrawerProps> = ({
  onAdded,
  onClose,
  onCloseCallback,
}) => {
  const [patient, setPatient] = useState<PatientSchema | null>(null);

  return (
    <Drawer
      isDynamicHeight
      title={patient ? 'add_patient.confirm_title' : 'add_patient.title'}
      titleValues={{
        fullName: patient?.relationships?.user?.data?.attributes?.fullName,
      }}
      translationDomain='medical_staff'
      onClose={onClose}
      errorMessage={null}
    >
      <AddPatientForm
        onAdded={(patient, options) => {
          const shouldClose = onAdded?.(patient, options)?.shouldClose;
          if (!shouldClose) {
            setPatient(patient);
          } else {
            onClose();
          }
        }}
        onClose={onClose}
        onCloseCallback={onCloseCallback}
      />
    </Drawer>
  );
};

export default AddPatientDrawer;
