import React, { useEffect, useMemo, useRef, useState } from 'react';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import TextareaField from 'instamed-styleguide/ui/atoms/fields/textarea/TextareaField';
import DocumentToUpload from '../documents/DocumentToUpload';
import { buildUrl } from '@/utils/Api';
import useAttachmentsDrawer, {
  InstaFileInfosProps,
} from '../documents/AttachDocumentDrawer';
import AudioRecorder from '../audio/AudioRecorder';
import { AudioToUpload } from '../audio/AudioPlayer';
import { AttachDocumentButton, SettingsButton, SignatureButton } from './DropdownButtons';
import { initialFileState, useMessageContext } from './MessageContext';
import CreateDocumentButton from '../documents/CreateDocumentDrawer';
import { useForm } from 'react-hook-form';
import TagPatients from './TagPatients';
import PatientRow from '../components/PatientRow';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import createMessageToSend, {
  uploadFilesAndGetDocuments
} from '../messageComposer/createMessageToSend';
import { useDiscussions } from '../ChatContext';
import useToast from '@/utils/hooks/useToast';
import ScheduleButton from './ScheduleButton';
import Discussion from '@/packages/back-end/Discussion';
import SpinnerLoader from 'instamed-styleguide/ui/atoms/loaders/spinner/SpinnerLoader';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import Message from '@/packages/back-end/message';
import useUploadRequest from '@/services/request/useUploadRequest';
import UploadedFile from '@/packages/back-end/uploaded-file';
import User, { isMedicalStaff, MeUser } from '@/packages/back-end/user';
import storage, { StorageKeys } from '@/services/request/helpers/storage';
import { Item } from '@/packages/back-end/jsonapi';
import { generateUniqueId } from '@/app/structure/page/chat/utils';
import useSearchDocumentDrawer from './search-document-drawer/useSearchDocumentDrawer';
import { ROLE } from '@/packages/types';
import DocumentRow from '@/app/structure/page/chat/documents/DocumentRow';



const containsOnlySpacesAndNewlines = (str: string): boolean => {
  const regex = /^[ \n\r\t]*$/;
  return regex.test(str);
};


export const MEDIUM_GRAY = '#9CA7AF';

type MessageComposerProps = {
  filesFromDnD?: File[] | null;
  discussionId?: string;
  trash?: boolean;
  containerClassName?: string;
  newConversationProps?: {
    creatingDiscussion: boolean;
    disableCreatingMessage: boolean;
  }
  textAreaClasses?: string;
  disabledComposer?: boolean;
  createDiscussion?: () => Promise<Discussion | null>;
};

const NEW_MESSAGE_URL = () => buildUrl('messages', undefined, {
  include:
    'owner,patient,patient.user,documents,documents._type',
});


const MessageComposer = ({
  filesFromDnD,
  discussionId,
  trash,
  containerClassName,
  textAreaClasses,
  newConversationProps,
  createDiscussion,
  disabledComposer
}: MessageComposerProps) => {
  const { t } = useTranslation('chat');
  const { addToast } = useToast();
  const { setNewDiscussion, setSelectedDiscussion, selectedDiscussion, newDiscussion } = useDiscussions();
  const { user } = useRecoilValue(authAtom);
  const isPatient = user?.attributes.role === ROLE.PATIENT;

  const [messageSent,setMessageSent] = useState<Message|undefined>();

  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [instaFileInfos, setInstaFileInfos] = useState<InstaFileInfosProps>();
  const [attachments, setAttachments] = useState<File[] | null>(null);
  const {
    signature,
    setSignature,
    setFiles,
    files,
    patient,
    documents,
    setDocuments,
    setPatient,
    addMessage,
    removeMessage,
    setSendingMessage
  } = useMessageContext();

  const { register, resetField,setValue, watch } = useForm({
    shouldUseNativeValidation: true,
  });
  const { onClick: openAttachmentsDrawer } = useAttachmentsDrawer({
    onCloseCallback: () =>setAttachments(null),
    displayDocumentFields: true
  });

  const { onOpen: onOpenSearchDocumentDrawer } = useSearchDocumentDrawer();
  const content = watch('content') as string;

  const {
    fetchData: requestSendMessage,
    loading: sendingMessage
  } = useJsonAPIRequest<{ data: Message }>({
    url: NEW_MESSAGE_URL(),
    skip: true,
    method: 'POST',
    abortable: false,
  });

  useEffect(() => {
    setSendingMessage(sendingMessage);
  }, [sendingMessage, setSendingMessage]);

  useEffect(() => {
    if (attachments) {
      openAttachmentsDrawer({
        attachments: attachments,
        uploadFile: (
          newFiles: File[],
          newInstaFileInfos: InstaFileInfosProps
        ) => {
          setInstaFileInfos(newInstaFileInfos);
          setFiles({
            ...files,
            attachments: [...files.attachments, ...newFiles],
          });
        },
      });
    }
  }, [attachments, files, openAttachmentsDrawer, setFiles]);

  const { fetchData: uploadFiles } = useUploadRequest<UploadedFile[]>();
  
  useEffect(() => {
    if (filesFromDnD) {
      setAttachments(filesFromDnD);
    }
  }, [filesFromDnD]);

  const submitMessage = async (
    discussionId: string,
    plannedDate?: string
  ) => {


    const tmpId = generateUniqueId();

    // Create message and proxy with the uploaded documents
    let { proxy, message, hasDocuments } = createMessageToSend({
      discussionId,
      documents,
      tmpId,
      content,
      signature,
      user,
      files,
      patient,
      createdDate: plannedDate,
    });

    addMessage(proxy);

    resetField('content');
    setFiles(initialFileState);
    setDocuments([]);
    setPatient(null);

    if(hasDocuments) {
      try {
        // Upload files and get documents
        const newDocuments = await uploadFilesAndGetDocuments({
          files,
          instaFileInfos,
          uploadFiles
        });


        const result= createMessageToSend({
          discussionId,
          documents,
          newDocuments,
          tmpId,
          content,
          signature,
          files,
          user,
          patient,
          createdDate: plannedDate,
        });

        proxy = result.proxy;
        message = result.message;
        hasDocuments = result.hasDocuments;

        addMessage(proxy);

      } catch (e) {

        removeMessage(proxy);

        setValue('content',content);
        setFiles(files);
        setDocuments(documents);
        setPatient(patient);

        throw e;

      }

    }



    try {
      const response = await requestSendMessage(message, { throw: true, displayToastOnError: true }) as Item<Message>;
      // Do not add the message here because discussionId might have changed in the meantime
      setMessageSent(response.data);
    } catch (e) {
      removeMessage(proxy);
      setValue('content',content);
      setFiles(files);
      setPatient(patient);
    }
  };

  const sendMessage = async (plannedDate?: string) => {
    if (createDiscussion) {
      const newDiscussion = await createDiscussion();
      if (newDiscussion?.id) {
        await submitMessage(newDiscussion.id, plannedDate);
        setNewDiscussion(null);
        addToast('success', t('toasts.new_discussion_created'));
        setSelectedDiscussion(newDiscussion);
      }
    } else {
      await submitMessage(discussionId as string, plannedDate);
    }
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>, user ?: MeUser) => {
    const enterToSend = storage.getItem(StorageKeys.DISCUSSIONS__ENTER_TO_SEND);
    if (e.key === 'Enter' && !e.shiftKey && enterToSend && !newConversationProps && isMedicalStaff(user)) {
      e.preventDefault();
      if (content) {
        sendMessage();
      }
    }
  };

  useEffect(() => {

    if(!messageSent) {
      return;
    }

    const added = addMessage(messageSent);

    if(!added) {
      addToast('success',t('toasts.message_sent_successfully'));
    }

    setMessageSent(undefined);

  }, [messageSent,addMessage,t,addToast]);


  const hasPatientSelected : boolean = useMemo(() => {

    if(newDiscussion) {
      return !!newDiscussion.participants?.find((item) => item.attributes.role === ROLE.PATIENT);
    }

    const participants = (selectedDiscussion?.relationships?.participants
      ?.data || []) as User[];

    return !!participants.find(
      ({ attributes }) => attributes.role === ROLE.PATIENT);



  },[newDiscussion,selectedDiscussion]);


  const disabled = (
    (!content || containsOnlySpacesAndNewlines(content)) &&
    !files.attachments.length &&
    !files.audio.length &&
    !documents.length &&
    !patient &&
    !files.medicalDocuments.length) || sendingMessage
    || newConversationProps?.creatingDiscussion
    || newConversationProps?.disableCreatingMessage || disabledComposer;

  return (
    <div
      className={cls(
        'flex flex-col m-3 bg-white rounded-md border border-gray-100',
        containerClassName
      )}
      data-testid='message_composer'
    >
      <TextareaField
        disabled={trash}
        className='h-full'
        inputContainerClassName='h-full'
        textAreaClasses={cls(
          trash ? 'cursor-not-allowed' : '',
          'max-h-[300px] min-h-[90px] rounded-xl text-[14px]',
          textAreaClasses
        )}
        placeholder={t(trash ? 'message_composer.trash_filter' : 'message_composer.write_message')}
        borderless
        resizable={false}
        {...register('content')}
        onKeyPress={(e) => handlePressEnter(e,user)}
      />
      <div
        className='flex flex-row justify-between items-center p-1 py-2 px-3
      bg-white rounded-b-md border-t border-gray-100'
      >
        <div className={cls('flex flex-col', {
          'lg:overflow-y-auto': files?.attachments?.length ||
            files?.audio?.length || files?.medicalDocuments?.length || patient
        })}>
          <div className='flex overflow-x-auto flex-row gap-2 items-center'>
            <DocumentToUpload
              type='attachments'
              files={files}
              setFiles={setFiles}
            />
            <DocumentToUpload
              type='medicalDocuments'
              files={files}
              setFiles={setFiles}
            />

            {documents?.map((document) => (<DocumentRow
              document={document}
              removeDocument={() => {
                setDocuments((prev) => prev.filter((doc) => doc.id !== document.id));
              }}
              owner={document.relationships.owner}
              key={`message-row-document-${document.id}`}
            />))}

            {!!files.audio?.length && <AudioToUpload />}
            {patient && (
              <PatientRow
                patient={patient}
                removePatient={() => setPatient(null)}
              />
            )}
          </div>
        </div>
        <div className='flex flex-row items-center'>
          <div className='flex flex-row items-center'>
            {!newConversationProps && <SettingsButton entity='discussion' />}
            <SignatureButton
              signature={signature}
              onChange={setSignature}
            />
            <AudioRecorder />
            <CreateDocumentButton isNew={!!newConversationProps} />
            <div>
              <AttachDocumentButton
                isPatient={isPatient}
                hasPatientSelected={hasPatientSelected}
                onClickUpload={() => inputFileRef?.current?.click()}
                onClickSearch={onOpenSearchDocumentDrawer}
              />
              <input
                ref={inputFileRef}
                id='file'
                type='file'
                className='hidden'
                onChange={(e) =>
                  setAttachments(Array.from(e.target.files as FileList))
                }
                multiple
                data-testid='upload_document_input'
              />
            </div>
            {!newConversationProps && <TagPatients />}
          </div>
          <div
            className={cls(
              'flex flex-row items-center py-1 pr-1 pl-2 ml-[8px] bg-gray-900 rounded-md',
              {
                'bg-gray-700': disabled,
              }
            )}
          >
            <button
              title={t(disabledComposer ? 'message_composer.trash_filter' : 'message_composer.send_message')}
              className='text-sm font-medium text-white'
              onClick={() => sendMessage()}
              disabled={disabled}
              data-testid='message_send_button'
            >
              {t('send')}
            </button>
            {!sendingMessage && <div className='mx-2 w-[1px] h-[15px] bg-white' />}
            {sendingMessage ? (
              <SpinnerLoader
                className='ml-2 w-3 h-3 text-white'
                data-testid='message_send_loader'
              />
            ) : (
              <ScheduleButton
                disabled={disabled}
                onSubmit={(selectedDate) => {
                  sendMessage(selectedDate);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
