import React, { useCallback, useEffect } from 'react';


import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import AiDrawer from './AiDrawer';
import useSearchParams from '@/utils/hooks/useSearchParams';
import useChangeSearchParams from '@/utils/hooks/useChangeSearchParams';
import Patient from '@/packages/back-end/patient';


const useAiDrawer = ({ patient } : { patient ?: Patient } = {}) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const { drawer_lia } = useSearchParams();
  const { onRemoveSearchParams } = useChangeSearchParams();


  const onOpen = useCallback(() => {

    openDrawer({
      content: <AiDrawer patient={patient} onClose={closeDrawer} />,
      size: 'medium',
    });
  }, [openDrawer,patient, closeDrawer,]);


  useEffect(() => {
    if(!drawer_lia) {
      return;
    }

    if(drawer_lia) {
      onOpen();
      onRemoveSearchParams(['drawer_lia']);
    }


  }, [onRemoveSearchParams,onOpen,drawer_lia]);



  return { onOpen, closeDrawer };
};

export default useAiDrawer;
