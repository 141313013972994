import React from 'react';
import { useTranslation } from 'react-i18next';
import { isDoctor } from '@/packages/back-end/user';
import { useEmails } from '@/app/structure/page/mssante/EmailsContext';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { NewEmailButton } from '@/app/structure/page/mssante/MSSante';


const NoEmailFound = () => {
  const { t } = useTranslation('ms_sante');

  const { user } = useRecoilValue(authAtom);

  const {
    isSecuredMessaging,
    queryParamsEmail,
    setImpersonatedUser,
    hasMultipleDoctors,
    setAddingEmail,
  } = useEmails();

  return (
    <div className='flex flex-col justify-center items-center p-8 w-full h-full bg-white rounded-md'>
      <h2 className='mb-3 text-lg'>
        {t(queryParamsEmail.search ? 'no_emails_found' :
          isDoctor(user) ? 'no_emails_found_page' : 'no_emails_found_page_assistant')}
      </h2>
      {isSecuredMessaging && isDoctor(user) && (
        <NewEmailButton
          icon='message'
          color='primary'
          onClick={() => setAddingEmail(true)}
        >
          {t('new_email')}
        </NewEmailButton>
      )}
      {isSecuredMessaging && hasMultipleDoctors && (
        <NewEmailButton
          icon='switchUser'
          color='primary'
          onClick={() => setImpersonatedUser(undefined)}
        >
          {t('choose_doctor')}
        </NewEmailButton>
      )}
    </div>
  );
};


export default NoEmailFound;
