import get from 'lodash/get';
import { addUrlParameters } from '@/utils/Api';
import { ROUTES as MEDIC_ROUTES } from '@/app/structure/body/routes/MedicalStaff.utils';
import { ROUTES as PATIENT_ROUTES } from '@/app/structure/body/routes/Patient.utils';


export const isWebappUrl = (url: string|undefined): boolean => {
  return !!(url?.startsWith('/v2') || url === '/' || url?.startsWith('/docteurs'));
};


export const buildWebappUrl = (path: string, args: Record<string, unknown> = {}): string => {

  path = path.substring(0,1) === '/' ? path : '/' + path;

  if(isWebappUrl(path)) {
    return addUrlParameters(path, args);
  }

  return addUrlParameters(`/v2${path}`, args);
};

const URL_EDIT_PROFIL_MEDIC = buildWebappUrl(MEDIC_ROUTES.medical_staff.profile);
const URL_EDIT_PROFIL_PATIENT = buildWebappUrl(PATIENT_ROUTES.profile);
const URL_EDIT_PROFIL_SECURITY = '/profile/security';

export const ROUTES = {
  editProfile: {
    ROLE_MEDECIN: URL_EDIT_PROFIL_MEDIC,
    ROLE_PATIENT: URL_EDIT_PROFIL_PATIENT,
    ROLE_MEDICAL_COORDINATOR: URL_EDIT_PROFIL_MEDIC,
    ROLE_INFIRMIERE: URL_EDIT_PROFIL_MEDIC,
    ROLE_ADMIN: URL_EDIT_PROFIL_SECURITY,
    ROLE_HOSPITAL_ADMIN: URL_EDIT_PROFIL_SECURITY,
    ROLE_INSTITUTION_MANAGER: URL_EDIT_PROFIL_SECURITY
  }
};



export const getHostName = () => window.location.hostname;
export const getProtocol = () => window.location.protocol;

export const getPort = () => window.location.port;

export const getUrl = () => getProtocol() + '//' + getHostName() + (getPort() ? ':' + getPort() : '');
export const getPathName = () => window.location.pathname;

export const getRoute = (key: string, role: string) => {
  const url = get(ROUTES, `${key}.${role}`);

  if(url?.startsWith('/v2')) {
    return url;
  }

  return url
    ? getUrl() + '/' + (url.startsWith('/') ? url.substring(1) : url)
    : null;
};
