import React, { useCallback, useEffect, useMemo } from 'react';
import { FC } from 'react';
import { OptionType } from '@ui/types';
import {
  ApiSelectorFieldProps, getValueInList,
  RenderLabelType, resetValue,
  TransformDataType
} from '@/components/form/fields/api-selector';
import SimpleSelector from '@ui/organisms/selectors/simple/SimpleSelector';
import { onChangeType, onMultipleChangeType } from '@ui/organisms/selectors/types';
import MultipleSelector from '@ui/organisms/selectors/multiple/MultipleSelector';
import useApiConstant from '@/utils/hooks/useApiConstant';
import { ConstantItemKeyType } from '@/services/store/store';
import { useController } from 'react-hook-form';

export type ConstantItemRow = { [key: string]: string | number }

export type ConstantItem = {
  description?: string,
  label?: string,
  placeholder?: string,
  showSelectAll?: boolean,
  data: ConstantItemRow
  filters?: {
    [key: string]: string | number
  }
}


export type ConstantSelectorProps =
  Omit<ApiSelectorFieldProps, 'url' | 'renderLabel' | 'transformData'> & {
  disabled?: boolean;
  entity: ConstantItemKeyType,
  property: string,
  ignoreOptionsByValue?: string[];
  sortFn?: (options: OptionType[]) => OptionType[],
  onLoaded?: (options: OptionType[]) => void,
}

const transformData : TransformDataType<OptionType,ConstantItem> = (data) => {

  if (!data) {
    return [];
  }

  return Object.keys(data.data).map((value) => {
    return {
      value: value,
      label: data.data[value],
    } as OptionType;
  });
};


const renderLabel : RenderLabelType<OptionType> = (item) => item.label;

const buildOption = (item: OptionType) => item;


const ConstantSelectorField: FC<ConstantSelectorProps> = ({
  entity,
  multiple,
  property,
  ignoreOptionsByValue,
  resetField,
  onLoaded,
  sortFn,
  name,
  showSelectAll = true,
  control,
  required,
  ...rest
}) => {


  const { getConstants,isLoading } = useApiConstant();

  const { field: { value, onChange } } = useController({
    control,
    name
  });

  const options = useMemo(() => {

    const item = getConstants({ entity,property });

    if(!item) {
      return [];
    }

    let items = transformData(item,renderLabel,buildOption);
    if (ignoreOptionsByValue?.length) {
      items = items.filter(({ value }) => !ignoreOptionsByValue.includes(String(value)));
    }

    return sortFn ? sortFn(items) : items;

  },[entity,property,ignoreOptionsByValue,getConstants,sortFn]);


  // Todo; put this in a hook
  const onFieldChange = useCallback((value?: OptionType | OptionType[] | null) => {

    if (null === value) {
      onChange(multiple ? [] : null);
      return;
    }
    onChange(value);

  }, [onChange, multiple]);

  const optionedValue = useMemo(() => {

    const option = getValueInList(options, value);

    if (option) {
      return option;
    }

    return multiple ? [] : null;


  }, [multiple, value, options]);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetValue({ name, value: optionedValue, resetField }), [name, resetField, getConstants]);

  useEffect(() => {
    if (!isLoading && options?.length) {
      onLoaded?.(options);
    }
  }, [isLoading, options, onLoaded]);

  if (!multiple) {
    return (<SimpleSelector
      data-testid='selector'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options={options}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={onFieldChange as onChangeType}
      required={!!required}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={optionedValue as OptionType}
      name={name}
      loadingData={isLoading}
      {...rest}
    />);
  }

  return (<MultipleSelector
    data-testid='selector'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options={options}
    showSelectAll={showSelectAll}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange={onFieldChange as onMultipleChangeType}
    name={name}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value={optionedValue as OptionType[]}
    loadingData={isLoading}
    {...rest}
  />);

};

export default ConstantSelectorField;
