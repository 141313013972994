import React, { lazy } from 'react';

import { ROUTES } from '@/app/structure/body/routes/Discussions.utils';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import { HOSPITAL_STAFF, ROLE } from '@/packages/types';
import MSSante from '@/app/structure/page/mssante/MSSante';

const Discussions = lazy(() => import('@/app/structure/page/chat/Discussions'));

const getRoutes = () => {

  const data = [];

  data.push(<PrivateRoute
    exact={true}
    roles={[...HOSPITAL_STAFF,ROLE.PATIENT]}
    key={ROUTES.messagerie.discussions}
    path={ROUTES.messagerie.discussions}>
    <Discussions />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={HOSPITAL_STAFF}
    key={ROUTES.messagerie.mssante}
    path={ROUTES.messagerie.mssante}>
    <MSSante />
  </PrivateRoute>
  );

  return data;
};

export default getRoutes;