import { Entity } from '@/packages/back-end/jsonapi';
import { buildUrl } from '@/utils/Api';
import User from './user';

export type PatientTagged = {
  attributes: {
    shareable: boolean;
    status: number;
    _id: number;
    id: string;
  };
  relationships: {
    user: {
      data: User;
    };
  };
};

type Discussion = Entity & {
  isLoading: boolean;
  selected: boolean;
  attributes: {
    id: string | null | undefined;
    _id: string;
    title: string;
    updatedDate: string;
    documentsCount: number;
    unreadMessagesCount: number;
    read: number[];
    received: number[];
    rights: {
      delete: boolean;
      archive: boolean;
      disarchive: boolean;
    };
  };
  id: string;
  relationships: {
    owner: {
      data: User;
    };
    participants: {
      data: User[];
    };
    CC: {
      data: User[];
    };
    patientsTagged: {
      data: PatientTagged[];
    };
    lastMessage: {
      data: {
        attributes: {
          content: string;
          read: string[];
        };
      };
    };
  };
};

export default Discussion;

export const DISCUSSIONS_URL = () =>
  buildUrl('discussions', undefined, {
    include:
      'owner,participants,lastMessage,CC,patientsTagged,patientsTagged.user',
  });
