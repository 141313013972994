import PublicProfile from '@/packages/back-end/PublicProfile';
import Medic from '@/packages/back-end/medic';
import Address from '@/packages/back-end/address';
import Phone from '@/packages/back-end/phone';
import User, { MeUser } from '@/packages/back-end/user';
import Specialty from '@/packages/back-end/specialty';
import ConsultationLocationTemplate from '@/packages/back-end/consultation-location';

export const flatMedicProfileRelationships = (
  profileDoctor?: PublicProfile
) => {
  const medic = profileDoctor?.relationships.medic.data as Medic | undefined;
  const user = medic?.relationships.user.data as User | undefined;
  const medicAddress = medic?.relationships?.professionalAddress?.data as
    | Address
    | undefined;
  const phoneNumber = user?.relationships?.phoneNumber?.data as
    | Phone
    | undefined;

  const consultationLocations =
    (profileDoctor?.relationships?.consultationLocations
      ?.data as ConsultationLocationTemplate[]) || [];

  const specialties =
    (medic?.relationships?.specialties?.data as Specialty[]) || [];

  return {
    medic,
    medicAddress,
    user,
    phoneNumber,
    consultationLocations,
    specialties,
  };
};

export const flatProfileMeRelationships = (meUser?: MeUser) => {
  const phones = meUser?.relationships?.phones?.data as Phone[] | undefined;

  // First, get the number confirmed
  let phoneNumberMe = phones?.find((phone) => phone.attributes.mobile && phone.attributes.confirmed);

  // Then get the mobile number
  if(!phoneNumberMe) {
    phoneNumberMe = phones?.find((phone) => phone.attributes.mobile);
  }

  return { phoneNumberMe };
};
