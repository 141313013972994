import { useMemo, useState } from 'react';
import useViewport from '@/utils/hooks/useViewport';
import { sizes } from '@/app/structure/drawer-manager/DrawerManager';


const MAX_WIDTH = sizes.large + 200;

const useSidebarWidth = (initialShow : boolean) => {

  const [showSidebar, setShowSidebar] = useState(initialShow);

  const { width, sm, xs,md } = useViewport();

  const sideBarWidth = useMemo(() => {

    if(!showSidebar) {
      return 0;
    }

    if(xs || sm || md) {
      return 0;
    }

    return Math.min(width - sizes.medium,MAX_WIDTH);

  },[xs,sm,md,showSidebar,width]);

  return {
    sideBarWidth,
    setShowSidebar
  };

};

export default useSidebarWidth;