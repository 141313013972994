import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';

import { attachErrorsToFields } from '@/services/request/utils';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { authAtom } from '@/services/store/store';

import { buildUrl } from '@/utils/Api';
import { yupResolver } from '@hookform/resolvers/yup';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import PasswordValidator from '@ui/molecules/password-validator/PasswordValidator';
import Button from '@ui/atoms/buttons/button/Button';
import usePasswordValidator from '@/utils/hooks/usePasswordValidator';
import { getError } from '@/components/form/utils';
import CGUAgreed from '@/components/cgu-agreed/CGUAgreed';

import { EnterResetPasswordCodeStepData } from './EnterResetPasswordCodeStep';
import { EnterEmailStepData } from './EnterEmailStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { TwoFactorMethod } from '@/auth/pages/login/types';
import { useBookMeeting } from '../../BookMeetingContext';

const schema = yup.object({
  password: yup.string().required('field_required'),
  repeat_password: yup.string().required('field_required'),
});

const enableAccountSchema = yup.object({
  password: yup.string().required('field_required'),
  repeat_password: yup.string().required('field_required'),
  CGUAgreed: yup.boolean().oneOf([true], 'field_required'),
});

type FormDataType = {
  password: string;
  repeat_password: string;
  CGUAgreed?: boolean;
};

export type ResetPasswordStepData = {
  twoFactorConfigs?: {
    token: string;
    method: TwoFactorMethod;
  };
};

type ResetPasswordStepProps = {
  stepNumber: number;
  open: boolean;
  enterEmailStepData: EnterEmailStepData;
  enterResetPasswordCodeStepData: EnterResetPasswordCodeStepData;
  setData: React.Dispatch<React.SetStateAction<ResetPasswordStepData>>;
  onToggle: (open: boolean) => void;
  loginLoading: boolean;
  onLogin: (
    password: string
  ) => Promise<{ success: boolean; token?: string; twoFactorMethod?: TwoFactorMethod }>;
};

const ResetPasswordStep: FC<ResetPasswordStepProps> = ({
  stepNumber,
  open,
  enterEmailStepData,
  enterResetPasswordCodeStepData,
  setData,
  onToggle,
  loginLoading,
  onLogin,
}) => {
  const { isEnabled } = enterEmailStepData;
  const { token } = enterResetPasswordCodeStepData;

  const keyPrefix = isEnabled
    ? 'reset_password_step'
    : 'reset_password_enable_account_step';

  const { user: meUser } = useRecoilValue(authAtom);

  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } = useScrollToHideStep(keyPrefix);

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const {
    loading,
    error: backErrors,
    fetchData: resetPassword,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('users/reset-password/reset'),
  });

  const {
    loading: enableMeLoading,
    error: enableMeBackErrors,
    fetchData: enableMe,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('users/me/enable'),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
    trigger,
    watch,
    setError,
  } = useForm<FormDataType>({
    resolver: yupResolver(isEnabled ? schema : enableAccountSchema),
  });

  const {
    strength,
    confirmStrength,
    register: registerPassword,
  } = usePasswordValidator<FormDataType>({
    passwordName: 'password',
    confirmName: 'repeat_password',
    register,
    watch,
    getValues,
    trigger,
  });

  const onSubmit = handleSubmit(
    async ({ password, repeat_password, CGUAgreed }) => {
      try {
        if (isEnabled) {
          await resetPassword(
            { token, password, repeat_password },
            { throw: true, displayToastOnError: true }
          );
        } else {
          await enableMe(
            { CGUAgreed, password, repeatPassword: repeat_password },
            { throw: true, displayToastOnError: true }
          );
        }

        const {
          success,
          token: tempToken = '',
          twoFactorMethod,
        } = await onLogin(password);

        if (token && twoFactorMethod) {
          setData({
            twoFactorConfigs: { token: tempToken, method: twoFactorMethod },
          });
          onScrollToHideStep();
        } else if (success) {
          onScrollToHideStep();
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }
  );

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
    attachErrorsToFields(setError, enableMeBackErrors);
  }, [backErrors, enableMeBackErrors, setError]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!meUser}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={meUser ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t(`${keyPrefix}.title`)}`}
      subtitle={!!meUser && t(`${keyPrefix}.subtitle`)}
      onToggle={onToggle}
      wrapperTestId='reset_password_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <div className='grid grid-cols-2 gap-4 w-full'>
          <PasswordValidator
            className='w-full'
            label={t(`${keyPrefix}.fields.password`)}
            strength={strength}
            error={getError('password', errors, errorT)}
            {...registerPassword('password')}
          />

          <PasswordValidator
            className='w-full'
            label={t(`${keyPrefix}.fields.repeat_password`)}
            strength={confirmStrength}
            error={getError('repeat_password', errors, errorT)}
            {...registerPassword('repeat_password')}
          />
        </div>

        <p className='text-sm'>{t(`${keyPrefix}.note`)}</p>

        {!isEnabled && (
          <div className='mb-4'>
            <CGUAgreed
              checkboxClassName='!mt-0'
              name='CGUAgreed'
              labelText={t(`${keyPrefix}.fields.cgu.label`)}
              linkText={t(`${keyPrefix}.fields.cgu.link`)}
              error={getError('CGUAgreed', errors, errorT)}
              register={register as (name: string) => { name: string }}
            />
          </div>
        )}

        <Button
          className='w-full'
          type='submit'
          color='primary'
          saving={loading || enableMeLoading || loginLoading}
          data-testid='reset_password_step.continue'
        >
          {t(`${keyPrefix}.continue`)}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default ResetPasswordStep;
