import React from 'react';

type HiddenProps = {
  name: string,
  value?: string
}

const Hidden : React.ForwardRefRenderFunction<HTMLInputElement, HiddenProps> = (props, ref) => (
  <input
    type='hidden'
    {...props}
    ref={ref} />
);


export default React.forwardRef(Hidden);
