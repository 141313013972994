import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { attachErrorsToFields } from '@/services/request/utils';

import { buildUrl } from '@/utils/Api';
import useMe from '@/utils/hooks/useMe';
import { yupResolver } from '@hookform/resolvers/yup';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import TextField from '@ui/atoms/fields/text/TextField';
import Button from '@ui/atoms/buttons/button/Button';
import { getError } from '@/components/form/utils';

import { getRetryValues, EnterPhoneStepData } from './EnterPhoneNumberStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { useBookMeeting } from '../../BookMeetingContext';

type FormDataType = {
  code: string;
};

const schema = yup.object({
  code: yup.string().required('field_required'),
});

export type ConfirmPhoneStepData = {
  confirmed?: boolean;
};

type ConfirmPhoneNumberStepProps = {
  stepNumber: number;
  open: boolean;
  data: ConfirmPhoneStepData;
  setData: React.Dispatch<React.SetStateAction<ConfirmPhoneStepData>>;
  enterPhoneStepData: EnterPhoneStepData;
  setEnterPhoneStepData: React.Dispatch<
    React.SetStateAction<EnterPhoneStepData>
  >;
  onToggle: (open: boolean) => void;
  onDone: () => void;
};

const ConfirmPhoneNumberStep: FC<ConfirmPhoneNumberStepProps> = ({
  stepNumber,
  open,
  data,
  setData,
  enterPhoneStepData,
  setEnterPhoneStepData,
  onToggle,
  onDone,
}) => {
  const { phone, retryAt } = enterPhoneStepData;

  const { isCreatedNewAccount, isBookMeetingCreated } = useBookMeeting();  

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('confirm_phone_step');

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const { fetchData: reloadUserMe, loading: reloadingUserMe } = useMe({
    skip: true,
  });

  const {
    fetchData: validatePhone,
    loading: validating,
    error: backErrors,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl(`phones/${phone?.attributes?._id}/validate`),
  });

  const {
    headers: sendValidationResponseHeaders,
    fetchData: sendValidationPhone,
    loading: sendingValidation,
    error: sendValidationBackErrors,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('phones/:id/send_validation'),
  });

  const {
    formState: { errors },
    setError,
    register,
    reset,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const [retryCountDown, setRetryCountDown] = useState(0);

  const onSendValidation = useCallback(async () => {
    try {
      await sendValidationPhone(
        {},
        {
          url: buildUrl(`phones/${phone?.attributes._id}/send_validation`),
          throw: true,
          displayToastOnError: true,
        }
      );
      // eslint-disable-next-line no-empty
    } catch {}
  }, [phone?.attributes._id, sendValidationPhone]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await validatePhone(data, {
        throw: true,
        displayToastOnError: true,
      });

      await reloadUserMe(
        {},
        {
          throw: true,
          displayToastOnError: true,
        }
      );

      reset();
      setData({ confirmed: true });
      onScrollToHideStep();
      onDone();
      // eslint-disable-next-line no-empty
    } catch {}
  });

  useEffect(() => {
    if (isCreatedNewAccount) {
      onSendValidation();
    }
  }, [isCreatedNewAccount, onSendValidation]);

  useEffect(() => {
    if (sendValidationResponseHeaders) {
      setEnterPhoneStepData((oldState) => ({
        ...oldState,
        ...getRetryValues(sendValidationResponseHeaders),
      }));
    }
  }, [sendValidationResponseHeaders, setEnterPhoneStepData]);

  useEffect(() => {
    attachErrorsToFields(setError, backErrors);
    attachErrorsToFields(setError, sendValidationBackErrors);
  }, [backErrors, sendValidationBackErrors, setError]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;

    const updateCountDownValue = () => {
      if (!retryAt) {
        if (intervalId) {
          clearInterval(intervalId);
        }
        return;
      }
      const timeLeft = Math.floor((retryAt.getTime() - Date.now()) / 1000);

      if (timeLeft <= 0 && intervalId) {
        clearInterval(intervalId);
      }
      setRetryCountDown(Math.max(timeLeft, 0));
    };

    updateCountDownValue();
    intervalId = setInterval(updateCountDownValue, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [retryAt]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.confirmed}
      status={data.confirmed ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('confirm_phone_step.title')}`}
      subtitle={!!data.confirmed && t('confirm_phone_step.subtitle')}
      onToggle={onToggle}
      wrapperTestId='confirm_phone_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <p className='text-sm font-medium'>
          {t('confirm_phone_step.description')}
        </p>
        <TextField
          label={t('confirm_phone_step.fields.code')}
          placeholder={t('confirm_phone_step.fields.code_placeholder')}
          {...register('code')}
          error={getError('code', errors, errorT)}
        />

        <div>
          <p className='mb-2 text-xs'>
            {t('confirm_phone_step.sms_send_to', {
              number: phone?.attributes?.number || '',
            })}
          </p>

          {!!retryAt && retryCountDown !== 0 && (
            <p className='h-5 text-xs'>
              {t('confirm_phone_step.resend_in_seconds', {
                seconds: retryCountDown,
              })}
            </p>
          )}

          {!!retryAt && retryCountDown === 0 && (
            <Button
              type='button'
              saving={sendingValidation}
              className='!h-5 text-xs font-bold text-primary-500 hover:bg-transparent !border-0'
              innerClassName='px-0'
              onClick={onSendValidation}
            >
              {t('confirm_phone_step.resend_sms')}
            </Button>
          )}
        </div>

        <Button
          type='submit'
          color='primary'
          saving={validating || reloadingUserMe}
          data-testid='confirm_phone_step.continue'
        >
          {t('confirm_phone_step.continue')}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default ConfirmPhoneNumberStep;
