import React, { lazy } from 'react';

import { ROUTES } from '@/app/structure/body/routes/Patient.utils';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import { HOSPITAL_STAFF, ROLE } from '@/packages/types';
import { PatientProvider } from '@/app/structure/page/patients/PatientContext';
import { PatientProfileProvider } from '../../page/patients/profile/PatientProfileContext';

const Dashboard = lazy(() => import('@/app/structure/page/patients/dashboard/Dashboard'));
const EditPatientCerfa = lazy(() => import('@/app/structure/page/patients/medical-document/EditCerfa'));
const Profile = lazy(() => import('@/app/structure/page/patients/profile/Profile'));
const PatientSettingNotifications = lazy(() => import('@/app/structure/page/patients/settings/Notifications'));

const getRoutes = () => {

  const data = [];

  data.push(
    <PrivateRoute
      exact
      roles={[ROLE.PATIENT]}
      key={ROUTES.dashboard}
      path={ROUTES.dashboard}>
      <Dashboard />
    </PrivateRoute>
  );

  data.push(
    <PrivateRoute
      exact
      roles={[ROLE.PATIENT]}
      key={ROUTES.profile}
      path={ROUTES.profile}>
      <PatientProfileProvider>
        <Profile />
      </PatientProfileProvider>
    </PrivateRoute>
  );

  // Medic files
  data.push(<PrivateRoute
    exact
    roles={[...HOSPITAL_STAFF,ROLE.PATIENT]}
    key={ROUTES.medical_staff.patient.notification_settings}
    path={ROUTES.medical_staff.patient.notification_settings}
  >
    <PatientProvider>
      <PatientSettingNotifications />
    </PatientProvider>
  </PrivateRoute>
  );
  data.push(
    <PrivateRoute
      exact
      roles={HOSPITAL_STAFF}
      key={ROUTES.medical_staff.patient.cerfas}
      path={ROUTES.medical_staff.patient.cerfas}>
      <PatientProvider>
        <EditPatientCerfa />
      </PatientProvider>
    </PrivateRoute>);


  return data;
};

export default getRoutes;