import React, { ReactNode, useContext, useMemo } from 'react';
import noop from 'lodash/noop';

import { Item } from '@/packages/back-end/jsonapi';
import { GET_ME } from '@/packages/back-end/profile';
import MedicProfile, { POST_COLLECTION, PUT_ITEM } from '@/packages/back-end/medic-profile';
import useJsonAPIRequest, { UseJsonApiRequestProps } from '@/services/request/useJsonAPIRequest';
import { FetchDataType, RequestError } from '@/services/request/useRequest';
import User, { isDoctor } from '@/packages/back-end/user';
import Phone from '@/packages/back-end/phone';

const INCLUDED = [
  'professionalAddress',
  'specialties',
  'user',
  'user.phones',
  'user.phoneNumber',
];

const getRequestData = (entity: null| undefined | MedicProfile): UseJsonApiRequestProps => {
  return {
    skip: true,
    method: entity ? 'put' : 'post',
    include: INCLUDED,
    url: entity ? PUT_ITEM(entity.attributes._id,
      isDoctor(entity.relationships.user.data as User)) : POST_COLLECTION
  };
};


const MedicProfileContext = React.createContext<{
  profile: MedicProfile | null;
  userProfile: User | null;
  userProfilePhones: Phone[];
  called?: boolean;
  loading: boolean;
  saving: boolean;
  error?: RequestError;
  updateErrors?: RequestError;
  fetchProfile: FetchDataType<unknown, Item<MedicProfile>>;
  updateProfile: FetchDataType<unknown, Item<MedicProfile>>;
}>({
  profile: null,
  userProfile: null,
  userProfilePhones: [],
  called: false,
  loading: false,
  saving: false,
  updateProfile: noop as unknown as FetchDataType<unknown, Item<MedicProfile>>,
  fetchProfile: noop as unknown as FetchDataType<unknown, Item<MedicProfile>>,
});

export const MedicProfileProvider = ({ children }: { children: ReactNode }) => {
  const {
    called,
    data: profile,
    fetchData,
    loading,
    error,
  } = useJsonAPIRequest<Item<MedicProfile>>({
    url: GET_ME,
    method: 'get',
    include: INCLUDED,
  });

  const {
    fetchData: updateProfile,
    error: updateErrors,
    loading: saving,
  } = useJsonAPIRequest<Item<MedicProfile>>(getRequestData(profile?.data));

  const userProfile = useMemo(() => {
    return (profile?.data?.relationships?.user?.data as User) || null;
  }, [profile]);

  const userProfilePhones = useMemo(() => {
    return (userProfile?.relationships?.phones?.data as Phone[]) || [];
  }, [userProfile]);

  return (
    <MedicProfileContext.Provider
      value={{
        called,
        updateProfile,
        saving,
        updateErrors,
        profile: profile?.data || null,
        userProfile,
        userProfilePhones,
        loading: !called && loading,
        error,
        fetchProfile: fetchData,
      }}
    >
      {children}
    </MedicProfileContext.Provider>
  );
};

export const useMedicProfile = () => {
  return useContext(MedicProfileContext);
};
