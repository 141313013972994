import DocumentIframe from '@/components/documents/DocumentIframe';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import React from 'react';
import styled from 'styled-components';

const ButtonClose = styled(Button)`
  svg {
    width: 23px;
    height: 23px;
  }
`;

const PreviewDocument = ({
  title,
  previewUrl,
  onCloseSidebar,
}: {
  title?: string;
  previewUrl?: string;
  onCloseSidebar?: () => void;
}) => {
  return (
    <div className='flex flex-col p-2 w-full h-full'>
      <div className='flex flex-row items-center'>
        {onCloseSidebar && (<ButtonClose icon='close' className='mr-3 border-[0px]'
          color='transparent'
          onClick={onCloseSidebar} />)}
        <p className='my-3 font-medium'>{title}</p>
      </div>
      <DocumentIframe maxWidth='100%' url={previewUrl} />
    </div>
  );
};

export default PreviewDocument;
