import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { authAtom } from '@/services/store/store';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/buttons/button/Button';
import PasswordField from '@ui/atoms/fields/password/PasswordField';
import { getError } from '@/components/form/utils';

import { EnterEmailStepData } from './EnterEmailStep';
import { useScrollToHideStep } from '../../useScrollToHideStep';
import { TwoFactorMethod } from '@/auth/pages/login/types';
import { useBookMeeting } from '../../BookMeetingContext';

const schema = yup.object({
  password: yup.string().required('field_required'),
});

type FormDataType = {
  password: string;
};

export type EnterPasswordStepData = {
  isLoggedIn?: boolean;
  isResetPassword?: boolean;
  twoFactorConfigs?: {
    token: string;
    method: TwoFactorMethod;
  };
};

type EnterPasswordStepProps = {
  stepNumber: number;
  open: boolean;
  enterEmailStepData: EnterEmailStepData;
  data: EnterPasswordStepData;
  setData: React.Dispatch<React.SetStateAction<EnterPasswordStepData>>;
  onToggle: (open: boolean) => void;
  loginLoading: boolean;
  onLogin: (
    password: string
  ) => Promise<{ success: boolean; token?: string; twoFactorMethod?: TwoFactorMethod }>;
  onForgotPassword: () => void;
};

const EnterPasswordStep: FC<EnterPasswordStepProps> = ({
  stepNumber,
  open,
  enterEmailStepData,
  data,
  setData,
  onToggle,
  loginLoading,
  onLogin,
  onForgotPassword,
}) => {
  const { email } = enterEmailStepData;

  const { user: meUser } = useRecoilValue(authAtom);

  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } = useScrollToHideStep(
    'enter_password_step'
  );

  const { t: errorT } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.errors',
  });
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const {
    loading: requestResetPasswordLoading,
    fetchData: requestResetPassword,
  } = useJsonAPIRequest({
    skip: true,
    method: 'post',
    url: buildUrl('users/reset-password'),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<FormDataType>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async ({ password }) => {
    try {
      const { success, token, twoFactorMethod } = await onLogin(password);

      if (token && twoFactorMethod) {
        setData({
          isLoggedIn: false,
          twoFactorConfigs: { token, method: twoFactorMethod },
        });
        onScrollToHideStep();
      } else if (success) {
        setData({ isLoggedIn: true, isResetPassword: false });
        onScrollToHideStep();
      }
      // eslint-disable-next-line no-empty
    } catch {}
  });

  const onResetPassword = async () => {
    try {
      await requestResetPassword(
        { username: email || '', sendCode: true },
        { throw: true, displayToastOnError: true }
      );

      setData({ isLoggedIn: false, isResetPassword: true });
      onForgotPassword();
      onScrollToHideStep();
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const subtitle = useMemo(() => {

    if(data.isLoggedIn) {
      return 'enter_password_step.subtitle.logged_in';
    }

    if(data.isResetPassword) {
      return 'enter_password_step.subtitle.reset_password';
    }

    if(data.twoFactorConfigs) {
      return 'enter_password_step.subtitle.two_factor';
    }

    return undefined;

  },[data.isLoggedIn,data.isResetPassword,data.twoFactorConfigs]);


  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={
        !!data.isLoggedIn || !!data.isResetPassword || !!data.twoFactorConfigs
      }
      status={(!!data.isLoggedIn || !!data.isResetPassword || !!data.twoFactorConfigs) ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('enter_password_step.title')}`}
      subtitle={subtitle ? t(subtitle,{
        firstName: meUser?.attributes?.firstName,
      }) : undefined}
      onToggle={onToggle}
      wrapperTestId='enter_password_step'
    >
      <form
        className='flex flex-col gap-4 w-full'
        onSubmit={onSubmit}
      >
        <PasswordField
          className='w-full'
          placeholder={t('enter_password_step.fields.password_placeholder')}
          error={getError('password', errors, errorT)}
          {...register('password')}
        />

        <Button
          type='submit'
          color='primary'
          saving={loginLoading}
          data-testid='enter_password_step.continue'
        >
          {t('enter_password_step.continue')}
        </Button>

        <Button
          className='underline'
          type='button'
          color='transparent'
          saving={requestResetPasswordLoading}
          onClick={onResetPassword}
          data-testid='enter_password_step.forgot_password'
        >
          {t('enter_password_step.forgot_password')}
        </Button>
      </form>
    </RetractableItem>
  );
};

export default EnterPasswordStep;
