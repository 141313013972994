import React, {
  UIEvent,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import Checkbox from 'instamed-styleguide/ui/atoms/fields/checkbox/Checkbox';
import Dropdown from 'instamed-styleguide/ui/molecules/dropdown/Dropdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DiscussionsRow from './Row';
import { DiscussionsLoader } from '../components/DiscussionsLoader';
import { useDiscussions } from '../ChatContext';
import SearchField from '../components/SearchField';
import { UrlArg } from '@/utils/Api';
import DeleteDiscussionModal from '../right-components/DeleteDiscussionModal';
import ArchiveButton from '../right-components/ArchiveButton';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import { isMedicalStaff } from '@/packages/back-end/user';
import MarkAsUnread from '../components/MarkAsUnread';
import MarkAsReadForMedicalTeam from '@/app/structure/page/chat/components/MarkAsReadForMedicalTeam';
import MarkAsRead from '@/app/structure/page/chat/components/MarkAsRead';
import Button from '@ui/atoms/buttons/button/Button';
import FilterDropdown from '@/app/structure/page/chat/left-components/FilterDropdown';
import SpinnerLoader from '@ui/atoms/loaders/spinner/SpinnerLoader';
import RestoreDiscussionModal from '../right-components/RestoreDiscussionModal';
import useBadge from '@/utils/hooks/useBadge';
import useUpdateDiscussion from '../components/useUpdateDiscussion';
import Discussion from '@/packages/back-end/Discussion';

export const DropdownFilter = styled(Dropdown)`
  border: 0;
  span {
    padding: 0;
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #9ca7af;
    }
  }
`;

export type HeaderLeftProps = {
  refresh: () => void;
  onPaginate: () => void;
  queryParams: UrlArg;
  page: number;
  fetchData?: (params?: { page?: number }) => void;
  closeDrawer?: () => void;
  setQueryParams: (params: UrlArg) => void;
  loading?: boolean;
};

function HeaderLeft({
  refresh,
  onPaginate,
  queryParams,
  setQueryParams,
  page,
  closeDrawer,
}: HeaderLeftProps) {
  const {
    discussions,
    setDiscussions,
    setLoadingDiscussions,
    selectedDiscussion,
    loadingDiscussions,
  } = useDiscussions();
  const { t } = useTranslation('chat');
  const { user } = useRecoilValue(authAtom);
  const {
    discussions: { total },
  } = useBadge();

  const { update, loading: loadingUpdate } = useUpdateDiscussion({
    discussion: selectedDiscussion as Discussion,
    isMultipleEdit: true,
    updateAll: true,
  });

  const [canUpdateLoadingDiscussion, setCanUpdateLoadingDiscussion] = useState(false);
  const [checked, setChecked] = useState(false);

  const onSearch = useCallback(
    (value: string) => {
      if (value && value.length > 3) {
        setQueryParams({ search: value });
      } else {
        setQueryParams({ search: null });
      }
    },
    [setQueryParams]
  );

  const selectAll = () => {
    const selectValue = !checked;
    const newDiscussions = discussions.map((el) => {
      return {
        ...el,
        selected: selectValue,
      };
    });
    setDiscussions(newDiscussions);
  };

  useEffect(() => {
    if (canUpdateLoadingDiscussion) {
      setLoadingDiscussions(loadingUpdate);
    }
  }, [loadingUpdate, canUpdateLoadingDiscussion, setLoadingDiscussions]);

  const renderDiscussions = useMemo(() => {
    if (!loadingDiscussions && !discussions?.length) {
      return (
        <p className='m-3'>
          {queryParams?.search
            ? t('no_discussions_found')
            : queryParams?.participant
              ? t('no_discussions_with_user')
              : t('no_discussions')}
        </p>
      );
    }

    let isAtLeastOneSelected = false;
    const rendr = discussions.map((discussion, index: number) => {
      if (discussion.selected) {
        isAtLeastOneSelected = true;
      }
      return (
        <div
          className={`${
            index < discussions.length ? 'border-t border-gray-100' : ''
          } w-full`}
          key={`${discussion.id}-discussion-row`}
        >
          <DiscussionsRow
            isFocused={selectedDiscussion?.id === discussion.id}
            discussion={discussion}
            search={queryParams?.search as string | undefined}
            isTrash={queryParams?.trash as boolean}
            closeDrawer={closeDrawer}
          />
        </div>
      );
    });

    setChecked(isAtLeastOneSelected);

    return rendr;
  }, [
    closeDrawer,
    discussions,
    loadingDiscussions,
    selectedDiscussion?.id,
    queryParams,
    t,
  ]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e?.target as HTMLElement;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom) {
      onPaginate();
    }
  };

  const markAllAsRead = () => {
    setCanUpdateLoadingDiscussion(true);
    update(
      {
        read: true,
      },
      'toasts.marked_discussions_as_read'
    );
  };

  return (
    <div
      className='flex flex-col ml-[0.5px] lg:ml-0 w-full lg:w-1/4 min-w-[280px]'
      data-testid='discussion_header_left'
    >
      <div className='flex overflow-hidden flex-col h-full bg-white rounded-lg'>
        <div
          className={'flex flex-row justify-center items-center p-2 space-x-2'}
        >
          <div
            className={`flex flex-row sm:gap-x-1 xl:gap-x-2 ${
              checked ? 'w-full justify-between overflow-y-auto' : 'w-1/3'
            }`}
          >
            <Checkbox
              name='select-all-discussions-checkbox'
              className='pr-1 mt-[-5px]'
              checked={checked}
              onChange={() => {
                setChecked(!checked);
                selectAll();
              }}
            />
            {!checked && (
              <Button
                className='pr-1'
                color='transparent'
                icon='rewind'
                iconSize={20}
                iconHoverColor='#072337'
                iconColor='#9ba6ae'
                onClick={refresh}
                title={t('refresh')}
              />
            )}
            {!checked && (
              <FilterDropdown
                queryParams={queryParams}
                setQueryParams={setQueryParams}
              />
            )}
            {checked && (
              <div className='flex flex-row gap-4 self-end'>
                {isMedicalStaff(user) && (
                  <MarkAsReadForMedicalTeam isMultipleEdit={true} />
                )}
                {false !== queryParams?._read && (
                  <MarkAsUnread isMultipleEdit={true} />
                )}
                {false == queryParams?._read && (
                  <MarkAsRead isMultipleEdit={true} />
                )}
                <ArchiveButton refresh={refresh} isMultipleEdit={true} />

                {isMedicalStaff(user) && (
                  <DeleteDiscussionModal isMultipleEdit={true} />
                )}
                {isMedicalStaff(user) && queryParams?.trash && (
                  <RestoreDiscussionModal isMultipleEdit={true} />
                )}
              </div>
            )}
          </div>
          {!checked && (
            <div className='w-2/3'>
              <SearchField
                name='search'
                defaultValue={
                  (queryParams.search as string | undefined) ?? null
                }
                onSearch={onSearch}
              />
            </div>
          )}
        </div>
        <div className='overflow-y-auto' onScroll={handleScroll}>
          {(loadingDiscussions && !discussions.length) ? (
            <DiscussionsLoader />
          ) : (
            renderDiscussions
          )}
        </div>
        {loadingUpdate && (<p className='flex gap-2 justify-center p-2 text-sm text-center border-t border-gray-100'>
          <SpinnerLoader />
        </p>)}
        {total > 0 && !loadingUpdate &&
          queryParams._read !== false &&
          discussions.filter((discussion) => discussion.selected).length >
            1 && (
          <button onClick={markAllAsRead}>
            <p className='flex gap-2 justify-center p-2 text-sm text-center border-t border-gray-100'>
              <p className='items-center p-2 text-sm font-semibold text-center text-primary-500 bg-white'>
                {t('mark_all_as_read', { discussionsNbr: total })}
              </p>
            </p>
          </button>
        )}
        {page > 1 && loadingDiscussions ? (
          <p className='flex gap-2 justify-center p-2 text-sm text-center border-t border-gray-100'>
            <SpinnerLoader />
            <span>{t('loading_messages')}</span>
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default HeaderLeft;
