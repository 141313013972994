import React, { FC, useMemo } from 'react';
import cls from 'classnames';

import Notification from '@/packages/back-end/notification';

import H5 from '@ui/atoms/texts/base/H5';
import Link from '@ui/atoms/texts/link/Link';
import P from '@ui/atoms/texts/base/P';

import { displayDate } from '@ui/utils/dateFormat';
import ReadButton from './ReadButton';
import ArchiveButton from './ArchiveButton';

export type NotificationItemType = {
  className?: string;
  archived: boolean;
  item: Notification;
  floating?: boolean;
  onUpdated?: (item: Notification) => void;
  onDeleted?: (item: Notification) => void;
  onItemClick: (item: Notification) => void;
};

const FLOATING_CLASS =
  'fixed top-5 right-20 z-10 w-[275px] border !border-gray-100';

const NotificationItem: FC<NotificationItemType> = ({
  archived,
  item,
  onUpdated,
  onDeleted,
  onItemClick,
  floating = false,
  className,
}) => {
  const date = useMemo(
    () =>
      item.attributes.createdDate
        ? displayDate(item.attributes.createdDate)
        : null,
    [item.attributes.createdDate]
  );

  const handleClick = onItemClick ? () => onItemClick(item) : undefined;

  const linkCls = cls(
    className,
    floating ? FLOATING_CLASS : '',
    'flex flex-col py-1 px-2 rounded-lg border',
    item.attributes.read
      ? 'bg-white border-gray-100 hover:bg-gray-50'
      : 'bg-primary-100 hover:bg-primary-200 border-primary-100'
  );

  return (
    <Link
      className={linkCls}
      onClick={handleClick}
      isBlock
    >
      <div className='flex gap-4 justify-between'>
        <H5 className='mb-1 !text-sm !text-gray-900'>
          {item.attributes.title}
        </H5>
        <div className='flex'>
          <ReadButton
            archived={archived}
            item={item}
            onUpdated={onUpdated}
            onDeleted={onDeleted}
          />
          <ArchiveButton
            archived={archived}
            item={item}
            onDeleted={onDeleted}
          />
        </div>
      </div>
      <P className='leading-6 text-gray-900'>
        {item.attributes.content}
        <time className='float-right text-gray-500'>{date}</time>
      </P>
    </Link>
  );
};

export default NotificationItem;
