import {
  Entity,
  Attributes as BaseAttributes,
  Relationship,
  Collection as BaseCollection
} from '@/packages/back-end/jsonapi';
import { buildUrl } from '@/utils/Api';
import { OptionType } from '@ui/types';

export type Attributes = BaseAttributes & {
  name: string,
}

type Service = Entity & {
  attributes: Attributes,
  relationships?: {
    institution?: {
      data?: Relationship
    }
  }
}

export default Service;


export type Collection = BaseCollection & {
  data: Service[]
}


export const GET_COLLECTION = (args: Record<string, string | boolean> = {}) => {
  return buildUrl('services', null, args);
};


export const buildOption = (service: Service) : OptionType<Service> => ({
  value: service.id,
  label: service.attributes.name,
  subtitle: service.relationships?.institution?.data?.attributes?.name as string | undefined,
  data: service
});