import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import User from '@/packages/back-end/user';
import Patient from '@/packages/back-end/patient';

import Link from '@/components/link/Link';
import { getEnv } from '@/utils/importMeta';

type ConnectionSheetProps = {
  patient: Patient;
};

const ConnectionSheet: FC<ConnectionSheetProps> = ({ patient }) => {
  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'add_patient.form',
  });

  const user = patient?.relationships?.user?.data as User;

  return (
    <div className='flex flex-col gap-4 text-sm text-gray-900-09' data-testid='connection_sheet'>
      <h3 className='text-base font-bold text-black'>
        {t('connection_sheet')}
      </h3>
      <div>
        <span>{t('connection_guide_part_1')}</span>
        <Link
          className='mx-1 underline'
          href={`https://${getEnv('VITE_HOST')}/`}
        >
          {`https://${getEnv('VITE_HOST')}/`}
        </Link>
        <span>{t('connection_guide_part_2')}</span>
      </div>

      <div>
        <span>{t('connection_guide_part_3')}</span>
        <Link
          className='mx-1 underline'
          href='https://suivi.instamed.local/v2/auth/login'
        >
          https://suivi.instamed.local/v2/auth/login
        </Link>
        <span>{t('connection_guide_part_4')}</span>
      </div>

      <div>
        <div className='flex gap-1'>
          <span className='font-medium text-gray-900'>{t('identifier')}:</span>
          <span>{user?.attributes?.username || ''}</span>
        </div>

        <div className='flex gap-1'>
          <span className='font-medium text-gray-900'>{t('password')}:</span>
          <span>{user?.attributes?.plainPassword || ''}</span>
        </div>
      </div>

      <p>{t('connection_note')}</p>
    </div>
  );
};

export default ConnectionSheet;
