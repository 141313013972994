import React, { FC } from 'react';
import Email, { Attachment } from '@/packages/back-end/mssante/email';
import { useTranslation } from 'react-i18next';
import Tabs, { TabsType } from '@ui/organisms/tabs/Tabs';
import DocumentIframe from '@/components/documents/DocumentIframe';
import ContainerHeader from '@ui/atoms/containers/container-header/ContainerHeader';
import EmailContent from '@/app/structure/page/mssante/email-template/EmailContent';
import Button from '@ui/atoms/buttons/button/Button';


type SidebarProps = {
  email?: Email,
  attachment: Attachment,
  onPrevious?: () => void,
  onNext?: () => void,
}

const Sidebar: FC<SidebarProps> = ({ email, onPrevious, onNext, attachment }) => {


  const { t } = useTranslation('ms_sante', {
    keyPrefix: 'classify_document_drawer.sidebar'
  });


  const tabs: TabsType = {
    content: {
      content: (<div>
        <DocumentIframe
          url={attachment.attributes.previewUrl}
        />
        <div className='flex flex-grow justify-between py-4' >
          {<Button
            icon='arrowLeft'
            disabled={!onPrevious}
            onClick={onPrevious}
          />}
          {onNext && <Button
            icon='arrowRight'
            disabled={!onNext}
            onClick={onNext}
          />}
        </div>
      </div>
      ),
      label: t('tabs.document')
    },
    document: {
      content: (<div className='flex h-full min-h-[80vh]'><EmailContent email={email} /></div>),
      label: t('tabs.content')
    }
  };

  return (
    <div className='overflow-hidden'>
      <ContainerHeader
        title={attachment.attributes.name}
        size='large'
      />
      <div className='px-4'>
        <Tabs tabs={tabs} />
      </div>
    </div>
  );

};

export default Sidebar;