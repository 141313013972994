import { Entity, Item as BaseItem, Relationship } from '@/packages/back-end/jsonapi';
import User from '@/packages/back-end/user';
import { buildUrl } from '@/utils/Api';

type Profile = Entity & {
  attributes: {
    _id: string,
  },
  relationships: {
    user : {
      data: User | Relationship
    }
  }
}

export default Profile;

export type Item = BaseItem<Profile>

export const GET_ME = buildUrl('profile/me');
