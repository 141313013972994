import { addUrlParameters } from '@/utils/Api';

type buildSearchUrlProps = {
  specialty?: string,
  city?: string | undefined,
  search?: string | undefined,
  page?: string | undefined,
  first_letter?: string | undefined,
  isSearchPage?: boolean
  payment_methods?: string[],
  languages?: string[]
}

export const buildSearchUrl = ({
  specialty,
  page = undefined,
  city,
  search,
  payment_methods,
  languages,
  first_letter,
  isSearchPage = false
}: buildSearchUrlProps) => {


  if(isSearchPage) {

    return addUrlParameters('/recherche', {
      specialty,
      city,
      payment_methods,
      languages,
      search,
      page: page === '1' ? undefined : page
    });
  }

  if(first_letter) {
    if (page === '1' || !page) {
      return `/annuaire/${first_letter}`;
    }

    return `/annuaire/${first_letter}/page/${page}`;
  }

  if(!specialty) {

    if(city) {
      return `/docteurs/medecin-generaliste/${city}`;
    }

    return '/docteurs';
  }

  if (page === '1' || !page) {
    return `/docteurs/${specialty}${city ? `/${city}` : ''}`;
  }

  return `/docteurs/${specialty}${city ? `/${city}` : ''}/page/${page}`;

};

export const ROUTES = {
  public: {
    home: '/',
    doctors_search: '/recherche',
    doctor_profile: '/docteurs/:specialty/:city/:canonical',
    doctor_profile_legacy: '/v2/public/doctors/:canonical',
    doctors_list_all: '/docteurs',
    doctors_list: '/docteurs/:specialty',
    doctors_list_page: '/docteurs/:specialty/page/:page',
    doctors_list_specialty: '/docteurs/:specialty/:city',
    doctors_list_specialty_page: '/docteurs/:specialty/:city/page/:page',
    doctors_directory: '/annuaire/:first_letter',
    doctors_directory_page: '/annuaire/:first_letter/page/:page',
  }
};
