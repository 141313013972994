import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Notification from '@/packages/back-end/notification';

import Button from '@ui/atoms/buttons/button/Button';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';

export type ArchiveButtonType = {
  archived: boolean;
  item: Notification;
  onDeleted?: (item: Notification) => void;
};

const ArchiveButton: FC<ArchiveButtonType> = ({
  archived,
  item,
  onDeleted,
}) => {
  const { t } = useTranslation('ui', { keyPrefix: 'notifications' });
  const { loading, fetchData: updateNotification } = useJsonAPIRequest({
    skip: true,
    url: buildUrl('notifications', item.attributes._id),
    method: 'PUT',
  });

  const updateArchive = useCallback(async () => {
    try {
      await updateNotification(
        { archived: !archived },
        { throw: true, displayToastOnError: true }
      );
      // eslint-disable-next-line no-empty
    } catch {}

    onDeleted?.(item);
  }, [item, archived, onDeleted, updateNotification]);

  return (
    <Button
      saving={loading}
      title={archived ? t('unarchive_notification') : t('archive_notification')}
      color='transparent'
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      icon={archived ? 'disarchive' : 'archive'}
      onClick={(event) => {
        event.stopPropagation();
        updateArchive();
      }}
      data-testid='archive_button'
    />
  );
};

export default ArchiveButton;
