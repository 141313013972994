import React, { FC, useCallback, useState } from 'react';
import Drawer from '@/components/drawer/Drawer';
import ChooseMedic from '@/app/structure/page/admin/dashboard/export-data/ChooseMedic';
import User from '@/packages/back-end/user';
import ChooseProperties from '@/app/structure/page/admin/dashboard/export-data/ChooseProperties';
import Confirm from '@/app/structure/page/admin/dashboard/export-data/Confirm';

type ExportDataDrawerProps = {
  onClose: (changed : boolean) => void;
};


enum STEP {
  CHOOSE_MEDIC = 1,
  CHOOSE_PROPERTIES = 2,
  CONFIRM = 3,
}


export type PropertiesType = {
  variables: string[] | boolean,
  entities: string[] | boolean
  email?: string | null
};

const ExportDataDrawer : FC<ExportDataDrawerProps> = ({ onClose }) => {

  const [step,setStep] = useState<STEP>(STEP.CHOOSE_MEDIC);

  const [user,setUser] = useState<User|undefined>();

  const onExport = useCallback(() => {
    setStep(STEP.CONFIRM);
  },[]);

  const onUserSelected = useCallback((user : User) => {

    setUser(user);
    setStep(STEP.CHOOSE_PROPERTIES);

  },[]);

  const onCancel = () => { onClose(false); };

  return (<Drawer
    title='dashboard.export_data.title'
    translationDomain='admin'
    onClose={onCancel}
  >
    {step >= STEP.CHOOSE_MEDIC && <ChooseMedic onUserSelected={onUserSelected} />}
    {step >= STEP.CHOOSE_PROPERTIES && user && <ChooseProperties
      onCancel={onCancel}
      onExport={onExport} user={user} />}
    {step >= STEP.CONFIRM && <Confirm
      onCancel={onCancel}
    />}
  </Drawer>);


};

export default ExportDataDrawer;