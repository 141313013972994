import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import { buildUrl, UrlArg } from '@/utils/Api';
import { OptionType } from '@ui/organisms/selectors/types';
import DocumentType from './document-type';
import User from './user';

export type UploadedDocument = {
  key: string,
  mimeType: string,
  name: string
  previewUrl: string
}

export enum VISIBILITY {
  PUBLIC,
  PRIVATE,
  SHARED_WITH_ACCESSORS,
  SHARED_WITH_PATIENT,
  HIDDEN,
  SHARED_IN_INSTITUTION,
  HARED_WITH_PATIENT_IN_INSTITUTION,
  MEDICAL_TEAM,
  ONLY_ME,
}

export type Thumbnails = {
  fileUrl: {
    '50x50': string | null,
    '300x*': string | null,
  }
};

type Document = Entity & {
  attributes: {
    createdDate: string,
    downloadUrl: string,
    fileName: string,
    fileUrl: string,
    content?: string,
    mimeType: string,
    ownerName: string,
    previewUrl: string,
    thumbnails: Thumbnails,
    title: string,
    uploadedDate: string,
    visibility: VISIBILITY,
    _id: string,
  },
  relationships: {
    _type?: Item<DocumentType|Relationship>,
    owner?: Item<User>
  }
}

export default Document;

export const PARSE_WITH_IA = () => buildUrl('documents/parse_with_ia');

export const GET_USER_DOCUMENTS_COLLECTION = (userId : string | number, args ?: UrlArg) =>
  buildUrl(`users/${userId}/documents`,undefined,args);

export const buildOption = (item: Document) : OptionType<Document> => ({
  label: item.attributes.title,
  subtitle: (item.relationships?._type?.data?.attributes?.name as string| undefined) ?? item.attributes.ownerName,
  value: item.id,
  data: item,
});