import React, { useCallback, useEffect, useState } from 'react';
import cls from 'classnames';
import TextField from 'instamed-styleguide/ui/atoms/fields/text/TextField';
import GooglePlacesAutocomplete, {
  PlaceType
} from '../form/fields/google-places-autocomplete/GooglePlacesAutocomplete';
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch
} from 'react-hook-form';
import { ResponseData } from '../form/UpdateForm';
import { getError } from '../form/utils';
import { TFunction } from 'i18next';
import Hidden from '@/components/form/fields/hidden/Hidden';

export interface IProps {
  className?: string;
  containerClassName?: string;
  customFieldClassName?: string;
  name?: string;
  hideAddressExtension?: boolean;
  alwaysShowAddressExtension?: boolean;
  showCountry?: boolean;
  loading: boolean;
  errors: FieldErrors;
  register: UseFormRegister<ResponseData>;
  setValue: UseFormSetValue<ResponseData>;
  watch: UseFormWatch<ResponseData>;
  t: TFunction;
  addressExtension?: string;
}

enum DISPLAYED_FIELD_TYPE {
  NONE = 0,
  SIMPLE = 1,
  FULL = 2
}

const AddressField = ({
  className,
  customFieldClassName,
  t,
  name,
  hideAddressExtension,
  alwaysShowAddressExtension,
  showCountry,
  loading,
  errors,
  register,
  containerClassName,
  watch,
  setValue,
}: IProps) => {
  let addressText = '';

  const getName = (fieldName: string) =>
    name ? `${name}.${fieldName}` : fieldName;

  const addressExtension = watch(getName('addressExtension'));
  const address = watch(getName('address'));
  const id = watch(getName('id'));

  const [displayAddressFields, setDisplayAddressFields] =
    useState<DISPLAYED_FIELD_TYPE>(
      addressExtension || id ? DISPLAYED_FIELD_TYPE.SIMPLE : DISPLAYED_FIELD_TYPE.NONE
    );


  useEffect(() => {

    if(displayAddressFields !== DISPLAYED_FIELD_TYPE.NONE) {
      return;
    }

    if(id || addressExtension) {
      setDisplayAddressFields(DISPLAYED_FIELD_TYPE.SIMPLE);
    }

  }, [displayAddressFields,addressExtension,id]);


  if (address) {
    const postalCode = watch(getName('postalCode')) || '';
    const city = watch(getName('city')) || '';
    addressText = `${address}, ${postalCode} ${city}`;
  }

  const onAddressChange = useCallback((place: PlaceType) => {
    const address = {
      address: place.address,
      id,
      addressExtension,
      postalCode: place.postalCode,
      city: place.city,
      country: place.country,
      latitude: place.latitude ? place.latitude.toString() : undefined,
      longitude: place.longitude ? place.longitude.toString() : undefined
    };

    setValue(name || 'address', address);
    setDisplayAddressFields(DISPLAYED_FIELD_TYPE.SIMPLE);
  },[name,setValue,id,addressExtension]);

  return (
    <>
      <div
        className={[
          'col-span-full lg:col-span-6 xl:col-span-4',
          displayAddressFields === DISPLAYED_FIELD_TYPE.FULL ? 'sr-only' : '',
          className
        ].join(' ')}
      >
        <GooglePlacesAutocomplete
          label={t('address')}
          loading={loading}
          onChange={onAddressChange}
          error={getError(getName('address'), errors, t)}
          defaultValue={addressText}
          onManualSelection={(value: string) => {
            setDisplayAddressFields(DISPLAYED_FIELD_TYPE.FULL);
            setValue(getName('address'), value);
          }}
        />
      </div>

      <Hidden {...register(getName('id'))} />

      {displayAddressFields === DISPLAYED_FIELD_TYPE.FULL && (
        <TextField
          className={cls('col-span-full lg:col-span-6 xl:col-span-4', className)}
          error={getError(getName('address'), errors, t)}
          loading={loading}
          label={t('address')}
          {...register(getName('address'), {
            required: true
          })}
        />
      )}

      {!hideAddressExtension && (!!alwaysShowAddressExtension ||
        !!addressExtension ||
        displayAddressFields === DISPLAYED_FIELD_TYPE.SIMPLE) && (
        <TextField
          className={cls('col-span-full lg:col-span-6 xl:col-span-4', className)}
          error={getError(getName('addressExtension'), errors, t)}
          label={t('addressExtension')}
          loading={loading}
          {...register(getName('addressExtension'))}
        />
      )}

      {displayAddressFields === DISPLAYED_FIELD_TYPE.FULL && (
        <div className={cls('grid grid-cols-12 col-span-12 gap-4',containerClassName)}>
          <div className={cls('col-span-6 lg:col-span-3 xl:col-span-2', customFieldClassName || className)}>
            <TextField
              error={getError(getName('postalCode'), errors, t)}
              label={t('postalCode')}
              loading={loading}
              {...register(getName('postalCode'))}
            />
          </div>
          <div className={cls('col-span-6 lg:col-span-3 xl:col-span-2', customFieldClassName || className)}>
            <TextField
              className='flex-grow'
              loading={loading}
              error={getError(getName('city'), errors, t)}
              label={t('city')}
              {...register(getName('city'))}
            />
          </div>
          {showCountry && (
            <div className={cls('col-span-6 lg:col-span-3 xl:col-span-2', customFieldClassName || className)}>
              <TextField
                className='flex-grow'
                loading={loading}
                error={getError(getName('country'), errors, t)}
                label={t('country')}
                {...register(getName('country'))}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AddressField;
