import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BookingSlot from '@/packages/back-end/booking-slot';

import BaseSkeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import Button from '@ui/atoms/buttons/button/Button';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import useGetPaginatedCollection from '@/utils/hooks/usePaginatedData';
import { buildUrl } from '@/utils/Api';
import { formatDate } from '@/components/date-format/dateFormat';

import BookingSlotsGroup from './BookingSlotsGroup';
import { SelectEventStepData } from './SelectEventStep';

import H3 from '@ui/atoms/texts/base/H3';
import ContainerBox from '@ui/atoms/containers/container-box/ContainerBox';
import { useBookMeeting } from '../BookMeetingContext';
import { useScrollToHideStep } from '../useScrollToHideStep';
import { flatMedicProfileRelationships } from '@/app/structure/page/public/doctor-profile/book-meeting-drawer/utils';
import DateField from '@/components/form/fields/date/DateField';

type EmptyProps = {
  goToPreviousStep?: () => void,
  resetDate?: () => void
}

const Empty: FC<EmptyProps> = ({ goToPreviousStep, resetDate }) => {

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer.select_slot_step.empty',
  });

  return (<div className='flex flex-col gap-4 justify-center px-8 mt-4 text-center'>
    <H3>{t('title')}</H3>
    <p>{t('subtitle')}</p>
    <div className='flex flex-row gap-2 justify-center'>
      {goToPreviousStep && (<Button onClick={goToPreviousStep} color='primary'>{t('choose_other_reason')}</Button>
      )}
      {resetDate && (<Button onClick={resetDate} color='secondary'>{t('reset_date')}</Button>
      )}
    </div>
  </div>);

};

export const Skeleton: FC = () => {
  return (
    <ContainerBox title={<BaseSkeleton size='base' className='w-52' />}>
      <div className='flex flex-row flex-wrap gap-4 justify-center w-full'>
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
        <BaseSkeleton className='w-28 h-12' />
      </div>
    </ContainerBox>
  );
};

export type SelectSlotStepData = {
  bookingSlot?: BookingSlot;
};

type SelectSlotStepProps = {
  stepNumber: number;
  open: boolean;
  onToggle: (open: boolean) => void;
  selectEventStepData: SelectEventStepData;
  onGoToPreviousStep?: () => void;
  data: SelectSlotStepData;
  setData: React.Dispatch<React.SetStateAction<SelectSlotStepData>>;
  onDone: () => void;
};

const SelectSlotStep: FC<SelectSlotStepProps> = ({
  stepNumber,
  onGoToPreviousStep,
  open,
  onToggle,
  selectEventStepData,
  data,
  setData,
  onDone,
}) => {

  const [displayDateField, setDisplayDateField] = useState(false);

  const [openedDate, setOpenedDate] = useState<string | null>(null);

  const { profileDoctor,consultationLocation, isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('select_slot_step');

  const { user } = flatMedicProfileRelationships(profileDoctor);

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const {
    loading: loadingApi,
    data: bookingSlots,
    hasMorePages,
    queryParams,
    reset,
    setQueryParams,
    onPaginate,
  } = useGetPaginatedCollection<BookingSlot>(
    buildUrl(
      `/api/v2/users/${user?.attributes?._id}/available_slots`,
      undefined,
      {
        consultation_location: consultationLocation?.id,
        event_type: selectEventStepData?.eventType?.id,
      }
    ),
    undefined,
    undefined,
    { skip: !selectEventStepData?.eventType?.id }
  );
  const loading = loadingApi && bookingSlots.length === 0;
  const moreLoading = loadingApi && bookingSlots.length !== 0;

  const bookingSlotsGroupByDate = useMemo(() => {
    const resultByDate: Record<string, BookingSlot[]> = {};

    bookingSlots.forEach((slot) => {
      const date = slot.attributes.dateBegin.slice(0, 10);
      if (!resultByDate[date]) {
        resultByDate[date] = [];
      }
      resultByDate[date].push(slot);
    });

    return Object.entries(resultByDate).map(([date, bookingSlots]) => ({
      date,
      bookingSlots,
    }));
  }, [bookingSlots]);

  useEffect(() => {
    reset();
  }, [selectEventStepData?.eventType?.id, reset]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.bookingSlot}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={data.bookingSlot ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('select_slot_step.title')}`}
      subtitle={
        !!data.bookingSlot && formatDate(data.bookingSlot.attributes.dateBegin)
      }
      onToggle={onToggle}
      wrapperTestId='select_slot_step'
    >
      <div className='flex flex-col gap-4 w-full'>
        {!loading && !bookingSlots.length && <Empty
          resetDate={queryParams.dateBegin ? () => setQueryParams({
            dateBegin: {
              after: new Date().toISOString()
            }
          }) : undefined}
          goToPreviousStep={onGoToPreviousStep} />}

        {loading ? (
          <Skeleton />
        ) : (
          bookingSlotsGroupByDate.map(({ date, bookingSlots }, index) => {
            return (
              <BookingSlotsGroup
                key={date}
                open={openedDate ? openedDate === date : index === 0}
                setDateOpen={setOpenedDate}
                date={date}
                selectedSlot={data.bookingSlot}
                bookingSlots={bookingSlots}
                onSelect={(bookingSlot) => {
                  setData({ bookingSlot });
                  onScrollToHideStep();
                  onDone();
                }}
              />
            );
          })
        )}

        {(hasMorePages || moreLoading) && (
          <div className='flex flex-row gap-2'>
            <Button
              saving={moreLoading}
              icon='outlineEvent'
              color='primary'
              className='w-full'
              onClick={onPaginate}
            >
              {t('select_slot_step.see_more_dates')}
            </Button>
            <div className='relative'>
              <Button
                saving={moreLoading}
                icon='calendarSearch'
                onClick={() => setDisplayDateField(true)}
                title={t('select_slot_step.search_date')}
              />
              {displayDateField && (<DateField
                maxDate={selectEventStepData.eventType?.attributes.bookingClosingDate ?
                  new Date(selectEventStepData.eventType?.attributes.bookingClosingDate) : undefined}
                minDate={selectEventStepData.eventType?.attributes.bookingOpeningDate ?
                  new Date(selectEventStepData.eventType?.attributes.bookingOpeningDate) : undefined}
                onChange={(date) => {
                  setDisplayDateField(false);
                  setQueryParams({
                    dateBegin: {
                      after: date?.toISOString()
                    }
                  });
                }}
                className='absolute right-0 bottom-12 shadow-md' name='startDate'
                inline={false} />)}
            </div>
          </div>
        )}
      </div>
    </RetractableItem>
  );
};

export default SelectSlotStep;
