import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import Drawer from '@/components/drawer/Drawer';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PatientsTaggedList,
  TitleList,
} from './InfoDrawerComponents';
import useSidebarWidth from '@/components/dashboard-staff/AiDrawer/useSidebarWidth';
import PreviewDocument from './PreviewDocument';
import Document from '@/packages/back-end/document';
import { DocumentsList } from './DocDrowerComponents';
import { useDiscussions } from '../ChatContext';
import Discussion from '@/packages/back-end/Discussion';

export const DOCUMENTS_URL = (id: string) =>
  buildUrl(`${id}/documents`, undefined, {
    include: 'owner,_type',
  });

type InfoDrawerProps = {
  onClose: () => void;
  setShowSidebar: (value: boolean) => void;
};

const InfosDrawer = ({
  onClose,
  setShowSidebar,
}: InfoDrawerProps) => {
  const { t } = useTranslation('chat');
  const [fileSelected, setFileSelected] = useState<Document | null>(null);
  const { selectedDiscussion } = useDiscussions();
  const { sideBarWidth } = useSidebarWidth(true);
  const discussion = selectedDiscussion as Discussion;

  const participants = discussion?.relationships?.participants?.data;
  const ccParticipants = discussion?.relationships?.CC?.data;
  const patientsTagged = discussion?.relationships?.patientsTagged?.data;

  const { data,loading } = useJsonAPIRequest<{ data: Document[] }>({
    url: DOCUMENTS_URL(discussion?.id),
    method: 'GET',
  });

  const { filesAudio, files } = useMemo(() => {
    const filesAudio: Document[] = [];
    const files: Document[] = [];
    data?.data.forEach((file: Document) => {
      if (file.attributes.mimeType.indexOf('audio/') > -1) {
        filesAudio.push(file);
      } else {
        files.push(file);
      }
    });
    return { filesAudio, files };
  }, [data]);

  const handleClose = () => {
    setFileSelected(null);
    setShowSidebar(false);
    onClose();
  };

  return (
    <Drawer
      title={t('info_drawer.title')}
      onClose={handleClose}
      sidebar={
        fileSelected ? <PreviewDocument
          previewUrl={fileSelected?.attributes?.previewUrl}
          title={fileSelected?.attributes?.title}
          onCloseSidebar={() => {
            setFileSelected(null);
            setShowSidebar(false);
          }}
        /> : <></>
      }
      sidebarWidth={fileSelected ? sideBarWidth : 0}
    >
      <div className='flex overflow-x-hidden overflow-y-auto flex-col'>
        {participants?.length > 0 && (
          <TitleList
            participants={participants}
            title={t('info_drawer.participants')}
            icon='users'
          />
        )}
        {ccParticipants?.length > 0 && (
          <TitleList
            participants={ccParticipants}
            title={t('info_drawer.cc_participants')}
            icon='users'
          />
        )}
        {patientsTagged?.length > 0 && (
          <PatientsTaggedList
            participants={patientsTagged}
            title={t('info_drawer.tagged_patients')}
            icon='team'
          />
        )}
        <DocumentsList
          documents={files}
          loading={loading}
          emptyLabel={t('info_drawer.no_document')}
          title={t('info_drawer.documents')}
          icon='instaFolder'
          type='file'
          onClick={(newFileSelected: Document) => {
            setShowSidebar(true);
            setFileSelected(newFileSelected);
          }}
        />
        <DocumentsList
          documents={filesAudio}
          loading={loading}
          emptyLabel={t('info_drawer.no_vocal_message')}
          title={t('info_drawer.vocal_messages')}
          icon='microphone'
          type='audio'
        />
      </div>
    </Drawer>
  );
};

const useInfosDrawer = () => {
  const { updateData, openDrawer, closeDrawer } = useDrawerManager();
  const { sideBarWidth, setShowSidebar } = useSidebarWidth(false);

  useEffect(() => {
    updateData({ sidebarWidth: sideBarWidth });
  }, [updateData, sideBarWidth]);


  const onClick = useCallback(
    () => {
      openDrawer({
        content: (
          <InfosDrawer
            onClose={closeDrawer}
            setShowSidebar={setShowSidebar}
          />
        ),
        size: 'basic',
        sidebarWidth: sideBarWidth,
      });
    },
    [closeDrawer, openDrawer, setShowSidebar, sideBarWidth]
  );

  return { onClick };
};

export default useInfosDrawer;
