import React, {
  useCallback,
  UIEvent,
  useEffect,
  useRef,
  useState, useMemo,
} from 'react';
import HeaderRight from './right-components/HeaderRight';
import { buildUrl } from '@/utils/Api';
import MessageRow from './messages/Message';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import { MessagesLoaders } from './messages/MessagesComponents';
import MessageComposer from './messages/MessageComposer';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useMercure from '@/services/request/useMercure';
import { useDiscussions } from './ChatContext';
import { useMessageContext } from './messages/MessageContext';
import Message from '@/packages/back-end/message';
import useURLParams from '@/utils/hooks/useURLParams';
import SpinnerLoader from '@ui/atoms/loaders/spinner/SpinnerLoader';
import useDragToUpload from '@/utils/hooks/useDragToUpload';

const DropArea = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
`;

export const NEW_MESSAGE_URL = () =>
  buildUrl('messages', undefined, {
    include: 'document',
  });

const Discussion = () => {
  const { selectedDiscussion } = useDiscussions();
  const { search, trash } = useURLParams<{ 
    search : string | undefined;
    trash: boolean | undefined
  }>(['search', 'trash']);

  const { messages,
    addMessage,
    removeMessage,
    onPaginate,
    setSearch,
    search: searchInMessages,
    currentPage,
    loading
  } = useMessageContext();

  const { t } = useTranslation('chat');
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const { user: me } = useRecoilValue(authAtom);

  const onFileDropped = useCallback((files) => setFiles(files),[]);

  const { onDrop,onDragLeave,isDragging,onDragOver } = useDragToUpload( { onFileDropped });

  const onMercureMessage = useCallback(
    (message : Message) => {

      if(message.attributes.deleted) {
        removeMessage(message);
      } else {
        addMessage(message);
      }

    },[removeMessage,addMessage]
  );

  useMercure<Message>('messages', onMercureMessage);

  useEffect(() => {
    if (messages?.length > 0 &&messageContainerRef?.current &&currentPage === 1 ) {
      messageContainerRef.current.scrollTop = messageContainerRef?.current?.scrollHeight;
    }
  }, [messageContainerRef, messages, currentPage]);



  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e?.target as HTMLElement;
    if (target.scrollTop < 10) {
      onPaginate();
    }
  };

  const isGrouped = useMemo(() => {

    if(selectedDiscussion?.relationships.CC?.data.length) {
      return true;
    }

    return (selectedDiscussion?.relationships?.participants?.data?.length ?? 0) > 2;

  },[selectedDiscussion]);

  return (
    <div
      className='flex overflow-hidden relative flex-col flex-shrink-0 mr-[56px] w-full h-full'
      data-testid='discussion'
    >
      <HeaderRight
        me={me}
        loading={!!loading && currentPage === 1}
        searchCallback={(value?: string) => setSearch(value)}
      />
      <div
        className='flex overflow-hidden relative flex-col pt-2 mt-4 w-full 
        lg:w-full h-full lg:h-full bg-white rounded-lg'
        style={{ zIndex: 3 }}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        {isDragging && (
          <DropArea className='flex absolute flex-col justify-center items-center w-full h-full'>
            <p className='text-lg font-semibold'>
              {t('drop_document_area_title')}
            </p>
            <p className='text-base font-normal text-gray-800'>
              {t('drop_document_area_subtitle')}
            </p>
          </DropArea>
        )}
        <div
          className='flex overflow-y-auto flex-col'
          ref={messageContainerRef}
          onScroll={handleScroll}
        >
          {loading && currentPage !== 1 && (<div className='flex justify-center items-center p-2' >
            <SpinnerLoader />
          </div>)}
          {loading && currentPage === 1 ? (
            <MessagesLoaders />
          ) : (
            messages.map((message, index) => {
              const prev = _.get(messages, index - 1);
              const next = _.get(messages,index +1);
              if (!message.id || message.id === message.attributes.tmpId) {
                return <MessageRow
                  isGrouped={isGrouped}
                  key={message.id}
                  message={message}
                  loading={true} />;
              }
              return (
                <MessageRow
                  isGrouped={isGrouped}
                  key={message.id}
                  isLast={message.relationships?.owner?.data.id
                    !== next?.relationships?.owner?.data?.id || next.attributes.automatic}
                  isFirst={((message.relationships?.owner?.data.id
                    !== prev?.relationships?.owner?.data?.id) || prev.attributes.automatic)}
                  message={message}
                  search={search || searchInMessages}
                  prevMessage={prev}
                />
              );
            })
          )}
        </div>
        <div className='mt-auto'>
          <MessageComposer
            trash={trash}
            discussionId={selectedDiscussion?.id}
            filesFromDnD={files}
            disabledComposer={trash}
          />
        </div>
      </div>
    </div>
  );
};

export default Discussion;
