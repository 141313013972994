import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import { buildUrl, UrlArg } from '@/utils/Api';
import { OptionType } from '@ui/types';
import { VISIBILITY } from '@/packages/back-end/document';



type DocumentType = Entity & {
  attributes : {
    name : string
    canonial: string
    defaultVisibility : VISIBILITY
  },
  relationships: {
    parent: Item<DocumentType | Relationship>
  }
}



export default DocumentType;

export const GET_COLLECTION = (args ?: UrlArg) => buildUrl('document_types',undefined, args);

export const buildOption = (entity : DocumentType) : OptionType<DocumentType> => {
  return {
    value: entity.id,
    label: entity.attributes.name,
    subtitle: typeof entity?.relationships?.parent?.data?.attributes?.name === 'string'
      ? entity.relationships.parent.data.attributes.name : undefined,
    data: entity
  };
};