import { STRENGTHS } from '@ui/organisms/forms/change-password/ChangePasswordForm';

export const RULES = [
  { label: 'min-len-8', reg: /^.{8,}$/ },
  { label: 'one-number', reg: /[0-9]/ },
  { label: 'one-maj', reg: /[A-Z]/ },
  { label: 'one-min', reg: /[a-z]/ },
];

/**
 * return password strength
 **/
export const getPasswordStrength = (nbValidRules: number): STRENGTHS => {
  if (nbValidRules > 3) { return STRENGTHS.STRONG; }
  if (nbValidRules > 1) { return STRENGTHS.MEDIUM; }
  if (nbValidRules > 0) { return STRENGTHS.WEAK; }
  return STRENGTHS.EMPTY;
};

/**
 * check if password is strong enougth
 */
export const isValidPassword = (val: string): boolean => {
  const strength: STRENGTHS = getPasswordStrength(
    RULES.filter(
      r => r.reg.test(val)
    ).length
  );

  return strength === STRENGTHS.STRONG;
};
