import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PublicProfile from '@/packages/back-end/PublicProfile';

import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';

import OptionCard from './OptionCard';
import ConsultationLocation from '@/packages/back-end/consultation-location';
import { useBookMeeting } from '@/app/structure/page/public/doctor-profile/book-meeting-drawer/BookMeetingContext';
import Empty from '@ui/molecules/empty/Empty';


type SelectConsultationLocationStepProps = {
  stepNumber: number;
  profileDoctor?: PublicProfile;
  open: boolean;
  onToggle: (open: boolean) => void;
  onDone: () => void;
};

const SelectConsultationLocationStep: FC<SelectConsultationLocationStepProps> = ({
  stepNumber,
  open,
  onToggle,
  onDone,
}) => {

  const {
    profileDoctor,
    setConsultationLocation,
    consultationLocation,
    isBookMeetingCreated,
  } = useBookMeeting();

  const locations = profileDoctor?.relationships?.consultationLocations?.data ?? [] as ConsultationLocation[];


  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  return (
    <RetractableItem
      withSeparator={!!consultationLocation}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      status={consultationLocation ? 'checked' : undefined}
      retractableType='hidden'
      title={`${t('step')} ${stepNumber} : ${t('select_consultation_location.title')}`}
      subtitle={consultationLocation?.attributes?.name}
      onToggle={onToggle}
      wrapperTestId='select_consultation_location'
    >
      <div className='flex flex-col gap-4 w-full'>
        {!locations.length && (<Empty message={t('select_consultation_location.no_location_available')} />)}
        {locations.map((item) => (
          <OptionCard
            key={item.id}
            selected={consultationLocation?.id === item?.id}
            label={item.attributes.name}
            subtitle={item.relationships?.address
              ?.data?.attributes?.displayAddress as string | undefined}
            onClick={() => {
              setConsultationLocation(item);
              onDone();
            }}
          />
        ))}
      </div>
    </RetractableItem>
  );
};

export default SelectConsultationLocationStep;
