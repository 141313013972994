export const downloadFileFromContent = (content: string, filename: string, type: string) => {

  const link = document.createElement('a');

  const file = new Blob([content], { type });

  link.href = URL.createObjectURL(file);

  link.download = filename;

  link.click();
  URL.revokeObjectURL(link.href);

};

export const downloadFileFromUrl = async (url: string, filename: string) => {
  const data = await fetch(url);
  const blobUrl = URL.createObjectURL(await data.blob());

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = filename;
  link.click();

  URL.revokeObjectURL(link.href);
};
