import React, { useCallback, useEffect, useState } from 'react';
import { buildUrl, parseId } from '@/utils/Api';
import H1 from 'instamed-styleguide/ui/atoms/texts/base/H1';
import HeaderLeft from './left-components/HeaderLeft';
import Discussion from './Discussion';
import styled from 'styled-components';
import { LoadingDiscussion } from './components/DiscussionsLoader';
import { useTranslation } from 'react-i18next';
import useMercure from '@/services/request/useMercure';
import { useHistory, useLocation } from 'react-router-dom';
import useViewport from '@/utils/hooks/useViewport';
import MobileHeaderLeft from './left-components/MobileHeaderLeft';
import { useDiscussions } from './ChatContext';
import NewDiscussion from './NewDiscussion';
import DiscussionType, { DISCUSSIONS_URL } from '@/packages/back-end/Discussion';
import useGetPaginatedCollection from '@/utils/hooks/usePaginatedData';
import NoDiscussionFound, { NewMessageButton } from './components/NoDiscussionFound';
import { addElementToCollection } from '@/app/structure/page/chat/utils';
import { MessagesProvider } from '@/app/structure/page/chat/messages/MessageContext';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { Item } from '@/packages/back-end/jsonapi';
import useToast from '@/utils/hooks/useToast';
import NoDiscussionSelected from '@/app/structure/page/chat/components/NoDiscussionSelected';
import useSearchParams from '@/utils/hooks/useSearchParams';


const initialParamsKeys = ['search','_read','participant','archived','participant_type'];
const initialQueryParams = {
  archived: false,
};

export const DISCUSSION_URL = (id: string) => buildUrl('discussions',parseId(id), {
  include:
    'owner,participants,lastMessage,CC,patientsTagged,patientsTagged.user',
});

function Discussions() {
  const { t } = useTranslation('chat');
  const { xl, lg, md } = useViewport();
  const location = useLocation();
  const history = useHistory();
  const searchParams = useSearchParams<{ discussion?: string }>();
  const [filterChanged, setFilterChanged] = useState<boolean>(false);
  const {
    setDiscussions,
    newDiscussion,
    setNewDiscussion,
    setSelectedDiscussion,
    loadingDiscussions,
    discussions,
    selectedDiscussion,
    setLoadingDiscussions,
  } = useDiscussions();

  const { addToast } = useToast();

  const {
    fetchData: loadSingleDiscussion,
    loading: loadingSingleDiscussion
  }
    = useJsonAPIRequest<Item<DiscussionType>>({
      url: DISCUSSIONS_URL(),
      skip: true,
      method: 'get'
    });


  const onMercureMessage = useCallback(
    async (discussion : DiscussionType) => {

      const newDiscussions = addElementToCollection(discussions,discussion,'updatedDate','desc');
      setDiscussions(newDiscussions);

      if(selectedDiscussion?.id === discussion.id) {
        setSelectedDiscussion(discussion);
      }

    },
    [selectedDiscussion,setSelectedDiscussion,discussions, setDiscussions]
  );

  useMercure<DiscussionType>('discussions', onMercureMessage);


  const {
    currentPage,
    loading,
    data,
    called,
    queryParams,
    setData,
    setQueryParams,
    onPaginate,
  } =
    useGetPaginatedCollection<DiscussionType>(DISCUSSIONS_URL()
      ,initialQueryParams,undefined,{},initialParamsKeys);

  const isDesktop = (xl || lg);

  useEffect(() => {
    if (data && data.length) {
      (async () => {
        let discussionId = location.hash.replace('#', '');
        if (searchParams?.discussion) {
          discussionId = searchParams.discussion;
        }
        const discussionFound = data.find(
          (el) => `${el.attributes._id}` === discussionId
        );

        let selected : Item<DiscussionType>|undefined;

        if(discussionId && !discussionFound) {
          try {
            selected = await loadSingleDiscussion(undefined, {
              url: DISCUSSION_URL(discussionId),
              throw: true
            }) as Item<DiscussionType>;
          } catch (_e) {
            // Do nothing
          }
        }

        if(selected) {
          setSelectedDiscussion(selected.data);
          setDiscussions(data.map(item => ({
            ...item,
            selected: false
          })));
        } else {
          if(isDesktop) {
            setSelectedDiscussion(discussionFound ?? null);
          } else {
            setSelectedDiscussion(discussionFound ?? data[0]);
          }
          setDiscussions(data.map(item => ({
            ...item,
            selected: false
          })));
        }
        if (queryParams.archived !== false) {
          setFilterChanged(true);
        }
        setLoadingDiscussions(false);

      })();
    }

    if(queryParams.participant) {
      setNewDiscussion({ selected: true });
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data,searchParams?.discussion,loadSingleDiscussion,t,addToast]);

  useEffect(() => {
    if (selectedDiscussion) {
      history.push(`/v2/discussions${location.search}#${selectedDiscussion.attributes._id}`);
    }
  }, [selectedDiscussion, history, location.search]);

  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      window.location.origin +
      window.location.pathname +
      window.location.search
    );
    setFilterChanged(false);
    setSelectedDiscussion(null);
  }, [queryParams, setSelectedDiscussion, filterChanged]);


  useEffect(() => {

    if(!loading && called && !loadingSingleDiscussion && !data.length) {
      setLoadingDiscussions(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading,called,loadingSingleDiscussion,setLoadingDiscussions]);


  return (
    <DiscussionsContainer
      className='flex flex-col mt-8 mb-2 w-full'
      lg={isDesktop}
      md={md}
    >
      <MessagesProvider discussionId={selectedDiscussion?.id} >
        <div className='flex flex-row justify-between items-center mb-4'>
          <div className='flex flex-row gap-3'>
            {!isDesktop && (
              <MobileHeaderLeft
                loading={loading}
                refresh={() => {
                  setLoadingDiscussions(true);
                  setQueryParams({ page: 1 });
                  setData([]);
                  setDiscussions([]);
                }}
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                onPaginate={() => {
                  setLoadingDiscussions(true);
                  onPaginate();
                }}
                page={currentPage}
              />
            )}
            <H1 className='mb-2'>{t('discussions_title')}</H1>
          </div>
          <div className='flex gap-4' >
            <NewMessageButton
              icon='instaEdit'
              color='primary'
              data-testid='new_message_button'
              onClick={() => setNewDiscussion({ selected: true })}
            >
              {t('new_message')}
            </NewMessageButton>
          </div>
        </div>
        <div className='flex overflow-hidden flex-col lg:flex-row flex-1 pb-2 w-full h-full'>
          {isDesktop && (
            <HeaderLeft
              loading={loading}
              refresh={() => {
                setLoadingDiscussions(true);
                setQueryParams({ page: 1 });
                setData([]);
                setDiscussions([]);
              }}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              onPaginate={() => {
                onPaginate() && setLoadingDiscussions(true);
              }}
              page={currentPage}
            />
          )}
          <div className='flex lg:ml-4 w-full lg:w-3/4 h-[95%] lg:h-full'>
            {newDiscussion ? (
              <NewDiscussion setQueryParams={setQueryParams} />
            ) : loading || (loadingDiscussions && !discussions?.length) ? (
              <LoadingDiscussion />
            ) : discussions?.length && selectedDiscussion ? (
              <Discussion />
            ) : (
              discussions?.length ? <NoDiscussionSelected /> :
                <NoDiscussionFound setQueryParams={setQueryParams} queryParams={queryParams} />
            )}
          </div>
        </div>
      </MessagesProvider>
    </DiscussionsContainer>
  );
}

export const DiscussionsContainer = styled.div<{ lg: boolean, md: boolean }>`
    height: ${props => props.lg ? 'calc(100vh - 60px)' : props.md ? '100vh' : '90vh'};
`;


export default Discussions;
