import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import Button from '@ui/atoms/buttons/button/Button';
import Alert from '@ui/atoms/alerts/Alert';
import { formatPhoneNumber } from 'react-phone-number-input';

import OptionCard from './OptionCard';
import { useBookMeeting } from '../BookMeetingContext';
import { useScrollToHideStep } from '../useScrollToHideStep';
import ConsultationLocation from '@/packages/back-end/consultation-location';
import Phone from '@/packages/back-end/phone';

export type SeenDoctorStepData = {
  seenDoctor?: boolean;
  notSeenDoctor?: boolean;
};

type SeenDoctorStepProps = {
  startStepNumber?: number;
  open: boolean;
  onToggle: (open: boolean) => void;
  data: SeenDoctorStepData;
  consultationLocation?: ConsultationLocation,
  setData: React.Dispatch<React.SetStateAction<SeenDoctorStepData>>;
  onClose: () => void;
  onDone: () => void;
};

const SeenDoctorStep: FC<SeenDoctorStepProps> = ({
  startStepNumber = 1,
  consultationLocation,
  open,
  onToggle,
  data,
  setData,
  onClose,
  onDone,
}) => {
  const { isBookMeetingCreated } = useBookMeeting();

  const { stepWrapperId, onScrollToHideStep } =
    useScrollToHideStep('seen_doctor_step');

  const phoneNumber = consultationLocation?.relationships.phone?.data as Phone | undefined;

  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (data.notSeenDoctor) {
      setShowAlert(true);
    }
  }, [data.notSeenDoctor]);

  return (
    <RetractableItem
      wrapperId={stepWrapperId}
      withSeparator={!!data.seenDoctor}
      status={data.notSeenDoctor || data.seenDoctor ? 'checked' : undefined}
      open={open && !isBookMeetingCreated}
      blocked={isBookMeetingCreated}
      retractableType='hidden'
      title={`${t('step')} ${startStepNumber} : ${t('seen_doctor_step.title')}`}
      subtitle={
        data.seenDoctor
          ? t('seen_doctor_step.yes')
          : data.notSeenDoctor
            ? t('seen_doctor_step.no')
            : undefined
      }
      onToggle={onToggle}
    >
      {!data.notSeenDoctor && (
        <div className='flex flex-col gap-4 w-full'>
          <OptionCard
            selected={!!data.seenDoctor}
            label={t('seen_doctor_step.yes')}
            onClick={() => {
              setData({ ...data, seenDoctor: true, notSeenDoctor: false });
              onScrollToHideStep();
              onDone();
            }}
          />
          <OptionCard
            selected={!!data.notSeenDoctor}
            label={t('seen_doctor_step.no')}
            onClick={() =>
              setData({ ...data, seenDoctor: false, notSeenDoctor: true })
            }
          />
        </div>
      )}

      {data.notSeenDoctor && (
        <div className='flex flex-col gap-4 w-full'>
          {showAlert && (
            <Alert
              className='py-2.5 px-4 mb-4'
              dismissible
              icon='warning'
              color='warning'
              onDismiss={() => setShowAlert(false)}
            >
              <span className='text-sm'>
                <span className='mr-1'>
                  {t('seen_doctor_step.doctor_not_accept_online')}
                </span>
                {!!phoneNumber?.attributes?.number && (
                  <>
                    <span className='mr-1'>
                      {t('seen_doctor_step.contact_doctor_at')}
                    </span>
                    <span className='font-bold'>
                      {formatPhoneNumber(phoneNumber.attributes.number)}
                    </span>
                  </>
                )}
              </span>
            </Alert>
          )}

          <div className='flex gap-4 mt-2'>
            <Button
              className='w-full'
              onClick={onClose}
            >
              {t('seen_doctor_step.back_button')}
            </Button>
            <Button
              className='w-full'
              color='primary'
              onClick={() =>
                setData({ ...data, seenDoctor: false, notSeenDoctor: false })
              }
            >
              {t('seen_doctor_step.find_another_doctor_button')}
            </Button>
          </div>
        </div>
      )}
    </RetractableItem>
  );
};

export default SeenDoctorStep;
