import { useCallback, useEffect, useState } from 'react';

type useDragToUploadProps = {
  onFileDropped: (files: File[]) => void
}



const useDragToUpload = ({ onFileDropped }: useDragToUploadProps) => {

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const onPaste = useCallback((e: ClipboardEvent) => {
    if (e.clipboardData?.files) {
      onFileDropped(Array.from(e.clipboardData.files));
    }

  }, [onFileDropped]);

  useEffect(() => {
    document.addEventListener('paste', onPaste);
    return () => {
      document.removeEventListener('paste', onPaste);
    };

  }, [onPaste]);

  const onDrop = useCallback((event: {
    preventDefault: () => void;
    dataTransfer: { files: FileList };
  }) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const newFiles = Array.from(droppedFiles);
    setIsDragging(false);
    onFileDropped(newFiles);
  },[onFileDropped]);

  const onDragLeave = useCallback((event) => {
    event.preventDefault();
    if (event.currentTarget.contains(event.relatedTarget as Node)) {
      return;
    } else {
      if (isDragging) {
        setIsDragging(false);
      }
    }
  },[isDragging]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  },[isDragging]);

  return {
    onDragOver,
    onDragLeave,
    isDragging,
    onDrop
  };


};

export default useDragToUpload;