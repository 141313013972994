import { Entity } from '@/packages/back-end/jsonapi';
import { buildUrl } from '@/utils/Api';

export enum TYPE {
  'message.new' = 'message.new',
  'invoice.payment_failed' = 'invoice.payment_failed',
  'alert.new' = 'alert.new',
  'alert.new_for_patient' = 'alert.new_for_patient',
  'link_medic_to_medic.new' = 'link_medic_to_medic.new',
  'link_medic_to_medic.done' = 'link_medic_to_medic.done',
  'link_medic_to_medic.cancel' = 'link_medic_to_medic.cancel',
  'link_medic_to_medic.revert' = 'link_medic_to_medic.revert',
  'document.new' = 'document.new',
  'document.new_bulk' = 'document.new_bulk',
  'document.new_for_patient' = 'document.new_for_patient',
  'document.new_bulk_for_patient' = 'document.new_bulk_for_patient',
}

type Notification = Entity & {
  attributes: {
    _id: string
    category?: string
    content: string
    createdDate: string
    data: {
      ids: string[]
      url: string
    }
    isTask?: boolean
    read: boolean
    title: string
    _type: TYPE
  }
}

export default Notification;

export const GET_COLLECTION = (args : unknown = undefined) =>
  buildUrl('notifications',null,args as Record<string, unknown>);
export const PUT_ITEM = (id : string | number ) => buildUrl('notifications', id);
export const CLEAR_ALL = () => buildUrl('notifications/clear');
export const ARCHIVE_ALL = () => buildUrl('notifications/archive');
