import React, { FC, useEffect, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getError, optionTypeValidator } from '@/components/form/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import Drawer from '@/components/drawer/Drawer';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import { authAtom } from '@/services/store/store';
import User from '@/packages/back-end/user';
import { ROLE } from '@/packages/types';
import Patient, {
  buildOption as buildPatientOption,
} from '@/packages/back-end/patient';
import Document, { buildOption as buildDocumentOption } from '@/packages/back-end/document';

import { buildUrl } from '@/utils/Api';

import { OptionType } from '@ui/types';
import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import Button from '@ui/atoms/buttons/button/Button';
import ApiSelectorField from '@/components/form/fields/api-selector';

import { DiscussionProps } from '../../types';
import { useDiscussions } from '../../ChatContext';

export type SearchFormDataType = {
  discussion: string;
  patient: OptionType<Patient> | null;
  documents: (OptionType<Document>)[];
  shouldTagPatient?: boolean;
};

const schema = yup.object({
  documents: optionTypeValidator().required(),
});

type FooterProps = {
  onClose?: () => void;
  onSubmit?: () => void;
};

const Footer: FC<FooterProps> = ({ onClose,onSubmit }) => {
  return (
    <ContainerFooter
      className='px-4 sm:pr-8 sm:pl-8'
      size='small'
    >
      <Button
        type='button'
        onClick={onClose}
      >
        <Trans i18nKey='ui.button.cancel' />
      </Button>
      <Button
        onClick={onSubmit}
        type='submit'
        color='primary'
        data-testid='search_document_submit_button'
      >
        <Trans i18nKey='ui.button.submit' />
      </Button>
    </ContainerFooter>
  );
};

const getDefaultValues = ({
  discussion,
}: {
  discussion: DiscussionProps | null;
}): SearchFormDataType => {
  return {
    discussion: discussion?.id || '',
    patient: null,
    documents: [],
  };
};

interface SearchDocumentDrawerProps {
  onClose: () => void;
  onValidate: (value : {
    patient ?: Patient,
    documents : Document[]
  }) => void;
}

const SearchDocumentDrawer: FC<SearchDocumentDrawerProps> = ({
  onClose,
  onValidate,
}) => {
  const { user } = useRecoilValue(authAtom);
  const isPatient = user?.attributes.role === ROLE.PATIENT;

  const { selectedDiscussion,newDiscussion } = useDiscussions();

  const { t: errorT } = useTranslation('chat', { keyPrefix: 'form_errors' });
  const { t } = useTranslation('chat', { keyPrefix: 'search_document_drawer' });

  const {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    resetField,
    handleSubmit,
  } = useForm<SearchFormDataType>({
    resolver: yupResolver(schema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getDefaultValues({ discussion: selectedDiscussion }),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const patient = watch('patient');

  const participantPatient = useMemo(() => {

    if(newDiscussion) {
      return newDiscussion.participants?.find((item) => item.attributes.role === ROLE.PATIENT);
    }

    const participants = (selectedDiscussion?.relationships?.participants
      ?.data || []) as User[];

    return participants.find(
      ({ attributes }) => attributes.role === ROLE.PATIENT
    );
  }, [newDiscussion,selectedDiscussion]);

  const documentUrl = useMemo(() => {
    if (participantPatient) {
      return `users/${participantPatient?.attributes._id}/documents`;
    }
    if (patient) {
      return `users/${
        (patient.data as Patient)?.relationships?.user?.data?.attributes?._id
      }/documents`;
    }
    return '';
  }, [participantPatient, patient]);


  const onSubmit = ({ documents, shouldTagPatient, patient }: SearchFormDataType) => {

    onValidate({
      documents: documents.map((item) => item.data) as Document[],
      patient: shouldTagPatient ? patient?.data : undefined
    });
    onClose();
  };

  useEffect(() => {
    if (participantPatient) {
      setValue('patient', { value: participantPatient.id, label: '' });
    }
  }, [participantPatient, setValue]);

  return (
    <Drawer
      title={t('title')}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      footer={
        <Footer
          onSubmit={() => handleSubmit(onSubmit)()}
          onClose={onClose}
        />
      }
    >
      <div className='flex flex-col flex-grow gap-6 p-4 sm:p-8' data-testid='search_document_form'>
        {!isPatient && !participantPatient && (
          <div className='flex flex-col gap-1'>
            <ApiSelectorField<Patient>
              apiSearch
              url={buildUrl('patients', undefined, { include: 'user' })}
              name='patient'
              error={getError('patient', errors, errorT)}
              label={t('fields.search_patient')}
              buildOption={buildPatientOption}
              control={control as unknown as Control}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resetField={resetField}
            />

            {!!patient && (
              <Checkbox
                label={t('fields.tag_patient_in_message')}
                {...register('shouldTagPatient')}
              />
            )}
          </div>
        )}

        {!!documentUrl && (
          <ApiSelectorField<Document>
            apiSearch
            multiple
            url={buildUrl(documentUrl,undefined,{
              include: 'owner,_type'
            })}
            name='documents'
            error={getError('documents', errors, errorT)}
            label={t('fields.search_document')}
            buildOption={buildDocumentOption}
            control={control as unknown as Control}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetField={resetField}
          />
        )}
      </div>
    </Drawer>
  );
};

export default SearchDocumentDrawer;
