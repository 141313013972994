import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import cls from 'classnames';

import { authAtom } from '@/services/store/store';

import NotConnectedNavigationBar from '@ui/organisms/navigation-bar/NotConnectedNavigationBar';
import AppStructure from '@/app/structure/app/App';
import { ROUTES as PUBLIC_ROUTES } from '@/app/structure/body/routes/Public.utils';

import { useLayout } from './LayoutContext';
import { getEnv } from '@/utils/importMeta';

const preventLayoutPages = [PUBLIC_ROUTES.public.home];

interface MainLayoutProps {
  publicLayout?: boolean;
  children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ publicLayout, children }) => {
  const { hideLayout, actions, cancelBtnProps, logoProps } = useLayout();
  const { isConnected } = useRecoilValue(authAtom);
  const history = useHistory();

  const [preventReload, setPreventReload] = useState(false);

  const logo = useMemo(() => {
    if (logoProps) {
      return logoProps;
    }
    return {
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
        history.push('/');
        return false;
      },
      link: '/',
    };
  }, [history, logoProps]);

  useEffect(() => {
    const handleTogglePreventReload = (event: Event) => {
      const prevent = (event as unknown as { detail: boolean }).detail;
      setPreventReload(!!prevent);
    };

    document.addEventListener(
      'public-app_prevent_reload',
      handleTogglePreventReload
    );

    return () => {
      document.removeEventListener(
        'public-app_prevent_reload',
        handleTogglePreventReload
      );
    };
  }, []);

  if (hideLayout) {
    return <>{children}</>;
  }

  if (
    !publicLayout ||
    (!preventReload &&
      isConnected &&
      !preventLayoutPages.includes(history.location.pathname))
  ) {
    return <AppStructure>{children}</AppStructure>;
  }

  return (
    <div className='flex relative flex-col items-stretch max-w-full min-h-screen bg-body'>
      {!isConnected && (
        <NotConnectedNavigationBar
          logo={logo}
          baseUrl={getEnv('VITE_PRO_URL')}
          cancelBtn={cancelBtnProps}
          actions={actions}
        />
      )}
      <div
        id='app_content_wrapper'
        className={cls(
          'w-full h-full',
          !isConnected && 'mt-[70px] lg:mt-[89px]'
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
