import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Item } from '@/packages/back-end/jsonapi';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import Email, { Attachment } from '@/packages/back-end/mssante/email';

import { buildUrl } from '@/utils/Api';
import AttachmentTemplate from '@/app/structure/page/mssante/attachment-template';

import HeaderRight from './HeaderRight';
import { EMAIL_INCLUDE_PARAMS, useEmails } from '../EmailsContext';
import EmailComposer from '../email-composer';
import { LoadingEmail } from '../components/EmailsLoader';
import { useTranslation } from 'react-i18next';
import EmailContent from '@/app/structure/page/mssante/email-template/EmailContent';
import NoEmailFound from '@/app/structure/page/mssante/email-template/NoEmailFound';

export const EMAIL_URL = ({
  id,
  email,
  impersonated
}: {
  id?: string;
  impersonated?: string | number,
  email?: Email | null;
}) => {

  const apiUrl = email?.attributes?.emailType === 'sent' ? 'ms_sante_sent_emails' : 'ms_sante_emails';

  return buildUrl(apiUrl, id || email?.attributes?._id, {
    include: EMAIL_INCLUDE_PARAMS,
    impersonated,
  });
};


const EmailTemplate = () => {
  const { t } = useTranslation('ms_sante', { keyPrefix: 'email_template' });

  const { loadingEmails, selectedEmail, impersonatedUser,setSelectedEmail } = useEmails();

  const {
    loading: loadingEmail,
    data: email,
    fetchData: loadEmailDetail,
  } = useJsonAPIRequest<Item<Email>>({
    url: EMAIL_URL({
      email: selectedEmail,
      impersonated: impersonatedUser
    }),
    method: 'GET',
    skip: true,
  });

  useEffect(() => {

    if (selectedEmail?.id) {
      loadEmailDetail();
    }
  }, [selectedEmail?.id, loadEmailDetail]);


  useEffect(() => {
    if(email && selectedEmail?.id === email?.data?.id) {
      setSelectedEmail(email.data);
    }

    // Here we just want to set selected email as the email that is being loaded
    // to get all the properties up to date (read...)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<File[] | null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const emailDetail = useMemo(() => {
    return email?.data as Email | undefined;
  }, [email]);

  const handleDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { files: FileList };
  }) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const newFiles = Array.from(droppedFiles);
    setIsDragging(false);
    setFiles((prevFiles) => [...(prevFiles ? prevFiles : []), ...newFiles]);
  };

  useEffect(() => {
    setAttachments(
      (emailDetail?.relationships?.attachments?.data as Attachment[]) || []
    );
  }, [emailDetail]);


  if ((loadingEmails && !selectedEmail) || loadingEmail) {
    return <LoadingEmail />;
  }

  if (!selectedEmail) {
    return <NoEmailFound />;
  }


  return (<div className='flex overflow-hidden flex-col gap-4 w-full h-full'>
    {!loadingEmail && <HeaderRight />}

    {loadingEmail ? (
      <LoadingEmail />
    ) : (<div
      className='flex overflow-hidden relative flex-col h-[calc(100vh-230px)] bg-white rounded-lg'
      onDrop={handleDrop}
      onDragLeave={(event) => {
        event.preventDefault();
        if (event.currentTarget.contains(event.relatedTarget as Node)) {
          return;
        } else {
          if (isDragging) {
            setIsDragging(false);
          }
        }
      }}
      onDragOver={(event) => {
        event.preventDefault();
        if (!isDragging) {
          setIsDragging(true);
        }
      }}
    >
      <div className='flex overflow-auto flex-col justify-between h-full'>
        <EmailContent email={email?.data} />

        {!!attachments.length && (
          <div className='flex flex-wrap flex-shrink-0 flex-grow-0
             gap-4 px-3 pt-2 mt-3 border-t border-gray-50'>
            {attachments.map((attachment) => (
              <AttachmentTemplate
                key={attachment.id}
                email={email?.data}
                impersonated={impersonatedUser}
                attachment={attachment as Attachment}
                attachments={attachments}
                setAttachments={setAttachments}
              />
            ))}
          </div>
        )}
      </div>

      <div className='flex-shrink-0'>
        <EmailComposer
          email={email?.data}
          filesFromDnD={files}
        />
      </div>

      {isDragging && (
        <DropArea className='flex absolute flex-col justify-center items-center w-full h-full'>
          <p className='text-lg font-semibold'>
            {t('drop_document_area_title')}
          </p>
          <p className='text-base font-normal text-gray-800'>
            {t('drop_document_area_subtitle')}
          </p>
        </DropArea>
      )}
    </div>
    )}
  </div>
  );
};

const DropArea = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
`;

export default EmailTemplate;
