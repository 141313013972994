import React, { FC } from 'react';

import Drawer from '@/components/drawer/Drawer';

import UploadAiDocument from '@/app/structure/menu/AiDrawer/UploadAiDocument';
import { Trans } from 'react-i18next';
import useUploadAiDocument from '@/app/structure/menu/AiDrawer/useUploadAiDocument';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import Button from '@ui/atoms/buttons/button/Button';
import Patient from '@/packages/back-end/patient';

type AddPatientDrawerProps = {
  onClose: () => void;
  patient?: Patient;
};

type FooterProps = {
  saving: boolean;
  onClose?: () => void;
};


const Footer: FC<FooterProps> = ({ onClose,saving }) => {
  return (
    <ContainerFooter
      className='px-4 sm:px-8'
      size='small'
    >
      <Button
        type='button'
        disabled={saving}
        onClick={onClose}
      >
        <Trans i18nKey='ui.button.cancel' />
      </Button>
      <Button
        saving={saving}
        type='submit'
        color='primary'
      >
        <Trans i18nKey='ui.button.submit' />
      </Button>
    </ContainerFooter>
  );
};


const AiDrawer: FC<AddPatientDrawerProps> = ({ patient,onClose }) => {

  const { onSubmit,saving,...uploadProps } = useUploadAiDocument({ patient,closeDrawer: onClose });

  return (
    <Drawer
      isDynamicHeight
      title={'title'}
      onSubmit={onSubmit}
      translationDomain='medical_staff'
      keyPrefix='right_bar.ai_drawer'
      footer={<Footer saving={saving} onClose={onClose} />}
      onClose={onClose}
      errorMessage={null}
    >
      <div className='py-4 px-2 sm:px-9'>
        <UploadAiDocument {...uploadProps} />
      </div>
    </Drawer>
  );
};

export default AiDrawer;
