import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import Modal from 'instamed-styleguide/ui/molecules/modal/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDiscussions } from '../ChatContext';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import useToast from '@/utils/hooks/useToast';
import { ResponseError } from '@/services/request/useRequest';
import Discussion from '@/packages/back-end/Discussion';

type DeleteDiscussionModalProps = {
  isMultipleEdit?: boolean;
  discussion?: Discussion;
  iconSize?: number
};

const DeleteDiscussionModal = ({
  isMultipleEdit = false,
  iconSize = 20,
  discussion,
}: DeleteDiscussionModalProps) => {
  const { t } = useTranslation('chat');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const {
    selectedDiscussion,
    setLoadingDiscussions,
    discussions,
    setSelectedDiscussion,
    setDiscussions,
  } = useDiscussions();

  const { addToast } = useToast();
  const discussionToDelete = discussion || selectedDiscussion;

  const { fetchData: deleteDiscussion,loading } = useJsonAPIRequest<{
    no_content: boolean;
  }>({
    url: isMultipleEdit
      ? buildUrl('discussions/bulk')
      : buildUrl(discussionToDelete?.id as string),
    method: 'DELETE',
    skip: true,
  });

  const handleRemoveDiscussion = async () => {
    const res = isMultipleEdit
      ? await deleteDiscussion({
        ids: discussions.filter((d) => d.selected).map((d) => d.id),
      }, {
        throw: true,
        displayToastOnError: true
      })
      : await deleteDiscussion(null, {
        throw: true,
        displayToastOnError: true
      });

    const err = res as ResponseError;
    if (err.error) {
      setLoadingDiscussions(false);
      setOpenDeleteModal(false);
      return;
    }

    //filter not deleted and select idx
    if (discussionToDelete?.attributes.rights.delete) {
      if (isMultipleEdit) {
        const tmpDiscussions = discussions.filter(
          (d) => !d.selected
        );

        setDiscussions(tmpDiscussions);
        setSelectedDiscussion(tmpDiscussions[0]);
      } else {
        const index = discussions.findIndex(
          (d) => d.id === discussionToDelete?.id
        );
        const nextIndex =
          index === discussions.length - 1 ? index - 1 : index + 1;
        setDiscussions(
          discussions.filter((d) => d.id !== discussionToDelete?.id)
        );

        if(selectedDiscussion?.id === discussionToDelete.id) {
          setSelectedDiscussion(discussions[nextIndex]);
        }
      }

      setLoadingDiscussions(false);
      addToast('success', t('toasts.discussion_deleted', { count: isMultipleEdit ? discussions.filter(
        (d) => d.selected
      )?.length : 1 }));
      setOpenDeleteModal(false);
    } else {
      setLoadingDiscussions(false);
      setOpenDeleteModal(false);
      addToast('danger', t('toasts.discussion_not_deleted'));
    }
  };

  if(!discussionToDelete?.attributes.rights.delete) {
    return null;
  }

  return (
    <>
      <Button
        title={t('header_right.delete')}
        onClick={() => setOpenDeleteModal(true)}
        saving={loading}
        color='transparent'
        icon='trash'
        iconColor='#9ba6ae'
        iconSize={iconSize}
        iconHoverColor='#072337'

      />
      {openDeleteModal && (
        <Modal title={t('delete_discussion.title')}>
          <div className='flex flex-col'>
            <p>
              {t(
                isMultipleEdit &&
                  discussions.filter((d) => d.selected).length > 1
                  ? 'delete_discussion.subtitle_plural'
                  : 'delete_discussion.subtitle'
              )}
            </p>
            <div className='flex flex-row justify-end mt-2'>
              <Button
                color='secondary'
                disabled={loading}
                title={t('cancel')}
                className='mr-2'
                onClick={() => setOpenDeleteModal(false)}
              >
                <p>{t('cancel')}</p>
              </Button>
              <Button
                color='primary'
                saving={loading}
                title={t('yes')}
                onClick={handleRemoveDiscussion}
              >
                <p>{t('yes')}</p>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteDiscussionModal;
