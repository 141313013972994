import * as yup from 'yup';
import { OptionType } from '@ui/organisms/selectors/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

export const numberTypeValidator = (message = 'invalid_number') =>
  yup.string().test(
    'is-number',
    message,
    (value) => {
      return typeof value === 'string' && !isNaN(parseInt(value));
    });

export const optionTypeValidator = (message = 'invalid_option_value') => yup.mixed().test(
  'is-option-type-valid',
  message,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore This is an optionType
  (value: OptionType | undefined | OptionType[]) => {

    if (typeof value === 'undefined') {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length !== 0;
    } else {
      return typeof value === 'object' && value?.value;
    }
  }
);


export const removeId = (obj : unknown) => {

  // Parfois je t'aime bien Typescript, mais parfois tu fais chier
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (obj.id && obj.id !== '') {
    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newObj = { ...obj };
  delete newObj.id;
  return newObj;

};


export const fileValidator = yup.mixed().test(
  'is-file-type-valid',
  'invalid_file',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore This is an optionType
  (value: FileList | undefined ) => {

    if (typeof value === 'undefined') {
      return false;
    }

    return value.length !== 0;

  }
);



export type TranslatorType = (text: string) => string;

export function getError(
  fieldName: string | string[],
  errors: FieldErrors,
  t: TranslatorType
): undefined | string {

  if(Array.isArray(fieldName)) {
    let error = undefined;
    fieldName.forEach((field) => {
      const result = getError(field,errors,t);
      if(result) {
        error = result;
      }
    });
    return error;
  }

  let error = errors[fieldName]?.message as string | undefined;

  if (!error) {

    const flatErrors = flattenObjectSearch(errors,'',fieldName);
    error = flatErrors?.message as string | undefined;

    if(!error) {
      return undefined;
    }
  }

  const errorMessage = t(error);

  return (errorMessage.includes('errors.') || errorMessage.endsWith(error) ?
    error : errorMessage) as string | undefined;

}

function flattenObjectSearch(
  obj: FieldErrors,
  prefix = '',
  search: string,
  loopCount = 0
): FieldErrors | undefined {
  const newObj: FieldErrors = {};

  loopCount++;

  // Avoid infinite loop
  if (loopCount >= 5) {
    return undefined;
  }

  for (const key in obj) {
    const path = prefix ? `${prefix}.${key}` : key;
    if (path === search) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return obj[key];
    }

    if (Array.isArray(obj[key])) {
      (obj[key] as unknown as Array<FieldErrors>).forEach((item : FieldErrors, index : number) => {
        if (typeof item === 'object' && !Array.isArray(item)) {
          Object.assign(
            newObj,
            flattenObjectSearch(item, `${path}[${index}]`, search, loopCount)
          );
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newObj[`${path}[${index}]`] = item;
        }
      });
    } else if (typeof obj[key] === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.assign(newObj, flattenObjectSearch(obj[key], path, search, loopCount));
    } else {
      newObj[path] = obj[key];
    }
  }

  return newObj;
}