export type UrlArg = Record<string, unknown>;

/**
 *
 * @param prefix
 * @param id
 */
import { Entity } from '@/packages/types';
import { URL_DATA } from '@/constants';

/**
 *
 * @param prefix
 * @param id
 * @param args
 */
export const buildUrl = (prefix: string, id?: string | number | null, args: UrlArg = {}): string => {

  if(prefix?.startsWith('/api/v2/')) {
    prefix = prefix.replace('/api/v2/','');
  }

  if (!id) {
    return addUrlParameters(`/api/v2/${prefix}`, args);
  }

  id = parseId(id);

  return addUrlParameters(`/api/v2/${prefix}/${id}`, args);

};


export const buildDataUrl = (prefix: string, id?: string | number | null, args: UrlArg = {}) : string =>
{

  let url = buildUrl(prefix, id, args);

  url = url.replace('/api/v2/', '/');

  return `${URL_DATA}${url}`;

};


export const getUrlUnion = (url : string) : string =>
{

  if(url.endsWith('&') || url.endsWith('?')) {
    return '';
  }

  return url.includes('?') ? '&' : '?';

};


/**
 *
 * @param url
 * @param args
 */
export const addUrlParameters = (url: string, args: UrlArg = {}): string => {

  if (!args || !Object.keys(args).length) {
    return url;
  }
  // Remove undefined values
  Object.keys(args).forEach((key) => {
    if(typeof args[key] === 'undefined' || null === args[key] || '' === args[key]) {
      delete args[key];
    }
  });


  let schema = '';

  if(url.startsWith('http://') || url.startsWith('https://')) {
    [schema, url] = url.split('://');
    schema += '://';
  }

  // if my url is /api/v2/:id get :id from args and replace it
  if (url.includes(':')) {
    const splittedUrl = url.split('/');

    splittedUrl.forEach((item,key) => {
      if(!item.includes(':')) {
        return;
      }
      const idKey = item.replace(':', '');
      
      if(idKey && args[idKey]) {
        splittedUrl[key] = args[idKey] as string;
        delete args[idKey];
      }
    });

    url = splittedUrl.join('/');
  }

  url = schema + url;

  if (!args || !Object.keys(args).length) {
    return url;
  }

  return url + getUrlUnion(url) + parseToUrlString(args);

};


// Thanks ChatGPT2 for this snippet
export const parseToUrlString = (args: UrlArg, prefix = ''): string => {

  return Object.keys(args)
    .map(key => {
      const value = args[key];
      const currentPrefix = prefix ? `${prefix}[${key}]` : key;
      if (Array.isArray(value)) {
        return value.map((val: UrlArg) => typeof val === 'object' ?
          parseToUrlString(val, `${currentPrefix}[]`) :
          `${currentPrefix}[]=${val}`).join('&');
      } else if (typeof value === 'object') {
        return parseToUrlString(value as UrlArg, currentPrefix);
      } else {
        return `${currentPrefix}=${value}`;
      }
    })
    .filter((str => !!str))
    .join('&');
};



/**
 *
 * @param id
 * @param asInt
 */
export const parseId = (id: string | undefined | null | number, asInt?: boolean): string | number | null => {

  if (typeof id === 'number') {
    return id;
  }

  if (!id) {
    return null;
  }

  const splittedId: string[] = id.split('/');

  id = splittedId[splittedId.length - 1];

  return asInt ? parseInt(id) : id;

};


export const mergeArrays = (data1: Entity[], data2: Entity[], addBefore = false): Entity[] => {

  const ids = data1.map((datum) => {
    return datum.id;
  });

  data2.forEach((datum) => {

    const index = ids.indexOf(datum.id);

    if (index === -1) {
      if (addBefore) {
        data1.unshift(datum);
        ids.unshift(datum.id);
      } else {
        data1.push(datum);
        ids.push(datum.id);
      }
    } else {
      data1[index] = datum;
    }
  });

  return [...data1];

};
