import React, { useCallback } from 'react';

import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import SearchDocumentDrawer, {
  SearchFormDataType,
} from './SearchDocumentDrawer';

const useSearchDocumentDrawer = ({
  onSubmit,
}: {
  onSubmit?: (data: SearchFormDataType) => void;
}) => {
  const { openDrawer, closeDrawer } = useDrawerManager();

  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onOpen = useCallback(() => {
    openDrawer({
      content: (
        <SearchDocumentDrawer
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ),
      size: 'medium',
    });
  }, [onClose, openDrawer, onSubmit]);

  return { onOpen, onClose };
};

export default useSearchDocumentDrawer;
