import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RetractableItem from '@ui/molecules/retractable-item/RetractableItem';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import Button from '@ui/atoms/buttons/button/Button';

type ConfirmProps = {
  onCancel: () => void;
}


const Confirm : FC<ConfirmProps> = ({ onCancel }) => {


  const { t } = useTranslation('admin', {
    keyPrefix: 'dashboard.export_data.confirm'
  });

  return <RetractableItem
    open={true}
    status={'checked'}
    title={t('title')}
    subtitle={t('subtitle.exported')}
  >
    <ContainerFooter>
      <Button
        onClick={onCancel}
        color='primary'
      >{t('buttons.close')}</Button>
    </ContainerFooter>
  </RetractableItem>;
  
};

export default Confirm;