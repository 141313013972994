import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState, SetStateAction, Dispatch,
} from 'react';
import { MeUser } from '@/packages/back-end/user';
import PublicProfile from '@/packages/back-end/PublicProfile';
import PublicProfileSearch from '@/packages/back-end/public-profile-search';
import ConsultationLocation from '@/packages/back-end/consultation-location';
import EventType from '@/packages/back-end/event-types';
import BookingSlot from '@/packages/back-end/booking-slot';

export type BookMeetingDefaultData = {
  publicProfileSearch?: PublicProfileSearch;
  publicProfile?: PublicProfile;
  consultationLocation?: ConsultationLocation;
  eventType?: EventType;
  bookingSlot?: BookingSlot;
}

type BookMeetingContextProps = {
  defaultData?: BookMeetingDefaultData;
  setDefaultData: Dispatch<SetStateAction<BookMeetingDefaultData|undefined>>
  profileDoctor?: PublicProfile;
  setProfileDoctor: (profileDoctor?: PublicProfile) => void;
  initMeUser?: MeUser;
  setInitMeUser: (initMeUser?: MeUser) => void;
  consultationLocation?: ConsultationLocation;
  setConsultationLocation: (consultationLocation?: ConsultationLocation) => void;
  meUserAfterLogin?: MeUser;
  setMeUserAfterLogin: (meUserAfterLogin?: MeUser) => void;
  numOfAccountSteps: number;
  setNumOfAccountSteps: (num: number) => void;
  hasInitialConsultationLocation: boolean;
  isCreatedNewAccount: boolean;
  setIsCreatedNewAccount: (bol: boolean) => void;
  isForceHideConfirmPhoneNumberStep: boolean;
  setIsForceHideConfirmPhoneNumberStep: (bol: boolean) => void;
  isBookMeetingCreated: boolean;
  onBookMetingCreated: () => void;
};

const BookMeetingContext = createContext<BookMeetingContextProps>({
  defaultData: undefined,
  setDefaultData: () => {
    throw new Error('setDefaultData not implemented');
  },
  profileDoctor: undefined,
  setProfileDoctor: () => {
    throw new Error('setProfileDoctor not implemented');
  },
  initMeUser: undefined,
  setInitMeUser: () => {
    throw new Error('setInitMeUser not implemented');
  },
  meUserAfterLogin: undefined,
  setMeUserAfterLogin: () => {
    throw new Error('setMeUserAfterLogin not implemented');
  },
  consultationLocation: undefined,
  setConsultationLocation: () => {
    throw new Error('setConsultationLocation not implemented');
  },
  numOfAccountSteps: 0,
  setNumOfAccountSteps: () => {
    throw new Error('setNumOfAccountSteps not implemented');
  },
  hasInitialConsultationLocation: false,
  isCreatedNewAccount: false,
  setIsCreatedNewAccount: () => {
    throw new Error('setIsCreatedNewAccount not implemented');
  },
  isForceHideConfirmPhoneNumberStep: false,
  setIsForceHideConfirmPhoneNumberStep: () => {
    throw new Error('setIsForceHideConfirmPhoneNumberStep not implemented');
  },
  isBookMeetingCreated: false,
  onBookMetingCreated: () => {
    throw new Error('onBookMetingCreated not implemented');
  },
});

export const BookMeetingProvider = ({
  defaultData: defaultDataProps,
  profileDoctor: profileDoctorProp,
  consultationLocation: consultationLocationProp,
  initMeUser: initMeUserProp,
  onBookMeetingCreated,
  children,
}: {
  defaultData?: BookMeetingDefaultData
  profileDoctor?: PublicProfile;
  consultationLocation?: ConsultationLocation;
  initMeUser?: MeUser;
  onBookMeetingCreated?: () => void;
  children: ReactNode;
}) => {

  const [defaultData, setDefaultData] = useState(defaultDataProps);

  const [profileDoctor, setProfileDoctor] = useState(
    profileDoctorProp || defaultData?.publicProfile
  );
  const [initMeUser, setInitMeUser] = useState(initMeUserProp);
  const [meUserAfterLogin, setMeUserAfterLogin] = useState(initMeUserProp);
  const [numOfAccountSteps, setNumOfAccountSteps] = useState(0);
  const [consultationLocation, setConsultationLocation] = useState(
    consultationLocationProp || defaultData?.consultationLocation
  );

  const [isCreatedNewAccount, setIsCreatedNewAccount] = useState(false);
  const [
    isForceHideConfirmPhoneNumberStep,
    setIsForceHideConfirmPhoneNumberStep,
  ] = useState(false);
  const [isBookMeetingCreated, setIsBookMeetingCreated] = useState(false);

  const keepRef = useRef({ onBookMeetingCreated });
  keepRef.current.onBookMeetingCreated = onBookMeetingCreated;

  const handleBookMetingCreated = useCallback(() => {
    setIsBookMeetingCreated(true);
    keepRef.current.onBookMeetingCreated?.();
  }, []);

  return (
    <BookMeetingContext.Provider
      value={{
        defaultData,
        profileDoctor,
        setProfileDoctor,
        initMeUser,
        setInitMeUser,
        setDefaultData,
        consultationLocation,
        setConsultationLocation,
        meUserAfterLogin,
        setMeUserAfterLogin,
        hasInitialConsultationLocation:
          !!consultationLocationProp ||
          (!!defaultData?.publicProfileSearch && !!defaultData?.bookingSlot),
        numOfAccountSteps,
        setNumOfAccountSteps,
        isCreatedNewAccount,
        setIsCreatedNewAccount,
        isForceHideConfirmPhoneNumberStep,
        setIsForceHideConfirmPhoneNumberStep,
        isBookMeetingCreated,
        onBookMetingCreated: handleBookMetingCreated,
      }}
    >
      {children}
    </BookMeetingContext.Provider>
  );
};

export const useBookMeeting = () => useContext(BookMeetingContext);
