import Discussion from '@/packages/back-end/Discussion';
import React, { ReactNode, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';
import { UserWithId } from './types';
import useURLParams from '@/utils/hooks/useURLParams';

export type NewDiscussion = {
  selected?: boolean;
  participants?: UserWithId[];
}

type ChatContextProps = {
  discussions: Discussion[];
  setDiscussions: Dispatch<SetStateAction<Discussion[]>>
  newDiscussion: NewDiscussion | null;
  setNewDiscussion: (discussion: NewDiscussion | null) => void;
  selectedDiscussion: Discussion | null;
  setSelectedDiscussion: Dispatch<SetStateAction<Discussion|null>>
  loadingSelectedDiscussion: boolean;
  setLoadingSelectedDiscussion: (loading: boolean) => void;
  loadingDiscussions: boolean;
  setLoadingDiscussions: (loading: boolean) => void;
};

const DiscussionsContext = createContext<ChatContextProps>({
  // discussions
  discussions: [] as Discussion[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDiscussions: () => {
    throw new Error('setDiscussions not implemented');
  },
  // loader for discussions
  loadingDiscussions: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLoadingDiscussions: (_value: boolean) => {
    throw new Error('setLoadingDiscussions not implemented');
  },
  // loader for selectedDiscussion
  loadingSelectedDiscussion: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setLoadingSelectedDiscussion: (_value: boolean) => {
    throw new Error('setLoadingSelectedDiscussion not implemented');
  },
  // new discussion
  newDiscussion: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setNewDiscussion: (_value: NewDiscussion | null) => {
    throw new Error('setNewDiscussion not implemented');
  },
  // the discussion selected (shown on the right part of the screen)
  selectedDiscussion: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedDiscussion: () => {
    throw new Error('setSelectedDiscussion not implemented');
  },
});

export const DiscussionsProvider = ({ children }: { children: ReactNode }) => {
  const { participant } = useURLParams<{participant : string | undefined}>(['participant']);

  const [discussions, setDiscussions] = useState<Discussion[]>([]);
  const [loadingDiscussions, setLoadingDiscussions] = useState<boolean>(true);
  const [loadingSelectedDiscussion, setLoadingSelectedDiscussion] =
    useState<boolean>(true);
  const [selectedDiscussion, setSelectedDiscussion] =
    useState<Discussion | null>(null);
  const [newDiscussion, setNewDiscussion] = useState<NewDiscussion | null>(
    participant ? { selected: true } : null
  );

  return (
    <DiscussionsContext.Provider
      value={{
        discussions,
        setDiscussions,
        selectedDiscussion,
        setSelectedDiscussion,
        newDiscussion,
        setNewDiscussion,
        loadingSelectedDiscussion,
        setLoadingSelectedDiscussion,
        loadingDiscussions,
        setLoadingDiscussions,
      }}
    >
      {children}
    </DiscussionsContext.Provider>
  );
};

export const useDiscussions = () => useContext(DiscussionsContext);
