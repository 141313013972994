import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import { formatDate } from '@/components/date-format/dateFormat';
import { useTranslation } from 'react-i18next';
import { MEDIUM_GRAY } from '../messages/MessageComposer';
import { DocumentThumbnail } from '../documents/DocumentRow';
import Document from '@/packages/back-end/document';
import Skeleton from '@ui/atoms/loaders/skeleton/Skeleton';
import { IconWithTitle } from './InfoDrawerComponents';

const RowSkeleton = () => (
  <div className='flex gap-2 w-full'>
    <Skeleton className='w-[30px] h-[50px]' />
    <div className='flex flex-col gap-3 w-full'>
      <Skeleton className='w-full' size='base' />
      <Skeleton className='w-full' size='small' />
    </div>
  </div>
);

export const DocumentsList = ({
  documents,
  emptyLabel,
  icon,
  onClick,
  title,
  loading,
  type,
}: {
  documents: Document[];
  emptyLabel: string;
  icon: string;
  loading: boolean;
  onClick?: (file: Document) => void;
  title: string;
  type: 'audio' | 'file';
}) => {
  return (
    <div className='flex overflow-hidden flex-col px-4 mt-8'>
      <IconWithTitle icon={icon} title={title}
        className='!w-full' />
      <div className={cls('overflow-y-auto mt-6 w-full max-h-[185px]')}>
        {documents?.length ? (
          documents?.map((file, index) =>
            type === 'audio' ? (
              <AudioRow
                audio={file}
                isLast={index >= documents.length - 1}
                key={`${file.id}-drawer-file-row`}
              />
            ) : (
              <div
                key={`${file.id}-drawer-file-row`}
                className={`flex flex-row pb-2 mb-2 space-x-2 items-start w-full
            ${index >= documents.length - 1 ? '' : 'border-b border-gray-100'}`}
                style={{ zIndex: 1 }}
              >
                <button
                  className='mr-3 rounded-md'
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(file);
                  }}
                >
                  <DocumentThumbnail
                    onClick={() => onClick && onClick(file)}
                    document={file.attributes}
                    className='!object-contain w-[32px] h-[50px]'
                  />
                </button>
                <button
                  className='w-8/12'
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(file);
                  }}
                >
                  <p className='text-sm font-semibold text-left truncate'>
                    {file.attributes.fileName}
                  </p>
                  <p className='text-xs text-left text-gray-900-09 truncate'>
                    <span>{`${file.relationships?.owner?.data?.attributes?.firstName[0]}.
                 ${file.relationships?.owner?.data?.attributes?.lastName}`}</span>{' '}
                    <span>{type === 'file'
                      ? ` • ${file.relationships?._type?.data?.attributes?.name}`
                      : ''}</span>
                  </p>
                </button>
                <div className='flex flex-col'>
                  <p className='w-2/12 text-xs text-left text-gray-900-09'>
                    {formatDate(file.attributes.uploadedDate, {
                      format: 'DD/MM/YYYY',
                    })}
                  </p>
                  <div className='flex flex-row-reverse mb-2'>
                    <a
                      className='px-1 pt-1'
                      download
                      href={file.attributes.downloadUrl}
                    >
                      <Icon icon='download' color={MEDIUM_GRAY} />
                    </a>
                    <button
                      className='px-1 pt-1'
                      onClick={(e) => {
                        e.preventDefault();
                        onClick && onClick(file);
                      }}
                    >
                      <Icon icon='eye' color={MEDIUM_GRAY} />
                    </button>
                  </div>
                </div>
              </div>
            )
          )
        ) : loading ? (
          <div className='flex flex-col gap-4'>
            <RowSkeleton />
            <RowSkeleton />
          </div>
        ) : (
          <p className='text-sm text-gray-700'>{emptyLabel}</p>
        )}
      </div>
    </div>
  );
};

export const AudioRow = ({
  audio,
  isLast,
}: {
  audio: Document;
  isLast: boolean;
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { t } = useTranslation('chat');
  const audioRef = useRef(new Audio(audio?.attributes?.fileUrl));

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.load();
    }
  }, [isPlaying]);

  return (
    <button
      type='button'
      onClick={() => setIsPlaying(!isPlaying)}
      className={`flex flex-row pb-2 mb-2 space-x-1 items-start w-full ${
        isLast ? '' : 'border-b border-gray-100'
      }`}
    >
      <div className='flex flex-row w-9/12'>
        <Icon
          icon={isPlaying ? 'stop' : 'play'}
          color='#517085'
          className='my-auto'
        />
        <div className='ml-4'>
          <p className='text-sm font-semibold text-left truncate'>
            {t('vocal_message.vocal_note_title', {
              date: formatDate(audio.attributes.uploadedDate, {
                format: 'DD MMM YYYY',
              }),
            })}
          </p>
          <p className='text-xs text-left text-gray-900-09 truncate'>
            {audio.relationships?.owner?.data?.attributes?.fullName}
          </p>
        </div>
      </div>
      <p className='w-2/12 text-xs text-right text-gray-900-09'>
        {formatDate(audio.attributes.uploadedDate, {
          format: 'DD/MM/YYYY',
        })}
      </p>
    </button>
  );
};
