import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import prosante_logo from '@ui/organisms/forms/login/prosante_connect.svg';
import Modal from '@ui/molecules/modal/Modal';
import Image from '@ui/atoms/images/image/Image';
import LinkButton from '@/components/link/LinkButton';
import { CONTEXT } from '@/packages/back-end/pro-sante-connect-profile';
import useProSanteConnect from '@/auth/hooks/useProSanteConnect';
import useToast from '@/utils/hooks/useToast';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { isDoctor } from '@/packages/back-end/user';

type ReconnectionModalProps = {
  link: string | null;
  impersonated?: string | number | undefined;
  refreshCallback?: () => Promise<unknown>;
  onClose: () => void;
};

const ReconnectionModal: FC<ReconnectionModalProps> = ({ link: baseLink,impersonated, onClose }) => {
  const { t } = useTranslation('ms_sante',{
    keyPrefix: 'reconnection_drawer'
  });

  const { user } = useRecoilValue(authAtom);

  const { addToast } = useToast();
  
  const [link,setLink] = useState<string|null>(baseLink);

  const { waitUntilProfileIsValid } = useProSanteConnect({
    impersonated,
    context: CONTEXT.REFRESH
  });

  useEffect(() => {
    setLink(baseLink);
  }, [baseLink]);


  useEffect(() => {

    if(isDoctor(user) || !impersonated || !link) {
      return;
    }

    waitUntilProfileIsValid().then(() => {
      addToast('success',t('toasts.success'));
      setLink('');
    });

  }, [waitUntilProfileIsValid,addToast,user,impersonated,t,link]);

  if (!link) {
    return null;
  }

  return (
    <Modal
      title={t('title')}
      footer={
        isDoctor(user) && (<button
          className='flex w-full'
          onClick={async () => {
            await waitUntilProfileIsValid();
            addToast('success',t('toasts.success'));
            setLink('');
          }}
        >
          <LinkButton
            className='flex gap-[10px] w-full'
            target='_blank'
            href={link}
          >
            <div className='flex gap-3 items-center w-full'>
              <Image
                className='h-6'
                src={prosante_logo}
                alt={t('link_button')}
              />
              <span>{t('link_button')}</span>
            </div>
          </LinkButton>
        </button>)
      }
      onDismiss={onClose}
    >
      <div className='flex flex-col m-2 space-y-4'>
        <span className='text-gray-900'>{isDoctor(user) ? t('detail') : t('detail_as_assistant')}</span>
      </div>
    </Modal>
  );
};

export default ReconnectionModal;
