import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CONTEXT, } from '@/packages/back-end/pro-sante-connect-profile';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import { buildUrl } from '@/utils/Api';
import { useMeProfile } from '@/utils/hooks/useMeProfile';
import Button from '@ui/atoms/buttons/button/Button';
import TextField from '@ui/atoms/fields/text/TextField';
import { useEmails } from './EmailsContext';
import prosante_logo from '@ui/organisms/forms/login/prosante_connect.svg';
import useProSanteConnect from '@/auth/hooks/useProSanteConnect';
import CircleLoader from '@ui/atoms/loaders/circle-loader/CircleLoader';
import P from '@ui/atoms/texts/base/P';
import useToast from '@/utils/hooks/useToast';
import { RequestError } from '@/services/request/utils';

type CreateUserProps = {
  email: string;
};

const CreateForm = () => {
  const { t } = useTranslation('ms_sante', { keyPrefix: 'connection' });

  const { data: profile } = useMeProfile();
  const { setSecuredMessaging, setPageRefreshing, refreshing, onRefresh } =
    useEmails();

  const [showStep2,setShowStep2] = useState(false);

  const { addToast } = useToast();

  const { onProSanteConnect, profile: pscProfile,loading: pscLoading } = useProSanteConnect({
    context: CONTEXT.REFRESH,
    target: '_blank',
  });

  const { loading, fetchData: createAccount } =
    useJsonAPIRequest<CreateUserProps>({
      url: buildUrl('ms_sante_users'),
      method: 'post',
      skip: true,
    });

  const { register, reset, handleSubmit } = useForm<CreateUserProps>();

  useEffect(() => {

    if (!profile) {
      return;
    }

    if (profile.data.attributes.emailMsSante) {
      reset({
        email: profile.data.attributes.emailMsSante,
      });
    }
  }, [reset, profile]);

  const onSubmit = async (data: CreateUserProps) => {
    await createAccount(data, {
      throw: true,
      displayToastOnError: true,
    });
    setPageRefreshing(true);
    try {
      await onRefresh();
      setSecuredMessaging(true);
    } catch (e) {
      addToast('danger',(e as RequestError).message);
    }
  };

  if(refreshing) {
    return (<div className='flex flex-col gap-4 justify-center items-center p-8 w-full h-full bg-white rounded-xl'>
      <CircleLoader />
      <P className='w-2/3 text-center' >{t('retrieving_emails')}</P>
    </div>);
  }

  return (
    <div className='flex flex-col gap-4 p-8 w-full bg-white rounded-xl'>
      <P className='text-xl font-bold'>{t('connect_to_ms_sante')}</P>
      <div>
        <P className='mb-0'>{t('connection_explanation')}</P>
        <P>{t('connection_benefit')}</P>
      </div>
      <P>{t('guide_title')}</P>

      <div>
        <P className='font-bold'>{t('guide_step_1')}</P>
        <P>{t('guide_step_1_detail')}</P>
      </div>

      <Button
        className='border-gray-900'
        saving={pscLoading}
        type='button'
        disabled={showStep2}
        image={prosante_logo}
        imageAlt={t('login_button')}
        onClick={async () => {

          await onProSanteConnect();

          addToast('success',t('toasts.success'));
          setShowStep2(true);

        }}
      >
        {t('login_button')}
      </Button>

      {showStep2 && (<div>
        <P className='font-bold'>{t('guide_step_2')}</P>
        <P>{t('guide_step_2_detail')}</P>
      </div>)}

      {showStep2 && (<TextField
        type='email'
        readOnly={!pscProfile}
        placeholder={t('email_placeholder')}
        {...register('email')}
      />)}

      {showStep2 && (<Button
        type='button'
        disabled={!pscProfile}
        saving={loading || refreshing}
        color='primary'
        onClick={handleSubmit(onSubmit)}
      >
        {t('link_button')}
      </Button>)}
    </div>
  );
};

export default CreateForm;
