import { Entity, Item, Relationship } from '@/packages/back-end/jsonapi';
import EventType from '@/packages/back-end/event-type';
import User from '@/packages/back-end/user';
import Specialty from '@/packages/back-end/specialty';
import { buildUrl, parseId, UrlArg } from '@/utils/Api';
import Patient from './patient';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ConsultationLocation from './consultation-location';
import { IconType } from '@ui/atoms/icons/icons';
import { Translator } from '@/utils/translation';
import { ROLE } from '@/packages/types';
import Address from '@/packages/back-end/address';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum STATUS {
  STATUS_IN_WAITING_ROOM = 1,
  STATUS_IN_CONSULTATION = 2,
  STATUS_SEEN = 3,
  STATUS_ABSENT_EXCUSED = 4,
  STATUS_ABSENT_UNEXCUSED = 5,
}

type Event = Entity & {
  attributes: {
    name: string;
    dateBegin: string;
    dateEnd: string;
    fullDay: boolean;
    duration?: number;
    notes?: string | null;
    status?: STATUS | null;
    visibility?: number;
    cancellationDate?: string | null;
    cancellationReason?: number | null;
    cancellationReasonOther?: string | null;
    unregisteredParticipants?: string[];
    cancelled?: boolean | null;
    color?: string;
    recurring?: boolean;
    recurringDays?: number[];
    recurringInterval?: number;
    recurringEndType?: string;
    repeatEndCount?: number;
    recurringStopDate?: string;
    eventRecurringDisplayValue?: string;
    rights?: {
      update_appointment?: boolean;
      update?: boolean;
      delete?: boolean;
      cancel?: boolean;
      uncancel?: boolean;
    }
  };
  relationships: {
    owner?: Item<Relationship | User>;
    patient?: Item<Relationship | Patient>;
    participants?: Item<(Relationship | User)[]>;
    _type?: Item<EventType | Relationship>;
    specialty?: Item<Specialty | Relationship>;
    consultationLocation?: Item<ConsultationLocation | Relationship>
  };
};

export default Event;

export const POST_COLLECTION = (args?: UrlArg) => buildUrl('events', undefined, args);

export const UPDATE_PARTICIPANTS =
  (id?: string | number,args?: UrlArg) =>
    buildUrl(`events/${parseId(id) ?? ''}/participants`, undefined, args);

export const DELETE_PARTICIPANT =(
  id?: string | number,
  participantId ?: string | number,
  args?: UrlArg
) =>
  buildUrl(`events/${parseId(id) ?? ''}/participants`, participantId, args);
export const PUT_ITEM = (id?: string | number, args?: UrlArg) => {
  return buildUrl('events', id, args);
};


export const PUT_APPOINTMENT = (id?: string | number, args?: UrlArg) => {
  return buildUrl('appointments', id, args);
};

export const DELETE_ITEM = (id: string | number, args?: UrlArg) => {
  return buildUrl('events', id, args);
};

export const DOWNLOAD_EVENTS = () => buildUrl('events', 'download');
export const DOWNLOAD_EVENT = (id?: string | number) =>
  buildUrl(`events/${id}`, 'download');

type EventInfos = {
  name : string,
  details: string,
  location: string
}

const getEventInfos = (event: Event | undefined,t : Translator) : EventInfos => {

  const doctor = event?.relationships?.participants?.data?.find(
    (participant) => participant?.attributes?.role !== ROLE.PATIENT
  ) as User | undefined;

  const type = event?.relationships?._type?.data as EventType | undefined;

  const location = event?.relationships?.consultationLocation?.data as ConsultationLocation | undefined;

  const address = location?.relationships?.address?.data as Address | undefined;

  return {
    name: event?.attributes?.name || doctor ? t('ui.appointment.doctor',{
      doctor: doctor?.attributes?.name
    }) : type?.attributes.name || '',
    details: event?.attributes?.notes || '',
    location: address ? address.attributes.displayAddress as string : ''
  };


};
export const GOOGLE_CALENDAR = (event: Event | undefined,t : Translator) => {
  const { dateBegin, dateEnd } = event?.attributes || {};

  let dateBeginValue = '';
  if (dateBegin) {
    dateBeginValue = dayjs.utc(dateBegin || '').format('YYYYMMDDTHHmm') + '00Z';
  }
  let dateEndValue = '';
  if (dateEnd) {
    dateEndValue = dayjs.utc(dateEnd || '').format('YYYYMMDDTHHmm') + '00Z';
  }

  const { name, location,details } = getEventInfos(event,t);


  const paramList: string[] = [
    `text=${name}`,
    `details=${details}`,
    `dates=${dateBeginValue}/${dateEndValue}`,
    `location=${location}`,
    `ctz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
  ];
  return encodeURI(
    `https://calendar.google.com/calendar/u/0/r/eventedit?${paramList.join(
      '&'
    )}`
  );
};

export const OUTLOOK_CALENDAR = (event: Event | undefined,t : Translator) => {
  const { dateBegin, dateEnd } = event?.attributes || {};

  let dateBeginValue = '';
  if (dateBegin) {
    dateBeginValue = dayjs.utc(dateBegin).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  }
  let dateEndValue = '';
  if (dateEnd) {
    dateEndValue = dayjs.utc(dateEnd).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  }

  const { name, location,details } = getEventInfos(event,t);


  const paramList: string[] = [
    'rru=addevent',
    `subject=${name || ''}`,
    `body=${details}`,
    `startdt=${dateBeginValue}`,
    `enddt=${dateEndValue}`,
    `location=${location}`,
  ];
  return encodeURI(
    `https://outlook.live.com/calendar/action/compose?${paramList.join('&')}`
  );
};

export const APPLE_CALENDAR = (event: Event | undefined,t : Translator) => {
  const { _id, dateBegin, dateEnd } = event?.attributes || {};
  let dateBeginValue = '';
  if (dateBegin) {
    dateBeginValue = dayjs.utc(dateBegin).format('YYYYMMDDTHHmmss') + 'Z';
  }
  let dateEndValue = '';
  if (dateEnd) {
    dateEndValue = dayjs.utc(dateEnd).format('YYYYMMDDTHHmmss') + 'Z';
  }

  const { name, location,details } = getEventInfos(event,t);

  const fileContent = [
    'VERSION:2.0',
    'BEGIN:VCALENDAR',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    `PRODID:-//${_id}//EN`,
    'BEGIN:VEVENT',
    `UID:${_id}`,
    `DTSTART;VALUE=DATE-TIME:${dateBeginValue}`,
    `DTEND;VALUE=DATE-TIME:${dateEndValue}`,
    `SUMMARY:${name}`,
    `DESCRIPTION:${details}`,
    `LOCATION:${location}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');

  return window.URL.createObjectURL(
    new File([fileContent], 'calendar.ics', { type: 'text/plain' })
  );
};


export const getIcon = (type : STATUS) : IconType => {

  const types = {
    [STATUS.STATUS_SEEN]: 'check',
    [STATUS.STATUS_ABSENT_UNEXCUSED]: 'warningOutline',
    [STATUS.STATUS_IN_CONSULTATION]: 'signature',
    [STATUS.STATUS_ABSENT_EXCUSED]: 'personOffOutline',
    [STATUS.STATUS_IN_WAITING_ROOM]: 'waitingRoom'
  };

  return types?.[type] ?? 'check';

};