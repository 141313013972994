import useRequest from '@/services/request/useRequest';
import { buildUrl } from '@/utils/Api';
import { useParams } from 'react-router-dom';
import { OnlineAppointmentContextType, searchContextAtom } from '@/services/store/store';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useRef } from 'react';
import useSearchParams from '@/utils/hooks/useSearchParams';

const loadings : Record<string, boolean> = {};

const useOnlineAppointmentContext = () => {

  const [context, setContext] = useRecoilState(searchContextAtom);
  const { city: cityUrl, specialty: specialtyUrl,first_letter } =
    useParams<{ city: string, specialty?: string, first_letter?: string}>();

  const { city: cityParams, specialty: specialtyParams } = useSearchParams();

  const city = ((cityUrl || cityParams) ?? undefined) as string | undefined;
  const specialty = ((specialtyUrl || specialtyParams) ?? undefined) as string | undefined;

  const setDoctorName = useCallback((doctorName: string | undefined) => {
    setContext((prevState) => ({
      ...prevState,
      doctorName,
      data: {
        ...prevState.data,
      }
    }));
  },[setContext]);

  const { fetchData } = useRequest<OnlineAppointmentContextType>({
    url: buildUrl('online_appointments', 'context', {
      city,
      specialty
    }),
    method: 'get',
    skip: true
  });

  // Use a ref to prevent the effect from running multiple times unnecessarily
  const hasFetchedRef = useRef(false);

  useEffect(() => {

    // If data for the current city and specialty is already loaded, do not fetch again
    if (context.city === city && context.specialty === specialty) {
      if(context.data?.cities?.length > 0 && context.data?.specialties?.length > 0) {
        return;
      }
    }

    // Avoid redundant fetching if another component or state change triggers this effect again
    if (hasFetchedRef.current) {
      return;
    }

    if(loadings[`${city}-${specialty}`]) {
      return;
    }
    loadings[`${city}-${specialty}`] = true;

    // Set loading state to true
    setContext((prevState) => ({
      ...prevState,
      isLoading: true
    }));

    hasFetchedRef.current = true;

    // Fetch data and update context state
    fetchData().then((fetchedData) => {
      setContext((prevState) => ({
        ...prevState,
        isLoading: false,
        city,
        specialty,
        data: fetchedData as OnlineAppointmentContextType
      }));
      hasFetchedRef.current = false; // Allow future fetches if city/specialty changes
      loadings[`${city}-${specialty}`] = false;
    }).catch(() => {
      setContext((prevState) => ({
        ...prevState,
        isLoading: false
      }));
      hasFetchedRef.current = false; // Allow fetch retry in case of error
      loadings[`${city}-${specialty}`] = false;
    });

  }, [city, specialty, fetchData, setContext, context.data?.cities?.length,
    context.data?.specialties?.length, context.city, context.specialty]);

  return {
    isLoading: context.isLoading,
    data: context.data,
    doctorName: context.doctorName,
    firstLetter: first_letter,
    city,
    setDoctorName,
  };
};

export default useOnlineAppointmentContext;
