import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Email from '@/packages/back-end/mssante/email';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import useToast from '@/utils/hooks/useToast';
import { buildUrl } from '@/utils/Api';

import Button from '@ui/atoms/buttons/button/Button';
import Modal from '@ui/molecules/modal/Modal';

import { useEmails } from '../EmailsContext';

interface DeleteButtonProps {
  email?: Email | null;
  iconSize?: number;
}

const DeleteButton: FC<DeleteButtonProps> = ({ email, iconSize = 20 }) => {
  const { t } = useTranslation('ms_sante');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { onDeleteEmail,impersonatedUser: impersonated } = useEmails();

  const { addToast } = useToast();

  const { loading, fetchData: deleteEmail } = useJsonAPIRequest({
    url: buildUrl('ms_sante_emails', email?.attributes?._id,{
      impersonated
    }),
    method: 'DELETE',
    skip: true,
  });

  const handleRemoveEmail = async () => {

    if(!email) {
      return;
    }

    await deleteEmail(undefined, { throw: true, displayToastOnError: true });
    setOpenDeleteModal(false);
    addToast('success', t('toasts.email_deleted'));
    onDeleteEmail(email);
  };

  if (!email) {
    return null;
  }


  if(email?.attributes.emailType === 'sent') {
    return null;
  }

  return (
    <>
      <Button
        title={t('header_right.delete')}
        saving={loading}
        color='transparent'
        icon='trash'
        iconColor='#9ba6ae'
        iconSize={iconSize}
        iconHoverColor='#072337'
        onClick={(event) => {
          event.stopPropagation();
          setOpenDeleteModal(true);
        }}
      />

      {openDeleteModal && (
        <Modal title={t('delete_email.title')}>
          <div className='flex flex-col'>
            <p>{t('delete_email.subtitle')}</p>
            <div className='flex flex-row justify-end mt-2'>
              <Button
                color='secondary'
                title={t('cancel')}
                className='mr-2'
                disabled={loading}
                onClick={() => setOpenDeleteModal(false)}
              >
                {t('cancel')}
              </Button>
              <Button
                color='primary'
                title={t('yes')}
                saving={loading}
                onClick={handleRemoveEmail}
              >
                {t('yes')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteButton;
