import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BookingSlot from '@/packages/back-end/booking-slot';

import DateFormat from '@/components/date-format/dateFormat';
import Button from '@ui/atoms/buttons/button/Button';
import ContainerBox from '@ui/atoms/containers/container-box/ContainerBox';

const LIMIT_COLLAPSE = 8;

type BookingSlotsGroupProps = {
  open: boolean;
  setDateOpen: (date: string) => void;
  date: string;
  selectedSlot?: BookingSlot;
  bookingSlots: BookingSlot[];
  onSelect: (slot: BookingSlot) => void;
};

const BookingSlotsGroup: FC<BookingSlotsGroupProps> = ({
  open,
  setDateOpen,
  date,
  selectedSlot,
  bookingSlots,
  onSelect,
}) => {
  const { t } = useTranslation('public', {
    keyPrefix: 'public_profile.book_meeting_drawer',
  });

  const [showAll, setShowAll] = useState(false);

  const displayBookingSlots = useMemo(() => {
    if (showAll) {
      return bookingSlots;
    }
    return bookingSlots.slice(0, LIMIT_COLLAPSE);
  }, [showAll, bookingSlots]);

  return (
    <ContainerBox
      togglable
      className='overflow-hidden'
      open={open}
      onToggle={() => setDateOpen(date)}
      containerClassName='!p-0'
      title={
        <DateFormat
          value={date}
          format='dddd DD MMMM'
        />
      }
    >
      <div className='flex flex-col w-full'>
        <div
          className='grid grid-cols-4 gap-4 p-4 w-full'
          data-testid='select_slot_step.group'
        >
          {displayBookingSlots.map((bookingSlot) => {
            return (
              <Button
                key={bookingSlot.id}
                color={ bookingSlot.id === selectedSlot?.id ? 'primary' : 'secondary' }
                onClick={() => onSelect(bookingSlot)}
              >
                <DateFormat
                  className='font-bold'
                  value={bookingSlot.attributes.dateBegin}
                  format='HH:mm'
                />
              </Button>
            );
          })}
        </div>

        {bookingSlots.length > LIMIT_COLLAPSE && (
          <div className='border-t border-gray-100'>
            <Button
              className='w-full !text-primary-500 rounded-none !border-0'
              onClick={() => setShowAll(!showAll)}
            >
              {t(
                showAll
                  ? 'select_slot_step.see_less'
                  : 'select_slot_step.see_more'
              )}
            </Button>
          </div>
        )}
      </div>
    </ContainerBox>
  );
};

export default BookingSlotsGroup;
