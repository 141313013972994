import { UrlArg } from '@/utils/Api';
import React, { FC, ReactNode } from 'react';
import { DropdownFilter } from '@/app/structure/page/mssante/left-components/HeaderLeft';
import { useTranslation } from 'react-i18next';
import { Pelet } from '@/app/structure/page/chat/left-components/Row';

type FilterDropdownProps = {
  queryParams: UrlArg,
  setQueryParams: (params: UrlArg) => void;
}

const FilterDropdown: FC<FilterDropdownProps> = ({ setQueryParams, queryParams }) => {

  const { t } = useTranslation('ms_sante');

  const hasFilter = queryParams?.archived === true ||
    typeof queryParams?.sent === 'boolean' ||
    queryParams?.archived === 'true' ||
    queryParams?.classified === false ||
    (queryParams?.read === false);

  return ( <DropdownFilter
    color='transparent'
    className='relative'
    popperClassName='!z-50'
    innerClassName='!gap-0'
    iconSize={20}
    iconHoverColor='#072337'
    iconColor='#9ba6ae'
    icon='filter'
    title={t('filter')}
    items={[
      {
        children: t('filters.all'),
        selected: !queryParams.archived &&
          (queryParams.read === null || queryParams.read === undefined) &&
          (queryParams.sent === null || queryParams.sent === undefined ) &&
          (queryParams.classified === null || queryParams.classified === undefined),
        onClick: () => setQueryParams({ archived: false, read: null, sent: null, classified: null }),
      },
      {
        children: t('filters.not_read'),
        selected: queryParams.read === false,
        onClick: () => setQueryParams({ read: queryParams.read === false ? null : false }),
      },
      {
        children: t('filters.to_classify'),
        selected: queryParams.classified === false,
        onClick: () => setQueryParams({ classified: queryParams.classified === false ? null : false,sent: null }),
      },
      {
        children: t('filters.archived'),
        selected: queryParams.archived === true,
        onClick: () => setQueryParams({ archived: !queryParams.archived, sent: null }),
      },
      {
        children: t('filters.sent'),
        selected: queryParams.sent === true,
        onClick: () => setQueryParams({ sent: queryParams.sent ? null : true, classified: null }),
      },
    ]}
  >
    {hasFilter ? (<Pelet className='absolute right-[-5px] bottom-[-5px]' />) as ReactNode : ''}
  </DropdownFilter>);

};

export default FilterDropdown;