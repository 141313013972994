import React, { useMemo, useState } from 'react';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import { MessageArrow, MessagesDateIndicator, TimeParagraph } from './MessagesComponents';
import { renderHighlightedText } from '../utils';
import DocumentRow from '../documents/DocumentRow';
import DeleteMessageModal from './DeleteMessageModal';
import PatientRow from '../components/PatientRow';
import Patient from '@/packages/back-end/patient';
import { formatDate } from '@/components/date-format/dateFormat';
import { useTranslation } from 'react-i18next';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import { MEDIUM_GRAY } from './MessageComposer';
import Message from '@/packages/back-end/message';
import Document from '@/packages/back-end/document';
import cls from 'classnames';
import UserAvatar from '@ui/molecules/user-avatar/UserAvatar';
import { getAvatarProps } from '@/utils/sharing/avatar/avatar';
import UserType from '@/utils/sharing/types/user';

const MessageRow = ({
  message,
  search,
  loading,
  isGrouped,
  isFirst,
  isLast,
  prevMessage,
}: {
  isLast?: boolean,
  isGrouped?: boolean,
  isFirst?: boolean,
  message: Message;
  search?: string;
  loading?: boolean;
  prevMessage?: Message;
}) => {
  const { automatic, content, createdDate, read, scheduled } =
    message.attributes || {};
  const { owner, patient, documents } = message.relationships || {};
  const { user } = useRecoilValue(authAtom);
  const { t } = useTranslation('chat');
  const [isMessageHover, setIsMessageHover] = useState(false);

  const isMyMessage = user?.attributes?._id === owner.data?.attributes?._id;
  const containerClasses = cls('flex mx-4 my-1 flex-row items-end', {
    'flex-row-reverse': isMyMessage
  });
  const classes = cls('relative inline-flex flex-col min-w-[80px] gap-1 p-2 rounded-2xl bg-[#f3f4f5]',
    { '!bg-[#333c9d]': isMyMessage });
  const textClasses = `${isMyMessage ? '!text-white' : ''}`;

  const DocumentMessage = useMemo(() => {
    if (!documents?.data?.length) {
      return <></>;
    }

    return documents?.data?.map((document: Document) => (
      <DocumentRow
        document={document}
        owner={owner}
        key={`message-row-document-${document.id}`}
      />
    ));
  }, [documents?.data, owner]);

  if (automatic) {
    return (
      <div className='flex justify-center p-4'>
        {renderHighlightedText(
          content as string,
          search,
          'text-xs text-gray-700'
        )}
      </div>
    );
  }

  const marginClass = (!isMyMessage && !isLast && isGrouped ? 'ml-[36px]' : '');

  return (
    <>
      <MessagesDateIndicator prev={prevMessage} message={message} />
      <div className='py-1 px-2 w-full'>
        <div className={cls('max-w-[75%]', {
          'ml-auto': isMyMessage,
          'mr-auto': !isMyMessage,
        })}>
          <div
            className={containerClasses}
            onMouseEnter={() => setIsMessageHover(true)}
            onMouseLeave={() => setIsMessageHover(false)}
            data-testid='chat_message'
          >
            <div className='flex flex-col justify-start'>
              {isFirst && isGrouped && owner.data.attributes?.fullName &&
              !isMyMessage &&
                (<p className={'mr-[3px] ml-[36px] text-xs text-gray-400-09'}>
                  {owner.data.attributes.fullName as string}
                </p>)}
              <div className='flex flex-row gap-2 items-end' >
                {isLast && !isMyMessage && isGrouped && <UserAvatar {...getAvatarProps({
                  user: owner.data.attributes as UserType,
                  colorLength: 10
                })} />}
                <div className={cls(classes,marginClass)} data-testid='chat_message_content'>
                  {renderHighlightedText(content as string, search, (textClasses + ' whitespace-pre-line'))}
                  {DocumentMessage}
                  {patient && <PatientRow patient={patient?.data as Patient} />}
                  <TimeParagraph
                    className={textClasses}
                    loading={loading}
                    createdDate={createdDate}
                    isMyMessage={isMyMessage}
                  />
                  {isLast && <MessageArrow isMyMessage={isMyMessage} />}
                </div>

              </div>
              {scheduled && (
                <div className='flex flex-row justify-end items-center mt-[2px]'>
                  <p className='mr-[3px] text-xs text-gray-400-09'>
                    {t('message_composer.scheduled_for', {
                      date: formatDate(createdDate),
                    })}
                  </p>
                  <Icon icon='clock' width={12}
                    height={12} color={MEDIUM_GRAY} />
                </div>
              )}
            </div>
            {isMyMessage && read?.length === 1 && (
              <DeleteMessageModal
                className={isMessageHover ? 'visible' : 'invisible'}
                message={message} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageRow;
