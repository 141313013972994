import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import BaseLink, { LinkButtonProps } from '@ui/atoms/texts/link-button/LinkButton';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import { isWebappUrl } from '@/utils/router';



const LinkButton: FC<LinkButtonProps & { disabled ?: boolean }> = ({ href,disabled, onClick,children, ...rest }) => {

  const history = useHistory();

  if(!onClick && href && isWebappUrl(href)) {
    onClick = (e) => {
      history.push(href);
      e.preventDefault();
    };
  }

  if(disabled) {
    return (<Button
      disabled
      {...rest}
    >{children}
    </Button>);
  }

  return (<BaseLink
    onClick={onClick}
    href={href}
    {...rest}
  >{children}
  </BaseLink>);
};


export default LinkButton;