export const ROUTES = {
  // These are the patient routes that only staff has access to
  medical_staff: {
    patient: {
      cerfas: '/v2/patients/:patientId/cerfas',
      notification_settings: '/v2/patients/:patientId/settings/notifications',
    }
  },
  dashboard: '/v2/patients/dashboard',
  'profile': '/v2/patients/profile'
};
