import React, { useState } from 'react';
import { FC } from 'react';
import { GET_COLLECTION } from '@/packages/back-end/service';

import ApiSelectorField, { ApiSelectorFieldProps } from '@/components/form/fields/api-selector';

type ServiceFieldProps = Omit<ApiSelectorFieldProps,'url'|'renderLabel'|'onChange'> & {
  hideIfSingleValue ?: boolean
};

const ServiceField : FC<ServiceFieldProps> = ({ hideIfSingleValue = false,...props }) => {

  const [displayField,setDisplayField] = useState<boolean>(true);

  if(!displayField) {
    return null;
  }


  return (<ApiSelectorField
    multiple={true}
    onLoaded={(values) => {

      if(!hideIfSingleValue) {
        return;
      }

      if(values.length < 2) {
        setDisplayField(false);
      }
    }}
    url={GET_COLLECTION({ mine: true })}
    {...props}
  />);
};

export default ServiceField;
