import React from 'react';
import User from '@/packages/back-end/user';
import { TFunction } from 'i18next';
import { DiscussionProps } from './types';
import cls from 'classnames';
import { Entity } from '@/packages/back-end/jsonapi';
import { formatDate as baseFormatDate } from '@/components/date-format/dateFormat';

export const renderHighlightedText = (
  text: string,
  search?: string,
  className?: string
) => {
  if (!search || !text) {
    return <p className={className}>{text}</p>;
  }

  const regex = new RegExp(search, 'gi'); // 'g' for global matches, 'i' for case-insensitivity
  const matches = [...text.matchAll(regex)];

  if (matches.length === 0) {
    return <p className={className}>{text}</p>;
  }

  const parts = text.split(regex);

  return (
    <p className={cls(className, matches.length ? 'mb-2' : '')}>
      {parts.map((part, index) => (
        <>
          {part}
          {index < matches.length && (
            <span className='bg-[#82daed]' data-testid='highlighted'>
              {matches[index][0]}
            </span>
          )}
        </>
      ))}
    </p>
  );
};

export const getParticipants = ({
  discussion,
  me,
  t,
}: {
  discussion: DiscussionProps;
  me?: User;
  t: TFunction;
}) => {

  const meString = (t('me') ?? '');

  const participants = discussion?.relationships?.participants?.data
    .map((participant) =>
      participant.id === me?.id ? meString : participant.attributes.fullName
    );

  const index = participants?.findIndex((item) => item === meString);

  if(participants?.length === 2 && index != -1) {
    participants.splice(index,1);
  }

  return participants?.join(', ') ?? '';

};


export const addElementToCollection =
  <T extends Entity>(
    collection : T[],
    item : T,
    orderKey? : keyof T['attributes'],
    order : 'desc' | 'asc' = 'asc'
  ) : T[] => {
    const foundIndex = collection.findIndex((obj) =>
      (obj.id === item.id || (item.attributes.tmpId && obj.attributes.tmpId === item.attributes.tmpId)));
    let newCollection: T[];


    if (foundIndex === -1) {
      newCollection = [item, ...collection];
    } else {
      newCollection = collection.map((obj, index) => (
        index === foundIndex ? item : obj
      ));
    }

    if(!orderKey || !(orderKey in item.attributes)) {
      return newCollection;
    }

    return sortCollection(newCollection,orderKey,order);

  };

export const sortCollection = <T extends Entity>(
  collection : T[],
  orderKey : keyof T['attributes'],
  order : 'desc' | 'asc' = 'asc'
) : T[] => {

  return collection.sort((a, b) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new Date((a.attributes[orderKey])) > new Date(b.attributes[orderKey]) ?
      (order === 'desc' ? -1 : 1) :
      (order === 'desc' ? 1 : -1)
  );
};

export function generateUniqueId() : string {
  const timestamp = new Date().getTime(); // Current time in milliseconds since the epoch
  const randomPart = Math.random().toString(36).substring(2, 15); // Random string using base-36 encoding
  return timestamp + '_' + randomPart;
}


export const formatDate = (objectDate : string) => {
  const today = new Date();
  const discussionDate = objectDate;

  if (
    baseFormatDate(today, { format: 'DD MM YYYY' }) ===
    baseFormatDate(discussionDate, { format: 'DD MM YYYY' })
  ) {
    return baseFormatDate(discussionDate, {
      format: 'HH:mm',
    }).replace(':', 'h');
  }
  if (
    baseFormatDate(today, { format: 'YYYY' }) ===
    baseFormatDate(discussionDate, { format: 'YYYY' })
  ) {
    return baseFormatDate(discussionDate, {
      format: 'DD/MM',
    });
  }
  return baseFormatDate(discussionDate, {
    format: 'DD/MM/YYYY',
  });
};



export const getHTMLBody = (title : string,content : string,iframeId : string) => {

  return `<!doctype html>
<html lang="fr">
<head>
    <meta charset="UTF-8">
    <meta name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>${title}</title>
</head>

<style>
    html,body {
        font-family: "sans-serif";
        scrollbar-width: thin;
        scrollbar-color: theme('colors.gray.100');
        overflow: initial;
    }

    *::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    
    *::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.gray.100');
    }
        
    img {
        max-width: 100%;
    }
    
</style>

<body>
    ${content}
</body>



<script type="text/javascript">
    
    const body = document.querySelector('body');
    
    body.style.height = body.scrollHeight + 'px';
    
    function sendHeight() {
  const height = document.documentElement.scrollHeight;
  window.parent.postMessage({
    frameHeight: height,
    frameId: '${iframeId}' 
  }, "*");
}

document.addEventListener('DOMContentLoaded', sendHeight);

window.onload = sendHeight; 
    
</script>

</html>`;

};
