import React, { FC, ReactNode, useMemo } from 'react';

import NotConnectedNavigationBar from '@ui/organisms/navigation-bar/NotConnectedNavigationBar';
import TwoColumnsTemplate from '@ui/templates/two-columns/TwoColumns';

import TypingLoginLabels from '@/auth/structure/typing-login-labels/TypingLoginLabels';
import { useLayout } from './LayoutContext';
import { getEnv } from '@/utils/importMeta';

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  const { hideLayout, cancelBtnProps, logoProps } = useLayout();

  const logo = useMemo(() => {
    if (logoProps) {
      return logoProps;
    }
    return { link: 'https://www.instamed.fr' };
  }, [logoProps]);

  if (hideLayout) {
    return <>{children}</>;
  }

  return (
    <div className='flex overflow-hidden flex-col h-screen'>
      <NotConnectedNavigationBar
        logo={logo}
        baseUrl={getEnv('VITE_PRO_URL')}
        cancelBtn={cancelBtnProps}
      />
      <TwoColumnsTemplate
        containerClassName='mt-[70px] lg:mt-[89px]'
        footerRight={<></>}
      >
        <TypingLoginLabels />
        {children}
      </TwoColumnsTemplate>
    </div>
  );
};

export default AuthLayout;
