import React, { FC, useMemo } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getError, optionTypeValidator } from '@/components/form/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import Drawer from '@/components/drawer/Drawer';
import ContainerFooter from '@ui/atoms/containers/container-footer/ContainerFooter';
import { authAtom } from '@/services/store/store';
import { ROLE } from '@/packages/types';
import Patient, {
  buildOption as buildPatientOption,
} from '@/packages/back-end/patient';
import { buildOption as buildDocumentOption } from '@/packages/back-end/document';

import { buildUrl } from '@/utils/Api';

import { OptionType } from '@ui/types';
import Button from '@ui/atoms/buttons/button/Button';
import ApiSelectorField from '@/components/form/fields/api-selector';

export type SearchFormDataType = {
  patient: OptionType | null;
  documents: OptionType[];
};

const schema = yup.object({
  documents: optionTypeValidator().required(),
});

type FooterProps = {
  onClose?: () => void;
};

const Footer: FC<FooterProps> = ({ onClose }) => {
  return (
    <ContainerFooter
      className='px-4 sm:pr-8 sm:pl-8'
      size='small'
    >
      <Button
        type='button'
        onClick={onClose}
      >
        <Trans i18nKey='ui.button.cancel' />
      </Button>
      <Button
        type='submit'
        color='primary'
      >
        <Trans i18nKey='ui.button.submit' />
      </Button>
    </ContainerFooter>
  );
};

const getDefaultValues = () => {
  return {
    patient: null,
    documents: [],
  };
};

interface SearchDocumentDrawerProps {
  onClose: () => void;
  onSubmit?: (data: SearchFormDataType) => void;
}

const SearchDocumentDrawer: FC<SearchDocumentDrawerProps> = ({
  onClose,
  onSubmit: onSubmitProp,
}) => {
  const { user } = useRecoilValue(authAtom);
  const isPatient = user?.attributes.role === ROLE.PATIENT;

  const { t: errorT } = useTranslation('chat', { keyPrefix: 'form_errors' });
  const { t } = useTranslation('chat', { keyPrefix: 'search_document_drawer' });

  const {
    control,
    formState: { errors },
    watch,
    resetField,
    handleSubmit,
  } = useForm<SearchFormDataType>({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
  });

  const patient = watch('patient');

  const documentUrl = useMemo(() => {
    if (patient) {
      return `users/${
        (patient.data as Patient)?.relationships?.user?.data?.attributes?._id
      }/documents`;
    }
    return '';
  }, [patient]);

  const onSubmit = async (data: SearchFormDataType) => {
    onSubmitProp?.(data);
    onClose();
  };

  return (
    <Drawer
      title={t('title')}
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      footer={<Footer onClose={onClose} />}
    >
      <div className='flex flex-col flex-grow gap-6 p-4 sm:p-8'>
        {!isPatient && (
          <div className='flex flex-col gap-1'>
            <ApiSelectorField
              apiSearch
              url={buildUrl('patients', undefined, { include: 'user' })}
              name='patient'
              error={getError('patient', errors, errorT)}
              label={t('fields.search_patient')}
              buildOption={buildPatientOption}
              control={control as unknown as Control}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              resetField={resetField}
            />
          </div>
        )}

        {!!documentUrl && (
          <ApiSelectorField
            apiSearch
            multiple
            showSelectAll={false}
            url={buildUrl(documentUrl,undefined,{
              include: 'owner,_type'
            })}
            name='documents'
            error={getError('documents', errors, errorT)}
            label={t('fields.search_document')}
            buildOption={buildDocumentOption}
            control={control as unknown as Control}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resetField={resetField}
          />
        )}
      </div>
    </Drawer>
  );
};

export default SearchDocumentDrawer;
