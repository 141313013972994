import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, { useRef, useState } from 'react';
import { MEDIUM_GRAY } from '../messages/MessageComposer';
import styled from 'styled-components';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { useTranslation } from 'react-i18next';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import useToast from '@/utils/hooks/useToast';
import Modal from 'instamed-styleguide/ui/molecules/modal/Modal';
import { useMessageContext } from '../messages/MessageContext';

const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  padding: 3px;
`;

const ButtonIcon = styled(Button)`
  svg path {
   fill: white;
  }
  &:hover {
    svg path {
      fill: #072337;
    }
   }
`;

const AudioRecorder = () => {
  const { t } = useTranslation('chat');
  const { addToast } = useToast();
  const { files, setFiles } = useMessageContext();
  const [openStateModal, setOpenStateModal] = useState(false);

  const [isRecording, setIsRecording] = useState<boolean>(false);
  const mediaRecorder = useRef<MediaRecorder | (() => void)>();
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        return streamData;
      } catch (err) {
        addToast('danger', t('vocal_message.cannot_register'));
        return null;
      }
    } else {
      addToast('danger', t('vocal_message.cannot_register'));
      return null;
    }
  };

  const startRecording = async () => {
    const stream = await getMicrophonePermission();
    setIsRecording(true);

    if (stream) {
      const media = new MediaRecorder(stream as MediaStream, {
        mimeType: 'audio/webm',
      });

      mediaRecorder.current = media;
      mediaRecorder.current.start();
      const localAudioChunks: Blob[] = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === 'undefined') {
          return;
        }
        if (event.data.size === 0) {
          return;
        }
        localAudioChunks.push(event.data);
      };
      setAudioChunks(localAudioChunks);
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    //stops the recording instance
    (mediaRecorder?.current as MediaRecorder).stop();
    stopTracks();

    (mediaRecorder.current as MediaRecorder).onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
      //creates a playable URL from the blob file.
      setFiles({ ...files, audio: [...files.audio, audioBlob] });
      setOpenStateModal(false);
    };
  };

  const cancelRecording = () => {
    setIsRecording(false);
    (mediaRecorder?.current as MediaRecorder).stop();
    stopTracks();
    (mediaRecorder.current as MediaRecorder).onstop = () => {
      setAudioChunks([]);
    };
  };

  const stopTracks = () => {
    const tracks = (mediaRecorder?.current as MediaRecorder).stream?.getTracks();
    tracks?.forEach(track => track.stop());
  };

  return (
    <>
      <ButtonAction
        title={t('message_composer.vocal_message')}
        onClick={() => setOpenStateModal(true)}
      >
        <Icon icon='microphone' width={23}
          height={23} color={MEDIUM_GRAY} />
      </ButtonAction>
      {openStateModal && <Modal
        title={t('vocal_message.title')}
        onDismiss={() => setOpenStateModal(false)}
      >
        <div className='flex flex-col items-center mt-4 w-full'>
          {isRecording ? (
            <div className='flex flex-col items-center w-full'>
              {mediaRecorder?.current && (
                <LiveAudioVisualizer
                  mediaRecorder={mediaRecorder.current as MediaRecorder}
                  width={230}
                  height={100}
                />
              )}
              <div className='flex flex-row justify-end mt-3 space-x-2 w-full'>
                <Button onClick={cancelRecording} color='secondary'>
                  {t('vocal_message.stop')}
                </Button>

                <Button onClick={stopRecording} type='button'
                  color='primary'>
                  {t('vocal_message.send')}
                </Button>
              </div>
            </div>
          ) : (
            <ButtonIcon onClick={startRecording}
              type='button'
              color='primary' icon='microphone'>
              {t('vocal_message.start')}
            </ButtonIcon>
          )}
        </div>
      </Modal>}
    </>
  );
};

export default AudioRecorder;
