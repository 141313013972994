import React, { useCallback } from 'react';
import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import ApiSelectorField from '@/components/form/fields/api-selector';
import { buildUrl } from '@/utils/Api';
import { useForm } from 'react-hook-form';
import { OptionType } from '@ui/types';
import { useTranslation } from 'react-i18next';
import Drawer from '@/components/drawer/Drawer';
import useDrawerManager from '@/app/structure/drawer-manager/useDrawerManager';
import Button from 'instamed-styleguide/ui/atoms/buttons/button/Button';
import { MEDIUM_GRAY } from './MessageComposer';
import styled from 'styled-components';
import { useMessageContext } from './MessageContext';
import Patient, { buildOption } from '@/packages/back-end/patient';
import { isMedicalStaff } from '@/packages/back-end/user';
import { authAtom } from '@/services/store/store';
import { useRecoilValue } from 'recoil';
import { useDiscussions } from '@/app/structure/page/chat/ChatContext';
import { ROLE } from '@/packages/types';

const ButtonAction = styled.button`
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  padding: 3px;
`;

const TagPatientsDrawer = ({
  onClose,
  setPatient,
}: {
  onClose: () => void;
  setPatient: (patient: Patient | null) => void;
}) => {
  const { t } = useTranslation('chat');
  const { control, watch, resetField } = useForm();

  const patient: OptionType<Patient> | undefined = watch('patient');

  const handleValidatePatient = () => {
    if (patient?.data) {
      setPatient(patient.data);
      onClose();
    }
  };

  return (
    <Drawer title={t('message_composer.tag_patient')} onClose={onClose}>
      <div className='mx-4 mt-5' data-testid='tag_patient_form'>
        <ApiSelectorField<Patient>
          url={buildUrl('patients', undefined, {
            favorite: true,
            include: 'user',
            archived: false,
          })}
          label={t('tag_patient.select')}
          name={'patient'}
          helpText={t('tag_patient.subtitle')}
          apiSearch={true}
          control={control}
          buildOption={buildOption}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resetField={resetField}
        />
        <div className='flex flex-row justify-end mt-5 space-x-2 w-full'>
          <Button onClick={onClose} color='secondary'>
            {t('cancel')}
          </Button>
          <Button onClick={handleValidatePatient} type='button'
            color='primary' data-testid='tag_patient_submit'>
            {t('save')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

const useTagPatientsDrawer = () => {
  const { openDrawer, closeDrawer } = useDrawerManager();
  const { setPatient } = useMessageContext();
  const onClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const onClick = useCallback(() => {
    openDrawer({
      content: <TagPatientsDrawer onClose={onClose} setPatient={setPatient} />,
      size: 'basic',
    });
  }, [onClose, openDrawer, setPatient]);

  return { onClick };
};

const TagPatients = () => {
  const { t } = useTranslation('chat');
  const { onClick } = useTagPatientsDrawer();
  const { user } = useRecoilValue(authAtom);

  const { selectedDiscussion } = useDiscussions();

  const patient = selectedDiscussion?.relationships.participants.data.find((el) => el.attributes.role === ROLE.PATIENT);

  if (!isMedicalStaff(user)) {
    return null;
  }

  // Do not allow tagging a patient in a discussion with a patient
  if(patient) {
    return null;
  }


  return (
    <ButtonAction
      title={t('message_composer.tag_patient')}
      onClick={onClick}
      data-testid='tag_patient_button'
    >
      <Icon icon='hashtag' width={21}
        height={21} color={MEDIUM_GRAY} />
    </ButtonAction>
  );
};

export default TagPatients;
