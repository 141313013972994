import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UseFormWatch,
  UseFormRegister,
  UseFormTrigger,
  FieldValues,
  Path
} from 'react-hook-form';

import { STRENGTHS, RuleProps } from '@ui/organisms/password-changer/PasswordChanger';

import {
  RULES,
  getPasswordStrength,
  isValidPassword
} from './usePasswordValidator.utils';

export type UsePasswordValidatorProps<T> = {
  confirmName?: string;
  passwordName?: string;
  register: UseFormRegister<T>;
  watch: UseFormWatch<T>;
  getValues: (s: string) => string;
  translate?: (v: string) => string;
  trigger: UseFormTrigger<T>;
};

export type UsePasswordValidatorResponse = {
  rules: RuleProps[];
  strength: STRENGTHS;
  confirmStrength: STRENGTHS;
  register: UseFormRegister<FieldValues>;
};

const usePasswordValidator = <FormInput = FieldValues>({
  confirmName = 'confirm',
  passwordName = 'password',
  watch,
  getValues,
  register,
  trigger,
  translate
}: UsePasswordValidatorProps<FormInput>): UsePasswordValidatorResponse => {
  const { t } = useTranslation('auth', { keyPrefix: 'form.error.passwords' });

  const password = watch(passwordName as Path<FormInput>);
  const confirmValue = watch(confirmName as Path<FormInput>);

  const registerField = useCallback(
    (name, opts = {}) => {
      if (name == passwordName) {
        return {
          ...register(name, opts),
          onKeyUp: () => getValues(confirmName) && trigger(confirmName as Path<FormInput>)
        };
      }

      return register(
        name,
        {
          ...opts,
          deps: [passwordName],
          validate: {
            ['password.mismatch']: (val: string) => {
              return val === getValues(passwordName) || t('mismatch');
            },
            ['password.tooWeak']: (val) => isValidPassword(val as string) || t('tooWeak')
          },
        }
      );
    },
    [register, passwordName, getValues, t, trigger, confirmName]
  );

  const state = useMemo(
    () => {
      const rules = RULES.map(
        ({ label, reg }) => ({
          label: translate ? translate(label) : label,
          isValid: password && reg.test(password as string),
          isValidConfirm: confirmValue && reg.test(confirmValue as string)
        })
      );

      return {
        rules,
        strength: getPasswordStrength(rules.filter(v => v.isValid).length),
        confirmStrength: getPasswordStrength(rules.filter(v => v.isValidConfirm).length)
      };
    },
    [password, confirmValue, translate]
  );

  return { ...state, register: registerField };
};

export default usePasswordValidator;
