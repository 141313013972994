import pick from 'lodash/pick';

import { MenuItemType } from '@ui/molecules/menu-item/MenuItem';
import { MenuHeaderType } from '@ui/molecules/menu-header/MenuHeader';
import { isSubMenuType } from '@ui/molecules/menu/Menu';
import { itemsType } from '@ui/organisms/left-menu/LeftMenu';
import { OptionType } from '@ui/organisms/selectors/types';
import { ObjectStringString } from '@ui/types';

import { getPathName, getUrl, isWebappUrl } from '@/utils/router';
import { LegacyMe, InstitutionType, Badges } from '@/packages/types';
import getAssetURL from '@/utils/getAssetURL';
import { isAdminRole, isSupport } from '@/packages/back-end/user';

export const iconsMapping: ObjectStringString = {
  groups: 'group',
  person_add: 'personAdd',
  recent_actors: 'recentActors',
  event: 'outlineEvent',
  group_work: 'groupWork',
  edit_note: 'editNote',
  hourglass_top: 'hourglassTop',
  manage_accounts: 'manageAccounts',
  person_off: 'personOff',
  calendar_view_month: 'calendarViewMonth',
  pin_drop: 'location',
  coronavirus: 'virus',
  text_snippet: 'textSnippet',
  fact_check: 'factCheck',
  quiz: 'quizz',
  wifi_tethering: 'wifi',
  settings_applications: 'settings',
  local_hospital: 'hospital',
  switch_account: 'switch',
  assignment: 'assignement',
  notifications: 'notification',
  tips_and_updates: 'tips',
  monitor_heart: 'broken',
  admin_panel_settings: 'panelSettings',
  report_problem: 'reportProblem',
  list_alt: 'listAlt',
  difference: 'fileCopy',
  table_chart: 'tableChart',
  psychology: 'ai',
  credit_card: 'creditCard',
  file_present: 'filePresent',
  loyalty: 'subscription',
  account_balance: 'bank',
  support_agent: 'support',
  web: 'webPage',
  engineering: 'specialty',
  tab: 'tab',
  grid_view: 'section',
  view_list: 'field',
  attach_email: 'sendDocument',
  insights: 'statistics',
  help_center: 'help',
  chat: 'discussion',
  send: 'mail',
  mail: 'mailLock',
  folder_shared: 'shareHistory',
  on_device_training: 'mobileAppVersion',
  ballot: 'cerfaTemplates'
};

export const mapIcon = (icon: string) => {
  return iconsMapping[icon] || icon;
};

export const matchUrl = (route: string) => {
  const reg = new RegExp(route, 'g');
  return reg.test(getPathName());
};

type BackItemType = {
  label: string;
  key: string;
  icon: string;
  iconPath?: string;
  route: string;
  available: boolean;
  url: string;
}

export type BackItemsType = BackItemType & {
  items?: BackItemType[]
}

export type HistoryType = {
  push : (url : string) => void
}

const getBadge = (key : string, badges ?: Badges) : number | undefined => {
  if(!badges) {
    return undefined;
  }

  switch (key) {
    case 'instamed' :
      return badges.discussions.total;
    case 'mssante':
      return badges.mssante.total;
    default:
      return undefined;
  }

};

type FormatItemType = (
  args: BackItemsType,
  openUpgradeModal : () => void,
  history : HistoryType,
  closeMenu : () => void,
  badges?: Badges
) => MenuItemType;
export const formatItem: FormatItemType =
  (
    { icon,iconPath, route,key, url, label, available, ...rest },
    openUpgradeModal ,
    history,
    closeMenu,
    badges
  ) => {

    const actionIfAvailable = isWebappUrl(url) ?
      {
        href: url,
        onItemClick: () => {
          history.push(url);
          closeMenu();
        }
      } : {
        href: url
      };

    const actionData = available ? actionIfAvailable : { onItemClick: openUpgradeModal };

    const badge = getBadge(key,badges);

    return {
      ...rest,
      label,
      key,
      icon: mapIcon(icon),
      iconPath,
      highlightBadge: true,
      badge,
      selected: matchUrl(route),
      ...actionData
    };
  };

type FormatItemsType = (
  items: BackItemsType[],
  openUpgradeModal : () => void ,
  history : HistoryType,
  closeMenu : () => void,
  badges? : Badges
) => itemsType[];
export const formatItems: FormatItemsType = (items,openUpgradeModal,history,closeMenu,badges) => {
  return items.map((item) => {
    let newItem = formatItem(item,openUpgradeModal,history,closeMenu) as itemsType;
    if (isSubMenuType(item)) {
      newItem = {
        ...newItem,
        items: item.items.map((it) => {
          return formatItem(it,openUpgradeModal,history,closeMenu,badges);
        })
      };
    }

    return newItem;
  });
};

type FormatInstitutionListReturnType = OptionType & { selected: boolean; id: number };
type FormatInstitutionListType = (institutions: InstitutionType[]) => FormatInstitutionListReturnType[];
export const formatInstitutionList:FormatInstitutionListType = (institutions) => {
  return institutions.map(({ id, name, domain, current }) => {
    return {
      id,
      value: domain,
      label: name,
      icon: 'hospital',
      selected: current,
    };
  });
};


type FormatHeaderType = (user: LegacyMe) => Omit<MenuHeaderType, 'onInstitutionChange'|'onReduceClick'>;
export const formatHeader:FormatHeaderType = (user) => {
  const { institutions } = user;
  const list = formatInstitutionList(institutions);
  const current = list.filter((institution) => institution.selected);

  return {
    logo: {
      src: getAssetURL('assets/images/logo_small.svg'),
      link: getUrl()
    },
    institutions: isAdminRole(user.role) || isSupport(user.role) ? {
      list: list.length > 1 ? list : undefined,
      value: current[0] ? [pick(current[0], ['value', 'label', 'icon'])] : [],
    } : undefined
  };
};
