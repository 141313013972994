import React, { FC, lazy } from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import { ADMINS, HOSPITAL_STAFF, ROLE } from '@/packages/types';
import { URLS } from '@/app/structure/page/subscriptions/utils';
import { TYPE } from '@/packages/back-end/subscription-group';

const PatientBillingSubscription = lazy(() => import('@/app/structure/page/patient-billing/Subscription'));
const LiaCredit = lazy(() => import('@/app/structure/page/lia-credit/LiaCredit'));
const Debug = lazy(() => import('@/app/structure/page/debug/Debug'));

import getAdminRoutes from '@/app/structure/body/routes/Admin';
import getResearchRoutes from '@/app/structure/body/routes/Research';
import getPublicRoutes from '@/app/structure/body/routes/Public';
import getDeveloperRoutes from '@/app/structure/body/routes/Developer';
import getPatientRoutes from '@/app/structure/body/routes/Patient';
import getMedicalStaffRoutes from '@/app/structure/body/routes/MedicalStaff';
import getDiscussionsRoutes from '@/app/structure/body/routes/Discussions';
import getSharedRoutes from '@/app/structure/body/routes/Shared';
import Http404 from '@/components/errors/Http404';
import Home from '@/app/structure/page/home/Home';

export const DEBUG_ROUTE = '/v2/debug';

const getRoutes = () => {

  const data = [
    ...getPublicRoutes(),
    ...getMedicalStaffRoutes(),
    ...getDiscussionsRoutes(),
    ...getAdminRoutes(),
    ...getPatientRoutes(),
    ...getDeveloperRoutes(),
    ...getResearchRoutes(),
    ...getSharedRoutes(),
  ];

  data.push(<PrivateRoute
    exact={true}
    roles={HOSPITAL_STAFF}
    key={DEBUG_ROUTE}
    path={DEBUG_ROUTE} >
    <Debug />
  </PrivateRoute>);

  data.push(<PrivateRoute
    exact={true}
    roles={[...HOSPITAL_STAFF,...ADMINS,ROLE.PATIENT]}
    key={'v2-home'}
    path={'/v2/'} >
    <Home />
  </PrivateRoute>
  );



  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN]}
    key={URLS.SUBSCRIPTION(TYPE.patient_billing)}
    path={URLS.SUBSCRIPTION(TYPE.patient_billing)} >
    <PatientBillingSubscription />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN]}
    key={URLS.SUBSCRIPTION(TYPE.lia_credit)}
    path={URLS.SUBSCRIPTION(TYPE.lia_credit)} >
    <LiaCredit />
  </PrivateRoute>
  );

  return data;
};


const Body: FC<RouteComponentProps> = () => {

  return (
    <Switch>
      {getRoutes()}
      {<Http404 />}
    </Switch>
  );
};

export default withRouter(Body);
