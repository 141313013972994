import React, { FC, useMemo } from 'react';
import _ from 'lodash';

import TextField, { TextFieldProps } from '@ui/atoms/fields/text/TextField';
import { Control, useController } from 'react-hook-form';

type SocialSecurityNumberFieldProps = Omit<TextFieldProps, 'onChange'> & {
  control: Control;
  name: string;
};

const SocialSecurityNumberField: FC<SocialSecurityNumberFieldProps> = ({
  control,
  defaultValue,
  name,
  ...rest
}) => {
  const {
    field: { name: fieldName, value, onChange },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const displayValue = useMemo(() => {
    if (_.isNil(value)) {
      return '';
    }
    const charList = [...String(value).replaceAll(' ', '')];
    // X XX XX XX XXX XXX XX
    charList.splice(13, 0, ' ');
    charList.splice(10, 0, ' ');
    charList.splice(7, 0, ' ');
    charList.splice(5, 0, ' ');
    charList.splice(3, 0, ' ');
    charList.splice(1, 0, ' ');
    return charList.join('').trim();
  }, [value]);

  return (
    <TextField
      value={displayValue}
      name={fieldName}
      onChange={(event) => {
        const validValue = String(event.target.value)
          .split('')
          .filter((char) => char === ' ' || /\d/.test(char))
          .join('')
          .slice(0, 15 + 6);
        onChange({ ...event, target: { ...event.target, value: validValue } });
      }}
      {...rest}
    />
  );
};

export default SocialSecurityNumberField;
