import CounterIndicator from '@ui/atoms/containers/counter-indicator/CounterIndicator';
import { Pelet } from '@/app/structure/page/chat/left-components/Row';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { DropdownFilter } from '@/app/structure/page/chat/left-components/HeaderLeft';
import useBadge from '@/utils/hooks/useBadge';
import { UrlArg } from '@/utils/Api';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { authAtom } from '@/services/store/store';
import { isMedicalStaff, isParamed } from '@/packages/back-end/user';
import { DropdownItemProps } from '@ui/atoms/dropdown/DropdownItem';
import { useDiscussions } from '@/app/structure/page/chat/ChatContext';
import useFilterUsersDrawer from './UsersDrawer';
import _ from 'lodash';


type FilterDropdownProps = {
  queryParams: UrlArg,
  setQueryParams: (params: UrlArg) => void;
}

const defaultFiltersValue = {
  trash: null,
  archived: null,
  _read: null, 
  participant_type: null,
  inbox: null,
  users: null,
};

const FilterDropdown: FC<FilterDropdownProps> = ({ setQueryParams, queryParams }) => {

  const { t } = useTranslation('chat');

  const { user } = useRecoilValue(authAtom);

  const { discussions: badge } = useBadge();

  const { setLoadingDiscussions,setDiscussions } = useDiscussions();

  const { onClick: openInfosDrawer } = useFilterUsersDrawer();

  const hasFilter = queryParams?.archived === true ||
    queryParams?.trash === true || queryParams?.users ||
    typeof queryParams?.participant_type === 'string' ||
    queryParams?.archived === 'true' ||
    (queryParams?._read === false);


  const update = useCallback((queryParams) => {

    setLoadingDiscussions(true);
    setQueryParams(queryParams);
    setDiscussions([]);

  },[setDiscussions,setLoadingDiscussions,setQueryParams]);


  const dropdownItems = useMemo(() => {

    const items: DropdownItemProps[] = [{
      children: (<span className='flex items-center'>
        {t('filters.all')}
      </span>),
      className: queryParams?.archived === false && queryParams?.trash === null ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, archived: false }),
    }, {
      children: (<span className='flex items-center'>
        {t('filters.not_read')}
        {!!badge.ungrouped && (<CounterIndicator className='ml-2' counter={badge.ungrouped} />)}
      </span>),
      className: queryParams?._read === false ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, _read: false }),
    },
    isParamed(user, true) ? {
      children: (<span className='flex items-center'>
        {t('filters.cc')}
      </span>),
      className: queryParams?.participant_type === 'cc' ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, participant_type: 'cc' }),
    } : {},
    isParamed(user, true) ? {
      children: (<span className='flex items-center'>
        {t('filters.direct')}
      </span>),
      className: queryParams?.participant_type === 'direct' ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, participant_type: 'direct' }),
    } : {},
    isMedicalStaff(user) ? {
      children: (<span className='flex items-center'>
        {t('filters.by_users')}
      </span>),
      className: (queryParams?.users as string[])?.length > 0 ? 'text-[#333c9d]' : '',
      onClick: () => openInfosDrawer({ filterByUsers: true,
        updateFilters: (users: string[]) => update({ ...defaultFiltersValue, users }) }),
    } : {},
    {
      children: (<span className='flex items-center'>
        {t('filters.archived')}
        {!!badge.archived && (<CounterIndicator className='ml-2' counter={badge.archived} />)}
      </span>),
      className: queryParams?.archived === true ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, archived: true }),
    },
    isMedicalStaff(user) ? {
      children: (<span className='flex items-center'>
        {t('filters.deleted')}
        {!!badge.trash && (<CounterIndicator className='ml-2' counter={badge.trash} />)}
      </span>),
      className: queryParams?.trash === true ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, trash: true }),
    } : {},
    isMedicalStaff(user) ? {
      children: (<span className='flex items-center'>
        {t('filters.sent_messages')}
        {!!badge.trash && (<CounterIndicator className='ml-2' counter={badge.trash} />)}
      </span>),
      className: queryParams?.inbox !== null && queryParams?.inbox === false ? 'text-[#333c9d]' : '',
      onClick: () => update({ ...defaultFiltersValue, inbox: false }),
    } : {},
    ];

    return items;

  }, [t, update, queryParams, badge, user, openInfosDrawer]);


  return (<DropdownFilter
    color='transparent'
    className='relative'
    popperClassName='!z-50'
    innerClassName='!gap-0'
    iconSize={20}
    iconHoverColor='#072337'
    iconColor='#9ba6ae'
    icon='filter'
    title={t('filter')}
    items={dropdownItems?.filter(el => !_.isEmpty(el))}
  >
    {hasFilter ? (<Pelet className='absolute right-[-5px] bottom-[-5px]' />) as ReactNode : ''}
  </DropdownFilter>);

};

export default FilterDropdown;