import Icon from 'instamed-styleguide/ui/atoms/icons/icon/Icon';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContainerBox from '@ui/atoms/containers/container-box/ContainerBox';
import DateField from '@/components/form/fields/date/DateField';
import TimeField from '@/components/form/fields/time/TimeField';
import { useForm } from 'react-hook-form';
import Button from '@ui/atoms/buttons/button/Button';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { createPortal } from 'react-dom';

type ScheduleModalProps = {
  onCancel : () => void;
  onAccept : (value : Date) => void;
}

type FormProps = FieldValues & {
  date : Date,
  time : string
}

const getDefaultValues = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 5) * 5;
  const adjustedDate = new Date(now);

  if (roundedMinutes >= 60) {
    adjustedDate.setHours(now.getHours() + 1);
    adjustedDate.setMinutes(0);
  } else {
    adjustedDate.setMinutes(roundedMinutes);
  }

  // Format the time string with zero-padding if needed
  const hours = adjustedDate.getHours();
  const formattedMinutes = adjustedDate.getMinutes().toString().padStart(2, '0');
  const timeString = `${hours}:${formattedMinutes}`;

  return {
    date: adjustedDate,
    time: timeString
  };
};

const ScheduleModal : FC<ScheduleModalProps> = ({ onCancel, onAccept }) => {

  const { t } = useTranslation('chat',{
    keyPrefix: 'program'
  });

  const { control,handleSubmit } = useForm<FormProps>({
    defaultValues: getDefaultValues()
  });

  const footer = (<>
    <Button
      onClick={onCancel}
      type={'button'}
      color='secondary' >{t('cancel')}</Button>
    <Button
      type='submit'
      color='primary' >{t('submit')}</Button>
  </>);

  const onSubmit = useCallback(({ date,time }) => {

    const [hours, minutes] = time.split(':').map(Number);

    date.setHours(hours);
    date.setMinutes(minutes);

    onAccept(date);

  },[onAccept]);

  return (<ContainerBox
    className='shadow-md'
    containerClassName='p-8'
    onSubmit={handleSubmit(onSubmit)}
    title={t('title')}
    footer={footer}
  >
    <div className='flex flex-col sm:flex-row gap-2'>
      <DateField
        minDate={new Date()}
        className='flex-grow'
        name='date'
        inline={false}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
      />
      <TimeField
        className='mt-4 sm:mt-0 w-full sm:w-28'
        name='time'
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control={control}
      />
    </div>
  </ContainerBox>);

};

const ScheduleButton = ({
  disabled,
  onSubmit,
}: {
  disabled?: boolean;
  onSubmit?: (date: string) => void;
}) => {
  const { t } = useTranslation('chat');
  const [openSchedule, setOpenOpenSchedule] = useState(false);
  const currentDate = new Date();
  currentDate.setMinutes(currentDate.getMinutes() + 5);

  return (
    <div>
      <button
        className='flex flex-row items-center'
        onClick={() => setOpenOpenSchedule(!disabled)}
        title={t('message_composer.schedule_message')}
      >
        <Icon icon='clock' color='white'
          className='mr-1' />
        <Icon icon='chevronUp' color='white'
          width={15} height={15} />
      </button>
      {openSchedule && (
        createPortal(<div className='absolute right-4 sm:right-24 bottom-24 left-4 sm:left-auto z-10'>
          <ScheduleModal
            onAccept={(value : Date) => {
              setOpenOpenSchedule(false);
              onSubmit?.(value?.toISOString());
            }}
            onCancel={() => setOpenOpenSchedule(false)}

          />
        </div>,document.querySelector('body') as HTMLElement)
      )}
    </div>
  );
};

export default ScheduleButton;
