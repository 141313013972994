import React, { lazy } from 'react';

import { ROUTES } from '@/app/structure/body/routes/MedicalStaff.utils';
import { MedicProfileProvider } from '@/app/structure/page/staff/profile/MedicProfileContext';
import PrivateRoute from '@/auth/core/private-route/PrivateRoute';
import { HOSPITAL_STAFF, ROLE } from '@/packages/types';

const MedicalStaffDashboard = lazy(() => import('@/app/structure/page/staff/dashboard/Dashboard'));
const Subscriptions = lazy(() => import('@/app/structure/page/subscriptions/Subscriptions'));
const MedicalRecordFields = lazy(() => import('@/app/structure/page/staff/medical-record-fields/MedicalRecordFields'));
const PatientsList = lazy(() => import('@/app/structure/page/staff/patients-list/PatientsList'));
const PatientShared = lazy(() => import('@/app/structure/page/staff/patient-shared/PatientShared'));
const MedicStatistics = lazy(() => import('@/app/structure/page/staff/statistics/Statistics'));
const SendFiles = lazy(() => import('@/app/structure/page/medics/send-files/SendFiles'));
const MedicProfile = lazy(() => import('@/app/structure/page/staff/profile/Profile'));
const ManageDuplicatePatients =
  lazy(() => import('@/app/structure/page/staff/manage-duplicate-patients/ManageDuplicatePatients'));
const MedicalTeam = lazy(() => import('@/app/structure/page/staff/medical-team/MedicalTeam'));

const DocumentTemplates = lazy(() => import('@/app/structure/page/staff/document-templates/DocumentTemplates'));
const EditCerfaTemplate =
  lazy(() => import('@/app/structure/page/staff/document-templates/edit-cerfa-template/EditCerfaTemplate'));

const getRoutes = () => {

  const data = [];

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.COORDINATOR, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.medical_record_fields}
    path={ROUTES.medical_staff.medical_record_fields}>
    <MedicalRecordFields />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.COORDINATOR, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.profile}
    path={ROUTES.medical_staff.profile}>
    <MedicProfileProvider>
      <MedicProfile />
    </MedicProfileProvider>
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.COORDINATOR, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.statistics}
    path={ROUTES.medical_staff.statistics}>
    <MedicStatistics />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={HOSPITAL_STAFF}
    key={ROUTES.medical_staff.cerfa_templates.list}
    path={ROUTES.medical_staff.cerfa_templates.list}>
    <DocumentTemplates />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    roles={HOSPITAL_STAFF}
    exact={true}
    key={ROUTES.medical_staff.cerfa_templates.update}
    path={ROUTES.medical_staff.cerfa_templates.update}>
    <EditCerfaTemplate />
  </PrivateRoute>
  );


  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.COORDINATOR, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.patients}
    path={ROUTES.medical_staff.patients}>
    <PatientsList />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.COORDINATOR, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.patient_shared}
    path={ROUTES.medical_staff.patient_shared}>
    <PatientShared />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.PARAMEDICAL, ROLE.ASSISTANT, ROLE.COORDINATOR]}
    key={ROUTES.medical_staff.dashboard}
    path={ROUTES.medical_staff.dashboard}>
    <MedicalStaffDashboard />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.subscriptions}
    path={ROUTES.medical_staff.subscriptions}>
    <Subscriptions />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={HOSPITAL_STAFF}
    key={ROUTES.medical_staff.send_files}
    path={ROUTES.medical_staff.send_files}>
    <SendFiles />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN, ROLE.PARAMEDICAL, ROLE.ASSISTANT, ROLE.COORDINATOR]}
    key={ROUTES.medical_staff.manage_merge_patients}
    path={ROUTES.medical_staff.manage_merge_patients}>
    <ManageDuplicatePatients />
  </PrivateRoute>
  );

  data.push(<PrivateRoute
    exact={true}
    roles={[ROLE.MEDECIN,ROLE.COORDINATOR,ROLE.PARAMEDICAL]}
    key={ROUTES.medical_staff.medical_team}
    path={ROUTES.medical_staff.medical_team}>
    <MedicalTeam />
  </PrivateRoute>
  );


  return data;
};

export default getRoutes;