import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';

import Email from '@/packages/back-end/mssante/email';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';

import Checkbox from '@ui/atoms/fields/checkbox/Checkbox';
import Icon from '@ui/atoms/icons/icon/Icon';

import { useEmails } from '../EmailsContext';
import { formatDate, renderHighlightedText } from '../../chat/utils';
import { EMAIL_URL } from '../email-template';
import ArchiveButton from '../action-buttons/ArchiveButton';
import ReadButton from '../action-buttons/ReadButton';
import { Pelet } from '@/app/structure/page/chat/left-components/Row';
import cls from 'classnames';
import DeleteButton from '@/app/structure/page/mssante/action-buttons/DeleteButton';

type RowProps = {
  className?: string;
  email: Email;
  isFocused: boolean;
  search?: string;
  onClick?: () => void;
};

const EmailsRow = ({
  className,
  email,
  isFocused,
  search,
  onClick,
}: RowProps) => {
  const { emails, setEmails, setSelectedEmail, onUpdateEmail,impersonatedUser } = useEmails();

  const [isRowHover, setIsRowHover] = useState(false);

  const [checked, setChecked] = useState<boolean>(email.selected);

  const isRead = !!email?.attributes?.read;

  const { fetchData: editEmail } = useJsonAPIRequest<{
    data: Email;
  }>({
    url: EMAIL_URL({ email, impersonated: impersonatedUser }),
    method: 'PUT',
    skip: true,
  });

  const background = isFocused
    ? 'bg-[#ebf2fb]'
    : isRead
      ? 'bg-[#ecedef]'
      : 'bg-white';


  const renderDate = useMemo(() => formatDate(email.attributes.createdDate), [email.attributes.createdDate]);

  useEffect(() => {
    setChecked(email?.selected);
  }, [email?.selected]);

  const onChangeCheckbox = () => {
    setChecked(!checked);
    setEmails(emails.map((item) => {
      if (item.id !== email.id) {
        return item;
      }
      return { ...item, selected: !item.selected };
    }));
  };

  const markAsRead: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    setSelectedEmail(email);
    onClick?.();

    if (isRead) {
      return;
    }

    const res = await editEmail({ read: true });
    const updatedEmail = (res as { data: Email })?.data;
    if (updatedEmail) {
      onUpdateEmail(updatedEmail);
    }
  };

  return (
    <div
      className={`flex flex-row justify-between w-full min-h-[82px] ${background} ${
        checked ? '!bg-gray-300' : background
      } p-2 ${className}`}
      onMouseEnter={() => setIsRowHover(true)}
      onMouseLeave={() => setIsRowHover(false)}
    >
      <div className='flex flex-row justify-start w-full'>
        <div className='flex flex-col items-center mt-[-5px] mr-3'>
          <Checkbox
            name={`row-checkbox-${email.id}`}
            checked={!!checked}
            onChange={onChangeCheckbox}
          />
          {!isRead && (<Pelet />)}
        </div>
        <div
          className='flex overflow-hidden flex-row w-full'
        >
          <button
            onClick={markAsRead}
            className='flex overflow-hidden flex-col w-8/12 xl:w-9/12'>
            <span
              className={`${
                isRead ? 'font-base' : 'font-semibold'
              } truncate block w-full text-left`}
            >
              {email.attributes.from.name ? email.attributes.from.name : email.attributes.from.email}
            </span>
            {renderHighlightedText(
              email.attributes.subject,
              search,
              `text-sm ${isRead ? 'font-base' : 'font-medium'} text-left`
            )}
            {renderHighlightedText(
              email.attributes.bodyText || '',
              search,
              'text-sm text-left truncate'
            )}
          </button>
          <div
            className='flex flex-col items-end w-4/12 xl:w-3/12'>
            <button
              onClick={markAsRead}
              className='flex flex-col items-end'>
              <span className='text-xs text-gray-900'>
                {renderDate}
              </span>
              {!!email.attributes.attachmentCount && (
                <span style={{ transform: 'rotate(39deg)' }}>
                  <Icon
                    icon='attachment'
                    width={15}
                    height={15}
                    color='#072338'
                  />
                </span>
              )}
            </button>
            <div className={cls('flex mt-1',isRowHover ? '' : 'invisible')} >
              <DeleteButton email={email} iconSize={16} />
              <ArchiveButton email={email} iconSize={16} />
              <ReadButton email={email} iconSize={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailsRow;
