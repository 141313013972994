import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Control,
  UseFormWatch,
  UseFormResetField,
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form/dist/types/form';
import useUploadRequest, { buildFormData } from '@/services/request/useUploadRequest';
import { PARSE_WITH_IA } from '@/packages/back-end/document';
import UploadedFile from '@/packages/back-end/uploaded-file';
import useToast from '@/utils/hooks/useToast';
import { useTranslation } from 'react-i18next';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { RequestError, STATUS_CODE } from '@/services/request/useRequest';
import Patient from '@/packages/back-end/patient';

export type UseUploadAiDocumentReturn = {
  control: Control;
  files: File[];
  register: UseFormRegister<FieldValues>;
  setFiles: Dispatch<SetStateAction<File[]>>;
  onSubmit: () => void;
  setValue : UseFormSetValue<FieldValues>;
  saving: boolean;
  patient?: Patient;
  errors: FieldErrors;
  watch: UseFormWatch<FieldValues>,
  resetField: UseFormResetField<FieldValues>;
}

type useUploadAiDocumentProps = {
  patient?: Patient,
  closeDrawer: () => void;
}

const useUploadAiDocument = ({ closeDrawer, patient }: useUploadAiDocumentProps): UseUploadAiDocumentReturn => {
  const [files, setFiles] = useState<File[]>([]);

  const { t } = useTranslation('medical_staff', {
    keyPrefix: 'right_bar.ai_drawer'
  });

  const { addToast } = useToast();

  const { loading: parsing, fetchData: parse } = useJsonAPIRequest({
    url: PARSE_WITH_IA(),
    method: 'post',
    skip: true,
  });

  const { loading: uploading, fetchData: upload } = useUploadRequest();

  const { control,setValue, register, handleSubmit, formState: { errors }, watch, resetField } = useForm();

  const onSubmit = useCallback(async ({ patient: selectedPatient, sync }: FieldValues) => {

    const response = await upload(buildFormData(files), {
      throw: true,
      displayToastOnError: true
    }) as UploadedFile | UploadedFile[];

    const fileUrls = Array.isArray(response) ? response.map((item) => item.key) : [response.key];
    const fileNames = Array.isArray(response) ? response.map((item) => item.name) : [response.name];

    const isAsync = sync !== true;

    try {
      await parse({
        user: patient?.relationships?.user?.data?.id ?? selectedPatient.data.relationships.user.data.id,
        async: isAsync,
        files: fileUrls,
        fileNames
      }, {
        throw: true,
        displayToastOnError: true
      });

      if (isAsync) {
        addToast('success', t('toasts.success'));
      } else {
        addToast('success', t('toasts.success_sync'));
      }
      closeDrawer();
    } catch (e) {
      if ((e as RequestError).code === STATUS_CODE.PAYMENT_REQUIRED) {
        return;
      }

      throw e;

    }
  }, [files, parse, patient, closeDrawer, upload, t, addToast]);

  return {
    control,
    register,
    setValue,
    patient,
    saving: uploading || parsing,
    onSubmit: handleSubmit(onSubmit),
    files, setFiles, errors, watch, resetField
  };
};

export default useUploadAiDocument;