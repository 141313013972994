import { useCallback, useEffect } from 'react';
import useRequest, { UseRequestProps } from '@/services/request/useRequest';
import { useRecoilState } from 'recoil';

import { Badges } from '@/packages/types';
import { buildUrl } from '@/utils/Api';

const URL = buildUrl('badges');

import { badgeState, defaultBadgeState } from '@/services/store/store';
import useMercure from '@/services/request/useMercure';

export type useBadge = (args?: Omit<UseRequestProps, 'url'>) => Badges;

const useBadge: useBadge = (props = {}) => {
  const [badges,setBadges] = useRecoilState(badgeState);

  const { called, data } = useRequest<Badges>({
    ...props,
    url: URL,
    abortable: true,
  });

  const onMercureMessage = useCallback((data : Badges) => {

    setBadges(data);
  }, [setBadges]);

  useMercure<Badges>('badges', onMercureMessage);

  useEffect(
    () => {
      if (!called) {
        return;
      }

      setBadges(data as Badges);
    },
    [data, setBadges, called]
  );

  return badges ?? defaultBadgeState;
};

export default useBadge;
