import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Notification from '@/packages/back-end/notification';

import Button from '@ui/atoms/buttons/button/Button';
import useJsonAPIRequest from '@/services/request/useJsonAPIRequest';
import { buildUrl } from '@/utils/Api';
import { Item } from '@/packages/back-end/jsonapi';

export type ReadButtonType = {
  archived?: boolean;
  item: Notification;
  onUpdated?: (item: Notification) => void;
  onDeleted?: (item: Notification) => void;
};

const ReadButton: FC<ReadButtonType> = ({ archived, item, onUpdated, onDeleted }) => {
  const { t } = useTranslation('ui', { keyPrefix: 'notifications' });

  const { loading, fetchData: updateNotification } = useJsonAPIRequest({
    skip: true,
    url: buildUrl('notifications', item.attributes._id),
    method: 'PUT',
  });

  const updateRead = useCallback(async () => {
    try {
      const payload: Record<string, boolean> = { read: !item.attributes.read };
      if (!payload.read) {
        payload.archived = false;
      }
      const response = await updateNotification(
        payload,
        { throw: true, displayToastOnError: true }
      ) as Item<Notification>;

      if (response?.data) {
        // Item was archived but mark as unread, it will become unarchived
        // -> delete it from archived list
        if (archived && !payload.read) {
          onDeleted?.(item);
        } else {
          onUpdated?.(response?.data);
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }, [archived, item, onUpdated, onDeleted, updateNotification]);

  return (
    <Button
      saving={loading}
      title={item.attributes.read ? t('mark_as_unread') : t('mark_as_read')}
      color='transparent'
      iconColor='#9ba6ae'
      iconHoverColor='#072337'
      icon={item.attributes.read ? 'messageCloud' : 'check'}
      onClick={(event) => {
        event.stopPropagation();
        updateRead();
      }}
      data-testid='mark_as_read_button'
    />
  );
};

export default ReadButton;
